import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BALANCE, ErrorType, LIQUIDITY, UseLendErrorProps } from "./types";

export default function useLendError({
  debouncedLendInputBn,
  underlyingBalanceBn,
  valueAtMaturityIsError,
  valueAtMaturityIsLoading,
}: UseLendErrorProps): string {
  const [error, setError] = useState<ErrorType | "">("");

  const { t } = useTranslation();

  const errorsMsgs = {
    [BALANCE]: t("errors.balanceExceeded"),
    [LIQUIDITY]: t("errors.insufficientLiquidity"),
  };

  useEffect(() => {
    const newErrors: (ErrorType | "")[] = [];

    if (!valueAtMaturityIsLoading) {
      if (valueAtMaturityIsError) {
        newErrors.push(LIQUIDITY);
      }
      if (debouncedLendInputBn && underlyingBalanceBn && debouncedLendInputBn.gt(underlyingBalanceBn)) {
        newErrors.push(BALANCE);
      }

      if (!newErrors.length) {
        setError("");
      } else if (!newErrors.includes(error)) {
        setError(newErrors[0]);
      }
    }
  }, [debouncedLendInputBn, underlyingBalanceBn, valueAtMaturityIsError, error, valueAtMaturityIsLoading]);

  return error ? errorsMsgs[error] : "";
}
