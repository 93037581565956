import { BigNumber } from "ethers";

export type UseLendErrorProps = {
  debouncedLendInputBn: BigNumber | undefined;
  underlyingBalanceBn: BigNumber | undefined;
  valueAtMaturityIsError: boolean;
  valueAtMaturityIsLoading: boolean;
};

// TODO: do this refactor everywhere (define as value instead of type)
export const BALANCE = "BALANCE";
export const LIQUIDITY = "LIQUIDITY";

export type ErrorType = typeof BALANCE | typeof LIQUIDITY;
