import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { HifiPool } from "../../api/getHifiPools/types";
import calcAPY from "../../utils/calcAPY";
import { InlineDottyProgress } from "../../components/DottyProgress";
import Box from "@mui/material/Box";
import TokenIcon from "../../icons/TokenIcon";
import Typography from "@mui/material/Typography";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import useQuoteForBuyingUnderlying from "../../hooks/useQuoteForBuyingUnderlying";
import useHifiPoolTotalLiquidityInUnderlying from "../../hooks/useHifiPoolTotalLiquidityInUnderlying";
import IconTypography from "../../components/IconTypography";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import isExpired from "../../utils/isExpired";

export default function BorrowRow({
  hifiPool,
  onMarketClick,
}: {
  hifiPool: HifiPool;
  onMarketClick: (hifiPool: HifiPool) => void;
}): JSX.Element {
  const { hToken } = hifiPool;
  const symbol = hToken.underlying.symbol as TokenSymbol;

  const {
    data: underlyingSpotPrice,
    status: underlyingSpotPriceStatus,
    isLoading: underlyingSpotPriceIsLoading,
  } = useQuoteForBuyingUnderlying({ hifiPool, underlyingOut: "1" });

  const { data: poolLiquidity, status: poolLiquidityStatus } = useHifiPoolTotalLiquidityInUnderlying(hifiPool);

  return (
    <TableRow hover onClick={() => onMarketClick(hifiPool)}>
      <TableCell align="left">
        <IconTypography variant="h5">
          <Box display="flex" alignItems="center">
            <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          </Box>
          {symbol}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{new Date(hToken.maturity * 1000).toLocaleDateString()}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {!isExpired(hifiPool) && underlyingSpotPriceIsLoading && <InlineDottyProgress />}
          {isExpired(hifiPool) && <span>&ndash;</span>}
          {!isExpired(hifiPool) &&
            underlyingSpotPriceStatus === "success" &&
            !!underlyingSpotPrice &&
            `${(calcAPY(1, parseFloat(underlyingSpotPrice), hifiPool.maturity * 1000) * 100).toFixed(2)}%`}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {poolLiquidityStatus === "loading" && <InlineDottyProgress />}
          {isExpired(hifiPool) && <span>&ndash;</span>}
          {!isExpired(hifiPool) &&
            poolLiquidityStatus === "success" &&
            !!poolLiquidity &&
            poolLiquidity > 0 &&
            `$${truncateDecimals(numberWithCommas(poolLiquidity), 2)}`}
          {poolLiquidityStatus === "success" && poolLiquidity === 0 && <span>&ndash;</span>}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
