import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { formatUnits } from "@ethersproject/units";
import { EventFilter } from "ethers";
import { useEffect, useRef } from "react";
import { QueryObserverOptions, QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import { v4 as uuidv4 } from "uuid";
import getAccountErc20Balance from "../../api/getAccountErc20Balance";
import { Erc20Token } from "../../api/getErc20/types";
import { getErc20Contract } from "../../utils/contracts";
import { buildQueryKey } from "../useAccountErc20Balance";
import useMemoRef from "../useMemoRef";

const listeners: { [key: string]: { from: EventFilter; to: EventFilter } } = {};
const registry: { [address: string]: { [uuid: string]: true } } = {};

export function buildQueryObject({
  tokenAddress,
  provider,
  account,
  options = {},
}: {
  tokenAddress: string;
  account: string;
  provider?: JsonRpcProvider;
  options?: QueryObserverOptions;
}): UseQueryOptions {
  return {
    queryKey: buildQueryKey({ account, tokenAddress }),
    queryFn: () => {
      return getAccountErc20Balance({ erc20Address: tokenAddress, account });
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!account && !!provider,
    ...options,
  };
}

export default function useUserErc20Balance({
  token,
  account,
  walletProvider,
}: {
  token: Erc20Token | undefined;
  account: string;
  walletProvider: Web3Provider | undefined;
}): QueryObserverResult<string> {
  const uuid = useRef(uuidv4());
  const erc20Contract = walletProvider && token && getErc20Contract({ address: token.id, provider: walletProvider });

  const {
    refetch,
    data: bal,
    status,
    ...others
  } = useQuery(
    buildQueryKey({ account, tokenAddress: token ? token.id : "" }),
    () => {
      if (token) return getAccountErc20Balance({ erc20Address: token.id, account });
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!account && !!token,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  if (erc20Contract && !listeners[erc20Contract.address] && walletProvider) {
    const from = erc20Contract.filters.Transfer(account, null);
    const to = erc20Contract.filters.Transfer(null, account);
    listeners[erc20Contract.address] = { to, from };
    walletProvider.on(from, () => {
      setTimeout(async () => {
        // I suspect that the updated balance is not quite ready yet in the wallet;
        // so wait a bit before fetching balance.
        try {
          // Wrap with try/catch in case the user changes the account they are on; seems that this should
          // be handled by the walletProvider.removeAllListeners(evt) below
          await refetch();
          // eslint-disable-next-line
        } catch (e) {}
      }, 2000);
    });
    walletProvider.on(to, () => {
      setTimeout(async () => {
        // I suspect that the updated balance is not quite ready yet in the wallet;
        // so wait a bit before fetching balance.
        try {
          // Wrap with try/catch in case the user changes the account they are on; seems that this should
          // be handled by the walletProvider.removeAllListeners(evt) below
          await refetch();
          // eslint-disable-next-line
        } catch (e) {}
      }, 2000);
    });
  }

  useEffect(() => {
    if (token) {
      if (!registry[token.id]) {
        registry[token.id] = {};
      }
      registry[token.id][uuid.current] = true;
      const uid = uuid.current;
      return () => {
        delete registry[token.id][uid];
        if (!Object.keys(registry[token.id]).length && walletProvider) {
          try {
            const { to, from } = listeners[token.id];
            walletProvider.removeAllListeners(to);
            walletProvider.removeAllListeners(from);

            // eslint-disable-next-line
          } catch (e) {}
          delete listeners[token.id];
        }
      };
    }
  }, [account, walletProvider, token]);

  const _data = useMemoRef(
    lastValue => {
      if (status === "success" && bal && token) {
        return formatUnits(bal, token.decimals);
      }
      return lastValue;
    },
    [bal, status, token],
  );

  return { refetch, data: _data, status, ...others } as QueryObserverResult<string>;
}
