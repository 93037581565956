import { memo } from "react";
import Table, { TableContainer } from "../../components/Table";
import BorrowRow from "../../containers/BorrowRow";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableHeader from "../../components/TableHeader";
import { HifiPool } from "../../api/getHifiPools/types";
import { useTranslation } from "react-i18next";

function BorrowMarketsTable({
  markets,
  title,
  onMarketClick,
}: {
  markets: HifiPool[];
  title: string;
  onMarketClick: (hifiPool: HifiPool) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <TableHeader variant="body2">{title}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body1" color="textSecondary">
                  {t("Asset")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Maturity Date")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("APY")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Liquidity")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!markets &&
              markets
                .sort((a, b) => a.maturity - b.maturity)
                .map(hifiPool => {
                  return <BorrowRow onMarketClick={onMarketClick} hifiPool={hifiPool} key={hifiPool.hToken.id} />;
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(BorrowMarketsTable);
