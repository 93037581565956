import { useCallback, useContext, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import MaxInputWithBalance from "../../components/MaxInputWithBalance";
import TokenIcon from "../../icons/TokenIcon";
import { Web3Provider } from "@ethersproject/providers";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { Collateral } from "../../api/getCollaterals/types";
import useUserLockedCollaterals from "../../hooks/useUserLockedCollaterals";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { InlineDottyProgress } from "../../components/DottyProgress";
import DialogContentArea from "../../components/DialogContentArea";
import useUserHypotheticalBorrowLimit from "../../hooks/useUserHypotheticalBorrowLimit";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import { Context as TransactionsContext, LAST_RESULT } from "contexts/transactionsBase";
import { depositCollateral, createDsProxy } from "../../utils/transactions";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../utils/analytics";
import { useProxyTargetContract } from "../../hooks/contracts";
import { getContractAddress, isTokenPermissive, isWeth } from "../../contracts/addresses";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import { AddressZero } from "@ethersproject/constants";
import FormHelperText from "@mui/material/FormHelperText";
import DialogDataRow from "../../components/DialogDataRow";
import useDepositError from "../../hooks/useDepositError";
import shouldSkipDSProxyConfirmations from "../../utils/shouldSkipDSProxyConfirmations";
import { displayBorrowLimitChange, displayCurrentSupplyChange } from "../ManageCollateralDialog";
import useAccountErc20Balance from "hooks/useAccountErc20Balance";
import useUserEthBalance from "hooks/useUserEthBalance";

export default function DepositEthTab({
  onCloseClick,
  collateral,
  account,
  walletProvider,
  chainName,
  setDepositInput,
  depositInput,
  setPendingTxId,
}: {
  onCloseClick: () => void;
  collateral: Collateral;
  account: string;
  walletProvider: Web3Provider | undefined;
  chainName: string;
  setDepositInput: React.Dispatch<React.SetStateAction<string>>;
  depositInput: string;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element {
  const { t } = useTranslation();

  const { addTransactions, isTransactionQueued } = useContext(TransactionsContext);

  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });

  // Fetch: balance, locked collateral, collateral allowance, withdrawable collateral, total debt, collateral limit ------|
  const { data: collateralBalance, status: collateralBalanceStatus } = useUserEthBalance({
    walletProvider,
    account,
  });
  const {
    data: lockedCollaterals,
    refetch: refetchLockedCollaterals,
    isFetching: lockedCollateralsIsFetching,
  } = useUserLockedCollaterals({ chainName, account });
  const { data: balanceSheetBalance, status: balanceSheetBalanceStatus } = useAccountErc20Balance({
    tokenAddress: collateral.id,
    account: getContractAddress(chainName, "balanceSheet"),
  });
  // Refresh the user's locked collateral (query is cached by default).  We do this in case the user was
  // liquidated and no longer has the locked collateral indicated by the previous query.  This helps
  // to prevent the user from submitting a tx that will fail and waste their ETH.
  useEffect(() => {
    refetchLockedCollaterals();
  }, [refetchLockedCollaterals]);
  // --------------------------------------------------------------------------------------------------------------------|

  // Value converstions --------------------------------------------------------------------------------------------|
  const depositInputFloat = parseFloat(depositInput);
  const depositInputBn = useMemo(() => {
    if (!depositInput) return;
    try {
      return parseUnits(depositInput, collateral.decimals);
      // eslint-disable-next-line
    } catch (e) {}
  }, [depositInput, collateral]);
  // ---------------------------------------------------------------------------------------------------------------|

  // Borrow limit fetches ------------------------------------------------------------------------------------------|
  const { data: borrowLimit, status: borrowLimitStatus } = useUserHypotheticalBorrowLimit({
    chainName,
    account,
  });

  const { data: newBorrowLimit, status: newBorrowLimitStatus } = useUserHypotheticalBorrowLimit({
    chainName,
    account,
    collateralModify: collateral,
    collateralAmountModify: depositInput || "0",
  });
  // ---------------------------------------------------------------------------------------------------------------|

  // Error messages -----------------------------------------------------------------------------------------------|
  const insufficientDepositFunds = useMemo(() => {
    if (collateralBalance && depositInputBn) {
      return parseUnits(collateralBalance, collateral.decimals).lt(depositInputBn);
    }
    return false;
  }, [depositInputBn, collateral, collateralBalance]);

  const depositError = useDepositError({
    insufficientDepositFunds,
    isLoading: collateralBalanceStatus === "loading" || balanceSheetBalanceStatus === "loading",
    depositInput,
    balanceSheetBalance,
    collateral,
  });
  // --------------------------------------------------------------------------------------------------------------|

  const depositButtonDisabled =
    !depositInputFloat ||
    depositInputFloat < 0 ||
    !!depositError ||
    collateralBalanceStatus === "loading" ||
    balanceSheetBalanceStatus === "loading";

  const proxyTargetContract = useProxyTargetContract();
  const onDepositSubmit = useCallback(
    e => {
      e.preventDefault();

      if (depositButtonDisabled || !proxyTargetContract || !dsProxyAddress || !depositInputBn) return;

      const txs = [];
      // If DSProxy has already been created, or if there is a create DSProxy tx queued up, then skip
      // DSProxy creation tx.
      if (
        dsProxyAddress === AddressZero &&
        !isTransactionQueued({
          methodName: "build()",
          contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
        })
      ) {
        txs.push(
          createDsProxy({
            contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
            description: t("transactions.dsProxy"),
            skipConfirmations: shouldSkipDSProxyConfirmations(chainName),
          }),
        );
      }

      const callbackArgs = { collateralAddress: collateral.id };

      logEvent("depositClick", {
        depositInput,
      });
      txs.push(
        depositCollateral({
          contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}` : dsProxyAddress,
          depositInput: depositInputBn,
          collateralAddress: collateral.id,
          proxyTargetContract,
          balanceSheetAddress: getContractAddress(chainName, "balanceSheet"),
          isWeth: isWeth(chainName, collateral.id),
          description: t("transactions.deposit.description", {
            amount: numberWithCommas(depositInput),
            token: collateral.symbol,
          }),
          completionMessage: t("transactions.deposit.completionMessage", {
            amount: numberWithCommas(depositInput),
            token: collateral.symbol,
          }),
          hasSignature: isTokenPermissive(chainName, collateral.id),
          callbackArgs,
        }),
      );
      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Deposit Collateral"),
      });
      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      addTransactions,
      chainName,
      collateral.id,
      collateral.symbol,
      depositInput,
      depositInputBn,
      dsProxyAddress,
      isTransactionQueued,
      proxyTargetContract,
      depositButtonDisabled,
      t,
      setPendingTxId,
    ],
  );

  return (
    <form onSubmit={onDepositSubmit}>
      <DialogContentArea>
        <MaxInputWithBalance
          tokenSymbol={collateral.symbol}
          autoFocus
          balance={collateralBalance && truncateDecimals(collateralBalance, 6)}
          error={!!depositError}
          balanceLabel={t("Wallet")}
          fullWidth
          style={{ fontSize: "1.75em" }}
          value={depositInput}
          onChange={(e: React.ChangeEvent) => setDepositInput((e.target as HTMLTextAreaElement).value)}
          max={collateralBalance}
          inputProps={{
            min: 0,
          }}
          decimals={collateral.decimals}
          startAdornment={
            <InputAdornment position="start">
              <TokenIcon style={{ fontSize: "1.15em" }} symbol={collateral.symbol as TokenSymbol} />
            </InputAdornment>
          }
        />
        {!!depositError && (
          <Typography variant="body1">
            <FormHelperText error>{depositError}</FormHelperText>
          </Typography>
        )}
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Currently Supplying")}
          </Typography>
          <Typography variant="h6" style={{ overflowWrap: "anywhere" }}>
            {lockedCollateralsIsFetching && <InlineDottyProgress />}
            {!lockedCollateralsIsFetching &&
              lockedCollaterals &&
              displayCurrentSupplyChange({
                currentValue: lockedCollaterals[collateral.id],
                newValue: formatUnits(
                  parseUnits(lockedCollaterals[collateral.id] || "0", collateral.decimals).add(
                    parseUnits(depositInput || "0", collateral.decimals),
                  ),
                  collateral.decimals,
                ),
                symbol: collateral.symbol,
              })}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Borrow Limit")}
          </Typography>
          <Typography variant="h6" style={{ overflowWrap: "anywhere" }}>
            {(borrowLimitStatus === "loading" || newBorrowLimitStatus === "loading") && <InlineDottyProgress />}
            {borrowLimitStatus !== "loading" &&
              newBorrowLimitStatus !== "loading" &&
              displayBorrowLimitChange({
                currentValue: borrowLimit,
                newValue: newBorrowLimit,
                currentValueStatus: borrowLimitStatus,
                newValueStatus: newBorrowLimitStatus,
              })}
          </Typography>
        </DialogDataRow>
      </DialogContentArea>
      <DialogContentArea>
        <Button
          disabled={depositButtonDisabled}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
        >
          {t("Deposit Collateral")}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </form>
  );
}
