import SvgIcon from "@mui/material/SvgIcon";
import { LogoProps } from "./types";

export default function Logo({ style }: LogoProps): JSX.Element {
  const heightStyle = { height: "auto" };
  const styles = { ...heightStyle, ...style };
  return (
    <SvgIcon viewBox="0 0 677 237" style={styles}>
      <path
        d="M114.356 190.626L190.626 114.356C195.688 109.306 202.546 106.47 209.696 106.47C216.846 106.47 223.705 109.306 228.766 114.356C233.816 119.418 236.653 126.276 236.653 133.426C236.653 140.576 233.816 147.435 228.766 152.496L152.496 228.766C147.435 233.816 140.576 236.653 133.426 236.653C126.276 236.653 119.418 233.816 114.356 228.766C109.306 223.705 106.47 216.846 106.47 209.696C106.47 202.546 109.306 195.688 114.356 190.626ZM7.88626 122.296C12.9475 127.343 19.8036 130.178 26.9513 130.178C34.0989 130.178 40.955 127.343 46.0163 122.296L122.296 46.0163C127.343 40.955 130.178 34.0989 130.178 26.9513C130.178 19.8036 127.343 12.9475 122.296 7.88626C117.235 2.83615 110.376 0 103.226 0C96.0761 0 89.2179 2.83615 84.1563 7.88626L7.88626 84.1563C2.83615 89.2179 0 96.0761 0 103.226C0 110.376 2.83615 117.235 7.88626 122.296ZM89.7063 146.916C94.768 151.966 101.626 154.803 108.776 154.803C115.926 154.803 122.785 151.966 127.846 146.916L146.916 127.846C151.966 122.785 154.803 115.926 154.803 108.776C154.803 101.626 151.966 94.768 146.916 89.7063C141.855 84.6562 134.996 81.82 127.846 81.82C120.696 81.82 113.838 84.6562 108.776 89.7063L89.7063 108.776C84.6562 113.838 81.82 120.696 81.82 127.846C81.82 134.996 84.6562 141.855 89.7063 146.916Z"
        fill="white"
      />
      <path
        d="M433.526 28.8562H457.906V210.386H433.526V128.176H338.616V210.386H313.976V28.8562H338.616V106.916H433.526V28.8562Z"
        fill="white"
      />
      <path
        d="M520.396 53.4962V28.8562H495.506V53.4962H520.396ZM519.616 210.376V80.2062H496.286V210.376H519.616Z"
        fill="white"
      />
      <path
        d="M586.016 80.1962V62.8262C586.016 52.1962 591.726 45.7162 600.526 45.7162C607.716 45.7162 611.906 49.2862 613.746 53.4962H632.576C631.218 45.9008 627.244 39.02 621.345 34.0467C615.446 29.0733 607.992 26.3208 600.276 26.2662C577.716 26.2662 562.676 41.2662 562.676 63.8662V80.1962H541.916V99.8962H562.666V210.386H586.016V99.8962H651.956V210.386H675.296V80.1962H586.016ZM651.186 28.8562V53.4962H676.076V28.8562H651.186Z"
        fill="white"
      />
    </SvgIcon>
  );
}
