import { QueryObserverResult, useQuery } from "react-query";
import getHifiPools from "../../api/getHifiPools";
import { HifiPool } from "../../api/getHifiPools/types";

export default function useHifiPools(chainName: string): QueryObserverResult<HifiPool[]> {
  return useQuery(["hifiPools", chainName], () => getHifiPools(chainName), {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
