import { Zero } from "@ethersproject/constants";
import { Web3Provider } from "@ethersproject/providers";
import useAccountErc20Balance from "hooks/useAccountErc20Balance";
import { BigNumber } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { MutationStatus, QueryStatus } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import isExpired from "../../utils/isExpired";
import useMemoRef from "hooks/useMemoRef";
import useQuoteForSellingHToken from "../useQuoteForSellingHToken";
import useQuoteForSellingUnderlying from "../useQuoteForSellingUnderlying";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import useUserBurnParams from "../useUserBurnParams";

export function getStatus({
  lpTokensStatus,
  burnParamsStatus,
  poolHTokensStatus,
  hTokensInUnderlyingStatus,
  poolHTokensInUnderlyingStatus,
  poolUnderlyingInHTokensStatus,
  poolUnderlyingStatus,
  hifiPool,
}: {
  lpTokensStatus: QueryStatus;
  burnParamsStatus: QueryStatus;
  poolHTokensStatus: QueryStatus;
  hTokensInUnderlyingStatus: QueryStatus;
  poolHTokensInUnderlyingStatus: QueryStatus;
  poolUnderlyingInHTokensStatus: QueryStatus;
  poolUnderlyingStatus: QueryStatus;
  hifiPool: HifiPool;
}): QueryStatus {
  let status: QueryStatus = "idle";
  if (
    lpTokensStatus === "error" ||
    burnParamsStatus === "error" ||
    poolHTokensStatus === "error" ||
    poolUnderlyingStatus === "error" ||
    (!isExpired(hifiPool) &&
      hTokensInUnderlyingStatus === "error" &&
      poolHTokensInUnderlyingStatus === "error" &&
      poolUnderlyingInHTokensStatus === "error")
  ) {
    status = "error";
  } else if (
    lpTokensStatus === "loading" ||
    burnParamsStatus === "loading" ||
    poolHTokensStatus === "loading" ||
    hTokensInUnderlyingStatus === "loading" ||
    poolHTokensInUnderlyingStatus === "loading" ||
    poolUnderlyingInHTokensStatus === "loading" ||
    poolUnderlyingStatus === "loading"
  ) {
    status = "loading";
  } else if (
    lpTokensStatus === "success" &&
    burnParamsStatus === "success" &&
    (isExpired(hifiPool) ||
      hTokensInUnderlyingStatus === "success" ||
      poolHTokensInUnderlyingStatus === "success" ||
      poolUnderlyingInHTokensStatus === "success")
  ) {
    status = "success";
  }
  return status;
}

export function useUserPoolLiquidityInUnderlying({
  account,
  hifiPool,
  lpTokenModifyAmount = Zero,
  walletProvider,
  chainName,
}: {
  account: string;
  hifiPool: HifiPool;
  lpTokenModifyAmount?: BigNumber;
  walletProvider?: Web3Provider;
  chainName: string;
}): { status: MutationStatus; data: number | undefined } {
  const { status: lpTokensStatus, data: lpTokens } = useUserErc20Balance({
    token: hifiPool,
    walletProvider,
    account,
  });

  const { data: burnParams, status: burnParamsStatus } = useUserBurnParams({
    account,
    chainName,
    hifiPool,
    poolTokensBurned:
      lpTokens && walletProvider ? parseUnits(lpTokens, hifiPool.decimals).add(lpTokenModifyAmount) : void 0,
  });

  // 1) First attempt to get a quote for selling the hTokens returned to user after burning lp tokens
  const { data: hTokensInUnderlying, status: hTokensInUnderlyingStatus } = useQuoteForSellingHToken({
    hTokenIn:
      burnParams && walletProvider && !isExpired(hifiPool)
        ? formatUnits(burnParams.hTokenReturned, hifiPool.hToken.decimals)
        : "",
    hifiPool,
  });

  // 2) If 1) fails, then attempt to get quote for selling the number of hTokens in the pool
  //   - Then we get the number of hTokens in the pool
  //   - Then we request a quote for selling the hTokens
  const { status: poolHTokensStatus, data: poolHTokens } = useAccountErc20Balance({
    tokenAddress: hifiPool.hToken.id,
    account: walletProvider && !isExpired(hifiPool) && hTokensInUnderlyingStatus === "error" ? hifiPool.id : "",
  });
  const { data: poolHTokensInUnderlying, status: poolHTokensInUnderlyingStatus } = useQuoteForSellingHToken({
    hTokenIn:
      poolHTokens && walletProvider && !isExpired(hifiPool) && hTokensInUnderlyingStatus === "error"
        ? formatUnits(poolHTokens, hifiPool.hToken.decimals)
        : "",
    hifiPool,
  });

  // 3) If 2) fails then we need to get a quote for selling the number of underlying inside the pool.
  //   - First we get the amount of underlying in the pool
  //   - Then we get a quote for selling the underlying
  const { data: poolUnderlying, status: poolUnderlyingStatus } = useAccountErc20Balance({
    tokenAddress: hifiPool.hToken.underlying.id,
    account: walletProvider && !isExpired(hifiPool) && poolHTokensInUnderlyingStatus === "error" ? hifiPool.id : "",
  });
  const { data: poolUnderlyingInHTokens, status: poolUnderlyingInHTokensStatus } = useQuoteForSellingUnderlying({
    underlyingIn:
      poolUnderlying && walletProvider && !isExpired(hifiPool)
        ? formatUnits(poolUnderlying, hifiPool.hToken.underlying.decimals)
        : "",
    hifiPool,
  });

  return useMemoRef(
    lastValue => {
      const status = getStatus({
        lpTokensStatus,
        burnParamsStatus,
        poolHTokensStatus,
        hTokensInUnderlyingStatus,
        poolHTokensInUnderlyingStatus,
        poolUnderlyingInHTokensStatus,
        poolUnderlyingStatus,
        hifiPool,
      });
      let data = lastValue?.data;

      if (status === "success" && burnParams?.underlyingReturned) {
        const underlyingReturnedFloat = parseFloat(
          formatUnits(burnParams.underlyingReturned, hifiPool.hToken.underlying.decimals),
        );
        const hTokensReturnedFloat = parseFloat(formatUnits(burnParams.hTokenReturned, hifiPool.hToken.decimals));

        if (isExpired(hifiPool)) {
          data = hTokensReturnedFloat + underlyingReturnedFloat;
        } else {
          const hTokensInUnderlyingFloat = hTokensInUnderlying ? parseFloat(hTokensInUnderlying) : void 0;

          const poolHTokensFloat = poolHTokens
            ? parseFloat(formatUnits(poolHTokens, hifiPool.hToken.decimals))
            : void 0;

          const poolHTokensInUnderlyingFloat = poolHTokensInUnderlying ? parseFloat(poolHTokensInUnderlying) : void 0;

          const poolUnderlyingInHTokensFloat = poolUnderlyingInHTokens ? parseFloat(poolUnderlyingInHTokens) : void 0;

          const poolUnderlyingFloat = poolUnderlying
            ? parseFloat(formatUnits(poolUnderlying, hifiPool.hToken.underlying.decimals))
            : void 0;

          let _hTokensInUnderlying = hTokensInUnderlyingFloat;

          if (!_hTokensInUnderlying && poolHTokensInUnderlyingFloat && poolHTokensFloat) {
            // In the case of 2), we estimate the price of hTokens with (poolHTokensFloat / poolHTokensInUnderlyingFloat)
            _hTokensInUnderlying = (poolHTokensFloat / poolHTokensInUnderlyingFloat) * hTokensReturnedFloat;
          } else if (poolUnderlyingInHTokensFloat && poolUnderlyingFloat) {
            // In the case of 3), we estimate the price of hTokens with (poolUnderlyingInHTokensFloat / poolUnderlyingFloat)
            // _hTokensInUnderlying = (poolUnderlyingInHTokensFloat / poolUnderlyingFloat) * hTokensReturnedFloat;
            _hTokensInUnderlying = (poolUnderlyingFloat / poolUnderlyingInHTokensFloat) * hTokensReturnedFloat;
          }
          // if (_hTokensInUnderlying && poolHTokensFloat) {
          //   let ratio = 1;
          //   if (hTokensReturnedFloat > poolHTokensFloat) {
          //     ratio = hTokensReturnedFloat / poolHTokensFloat;
          //   }
          //   data = _hTokensInUnderlying * ratio + underlyingReturnedFloat;
          // } else {
          //   data = 0;
          // }
          if (typeof _hTokensInUnderlying === "number") {
            data = _hTokensInUnderlying + underlyingReturnedFloat;
          } else {
            data = 0;
          }
        }
      }

      return {
        data,
        status,
      };
    },
    [
      hifiPool,
      burnParamsStatus,
      hTokensInUnderlying,
      hTokensInUnderlyingStatus,
      lpTokensStatus,
      poolHTokens,
      poolHTokensStatus,
      burnParams,
      poolHTokensInUnderlying,
      poolHTokensInUnderlyingStatus,
      poolUnderlying,
      poolUnderlyingInHTokens,
      poolUnderlyingInHTokensStatus,
      poolUnderlyingStatus,
    ],
  );
}
