import { MulticallBatcher } from "contexts/web3Base";
import { getHTokenMulticallContract } from "../../utils/contracts";
import { BigNumber } from "ethers";
import { formatUnits } from "@ethersproject/units";
import { HToken } from "../getHifiPools/types";

const getHTokenDepositorBalance = async ({
  hToken,
  depositor,
}: {
  hToken: HToken;
  depositor: string;
}): Promise<string> => {
  const hTokenContract = getHTokenMulticallContract({ address: hToken.id });
  const balance: BigNumber = await MulticallBatcher.queue(hTokenContract.getDepositorBalance(depositor));
  return formatUnits(balance, hToken.underlying.decimals);
};

export default getHTokenDepositorBalance;
