import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import classnames from "classnames";

const PREFIX = "SnackbarTimerBar";

const classes = {
  timerBar: `${PREFIX}-timerBar`,
  fullWidth: `${PREFIX}-fullWidth`,
};

type RootProps = {
  delay: number;
};

const Root = styled("div")<RootProps>(({ delay }) => ({
  [`&.${classes.timerBar}`]: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    height: "6px",
    width: "100%",
    backgroundColor: "#838383",
    transition: `width ${delay}s linear`,
  },

  [`&.${classes.fullWidth}`]: {
    width: "0% !important",
  },
}));

export default function SnackbarTimerBar({ delay }: { delay: number }): JSX.Element {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  return <Root delay={delay} className={classnames(classes.timerBar, { [classes.fullWidth]: rendered })} />;
}
