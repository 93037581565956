import { useEffect, useContext, useRef, useCallback } from "react";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/LaunchOutlined";
import SnackbarTimerBar from "../../components/SnackbarTimeBar";

const DELAY = 30;

const DismissAction = ({ snackKey }: { snackKey: string }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      sx={{ color: "text.secondary", padding: "8px" }}
      onClick={() => {
        // @ts-ignore
        closeSnackbar(snackKey);
      }}
      size="large"
    >
      <CloseOutlined />
    </IconButton>
  );
};

const action = (key: string) => {
  return <DismissAction snackKey={key} />;
};

export default function useTransactionAlerts({ chainName, account }: { chainName: string; account: string }): void {
  const { getTransactions } = useContext(TransactionsContext);
  const localStorageShownTxs = window.localStorage.getItem("shownTxs");
  const shownTxs = useRef<Record<string, boolean>>(localStorageShownTxs ? JSON.parse(localStorageShownTxs) : {});
  const pendingTxs = useRef<Record<string, boolean>>({});
  const txs = getTransactions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let etherscanDomain = chainName !== "homestead" ? `${chainName}.etherscan.io` : "etherscan.io";
  if (chainName === "matic") {
    etherscanDomain = "polygonscan.com";
  }

  const saveStateToLocalStorage = useCallback(() => {
    window.localStorage.setItem("shownTxs", JSON.stringify(shownTxs.current));
  }, [shownTxs]);

  useEffect(
    function beforeunload() {
      try {
        window.addEventListener("beforeunload", saveStateToLocalStorage);

        return () => window.removeEventListener("beforeunload", saveStateToLocalStorage);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    [saveStateToLocalStorage],
  );

  useEffect(() => {
    // Close all alerts when switching accounts
    Object.keys(shownTxs.current).forEach(txId => {
      try {
        closeSnackbar(txId);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    });
  }, [account, closeSnackbar]);

  useEffect(() => {
    txs.forEach(tx => {
      if (tx.requiredConfirmations > tx.confirmations) {
        pendingTxs.current = { ...pendingTxs.current, [tx.id]: true };
      }
      if (!shownTxs.current[tx.id] && pendingTxs.current[tx.id]) {
        if (tx.requiredConfirmations <= tx.confirmations && !tx.error && tx.completionMessage) {
          setTimeout(() => {
            enqueueSnackbar(
              <Box width={350} display="flex">
                {!tx.txHash && <Typography variant="body1">{tx.description}</Typography>}
                {!!tx.txHash && (
                  <Link
                    color="textPrimary"
                    target="_blank"
                    variant="body1"
                    underline="hover"
                    href={`https://${etherscanDomain}/tx/${tx.txHash}`}
                  >
                    {tx.completionMessage}
                    <LaunchIcon style={{ verticalAlign: "middle", fontSize: "1.2rem", marginLeft: ".3em" }} />
                  </Link>
                )}
                <SnackbarTimerBar delay={DELAY} />
              </Box>,
              {
                key: tx.id,
                variant: "success",
                action,
                autoHideDuration: DELAY * 1000,
                disableWindowBlurListener: true,
              },
            );
          }, 200);
          shownTxs.current = { ...shownTxs.current, [tx.id]: true };
        } else if (tx.error) {
          setTimeout(() => {
            enqueueSnackbar(
              <Box width={350}>
                {!tx.txHash && <Typography variant="body1">{tx.description}</Typography>}
                {!!tx.txHash && (
                  <Link
                    color="textPrimary"
                    target="_blank"
                    variant="body1"
                    underline="hover"
                    href={`https://${etherscanDomain}/tx/${tx.txHash}`}
                  >
                    {tx.description}

                    <LaunchIcon style={{ verticalAlign: "middle", fontSize: "1.2rem", marginLeft: ".3em" }} />
                  </Link>
                )}
                <Typography style={{ overflowWrap: "anywhere" }} color="textSecondary" variant="subtitle1">
                  {tx.errorMessage.substring(0, 250)}
                </Typography>
                <SnackbarTimerBar delay={DELAY} />
              </Box>,
              {
                key: tx.id,
                variant: "error",
                action,
                autoHideDuration: DELAY * 1000,
                disableWindowBlurListener: true,
              },
            );
            shownTxs.current = { ...shownTxs.current, [tx.id]: true };
          }, 200);
        }
      }
    });
  }, [txs, enqueueSnackbar, etherscanDomain, closeSnackbar]);
}
