import { useMemo, useContext } from "react";
import { Context as Web3Context, DEFAULT_NETWORK } from "../../contexts/web3";
import abis from "../../contracts/abis";
import { getContractAddress } from "../../contracts/addresses";
import { UseContractProps } from "./types";
import { getContract } from "utils/contracts";
import { Contract } from "@ethersproject/contracts";

/* Returns null on errors */
export function useContract({ abi, address }: UseContractProps): Contract | null {
  const { provider } = useContext(Web3Context);
  return useMemo(() => {
    return getContract({ abi, address, provider });
  }, [abi, address, provider]);
}

export function useContractByName(contractName: string): Contract | null {
  const { chainName } = useContext(Web3Context);
  return useContract({
    abi: abis[contractName],
    address: getContractAddress(chainName || DEFAULT_NETWORK, contractName),
  });
}

export function useBalancerExchangeProxyContract(): Contract | null {
  return useContractByName("balancerExchangeProxy");
}

export function useErc20ContractByName(contractName?: string): Contract | null {
  const { chainName } = useContext(Web3Context);
  return useContract({
    abi: abis.erc20,
    address: contractName ? getContractAddress(chainName || DEFAULT_NETWORK, contractName) : "",
  });
}

export function useErc20Contract(address: string): Contract | null {
  return useContract({
    abi: abis.erc20,
    address,
  });
}

export function useProxyTargetContract(): Contract | null {
  return useContractByName("proxyTarget");
}
