import { BigNumber } from "ethers";

export const OWED = "OWED";
export const BALANCE = "BALANCE";
export const LIQUIDITY = "LIQUIDITY";

export type ErrorType = typeof OWED | typeof BALANCE | typeof LIQUIDITY;

export type UsePaybackErrorProps = {
  debouncedPaybackInputBn?: BigNumber;
  debtInUnderlyingBn?: BigNumber;
  underlyingBalanceBn?: BigNumber;
  hTokensRepaidIsError: boolean;
  hTokensRepaidIsLoading: boolean;
};
