import abis from "../../contracts/abis";
import { getContractAddress } from "../../contracts/addresses";
import { getContract } from "utils/contracts";
import { Web3Provider } from "@ethersproject/providers";
import { AddressZero } from "@ethersproject/constants";

const getUserDSProxy = async ({
  account,
  chainName,
  walletProvider,
}: {
  account: string;
  chainName: string;
  walletProvider?: Web3Provider;
}): Promise<string> => {
  if (walletProvider) {
    const dsProxyRegistryContract = getContract({
      abi: abis.dsProxyRegistry,
      address: getContractAddress(chainName, "dsProxyRegistry"),
      provider: walletProvider,
    });
    if (dsProxyRegistryContract) {
      return (await dsProxyRegistryContract.proxies(account)).toLowerCase();
    }
  }
  return AddressZero as string;
};

export default getUserDSProxy;
