import { styled } from "@mui/material/styles";

const PREFIX = "DialogContentArea";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& + &": {
      marginTop: theme.spacing(8),
    },
  },
}));

export default function DialogContentArea({
  children,
}: {
  children: (JSX.Element | boolean)[] | JSX.Element | boolean;
}): JSX.Element {
  return <Root className={classes.root}>{children}</Root>;
}
