import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { QueryObserverResult } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import isExpired from "../../utils/isExpired";
import useQuoteForBuyingHToken from "../useQuoteForBuyingHToken";

export default function useDebtInUnderlying({
  hifiPool,
  balance,
}: {
  hifiPool: HifiPool;
  balance: string;
}): QueryObserverResult<BigNumber | undefined> {
  const {
    data: debtInUnderlying,
    status: debtInUnderlyingStatus,
    ...others
  } = useQuoteForBuyingHToken({
    hifiPool,
    hTokenOut: balance,
  });

  return {
    // If calculating the debt denominated in underlying results in an error, meaning there is
    // insufficient liquidity in the AMM, then just return the outstanding balance in HTokens.
    data:
      debtInUnderlyingStatus === "error" || isExpired(hifiPool)
        ? parseUnits(balance, hifiPool.hToken.decimals).div(hifiPool.underlyingPrecisionScalar)
        : debtInUnderlying && parseUnits(debtInUnderlying, hifiPool.hToken.underlying.decimals),
    status: debtInUnderlyingStatus === "error" || isExpired(hifiPool) ? "success" : debtInUnderlyingStatus,
    ...others,
  } as QueryObserverResult<BigNumber | undefined>;
}
