import { useCallback, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
// @ts-ignore
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import MaxInputWithBalance from "../../components/MaxInputWithBalance";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { HifiPool } from "../../api/getHifiPools/types";
import DialogContentArea from "../../components/DialogContentArea";
import { Context as TransactionsContext, LAST_RESULT } from "contexts/transactionsBase";
import useDebounce from "hooks/useDebounce";
import calcAPY from "../../utils/calcAPY";
import { InlineDottyProgress } from "../../components/DottyProgress";
import { parseUnits } from "@ethersproject/units";
import FormHelperText from "@mui/material/FormHelperText";
import { multiplyBigNumberByScalar, numberWithCommas, truncateDecimals } from "utils/numbers";
import { BigNumber } from "ethers";
import useQuoteForSellingUnderlying from "../../hooks/useQuoteForSellingUnderlying";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { Web3Provider } from "@ethersproject/providers";
import useLendError from "../../hooks/useLendError";
import useAllowance from "hooks/useAllowance";
import { approve, createDsProxy, permit, sellUnderlying } from "../../utils/transactions";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../utils/analytics";
import { useProxyTargetContract } from "../../hooks/contracts";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import DialogDataRow from "../../components/DialogDataRow";
import { AddressZero } from "@ethersproject/constants";
import shouldSkipDSProxyConfirmations from "../../utils/shouldSkipDSProxyConfirmations";
import { getContractAddress, isTokenPermissive } from "../../contracts/addresses";
import { Context as TosContext } from "@hifi/react-tos-modal";

export default function LendTab({
  onCloseClick,
  hifiPool,
  account,
  chainName,
  walletProvider,
  connect,
  connecting,
  setPendingTxId,
  setLendInput,
  lendInput,
}: {
  onCloseClick: () => void;
  hifiPool: HifiPool;
  account: string;
  chainName: string;
  walletProvider?: Web3Provider;
  connect: () => void;
  connecting: boolean;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
  setLendInput: React.Dispatch<React.SetStateAction<string>>;
  lendInput: string;
}): JSX.Element {
  const { t } = useTranslation();

  const { addTransactions, isTransactionQueued } = useContext(TransactionsContext);

  const { userAccepted, invokeModal } = useContext(TosContext);

  const { debouncedValue: debouncedLendInput, loading: loadingDebouncedLendInput } = useDebounce(lendInput);

  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });
  const { data: underlyingAllowance, isLoading: underlyingAllowanceIsLoading } = useAllowance({
    token: hifiPool.hToken.underlying,
    spenderAddress: dsProxyAddress,
    account,
  });

  const {
    data: valueAtMaturity,
    status: valueAtMaturityStatus,
    isFetching: valueAtMaturityIsFetching,
    isError: valueAtMaturityIsError,
    isLoading: valueAtMaturityIsLoading,
  } = useQuoteForSellingUnderlying({
    hifiPool,
    underlyingIn: debouncedLendInput,
  });

  const { data: underlyingBalance } = useUserErc20Balance({
    token: hifiPool.hToken.underlying,
    account,
    walletProvider,
  });

  // Value conversions and cals -------------------------------------------------------------------------------------|
  const debouncedLendInputFloat = parseFloat(debouncedLendInput);
  const debouncedLendInputBn = useMemo(() => {
    if (debouncedLendInput) {
      return parseUnits(debouncedLendInput, hifiPool.hToken.underlying.decimals);
    }
  }, [debouncedLendInput, hifiPool]);

  const underlyingBalanceBn = useMemo(() => {
    if (underlyingBalance) {
      return parseUnits(underlyingBalance, hifiPool.hToken.underlying.decimals);
    }
  }, [underlyingBalance, hifiPool]);

  // const hTokensRepaidBn = useMemo(() => {
  //   if (hTokensRepaid) {
  //     return parseUnits(hTokensRepaid, hifiPool.hToken.decimals);
  //   }
  // }, [hTokensRepaid, hifiPool]);

  const valueAtMaturityBn = useMemo(() => {
    if (valueAtMaturity) {
      return parseUnits(valueAtMaturity, hifiPool.hToken.decimals);
    }
  }, [valueAtMaturity, hifiPool]);
  // --------------------------------------------------------------------------------------------------------------|

  // Loading, disabled, and error calcs ---------------------------------------------------------------------------|
  const lendInputErrorMsg = useLendError({
    debouncedLendInputBn,
    underlyingBalanceBn,
    valueAtMaturityIsError,
    valueAtMaturityIsLoading,
  });

  const loadingLend = loadingDebouncedLendInput || valueAtMaturityIsFetching || underlyingAllowanceIsLoading;

  const isLendSubmitDisabled =
    loadingLend || !debouncedLendInputBn || debouncedLendInputBn.lte(BigNumber.from("0")) || !!lendInputErrorMsg;
  // --------------------------------------------------------------------------------------------------------------|

  const proxyTargetContract = useProxyTargetContract();
  const onLendSubmit = useCallback(
    e => {
      e.preventDefault();

      if (!account) {
        if (userAccepted) {
          connect();
          return;
        } else {
          invokeModal(() => {
            connect();
          });
          return;
        }
      }

      if (
        isLendSubmitDisabled ||
        !proxyTargetContract ||
        !debouncedLendInputBn ||
        !valueAtMaturityBn ||
        !dsProxyAddress
      )
        return;

      const txs = [];

      // If DSProxy has already been created, or if there is a create DSProxy tx queued up, then skip
      // DSProxy creation tx.
      if (
        dsProxyAddress === AddressZero &&
        !isTransactionQueued({
          methodName: "build()",
          contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
        })
      ) {
        txs.push(
          createDsProxy({
            contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
            description: t("transactions.dsProxy"),
            skipConfirmations: shouldSkipDSProxyConfirmations(chainName),
          }),
        );
      }

      if (isTokenPermissive(chainName, hifiPool.hToken.underlying.id)) {
        txs.push(
          permit({
            contractAddress: hifiPool.hToken.underlying.id,
            description: t("transactions.messages.allowance", {
              spender: "Hifi",
              token: hifiPool.hToken.underlying.symbol,
            }),
            ownerAddress: account,
            spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
            amount: debouncedLendInputBn.toString(),
          }),
        );
      } else {
        if (
          underlyingAllowance === "0" &&
          !isTransactionQueued({ methodName: "approve", contractAddress: hifiPool.hToken.underlying.id })
        ) {
          txs.push(
            approve({
              description: t("transactions.messages.allowance", {
                spender: "Hifi",
                token: hifiPool.hToken.underlying.symbol,
              }),
              contractAddress: hifiPool.hToken.underlying.id,
              spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
              callbackArgs: {
                spenderAddress: "DSPROXY_ADDRESS",
                tokenAddress: hifiPool.hToken.underlying.id,
              },
            }),
          );
        }
      }

      logEvent("lendClick", {
        lendInput: debouncedLendInput,
        hTokenAddress: hifiPool.hToken.id,
      });

      txs.push(
        sellUnderlying({
          contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}-1` : dsProxyAddress,
          description: t("transactions.lend.description", {
            lendAmount: numberWithCommas(debouncedLendInput),
            token: hifiPool.hToken.underlying.symbol,
          }),
          completionMessage: t("transactions.lend.completionMessage", {
            lendAmount: numberWithCommas(debouncedLendInput),
            token: hifiPool.hToken.underlying.symbol,
          }),
          proxyTargetContract,
          hifiPoolAddress: hifiPool.id,
          hTokenAmount: multiplyBigNumberByScalar(valueAtMaturityBn, 0.98, hifiPool.hToken.decimals),
          underlyingAmount: debouncedLendInputBn,
          hasSignature: isTokenPermissive(chainName, hifiPool.hToken.underlying.id),
          callbackArgs: {
            hTokenAddress: hifiPool.hToken.id,
            underlyingAddress: hifiPool.hToken.underlying.id,
            hifiPoolAddress: hifiPool.id,
          },
        }),
      );

      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Lend"),
      });
      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      isLendSubmitDisabled,
      addTransactions,
      hifiPool,
      isTransactionQueued,
      setPendingTxId,
      t,
      underlyingAllowance,
      debouncedLendInput,
      debouncedLendInputBn,
      proxyTargetContract,
      valueAtMaturityBn,
      dsProxyAddress,
      account,
      connect,
      chainName,
      userAccepted,
      invokeModal,
    ],
  );

  return (
    <form onSubmit={onLendSubmit}>
      <DialogContentArea>
        <Box mb={4}>
          <Typography display="inline" variant="h5" color="textSecondary">
            {t("Maturity Date")}:&nbsp;&nbsp;
          </Typography>
          <Typography variant="h5" display="inline">
            {new Date(hifiPool.hToken.maturity * 1000).toLocaleString()}
          </Typography>
        </Box>
        <MaxInputWithBalance
          autoFocus
          fullWidth
          style={{ fontSize: "1.75em" }}
          value={lendInput}
          max={underlyingBalance}
          inputProps={{
            min: 0,
          }}
          error={!!lendInputErrorMsg}
          balanceLabel={t("Wallet")}
          tokenSymbol={hifiPool.hToken.underlying.symbol}
          balance={underlyingBalance && truncateDecimals(underlyingBalance, 2)}
          onChange={(e: React.ChangeEvent) => setLendInput((e.target as HTMLTextAreaElement).value)}
          decimals={hifiPool.hToken.underlying.decimals}
          startAdornment={
            <InputAdornment position="start">
              <TokenIcon style={{ fontSize: "1.15em" }} symbol={hifiPool.hToken.underlying.symbol as TokenSymbol} />
            </InputAdornment>
          }
        />
        {!!lendInputErrorMsg && (
          <Typography variant="body1">
            <FormHelperText error>{lendInputErrorMsg}</FormHelperText>
          </Typography>
        )}
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Estimated APY")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(valueAtMaturityIsFetching || loadingDebouncedLendInput) && <InlineDottyProgress />}
            {!loadingDebouncedLendInput &&
              !valueAtMaturityIsFetching &&
              valueAtMaturityStatus === "success" &&
              valueAtMaturity &&
              debouncedLendInputFloat > 0 &&
              `${(
                calcAPY(debouncedLendInputFloat, parseFloat(valueAtMaturity), hifiPool.maturity * 1000) * 100
              ).toFixed(2)}%`}
            {!loadingDebouncedLendInput &&
              !valueAtMaturityIsFetching &&
              (!valueAtMaturity || debouncedLendInputFloat === 0) && <span>&ndash;</span>}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Value at Maturity")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(valueAtMaturityIsFetching || loadingDebouncedLendInput) && <InlineDottyProgress />}
            {!loadingDebouncedLendInput &&
              !valueAtMaturityIsFetching &&
              valueAtMaturityStatus === "success" &&
              valueAtMaturity &&
              `$${numberWithCommas(truncateDecimals(valueAtMaturity, 2))}`}
            {!loadingDebouncedLendInput && !valueAtMaturityIsFetching && !valueAtMaturity && <span>&ndash;</span>}
          </Typography>
        </DialogDataRow>
      </DialogContentArea>
      <DialogContentArea>
        <Button
          type="submit"
          disabled={(isLendSubmitDisabled && !!account) || connecting}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          {connecting && t("Connecting...")}
          {!connecting && (account ? `${t("Lend")} ${hifiPool.hToken.underlying.symbol}` : t("Connect Wallet"))}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </form>
  );
}
