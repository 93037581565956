import { useEffect, useRef, useState } from "react";
import { initializeApp, getApps, getApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { firebaseConfig } from "../../config";

const env = { production: "prod", development: "dev", test: "dev" }[process.env.NODE_ENV];

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const database = getDatabase(app);

const useAppVersionCheck = (): boolean => {
  const [isOutdated, setIsOutdated] = useState(false);
  const [latestTimestamp, setLatestTimestamp] = useState<number | null>(null);
  const initialTimestampRef = useRef<number | null>(null);

  useEffect(() => {
    const deployTimestampRef = ref(database, `latestAppDeploy/${env}/timestamp`);

    const unsubscribe = onValue(deployTimestampRef, snapshot => {
      const newTimestamp = snapshot.val();

      if (initialTimestampRef.current === null) {
        initialTimestampRef.current = newTimestamp;
      }

      setLatestTimestamp(newTimestamp);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    let updateTimeout: ReturnType<typeof setTimeout>;
    if (
      latestTimestamp !== null &&
      initialTimestampRef.current !== null &&
      latestTimestamp > initialTimestampRef.current
    ) {
      // Wait for 20 seconds before showing the banner
      updateTimeout = setTimeout(() => setIsOutdated(true), 20 * 1000);
    }

    return () => {
      if (updateTimeout) clearTimeout(updateTimeout);
    };
  }, [latestTimestamp]);

  return isOutdated;
};

export default useAppVersionCheck;
