import { Fragment } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import LaunchIcon from "@mui/icons-material/LaunchOutlined";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import CopyAddress from "../CopyAddress";
import { CloseSection, Dialog } from "../ActionDialog";
import DialogTitle from "@mui/material/DialogTitle";
import shortenAddress from "../../utils/shortenAddress";
import { AccountDialogProps } from "./types";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import IconTypography from "../IconTypography";
import Switch from "@mui/material/Switch";
import useSettings from "../../hooks/useSettings";

const PREFIX = "AccountDialog";

const classes = {
  copyAddress: `${PREFIX}-copyAddress`,
  linkText: `${PREFIX}-linkText`,
  alignedText: `${PREFIX}-alignedText`,
  linkWrapper: `${PREFIX}-linkWrapper`,
  etherscanIcon: `${PREFIX}-etherscanIcon`,
  icon: `${PREFIX}-icon`,
  transactionsSection: `${PREFIX}-transactionsSection`,
  button: `${PREFIX}-button`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  circularProgress: `${PREFIX}-circularProgress`,
  purpleLink: `${PREFIX}-purpleLink`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.copyAddress}`]: {
    "&:hover, &:focus": {
      color: theme.palette.text.primary,
      outline: "none",
      textDecoration: "none",
    },
  },

  [`& .${classes.linkText}`]: {
    "&:hover, &:focus": {
      outline: "none",
      textDecoration: "underline",
    },
  },

  [`& .${classes.alignedText}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.linkWrapper}`]: {
    display: "flex",
  },

  [`& .${classes.etherscanIcon}`]: {
    marginLeft: theme.spacing(5),
  },

  [`& .${classes.icon}`]: {
    marginRight: ".4em",
  },

  [`& .${classes.transactionsSection}`]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },

  [`& .${classes.button}`]: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },

  [`& .${classes.buttonContainer}`]: {
    position: "relative",
  },

  [`& .${classes.circularProgress}`]: {
    marginTop: "3px",
    marginRight: theme.spacing(2),
  },

  [`& .${classes.purpleLink}`]: {
    color: theme.palette.purpleText,
  },
}));

function AccountDialog(props: AccountDialogProps): JSX.Element {
  const {
    walletProvider,
    account,
    // connect,
    reconnect,
    disconnect,
    providerName,
    connecting,
    chainName,
    onClose,
    open,
    providerOptions,
    transactions,
    // cancelTransaction,
    clearAllTransactions,
  } = props;

  let etherscanDomain = chainName !== "homestead" ? `${chainName}.etherscan.io` : "etherscan.io";
  if (chainName === "matic") {
    etherscanDomain = "polygonscan.com";
  }
  const txs = transactions.slice().reverse();

  const { t } = useTranslation();

  const { showDustyPositions, setShowDustyPositions } = useSettings();

  return (
    <StyledDialog onClose={onClose} open={open}>
      {!connecting && <DialogTitle>{t("Account")}</DialogTitle>}
      {/* {!walletProvider && !connecting && (
        <DialogContent>
          <Button variant="contained" color="primary" fullWidth size="large" onClick={connect}>
            {t("Connect Wallet")}
          </Button>
        </DialogContent>
      )} */}
      {walletProvider && !connecting && (
        <Fragment>
          <DialogContent dividers>
            <Box display="flex" className={classes.buttonContainer}>
              <Box flexGrow={1}>
                <Typography color="textSecondary" variant="body1">
                  {t("accountDialog.connectedWith", { walletProvider: providerName })}
                </Typography>
              </Box>
              {Object.keys(providerOptions).length > 0 && (
                <Box flexGrow={0}>
                  <Button
                    className={classes.button}
                    color="info"
                    variant="contained"
                    onClick={() => {
                      onClose();
                      reconnect();
                    }}
                    size="small"
                  >
                    {t("Disconnect and change")}
                  </Button>
                </Box>
              )}
              {Object.keys(providerOptions).length === 0 && (
                <Box flexGrow={0}>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      onClose();
                      disconnect();
                    }}
                    size="small"
                  >
                    {t("Disconnect")}
                  </Button>
                </Box>
              )}
            </Box>
            {account && (
              <Box mt={3}>
                <IconTypography variant="h5">
                  <Box display="flex" alignItems="center" mr={".75em"}>
                    <Jazzicon diameter={24} seed={jsNumberForAddress(account)} />
                  </Box>
                  {shortenAddress(account)}
                </IconTypography>
              </Box>
            )}
            <Box mt={3}>
              <Typography color="textSecondary" variant="body1" className={classes.linkWrapper}>
                <CopyAddress text={account} />
                <Link
                  target="_blank"
                  className={classnames(classes.copyAddress, classes.alignedText)}
                  underline="hover"
                  color="textSecondary"
                  href={`https://${etherscanDomain}/address/${account}`}
                >
                  <LaunchIcon
                    className={classnames(classes.etherscanIcon, classes.icon)}
                    style={{ verticalAlign: "middle", fontSize: "1.3rem" }}
                  />
                  {t("View on Explorer")}
                </Link>
              </Typography>
            </Box>
          </DialogContent>
          <DialogContent dividers sx={{ borderTop: "none" }}>
            <Typography mb={3} variant="body1">
              {t("Settings")}
            </Typography>
            <Box display="flex" mb={4} className={classes.buttonContainer}>
              <Box display="flex" flexGrow={1}>
                <Typography color="textSecondary" variant="body1">
                  {t("accountDialog.showDusty")}
                </Typography>
              </Box>
              <Switch
                sx={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: 0,
                }}
                checked={showDustyPositions}
                onChange={(_e, checked) => setShowDustyPositions(checked)}
              />
            </Box>
          </DialogContent>
          <DialogContent className={classes.transactionsSection}>
            {!txs.length && <Typography variant="body1">{t("emptyState.accountDialog.transactions")}</Typography>}
            {!!txs.length && (
              <>
                <Box display="flex" mb={4} className={classes.buttonContainer}>
                  <Box flexGrow={1}>
                    <Typography variant="body1">{t("Recent Transactions")}</Typography>
                  </Box>
                  <Button
                    className={classes.button}
                    color="info"
                    variant="contained"
                    onClick={clearAllTransactions}
                    size="small"
                  >
                    {t("Clear all")}
                  </Button>
                </Box>
                <Box pr={4} mr={-4} mb={-2} maxHeight={250} style={{ overflowX: "hidden", overflowY: "auto" }}>
                  <Grid container spacing={4}>
                    {txs.map(tx => {
                      const batch = txs.filter(t => t.batchId === tx.batchId);
                      if (batch.findIndex(t => !!t.error) > batch.findIndex(t => t.id === tx.id)) {
                        // Any queued up txs inside a batch that come after an error tx in said batch should not be shown
                        return false;
                      }

                      const txHash = tx.txHash;
                      const link = txHash && `https://${etherscanDomain}/tx/${txHash}`;
                      const processing = !tx.error && !!txHash && tx.requiredConfirmations > tx.confirmations;
                      let color: "primary" | "error" | "textSecondary" = "primary";
                      if (tx.error) {
                        color = "error";
                      } else if (processing || !txHash) {
                        color = "textSecondary";
                      }
                      return (
                        <Fragment key={tx.id}>
                          <Grid item xs={9}>
                            {!txHash && (
                              <Typography color={color} variant="body1">
                                {tx.description}
                              </Typography>
                            )}
                            {!!txHash && (
                              <Link
                                color={color}
                                target="_blank"
                                variant="body1"
                                underline="hover"
                                href={link}
                                className={classnames(classes.linkText, {
                                  [classes.purpleLink]: !processing && !tx.error,
                                })}
                              >
                                {tx.description}
                                <LaunchIcon
                                  style={{ verticalAlign: "middle", fontSize: "1.2rem", marginLeft: ".3em" }}
                                />
                              </Link>
                            )}
                          </Grid>
                          <Grid container justifyContent="flex-end" alignItems="flex-start" item xs={3}>
                            {processing && (
                              <CircularProgress className={classes.circularProgress} color="inherit" size="1.25rem" />
                            )}
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Grid>
                </Box>
              </>
            )}
            <CloseSection onClose={onClose} />
          </DialogContent>
        </Fragment>
      )}
    </StyledDialog>
  );
}

AccountDialog.displayName = "AccountDialog";

export default AccountDialog;
