import { MulticallBatcher } from "contexts/web3Base";
import deepFreeze from "utils/deepFreeze";
import BPromise from "bluebird";
import get from "lodash/get";
import { getHifiPoolMulticallContract, getHTokenMulticallContract } from "../../utils/contracts";
import getErc20 from "api/getErc20";
import { HifiPool, HToken } from "./types";
import { hifiPoolAddresses } from "../../contracts/addresses";

const fetchHToken = async (address: string): Promise<HToken> => {
  const hTokenContract = getHTokenMulticallContract({ address });
  return new Promise(async (resolve, reject) => {
    try {
      const underlyingAddress = await MulticallBatcher.queue(hTokenContract.underlying());
      const res = await Promise.all([
        getErc20(address),
        MulticallBatcher.queue(hTokenContract.maturity()),
        getErc20(underlyingAddress),
      ]);
      resolve({
        ...res[0],
        maturity: parseFloat(res[1].toString()),
        underlying: res[2],
      });
    } catch (e) {
      reject(e);
    }
  });
};

const getHifiPool = async (address: string): Promise<HifiPool> => {
  const poolContract = getHifiPoolMulticallContract({ address });

  return new Promise(async (resolve, reject) => {
    try {
      const hTokenAddress = await MulticallBatcher.queue(poolContract.hToken());
      const res = await Promise.all([
        getErc20(address),
        MulticallBatcher.queue(poolContract.maturity()),
        MulticallBatcher.queue(poolContract.underlyingPrecisionScalar()),
        fetchHToken(hTokenAddress),
      ]);
      resolve(
        deepFreeze({
          ...res[0],
          maturity: parseFloat(res[1].toString()),
          underlyingPrecisionScalar: res[2],
          hToken: res[3],
        }),
      );
    } catch (e) {
      reject(e);
    }
  });
};

export default async function (chainName: string): Promise<HifiPool[]> {
  const addresses: string[] = get(hifiPoolAddresses, chainName, []);
  return BPromise.map(addresses, poolAddress => {
    return getHifiPool(poolAddress);
  });
}
