import { QueryObserverResult, useQuery } from "react-query";

const fetchTvl = async () => {
  // Prepare the headers conditionally based on whether the API key is present
  const headers: HeadersInit = {};
  if (process.env.REACT_APP_TVL_SERVICE_API_KEY) {
    headers["x-api-key"] = process.env.REACT_APP_TVL_SERVICE_API_KEY;
  }

  const response = await fetch("https://us-central1-tvl-service.cloudfunctions.net/getHifiProtocolTVL", {
    headers: headers,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export default function useTvl(): QueryObserverResult<string> {
  return useQuery(["tvl"], fetchTvl);
}
