import { BigNumber } from "ethers";
import { QueryObserverResult, useQuery } from "react-query";
import getUnderlyingOffered from "../../api/getUnderlyingOffered";
import { HifiPool } from "../../api/getHifiPools/types";

export function useUnderlyingOffered({
  underlyingInput,
  hifiPool,
}: {
  underlyingInput: BigNumber | undefined;
  hifiPool: HifiPool;
}): QueryObserverResult<BigNumber> {
  return useQuery(
    ["underlyingOffered", hifiPool.id, underlyingInput],
    () => {
      if (underlyingInput) {
        if (underlyingInput.eq(BigNumber.from("0"))) {
          return BigNumber.from("0");
        } else {
          return getUnderlyingOffered({ hifiPool, underlyingInput });
        }
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0.5 * 60 * 1000,
      staleTime: 0.5 * 60 * 1000,
      refetchInterval: 0.5 * 60 * 1000,
      enabled: !!underlyingInput && underlyingInput.gte(BigNumber.from("0")),
    },
  );
}
