import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Dialog } from "../../components/ActionDialog";
import { HifiPool } from "../../api/getHifiPools/types";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { Web3Provider } from "@ethersproject/providers";
import { useTranslation } from "react-i18next";
// import { useMintInputs } from "../../hooks/useMintInputs";
import usePendingTransactionUI from "../../hooks/usePendingTransactionUI";
import SingleTaskPanel from "../../components/SingleTaskPanel";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import { QueryStatus } from "react-query";
// import { useUnderlyingOutForRemoveLiquidityAndSellHToken } from "../../hooks/useUnderlyingOutForRemoveLiquidityAndSellHToken";
import isExpired from "../../utils/isExpired";
import AddLiquidityTab from "../AddLiquidityTab";
import RemoveLiquidityTab from "../RemoveLiquidityTab";

export function displayShareOfPoolChange({
  currentValue,
  currentValueStatus,
  newValue,
  newValueStatus,
  isError,
}: {
  currentValue?: number;
  currentValueStatus: QueryStatus;
  newValue?: number;
  newValueStatus: QueryStatus;
  isError?: boolean;
}): JSX.Element {
  if (
    currentValueStatus !== "success" ||
    newValueStatus !== "success" ||
    (!newValue && !currentValue) ||
    (!!newValue && newValue < 0) ||
    (newValue && newValue > 1) ||
    isError
  ) {
    return <span>&ndash;</span>;
  } else if ((typeof newValue === "undefined" || newValue === currentValue) && !!currentValue) {
    return <span>{Math.round(currentValue * 100)}%</span>;
  } else {
    return (
      <span>
        {Math.round((currentValue || 0) * 100)}%&nbsp;&nbsp;&rarr;&nbsp;&nbsp;
        {Math.round((newValue || 0) * 100)}%
      </span>
    );
  }
}

export function displayMyLiquidityChange({
  currentValue,
  currentValueStatus,
  newValue,
  newValueStatus,
}: {
  currentValue?: string;
  currentValueStatus: QueryStatus;
  newValue?: string;
  newValueStatus: QueryStatus;
}): JSX.Element {
  const newValueFloat = newValue && parseFloat(newValue);
  const currentValueFloat = currentValue && parseFloat(currentValue);
  if (
    currentValueStatus !== "success" ||
    newValueStatus !== "success" ||
    (!currentValueFloat && !newValueFloat) ||
    (!!newValueFloat && newValueFloat < 0)
  ) {
    return <span>&ndash;</span>;
  } else if (!!currentValue && (!newValue || newValue === currentValue)) {
    return <span>${numberWithCommas(truncateDecimals(currentValue, 2))}</span>;
  } else {
    return (
      <span>
        ${truncateDecimals(numberWithCommas(currentValue || 0), 2)}&nbsp;&nbsp;&rarr;&nbsp;&nbsp;$
        {truncateDecimals(numberWithCommas(newValue || 0), 2)}
      </span>
    );
  }
}

export default function ManageLiquidity({
  onCloseClick,
  actionType = "add",
  pool,
  account,
  walletProvider,
  chainName,
  connect,
  connecting,
}: {
  onCloseClick: () => void;
  actionType?: "add" | "remove";
  pool: HifiPool;
  account: string;
  walletProvider: Web3Provider | undefined;
  chainName: string;
  connect: () => void;
  connecting: boolean;
}): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(actionType === "add" ? 0 : 1);
  const [addLiquidityInput, setAddLiquidityInput] = useState("");
  const [removeLiquidityPercentInput, setRemoveLiquidityPercentInput] = useState(0);

  const { t } = useTranslation();

  // const { data: underlyingOut } = useUnderlyingOutForRemoveLiquidityAndSellHToken({
  //   poolTokensBurned: debouncedRemoveLiquidityPercentInput,
  //   hifiPool: pool,
  // });

  const { data: lpTokenBalance } = useUserErc20Balance({ token: pool, walletProvider, account });

  const { showTx, transaction, setPendingTxId } = usePendingTransactionUI(onCloseClick);

  // const { data: mintInputs, status: mintInputsStatus } = useMintInputs({
  //   underlyingOffered: addLiquidityInputBn,
  //   hifiPoolAddress: pool.id,
  // });

  const _onClose = useCallback(
    (_evt, _reason) => {
      if (_reason !== "backdropClick" || !transaction || !showTx) {
        onCloseClick();
      }
    },
    [onCloseClick, transaction, showTx],
  );

  return (
    <Dialog onClose={_onClose} open={!transaction}>
      {transaction && showTx && (
        <DialogContent>
          <SingleTaskPanel chainName={chainName} onClose={onCloseClick} transaction={transaction} />
        </DialogContent>
      )}
      {!showTx && (
        <>
          <Box>
            <Tabs
              value={selectedTab}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="inherit"
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <Tab disabled={isExpired(pool)} disableRipple label={<Typography variant="h4">{t("Add")}</Typography>} />
              <Tab
                disabled={!lpTokenBalance || parseFloat(lpTokenBalance) === 0}
                disableRipple
                label={<Typography variant="h4">{t("Remove")}</Typography>}
              />
            </Tabs>
            <Box position="relative" />
          </Box>
          <DialogContent>
            {selectedTab === 0 && (
              <AddLiquidityTab
                onCloseClick={onCloseClick}
                pool={pool}
                account={account}
                walletProvider={walletProvider}
                chainName={chainName}
                connect={connect}
                connecting={connecting}
                setAddLiquidityInput={setAddLiquidityInput}
                addLiquidityInput={addLiquidityInput}
                setPendingTxId={setPendingTxId}
              />
            )}
            {selectedTab === 1 && (
              <RemoveLiquidityTab
                onCloseClick={onCloseClick}
                pool={pool}
                account={account}
                walletProvider={walletProvider}
                chainName={chainName}
                setRemoveLiquidityPercentInput={setRemoveLiquidityPercentInput}
                removeLiquidityPercentInput={removeLiquidityPercentInput}
                setPendingTxId={setPendingTxId}
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
