import { FirebaseAppProvider, useFirebaseApp, FunctionsProvider, DatabaseProvider } from "reactfire";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";
import { firebaseConfig } from "../../config";

function LocalFunctionsProvider({ children }: { children: JSX.Element }) {
  const functionsInstance = getFunctions(useFirebaseApp());
  if (process.env.REACT_APP_USE_LOCAL_EMULATOR === "true" && process.env.REACT_APP_LOCAL_FUNCTIONS_EMULATOR_PORT) {
    connectFunctionsEmulator(
      functionsInstance,
      "localhost",
      Number(process.env.REACT_APP_LOCAL_FUNCTIONS_EMULATOR_PORT),
    );
  }
  return <FunctionsProvider sdk={functionsInstance}>{children}</FunctionsProvider>;
}

function LocalDatabaseProvider({ children }: { children: JSX.Element }) {
  const databaseInstance = getDatabase(useFirebaseApp());
  if (process.env.REACT_APP_USE_LOCAL_EMULATOR === "true" && process.env.REACT_APP_LOCAL_DATABASE_EMULATOR_PORT) {
    connectDatabaseEmulator(databaseInstance, "localhost", Number(process.env.REACT_APP_LOCAL_DATABASE_EMULATOR_PORT));
  }
  return <DatabaseProvider sdk={databaseInstance}>{children}</DatabaseProvider>;
}

export const FirebaseProviders: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <LocalFunctionsProvider>
        <LocalDatabaseProvider>{children}</LocalDatabaseProvider>
      </LocalFunctionsProvider>
    </FirebaseAppProvider>
  );
};
