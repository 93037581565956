import { useContext } from "react";
import { Context as Web3Context } from "../../contexts/web3";
import TokenIcon from "../../icons/TokenIcon";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Collateral } from "../../api/getCollaterals/types";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { InlineDottyProgress } from "../../components/DottyProgress";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import IconTypography from "../../components/IconTypography";
import useUserEthBalance from "hooks/useUserEthBalance";

export default function EthCollateralRow({
  collateral,
  onCollateralClick,
}: {
  collateral: Collateral;
  onCollateralClick: (collateral: Collateral) => void;
}): JSX.Element {
  const symbol = collateral.symbol as TokenSymbol;
  const { walletProvider, account } = useContext(Web3Context);
  const {
    data: balance,
    isLoading: isBalanceLoading,
    status: balanceStatus,
  } = useUserEthBalance({ walletProvider, account });
  const balanceFloat = parseFloat(balance || "0");

  return (
    <TableRow
      hover={!!account}
      onClick={() => {
        if (account) {
          onCollateralClick(collateral);
        }
      }}
    >
      <TableCell align="left">
        <IconTypography variant="h5">
          <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          {symbol}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {isBalanceLoading && <InlineDottyProgress />}
          {balanceStatus === "success" &&
            balance &&
            // balanceFloat > 0 &&
            `${numberWithCommas(truncateDecimals(balance, 4))} ${balanceFloat > 0 ? collateral.symbol : ""}`}
          {!account && <span>&ndash;</span>}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{Math.round(parseFloat(collateral.collateralizationRatio) * 100)}%</Typography>
      </TableCell>
    </TableRow>
  );
}
