import { useMemo } from "react";
import { QueryObserverResult } from "react-query";
import useHifiPools from "../useHifiPools";
import { HTokenMap } from "./types";

export default function useHTokenMap(chainName: string): QueryObserverResult<HTokenMap> {
  const { data, ...others } = useHifiPools(chainName);

  const resp: HTokenMap | undefined = useMemo(() => {
    if (data) {
      return data.reduce((memo, hifiPool) => {
        return { ...memo, [hifiPool.hToken.id]: hifiPool.hToken };
      }, {});
    }
  }, [data]);

  return { data: resp, ...others } as QueryObserverResult<HTokenMap>;
}
