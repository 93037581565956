import { useMemo, useState, useCallback } from "react";
import { Provider as Web3Provider } from "../contexts/web3";
import { Provider as TosModalProvider } from "@hifi/react-tos-modal";
import { Provider as ApolloProvider } from "./apollo";
import TransactionsProvider from "./transactions";
import { Provider as TransactionsDialogProvider } from "./transactionsDialog";
import { Provider as SettingsProvider } from "./settings";
import { Theme } from "@mui/material/styles";
import { ThemeColors } from "web3modal";
import { Dialog } from "../components/ActionDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Bowser from "bowser";
import { SnackbarProvider } from "notistack";
import Slide from "@mui/material/Slide";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import { ReactQueryDevtools } from "react-query/devtools";
import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { setAccount } from "../utils/analytics";
import { FirebaseProviders } from "./firebase";

const queryClient = new QueryClient();

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  textUnderlineOffset: "0.275rem",
  color: theme.palette.text.primary,
  "&:hover": {
    color: theme.palette.purpleText,
  },
}));

const ContentComponent = (
  <div style={{ textAlign: "center", fontSize: "18px !important" }}>
    <p style={{ fontSize: "1.125rem", lineHeight: "2rem" }}>
      By continuing, I accept Hifi{"'"}s{" "}
      <StyledLink href="https://hifi.finance/terms" underline="none" target="_blank">
        Terms of Service
      </StyledLink>
      .
    </p>
  </div>
);

const tosModalTheme = {
  background: {
    opacity: "0.8",
  },
  button: {
    height: "3rem",
    width: "15rem",
    fontSize: "1.375rem",
  },
  content: {
    scrollEnabled: false,
  },
  foreground: {
    backgroundColor: "rgb(24, 24, 24)",
    borderRadius: "12px",
    paddingTopBottom: "34px",
    height: "13.75rem",
    heightMobile: "18rem",
    paddingSides: "34px",
    width: "35rem",
  },
};

export default function Providers({ children, theme }: { children: JSX.Element; theme: Theme }): JSX.Element {
  const [showOnlySupport, setShowOnlySupport] = useState(false);
  const { primary, secondary } = theme.palette.text;
  const { hover } = theme.palette.action;
  const { lightPaperBg } = theme.palette;

  const { t } = useTranslation();

  const walletModalTheme = useMemo(() => {
    return {
      background: lightPaperBg,
      main: primary,
      secondary,
      hover,
    } as ThemeColors;
  }, [primary, secondary, hover, lightPaperBg]);

  const onConnect = () => {
    const browser = Bowser.parse(window.navigator.userAgent);

    if (
      browser.platform.type !== "desktop" ||
      (browser.browser.name !== "Chrome" && browser.browser.name !== "Firefox")
    ) {
      setShowOnlySupport(true);
      return false;
    } else {
      return true;
    }
  };

  const closeOnlySupportDialog = useCallback(() => {
    setShowOnlySupport(false);
  }, []);

  const onAccountChange = useCallback(account => {
    setAccount(account);
  }, []);

  return (
    <>
      <FirebaseProviders>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <Web3Provider onAccountChange={onAccountChange} onConnect={onConnect} walletModalTheme={walletModalTheme}>
            <TransactionsProvider>
              <TransactionsDialogProvider>
                <TosModalProvider
                  contentComponent={ContentComponent}
                  modalButtonText={"I Accept"}
                  theme={tosModalTheme}
                >
                  <SettingsProvider>
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      // @ts-ignore
                      TransitionComponent={Slide}
                      maxSnack={5}
                    >
                      <ApolloProvider>{children}</ApolloProvider>
                    </SnackbarProvider>
                  </SettingsProvider>
                </TosModalProvider>
              </TransactionsDialogProvider>
            </TransactionsProvider>
          </Web3Provider>
        </QueryClientProvider>
      </FirebaseProviders>

      <Dialog fullScreen={false} onClose={closeOnlySupportDialog} open={showOnlySupport}>
        <DialogTitle>{t("unsupportedBrowser.title")}</DialogTitle>
        <DialogContent dividers style={{ borderBottom: "none" }}>
          <Typography color="textSecondary" variant="body1">
            {t("unsupportedBrowser.message")}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
