import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import { actionPanelWidths } from "../../theme";
import useWindowBreakpoint from "hooks/useWindowBreakpoint";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const _Dialog = function (props: DialogProps) {
  const bp = useWindowBreakpoint();
  return <MuiDialog transitionDuration={0} fullScreen={bp === "xs"} {...props} />;
};

export const Dialog = styled(_Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    ...actionPanelWidths(theme),
    maxWidth: "none",
  },
}));

export function CloseSection({ onClose }: { onClose: () => void }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" mt={6} mb={-3}>
      <Link onClick={onClose} underline="hover" color="textSecondary" component="button" variant="body1">
        {t("Close")}
      </Link>
    </Box>
  );
}
