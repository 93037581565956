import { ref } from "firebase/database";
import { useDatabaseObjectData, useDatabase } from "reactfire";

type LendWhitelist = { [key: string]: true };

export const useWhitelistedEthAddresses = (): string[] | undefined => {
  const database = useDatabase();
  const { status, data } = useDatabaseObjectData<LendWhitelist>(ref(database, "lendWhitelist"));

  if (status === "success" && data) {
    return Object.keys(data);
  }
};
