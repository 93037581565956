import { useContext, SetStateAction, Dispatch, useState, createContext, useCallback, useEffect } from "react";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import { Context as Web3Context } from "../../contexts/web3";
import groupBy from "lodash/groupBy";
import TaskDialog from "../../components/TaskDialog";
import { TransactionState } from "contexts/transactionsBase/types";

export const Context = createContext<{ isTaskDialogOpen: boolean; getClosedBatches: () => { [key: string]: boolean } }>(
  {
    isTaskDialogOpen: false,
    getClosedBatches: () => ({}),
  },
);

export const Provider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { getTransactions, cancelTransaction, getBatchName } = useContext(TransactionsContext);
  const { chainName } = useContext(Web3Context);

  const localStorageClosedBatches = window.localStorage.getItem("closedTxBatches");

  const [closedBatches, setClosedBatches]: [
    { [key: string]: boolean },
    Dispatch<SetStateAction<{ [key: string]: boolean }>>,
  ] = useState(localStorageClosedBatches ? JSON.parse(localStorageClosedBatches) : {});

  const saveStateToLocalStorage = useCallback(() => {
    window.localStorage.setItem("closedTxBatches", JSON.stringify(closedBatches));
  }, [closedBatches]);

  useEffect(
    function beforeunload() {
      try {
        window.addEventListener("beforeunload", saveStateToLocalStorage);

        return () => window.removeEventListener("beforeunload", saveStateToLocalStorage);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    [saveStateToLocalStorage],
  );

  const txs = getTransactions();

  const batches = groupBy(
    txs.filter(t => !closedBatches[t.batchId]),
    tx => tx.batchId,
  );
  let batch: Array<TransactionState> = [];
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  let batchId: string = "";
  Object.keys(batches).forEach(bId => {
    if (
      batches[bId].length > 0 &&
      !batch.length &&
      // Some of the transactions are not yet confirmed
      batches[bId].some(
        tx =>
          // Standard tx
          (!!tx.abiName && tx.confirmations < tx.requiredConfirmations) ||
          // Erc-2612 signature
          (!tx.abiName && typeof tx.rawResult === "undefined"),
      ) &&
      // The last transaction in the batch is not erroneous
      !batches[bId][batches[bId].length - 1].error
    ) {
      batch = batches[bId];
      batchId = bId;
    }
  });

  const firstBatchedTxIndex = txs.findIndex(tx => !!batch.length && tx.id === batch[0].id);

  const previousTx = txs.find((tx, i) => {
    return (
      // Previous tx is processing
      !!tx.abiName &&
      ((!!tx.txHash && tx.confirmations < tx.requiredConfirmations) ||
        // OR previous tx is waiting to be confirmed
        (!tx.txHash && tx.attempted)) &&
      i < firstBatchedTxIndex &&
      !tx.error
    );
  });

  const getClosedBatches = useCallback(() => {
    return closedBatches;
  }, [closedBatches]);

  return (
    <Context.Provider value={{ isTaskDialogOpen: !!batch.length, getClosedBatches }}>
      <TaskDialog
        open={!!batch.length}
        onClose={() => setClosedBatches({ ...closedBatches, [batchId]: true })}
        title={getBatchName(batchId) || ""}
        chainName={chainName}
        previousTx={previousTx}
        tasks={batch.map(tx => {
          let status: "pending" | "processing" | "error" | "completed" = "pending";
          if (tx.error) {
            status = "error";
          } else {
            if (!!tx.abiName && tx.txHash) {
              // Standard tx
              if (tx.requiredConfirmations > tx.confirmations) {
                status = "processing";
              } else {
                status = "completed";
              }
            } else if (!tx.abiName) {
              // erc-2612 sig.
              if (typeof tx.result !== "undefined") {
                status = "completed";
              }
            }
          }

          const progressObj: { progress: number } | Record<string, unknown> =
            status === "processing" && tx.requiredConfirmations > 1
              ? { progress: Math.min((tx.confirmations / tx.requiredConfirmations) * 100, 100) }
              : {};

          return {
            id: tx.id,
            status,
            description: tx.description,
            txHash: tx.txHash,
            error: tx.error,
            attempted: tx.attempted,
            onCancel: () => {
              cancelTransaction(tx);
            },
            ...progressObj,
          };
        })}
      />
      {children}
    </Context.Provider>
  );
};
