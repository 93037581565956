import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getSupportedChains } from "../../contracts/addresses";
import AppBar from "../../containers/AppBar";
import { Context as Web3Context } from "../../contexts/web3";
import BorrowV1 from "../../pages/BorrowV1";
import LendV1 from "../../pages/LendV1";
import PoolV1 from "../../pages/PoolV1";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Switch, Route, Redirect } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import useTransactionAlerts from "../../hooks/useTransactionAlerts";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SwitchEthereumButton from "../../components/SwitchEthereumButton";
import useAppVersionCheck from "../../hooks/useAppVersionCheck";
import { setNetwork } from "../../utils/analytics";
import useCanLend from "../../hooks/useCanLend";

const PREFIX = "StyledBox";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: 425,
    [theme.breakpoints.down("md")]: {
      width: 350,
    },
  },
}));

// eslint-disable-next-line react/display-name
const withSupportedNetwork = (Page: React.ComponentType): React.FC => {
  const Comp = () => {
    const { chainName } = useContext(Web3Context);
    const chainSupported = !chainName || getSupportedChains().indexOf(chainName) > -1;

    const { t } = useTranslation();
    return (
      <>
        {chainSupported && <Page />}
        {!chainSupported && (
          <StyledBox pl={4} pr={4} display="flex" justifyContent="center">
            <Box display="flex" mt={20} alignItems="center" flexDirection="column" className={classes.root}>
              <Typography variant="h2">{t("unsupportedNetwork.title")}</Typography>
              <Box mt={4} mb={4}>
                <Typography align="center" variant="h6" color="textSecondary">
                  {t("unsupportedNetwork.message", { chainName })}
                </Typography>
              </Box>
              <SwitchEthereumButton />
            </Box>
          </StyledBox>
        )}
      </>
    );
  };
  Comp.displayName = "wrapped_withSupportedNetwork";
  return Comp;
};

const BorrowRoute = withSupportedNetwork(BorrowV1);
const LendRoute = withSupportedNetwork(LendV1);
const PoolRoute = withSupportedNetwork(PoolV1);

const MENU_PREFIX = "MuiMenu";

const menuClasses = {
  root: `${MENU_PREFIX}-root`,
};

const StyledMenu = styled(Menu)(() => ({
  [`&.${menuClasses.root}`]: {
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent !important",
    },
  },
}));

export default function AppContainer(): JSX.Element {
  const { chainName, account, initialized } = useContext(Web3Context);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const open = Boolean(anchorEl);

  useTransactionAlerts({ chainName, account });
  const { t } = useTranslation();

  useEffect(() => {
    if (initialized) {
      setNetwork(chainName);
    }
  }, [initialized, chainName]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    if (event.currentTarget) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const appOutdated = useAppVersionCheck();

  const canLend = useCanLend();

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box pt={8} mb={12}>
        <Container maxWidth="xl">
          <AppBar />
        </Container>

        {appOutdated && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={6}
            sx={{ backgroundColor: "primary.main" }}
            height={36}
            width="100%"
          >
            <Typography variant="subtitle1" position="relative">
              A new version of Hifi is available.{" "}
              <Link
                color="common.white"
                underline="always"
                sx={{ cursor: "pointer" }}
                onClick={() => window.location.reload()}
              >
                Refresh to update
              </Link>
              .
            </Typography>
          </Box>
        )}
      </Box>

      <Container maxWidth="xl">
        <Container disableGutters>
          <Switch>
            {canLend && (
              <Route path="/lend">
                <LendRoute />
              </Route>
            )}
            <Route path="/borrow">
              <BorrowRoute />
            </Route>
            <Route path="/pool">
              <PoolRoute />
            </Route>
            <Redirect from="/" to="/borrow" />
          </Switch>
        </Container>
      </Container>

      <Box display="flex" flexGrow="1" alignItems="flex-end" justifyContent="center" pb={8} pt={8}>
        <Link variant="subtitle1" component="button" underline="hover" color="textSecondary" onClick={handleClick}>
          {t("previousVersion")}
        </Link>
        <StyledMenu
          anchorOrigin={{ vertical: -90, horizontal: "right" }}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={open}
          className={menuClasses.root}
        >
          <MenuItem onClick={() => (location.href = "https://v0.hifi.finance")}>v0</MenuItem>
          <MenuItem onClick={() => (location.href = "https://v1.hifi.finance")}>v1</MenuItem>
          <MenuItem onClick={() => (location.href = "https://v1-1.hifi.finance")}>v1.1</MenuItem>
        </StyledMenu>
      </Box>
    </Box>
  );
}
