import { BigNumber } from "ethers";
import { MulticallBatcher } from "../../contexts/web3Base";
import { getErc20MulticallContract } from "../../utils/contracts";

const getAllowance = async ({
  tokenAddress,
  spenderAddress,
  account,
}: {
  tokenAddress: string;
  spenderAddress: string;
  account: string;
}): Promise<BigNumber | undefined> => {
  const contract = getErc20MulticallContract({ address: tokenAddress });
  if (contract) {
    return await MulticallBatcher.queue(contract.allowance(account, spenderAddress));
  }
};

export default getAllowance;
