import { useCallback } from "react";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import LaunchIcon from "@mui/icons-material/LaunchOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import CancelIcon from "@mui/icons-material/Cancel";
import LinearProgress from "@mui/material/LinearProgress";
import { Color, TaskDialogProps } from "./types";
import { useTranslation } from "react-i18next";
import { CloseSection, Dialog } from "../ActionDialog";
import DialogTitle from "@mui/material/DialogTitle";
import classnames from "classnames";

const PREFIX = "TaskDialog";

const classes = {
  text: `${PREFIX}-text`,
  icon: `${PREFIX}-icon`,
  completed: `${PREFIX}-completed`,
  processing: `${PREFIX}-processing`,
  dialogContent: `${PREFIX}-dialogContent`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const RootDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.text}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.icon}`]: {
    marginTop: ".08em",
    position: "absolute",
    left: 0,
    fontSize: "1.4rem",
  },

  [`& .${classes.completed}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.processing}`]: {
    marginTop: ".15em",
  },

  [`& .${classes.dialogContent}`]: {
    borderBottom: "none",
  },
}));

const ProgressBar = styled(LinearProgress)(() => ({
  "&.MuiLinearProgress-root": {
    height: ".75em",
    borderRadius: 400,
  },
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: "#F2F2F2",
  },
}));

const TaskDialog = (props: TaskDialogProps): JSX.Element => {
  const { onClose, open, tasks, chainName, previousTx } = props;

  const _onClose = useCallback(
    (_evt, reason) => {
      if (reason !== "backdropClick") {
        onClose();
      }
    },
    [onClose],
  );

  const etherscanSubdomain = chainName !== "homestead" ? `${chainName}.` : "";

  const { t } = useTranslation();

  let title = t("transactions.messages.confirm");
  if (tasks.some(t => t.status === "error")) {
    title = t("transactions.messages.failed");
  } else if (tasks.some(t => t.status === "processing") || !!previousTx?.txHash) {
    title = t("transactions.messages.pending");
  } else if (tasks.every(t => t.status === "completed")) {
    title = t("transactions.messages.complete");
  }

  return tasks.length ? (
    <RootDialog maxWidth="sm" onClose={_onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        {!!previousTx && (
          <Box display="flex" mb={5}>
            <Box display="flex" minWidth="2.25rem" position="relative">
              {!!previousTx.txHash && (
                <CircularProgress
                  color="inherit"
                  className={classnames(classes.icon, classes.processing)}
                  size="1.2rem"
                />
              )}
            </Box>
            <Box flexGrow={1}>
              <Box display="flex">
                <Typography variant="body1" color={previousTx.txHash ? "textPrimary" : "textSecondary"}>
                  {t("Previous transaction")}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {tasks.map((task, i) => {
          const { status, description, id, progress, txHash } = task;
          let link = txHash && `https://${etherscanSubdomain}etherscan.io/tx/${txHash}`;
          if (chainName === "matic" && txHash) {
            link = `https://polygonscan.com/tx/${txHash}`;
          }

          return (
            <Box key={id} display="flex" mt={i > 0 ? 5 : 0}>
              <Box display="flex" minWidth="2.25rem" position="relative">
                {status === "completed" && (
                  <CheckCircleIcon color="primary" className={classnames(classes.icon, classes.completed)} />
                )}
                {status === "processing" && (
                  <CircularProgress color="inherit" className={classes.processing} size="1.2rem" />
                )}
                {status === "error" && <CancelIcon color="error" className={classes.icon} />}
              </Box>
              <Box flexGrow={1}>
                <Box display="flex">
                  {!!task.txHash && (
                    <Link
                      variant="body1"
                      href={link}
                      underline="hover"
                      target="_blank"
                      color={
                        {
                          completed: "textPrimary",
                          error: "textPrimary",
                          processing: "textPrimary",
                          pending: "textSecondary",
                        }[status] as Color
                      }
                    >
                      {description}
                      {!!txHash && (
                        <LaunchIcon
                          style={{
                            verticalAlign: "middle",
                            fontSize: "1.2rem",
                            marginLeft: ".3em",
                          }}
                        />
                      )}
                    </Link>
                  )}
                  {!task.txHash && (
                    <Typography
                      variant="body1"
                      color={
                        {
                          completed: "textPrimary",
                          error: "textPrimary",
                          processing: "textPrimary",
                          pending: "textSecondary",
                        }[status] as Color
                      }
                    >
                      {description}
                      {!!txHash && (
                        <Link underline="hover" variant="body1" color="textPrimary" href={link} target="_blank">
                          <LaunchIcon
                            style={{
                              verticalAlign: "middle",
                              fontSize: "1.2rem",
                              marginLeft: ".3em",
                            }}
                          />
                        </Link>
                      )}
                    </Typography>
                  )}
                </Box>
                {typeof progress === "number" && (
                  <Box pt={2}>
                    <ProgressBar variant="determinate" value={progress} />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
        <CloseSection onClose={onClose} />
      </DialogContent>
    </RootDialog>
  ) : (
    <></>
  );
};

TaskDialog.displayName = "TaskDialog";

export default TaskDialog;
