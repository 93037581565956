import { getHTokenMulticallContract } from "../../utils/contracts";
import { MulticallBatcher } from "contexts/web3Base";
import deepFreeze from "utils/deepFreeze";
import { BigNumber } from "ethers";

const getTotalUnderlyingReserve = async (hTokenAddress: string): Promise<BigNumber> => {
  const hTokenContract = getHTokenMulticallContract({ address: hTokenAddress });
  const totalUnderlyingReserve: BigNumber = await MulticallBatcher.queue(hTokenContract.totalUnderlyingReserve());

  return deepFreeze(totalUnderlyingReserve);
};

export default getTotalUnderlyingReserve;
