import { useCallback, useContext } from "react";
import { Context as Web3Context } from "../../contexts/web3";
import { Provider } from "contexts/transactionsBase";
import abis from "../../contracts/abis";

const TransactionsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { account, walletProvider, chainName, provider } = useContext(Web3Context);

  const getAbiByName = useCallback((name: string) => {
    return abis[name] || null;
  }, []);

  return (
    <Provider
      walletProvider={walletProvider}
      chainName={chainName}
      provider={provider}
      account={account}
      getAbiByName={getAbiByName}
    >
      {children}
    </Provider>
  );
};

export default TransactionsProvider;
