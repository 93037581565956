import { UserLockedCollaterals } from "../../api/getUserLockedCollaterals/types";
import useUserLockedCollaterals from "../useUserLockedCollaterals";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { useCallback } from "react";

export default function useUserEligibleLockedCollaterals({
  chainName,
  account,
}: {
  chainName: string;
  account: string;
}): QueryResponse<UserLockedCollaterals> {
  const res = useUserLockedCollaterals({
    chainName,
    account,
  });

  return useCombinedQueryHooks(
    [res],
    useCallback(data => {
      const [lockedCollaterals] = data as [UserLockedCollaterals];

      const filteredLockedCollateralAddresses = Object.keys(lockedCollaterals || []);
      return filteredLockedCollateralAddresses.reduce((memo, collateralAddress) => {
        if (lockedCollaterals) {
          return { ...memo, [collateralAddress]: lockedCollaterals[collateralAddress] };
        }
        return memo;
      }, {});
    }, []),
  );
}
