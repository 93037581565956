import { Fragment, useContext, useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Context as Web3Context } from "../../contexts/web3";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import AccountDialog from "../../components/AccountDialog";
import shortenAddress from "../../utils/shortenAddress";
import useWindowBreakpoint from "hooks/useWindowBreakpoint";
import { ConnectWalletButtonProps } from "./types";
import { useTranslation } from "react-i18next";
import useUserEthBalance from "hooks/useUserEthBalance";
import { UNAVAILBLE } from "constants/loadingStates";
import { Context as TosContext } from "@hifi/react-tos-modal";

function ConnectWalletButton(props: ConnectWalletButtonProps): JSX.Element {
  const { ensName, className, ...others } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    walletProvider,
    account,
    connect,
    reconnect,
    providerName,
    connecting,
    chainName,
    disconnect,
    providerOptions,
    status,
  } = useContext(Web3Context);
  const { getTransactions, cancelTransaction, clearAllTransactions } = useContext(TransactionsContext);
  const { userAccepted, invokeModal } = useContext(TosContext);
  const { data: ethBalance } = useUserEthBalance({ walletProvider, account });

  const onCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    if (walletProvider) {
      setDialogOpen(false);
    }
  }, [walletProvider]);

  let txs = getTransactions();
  txs = txs.filter(tx => !!tx.abiName);

  const pending = !!txs && txs.some(tx => tx.txHash && tx.confirmations < tx.requiredConfirmations && !tx.error);

  const bp = useWindowBreakpoint();

  const { t } = useTranslation();

  const wrongNetwork = status === UNAVAILBLE;

  return (
    <Fragment>
      <Button
        variant="contained"
        size="small"
        className={className}
        color="info"
        disabled={connecting || wrongNetwork}
        onClick={() => {
          if (wrongNetwork) return;

          if (!account) {
            if (userAccepted) {
              connect();
            } else {
              invokeModal(() => {
                connect();
              });
            }
          } else {
            setDialogOpen(true);
          }
        }}
        {...others}
      >
        {!account && !connecting && !wrongNetwork && t("Connect Wallet")}
        {wrongNetwork && t("Wrong Network")}
        {connecting && t("Connecting...")}
        {pending && <CircularProgress size="1em" style={{ marginRight: ".5em", color: "white" }} thickness={5} />}
        {pending && t("Pending...")}
        {!pending &&
          ethBalance &&
          !!account &&
          `${parseFloat(ethBalance).toFixed(3)} ${chainName === "matic" ? "MATIC" : "ETH"}`}
        {!!account && (
          <Fragment>
            <Box pl={4} textOverflow="ellipsis" overflow="hidden" maxWidth="10em" whiteSpace="nowrap">
              {ensName ? ensName : shortenAddress(account)}
            </Box>
            <Box pl={2} display="flex" alignItems="center">
              <Jazzicon diameter={bp === "xs" ? 12 : 18} seed={jsNumberForAddress(account)} />
            </Box>
          </Fragment>
        )}
      </Button>
      <AccountDialog
        walletProvider={walletProvider}
        account={account}
        connect={connect}
        reconnect={reconnect}
        disconnect={disconnect}
        providerName={providerName}
        chainName={chainName}
        onClose={onCloseDialog}
        connecting={connecting}
        open={dialogOpen && !connecting}
        providerOptions={providerOptions}
        transactions={txs}
        cancelTransaction={cancelTransaction}
        clearAllTransactions={clearAllTransactions}
      />
    </Fragment>
  );
}

ConnectWalletButton.displayName = "ConnectWalletButton";

export default ConnectWalletButton;
