import { styled } from "@mui/material/styles";
import get from "lodash/get";
import collateralRatioColors from "../../utils/collateralRatioColors";

const PREFIX = "RadioText";

const classes = {
  ratio: `${PREFIX}-ratio`,
};

type RootProps = {
  percent: number;
};

const Root = styled("span")<RootProps>(({ theme, percent }) => ({
  [`&.${classes.ratio}`]: {
    color: percent === 0 ? theme.palette.text.primary : get(theme.palette, collateralRatioColors(percent)),
  },
}));

export default function RadioText(props: { percent: number }): JSX.Element {
  return (
    <Root percent={props.percent} className={classes.ratio}>
      {props.percent}%
    </Root>
  );
}
