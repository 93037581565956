import { memo, useCallback, useContext, useMemo, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import OverlayProgress from "../../components/OverlayProgress";
import { Context as Web3Context } from "../../contexts/web3";
import { usePageEvent } from "../../hooks/analytics";
import { useTranslation } from "react-i18next";
import { HifiPool } from "../../api/getHifiPools/types";
import ManageLiquidityDialog from "../../containers/ManageLiquidityDialog";
import useUserLiquidityPools from "../../hooks/useUserLiquidityPools";
import useHifiPools from "../../hooks/useHifiPools";
import isExpired from "../../utils/isExpired";
import MyLiquidityTable from "../../components/MyLiquidityTable";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import { LighterPaper } from "../../components/Paper";
import HeadlineGrid from "../../components/HeadlineGrid";
import EmptyHeadline from "../../components/EmptyHeadline";

const _MyLiquidity = ({ truncatedFloatTotalLiquidity }: { truncatedFloatTotalLiquidity: number }) => {
  const { t } = useTranslation();
  return (
    <HeadlineGrid item lg={9} md={9} sm={11} xs={12}>
      <LighterPaper>
        <Box p={6} display="flex" flexDirection="column" alignItems="center">
          <Typography color="textSecondary" variant="body2">
            {t("My Liquidity")}
          </Typography>
          <Box mt={1}>
            <Typography variant="h4">${numberWithCommas(truncateDecimals(truncatedFloatTotalLiquidity, 2))}</Typography>
          </Box>
        </Box>
      </LighterPaper>
    </HeadlineGrid>
  );
};
const MyLiquidity = memo(_MyLiquidity);

export default function Pool(): JSX.Element {
  usePageEvent("pool");

  const [managedPool, setManagedPool] = useState<HifiPool | null>(null);
  const [myLiquidities, setMyLiquidities] = useState<Record<string, number | undefined>>({});

  const { chainName, account, walletProvider, connect, connecting } = useContext(Web3Context);

  const { data: myLiquidityPools, status: myLiquidityPoolsStatus } = useUserLiquidityPools({
    account,
    chainName,
    walletProvider,
  });

  const { data: pools } = useHifiPools(chainName);
  const otherPools = useMemo(() => {
    if (myLiquidityPoolsStatus !== "loading" && pools?.length) {
      const _myLiquidityPools = myLiquidityPools || [];
      return pools.filter(pool => {
        return !_myLiquidityPools.find(p => p.id === pool.id) && !isExpired(pool);
      });
    }
  }, [pools, myLiquidityPools, myLiquidityPoolsStatus]);

  const { t } = useTranslation();

  const onPoolClick = useCallback(hifiPool => {
    setManagedPool(hifiPool);
  }, []);

  const onCloseLiquidityClick = useCallback(() => {
    setManagedPool(null);
  }, []);

  const onMyLiquidityChange = useCallback((poolId: string, liquidity: number | undefined) => {
    setMyLiquidities(state => {
      return { ...state, [poolId]: liquidity };
    });
  }, []);

  const myTotalLiquidity = useMemo(() => {
    if (myLiquidityPools?.every(pool => typeof myLiquidities[pool.id] === "number")) {
      return myLiquidityPools?.reduce((memo, pool) => {
        if (typeof myLiquidities[pool.id] === "number") {
          // @ts-ignore
          return memo + myLiquidities[pool.id];
        }
        return memo;
      }, 0);
    }
  }, [myLiquidityPools, myLiquidities]);

  return (
    <>
      {myLiquidityPoolsStatus === "loading" && (
        <Box pt={10} position="relative">
          <OverlayProgress />
        </Box>
      )}
      {myLiquidityPoolsStatus !== "loading" && (
        <>
          <Grid container spacing={6} justifyContent="center">
            {!!account && !!myTotalLiquidity && myTotalLiquidity > 0 && (
              <MyLiquidity truncatedFloatTotalLiquidity={myTotalLiquidity} />
            )}
            {/* Show a blank spot for either the balance or the help banner to appear in */}
            {!!myLiquidityPools?.length && typeof myTotalLiquidity === "undefined" && <EmptyHeadline />}
            {(!myLiquidityPools?.length || !account) && (
              <HeadlineGrid item lg={9} md={9} sm={11} xs={12}>
                <Box p={6} sx={{ backgroundColor: "#1B1B1B", borderRadius: 1 }}>
                  <Link
                    href="https://www.youtube.com/watch?v=O_iNp0yoMVc"
                    style={{ marginBottom: ".2em", display: "inline-block" }}
                    color="textPrimary"
                    variant="h4"
                    underline="none"
                    target="_blank"
                  >
                    {t("helpBanners.pool.step0.title")}
                    <ArrowForwardIcon style={{ verticalAlign: "middle", marginLeft: ".2em" }} />
                  </Link>
                  <Typography variant="body1">{t("helpBanners.pool.step0.subtitle")}</Typography>
                </Box>
              </HeadlineGrid>
            )}

            {!!myLiquidityPools?.length && (
              <MyLiquidityTable
                myLiquidityPools={myLiquidityPools}
                walletProvider={walletProvider}
                onPoolClick={onPoolClick}
                account={account}
                title={t("My Liquidity")}
                onMyLiquidityChange={onMyLiquidityChange}
                chainName={chainName}
              />
            )}
            {!!otherPools?.length && (
              <MyLiquidityTable
                myLiquidityPools={otherPools}
                walletProvider={walletProvider}
                onPoolClick={onPoolClick}
                account={account}
                title={t("Liquidity Pools")}
                chainName={chainName}
              />
            )}
          </Grid>
        </>
      )}
      {!!managedPool && (
        <ManageLiquidityDialog
          pool={managedPool}
          account={account}
          actionType={isExpired(managedPool) ? "remove" : "add"}
          onCloseClick={onCloseLiquidityClick}
          walletProvider={walletProvider}
          chainName={chainName}
          connect={connect}
          connecting={connecting}
        />
      )}
    </>
  );
}
