import { QueryObserverResult, useQuery } from "react-query";

const fetchIPAddress = async () => {
  const response = await fetch("https://api.ipify.org?format=json");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.ip;
};

export default function useClientIpAddress(): QueryObserverResult<string> {
  return useQuery(["clientIpAddress"], fetchIPAddress);
}
