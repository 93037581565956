import { useCallback, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import MaxInputWithBalance from "../../components/MaxInputWithBalance";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { HifiPool } from "../../api/getHifiPools/types";
import DialogContentArea from "../../components/DialogContentArea";
import { Context as TransactionsContext, LAST_RESULT } from "contexts/transactionsBase";
import useDebounce from "hooks/useDebounce";
import { InlineDottyProgress } from "../../components/DottyProgress";
import { formatUnits, parseUnits } from "@ethersproject/units";
import FormHelperText from "@mui/material/FormHelperText";
import { multiplyBigNumberByScalar, numberWithCommas, truncateDecimals } from "utils/numbers";
import { BigNumber } from "ethers";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { Web3Provider } from "@ethersproject/providers";
import useLendError from "../../hooks/useLendError";
import { createDsProxy, permit, sellHToken } from "../../utils/transactions";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../utils/analytics";
import { useProxyTargetContract } from "../../hooks/contracts";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import DialogDataRow from "../../components/DialogDataRow";
import { AddressZero } from "@ethersproject/constants";
import MaxInput from "components/MaxInput";
import useQuoteForSellingHToken from "../../hooks/useQuoteForSellingHToken";
import useWindowBreakpoint from "hooks/useWindowBreakpoint";
import { getInputJazziconSize } from "../../utils/getJazziconSize";
import shouldSkipDSProxyConfirmations from "../../utils/shouldSkipDSProxyConfirmations";
import { getContractAddress } from "../../contracts/addresses";

export default function SellTab({
  onCloseClick,
  hifiPool,
  account,
  chainName,
  walletProvider,
  setPendingTxId,
  setSellInput,
  paybackInput,
}: {
  onCloseClick: () => void;
  hifiPool: HifiPool;
  account: string;
  chainName: string;
  walletProvider?: Web3Provider;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
  setSellInput: React.Dispatch<React.SetStateAction<string>>;
  paybackInput: string;
}): JSX.Element {
  const bp = useWindowBreakpoint();

  const { t } = useTranslation();

  const { addTransactions, isTransactionQueued } = useContext(TransactionsContext);

  const { debouncedValue: debouncedSellInput, loading: loadingDebouncedSellInput } = useDebounce(paybackInput);

  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });

  const {
    data: hTokenPrice,
    status: hTokenPriceStatus,
    isFetching: hTokenPriceIsFetching,
  } = useQuoteForSellingHToken({
    hTokenIn: "1",
    hifiPool,
  });
  const {
    data: underlyingPurchased,
    status: underlyingPurchasedStatus,
    isFetching: underlyingPurchasedIsFetching,
    isError: underlyingPurchasedIsError,
    isLoading: underlyingPurchasedIsLoading,
  } = useQuoteForSellingHToken({
    hTokenIn: debouncedSellInput,
    hifiPool,
  });

  const { data: hTokenBalance } = useUserErc20Balance({
    token: hifiPool.hToken,
    account,
    walletProvider,
  });

  // Value conversions and cals -------------------------------------------------------------------------------------|
  const debouncedSellInputBn = useMemo(() => {
    if (debouncedSellInput) {
      return parseUnits(debouncedSellInput, hifiPool.hToken.decimals);
    }
  }, [debouncedSellInput, hifiPool]);

  const hTokenBalanceBn = useMemo(() => {
    if (hTokenBalance) {
      return parseUnits(hTokenBalance, hifiPool.hToken.decimals);
    }
  }, [hTokenBalance, hifiPool]);

  // const hTokensRepaidBn = useMemo(() => {
  //   if (hTokensRepaid) {
  //     return parseUnits(hTokensRepaid, hifiPool.hToken.decimals);
  //   }
  // }, [hTokensRepaid, hifiPool]);

  const priceImpact = useMemo(() => {
    if (underlyingPurchased && hTokenPrice && debouncedSellInput) {
      const underlyingPurchasedFloat = parseFloat(underlyingPurchased);
      const hTokenPriceFloat = parseFloat(hTokenPrice);
      const debouncedSellInputFloat = parseFloat(debouncedSellInput);
      return Math.abs(underlyingPurchasedFloat / debouncedSellInputFloat / hTokenPriceFloat - 1) * 100;
    }
  }, [debouncedSellInput, hTokenPrice, underlyingPurchased]);

  const underlyingPurchasedWithSlippageBn = useMemo(() => {
    if (underlyingPurchased) {
      return multiplyBigNumberByScalar(
        parseUnits(underlyingPurchased, hifiPool.hToken.underlying.decimals),
        0.98,
        hifiPool.hToken.underlying.decimals,
      );
    }
  }, [hifiPool, underlyingPurchased]);
  // --------------------------------------------------------------------------------------------------------------|

  // Loading, disabled, and error calcs ---------------------------------------------------------------------------|
  const sellInputErrorMsg = useLendError({
    debouncedLendInputBn: debouncedSellInputBn,
    underlyingBalanceBn: hTokenBalanceBn,
    valueAtMaturityIsError: underlyingPurchasedIsError,
    valueAtMaturityIsLoading: underlyingPurchasedIsLoading,
  });

  const loadingSell = loadingDebouncedSellInput || underlyingPurchasedIsFetching;

  const isSellSubmitDisabled =
    loadingSell || !debouncedSellInputBn || debouncedSellInputBn.lte(BigNumber.from("0")) || !!sellInputErrorMsg;
  // debtInUnderlyingStatus === "error" ||
  // ;
  // --------------------------------------------------------------------------------------------------------------|

  const proxyTargetContract = useProxyTargetContract();

  const onSellSubmit = useCallback(
    e => {
      e.preventDefault();

      if (
        isSellSubmitDisabled ||
        !proxyTargetContract ||
        !debouncedSellInputBn ||
        !underlyingPurchasedWithSlippageBn ||
        !dsProxyAddress
      )
        return;

      const txs = [];

      // If DSProxy has already been created, or if there is a create DSProxy tx queued up, then skip
      // DSProxy creation tx.
      if (
        dsProxyAddress === AddressZero &&
        !isTransactionQueued({
          methodName: "build()",
          contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
        })
      ) {
        txs.push(
          createDsProxy({
            contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
            description: t("transactions.dsProxy"),
            skipConfirmations: shouldSkipDSProxyConfirmations(chainName),
          }),
        );
      }

      // if (
      //   hTokenAllowance === "0" &&
      //   !isTransactionQueued({ methodName: "approve", contractAddress: hifiPool.hToken.id })
      // ) {
      //   txs.push(
      //     approve({
      //       description: t("transactions.messages.allowance", {
      //         spender: "Hifi",
      //         token: hifiPool.hToken.symbol,
      //       }),
      //       contractAddress: hifiPool.hToken.id,
      //       spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
      //       callbackArgs: {
      //         spenderAddress: "DSPROXY_ADDRESS",
      //         tokenAddress: hifiPool.hToken.id,
      //       },
      //     }),
      //   );
      // }

      logEvent("sellClick", {
        lendInput: debouncedSellInput,
        hTokenAddress: hifiPool.hToken.id,
      });

      txs.push(
        permit({
          contractAddress: hifiPool.hToken.id,
          description: t("transactions.messages.allowance", {
            spender: "Hifi",
            token: hifiPool.hToken.symbol,
          }),
          ownerAddress: account,
          spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
          amount: debouncedSellInputBn.toString(),
        }),
      );

      txs.push(
        sellHToken({
          contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}-1` : dsProxyAddress,
          description: t("transactions.sellPosition.description", {
            amount: numberWithCommas(formatUnits(debouncedSellInputBn, hifiPool.hToken.decimals)),
            token: hifiPool.hToken.symbol,
          }),
          completionMessage: t("transactions.sellPosition.completionMessage", {
            amount: numberWithCommas(formatUnits(debouncedSellInputBn, hifiPool.hToken.decimals)),
            token: hifiPool.hToken.symbol,
          }),
          proxyTargetContract,
          hifiPoolAddress: hifiPool.id,
          hTokenAmount: debouncedSellInputBn,
          underlyingAmount: underlyingPurchasedWithSlippageBn,
          callbackArgs: {
            hTokenAddress: hifiPool.hToken.id,
            underlyingAddress: hifiPool.hToken.underlying.id,
            hifiPoolAddress: hifiPool.id,
          },
        }),
      );

      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Sell Position"),
      });
      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      addTransactions,
      debouncedSellInput,
      debouncedSellInputBn,
      account,
      hifiPool,
      isSellSubmitDisabled,
      isTransactionQueued,
      proxyTargetContract,
      setPendingTxId,
      t,
      underlyingPurchasedWithSlippageBn,
      dsProxyAddress,
      chainName,
    ],
  );

  return (
    <form onSubmit={onSellSubmit}>
      <DialogContentArea>
        <Box mb={4}>
          <Typography display="inline" variant="h5" color="textSecondary">
            {t("Maturity Date")}:&nbsp;&nbsp;
          </Typography>
          <Typography variant="h5" display="inline">
            {new Date(hifiPool.hToken.maturity * 1000).toLocaleString()}
          </Typography>
        </Box>
        <MaxInputWithBalance
          autoFocus
          fullWidth
          balance={hTokenBalance && truncateDecimals(hTokenBalance, 6)}
          balanceLabel={t("Wallet")}
          tokenSymbol={`h${hifiPool.hToken.underlying.symbol}`}
          onChange={(e: React.ChangeEvent) => setSellInput((e.target as HTMLTextAreaElement).value)}
          value={paybackInput}
          style={{ fontSize: "1.75em" }}
          decimals={hifiPool.hToken.decimals}
          max={hTokenBalance}
          inputProps={{
            min: 0,
          }}
          error={!!sellInputErrorMsg}
          startAdornment={
            <InputAdornment position="start">
              <Jazzicon diameter={getInputJazziconSize(bp)} seed={jsNumberForAddress(hifiPool.hToken.id)} />
            </InputAdornment>
          }
        />
        {!!sellInputErrorMsg && (
          <Typography variant="body1">
            <FormHelperText error>{sellInputErrorMsg}</FormHelperText>
          </Typography>
        )}
        <Box display="flex" pt={2.5} pb={2.5} justifyContent="center">
          <ArrowDownwardIcon style={{ fontSize: "2.5em" }} />
        </Box>
        <MaxInput
          fullWidth
          disabled
          value={sellInputErrorMsg ? "" : debouncedSellInput ? underlyingPurchased : ""}
          style={{ fontSize: "1.75em" }}
          placeholder="0"
          startAdornment={
            <InputAdornment position="start">
              <TokenIcon style={{ fontSize: "1.15em" }} symbol={hifiPool.hToken.underlying.symbol as TokenSymbol} />
            </InputAdornment>
          }
        />
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            h{hifiPool.hToken.underlying.symbol} {t("Price")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {hTokenPriceStatus === "loading" && <InlineDottyProgress />}
            {hTokenPriceStatus === "success" &&
              hTokenPrice &&
              parseFloat(hTokenPrice) > 0 &&
              `${numberWithCommas(truncateDecimals(hTokenPrice, 2))} ${hifiPool.hToken.underlying.symbol}`}
            {hTokenPriceStatus !== "loading" && (!hTokenPriceStatus || parseFloat(hTokenPriceStatus) <= 0) && (
              <span>&ndash;</span>
            )}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Price Impact")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(hTokenPriceIsFetching || underlyingPurchasedIsFetching || loadingDebouncedSellInput) && (
              <InlineDottyProgress />
            )}
            {!loadingDebouncedSellInput &&
              !hTokenPriceIsFetching &&
              !underlyingPurchasedIsFetching &&
              underlyingPurchasedStatus === "success" &&
              hTokenPriceStatus === "success" &&
              typeof priceImpact === "number" &&
              `${parseFloat(priceImpact.toFixed(2)) === 0 ? "0" : priceImpact.toFixed(2)}%`}
            {!loadingDebouncedSellInput &&
              !underlyingPurchasedIsFetching &&
              !hTokenPriceIsFetching &&
              typeof priceImpact !== "number" && <span>&ndash;</span>}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Minimum Received")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(underlyingPurchasedIsFetching || loadingDebouncedSellInput) && <InlineDottyProgress />}
            {!loadingDebouncedSellInput &&
              !underlyingPurchasedIsFetching &&
              underlyingPurchasedStatus === "success" &&
              underlyingPurchasedWithSlippageBn &&
              `${numberWithCommas(
                formatUnits(underlyingPurchasedWithSlippageBn, hifiPool.hToken.underlying.decimals),
              )} ${hifiPool.hToken.underlying.symbol}`}
            {!loadingDebouncedSellInput && !underlyingPurchasedIsFetching && !underlyingPurchased && (
              <span>&ndash;</span>
            )}
          </Typography>
        </DialogDataRow>
      </DialogContentArea>
      <DialogContentArea>
        <Button
          type="submit"
          disabled={isSellSubmitDisabled}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          {t("Sell Position")}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </form>
  );
}
