import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { QueryObserverResult, useQuery } from "react-query";
import { HToken } from "../../api/getHifiPools/types";
import getHTokenDepositorBalance from "../../api/getHTokenDepositorBalance";
import useUserDSProxy from "../useUserDSProxy";

export function buildQueryKey({
  account,
  tokenAddress,
}: {
  account: string;
  tokenAddress: string;
}): [string, string, string] {
  return ["hTokenDepositorBalance", account, tokenAddress];
}

export default function useUserHTokenDepositorBalance({
  hToken,
  account,
  chainName,
}: {
  hToken: HToken;
  account: string;
  chainName: string;
}): QueryObserverResult<string | undefined> {
  const dsProxyRes = useUserDSProxy({
    chainName,
    account,
  });
  const { data: dsProxyAddress } = dsProxyRes;

  const resp = useQuery(
    buildQueryKey({ account: dsProxyAddress || "", tokenAddress: hToken.id }),
    () => {
      if (dsProxyAddress) return getHTokenDepositorBalance({ hToken, depositor: dsProxyAddress });
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!dsProxyAddress,
    },
  );

  const { status, isFetching } = useCombinedQueryHooks([dsProxyRes, resp]);

  return {
    ...resp,
    status,
    isFetching,
  } as QueryObserverResult<string | undefined>;
}
