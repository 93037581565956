import { Collateral } from "../../api/getCollaterals/types";
import useCollaterals from "../useCollaterals";
import useHifiPools from "../useHifiPools";
import { HifiPool } from "../../api/getHifiPools/types";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";
import { useCallback } from "react";

export default function useEligibleCollaterals(chainName: string): QueryResponse<Collateral[]> {
  const hifiPoolsQueryRes = useHifiPools(chainName);
  const collateralsQueryRes = useCollaterals(chainName);

  return useCombinedQueryHooks(
    [hifiPoolsQueryRes, collateralsQueryRes],
    useCallback(data => {
      const [hifiPools, collaterals] = data as [HifiPool[], Collateral[]];
      return collaterals.filter(collateral => {
        return (
          // Filter out the collaterals that are underlying for the pools
          !hifiPools.find(pool => pool.hToken.underlying.id === collateral.id)
        );
      });
    }, []),
  );
}
