import { BalanceSheetV2__factory } from "@hifi/protocol/dist/types/factories/contracts/core/balance-sheet/BalanceSheetV2__factory";
import { HToken__factory } from "@hifi/protocol/dist/types/factories/contracts/core/h-token/HToken__factory";
// import { HifiProxyTarget__factory } from "@hifi/proxy-target/dist/types/factories/contracts/HifiProxyTarget__factory";
import { Fintroller__factory } from "@hifi/protocol/dist/types/factories/contracts/core/fintroller/Fintroller__factory";
import { ChainlinkOperator__factory } from "@hifi/protocol/dist/types/factories/contracts/oracles/ChainlinkOperator__factory";
import { HifiPool__factory } from "@hifi/amm/dist/types/factories/contracts/HifiPool__factory";
import proxyRegistryAbi from "./proxyRegistry.json";
import wethAbi from "./weth.json";
import proxyTargetAbi from "./proxyTarget.json";
import wbtcAbi from "./wbtc.json";
import usdcAbi from "./usdc.json";

import dsProxyRegistryAbi from "./dsProxyRegistry.json";
import dsProxyAbi from "./dsProxy.json";
import erc20Abi from "./erc20.json";
import erc20PermitAbi from "./erc20Permit.json";
import erc721aAbi from "./erc721a.json";
import erc721EnumerableAbi from "./erc721Enumerable.json";

const _abis: Record<string, Record<string, unknown>[]> = {
  erc20: erc20Abi,
  erc20Permit: erc20PermitAbi,
  dsProxyRegistry: dsProxyRegistryAbi,
  dsProxy: dsProxyAbi,
  erc721a: erc721aAbi,
  erc721Enumerable: erc721EnumerableAbi,
};

const abis = {
  ["balanceSheet" as string]: BalanceSheetV2__factory.abi,
  // ["proxyTarget" as string]: HifiProxyTarget__factory.abi,
  ["hToken" as string]: HToken__factory.abi,
  ["fintroller" as string]: Fintroller__factory.abi,
  ["oracle" as string]: ChainlinkOperator__factory.abi,
  ["hifiPool" as string]: HifiPool__factory.abi,
  ["dsProxyRegistry" as string]: proxyRegistryAbi,
  ["dsProxy" as string]: dsProxyAbi,
  ["proxyTarget" as string]: proxyTargetAbi,
  ["WETH" as string]: wethAbi,
  ["WBTC" as string]: wbtcAbi,
  ["USDC" as string]: usdcAbi,
  ..._abis,
};

export default abis;
