import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { init } from "./utils/analytics";
import "./locales/i18n";
import { firebaseConfig } from "./config";

if (process.env.NODE_ENV === "production") {
  init(firebaseConfig);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
