import { DependencyList, useMemo, useRef } from "react";

export default function useMemoRef<T>(factory: (lastValue: T | undefined) => T, deps: DependencyList): T {
  const lastValue = useRef<T>();

  return useMemo<T>(() => {
    lastValue.current = factory(lastValue.current);
    return lastValue.current;
  }, deps); // eslint-disable-line
}
