import SvgIcon from "@mui/material/SvgIcon";
import { WrappedBitcoinProps } from "./types";

export default function WrappedBitcoin({ style }: WrappedBitcoinProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 45 45" style={style}>
      <path
        d="M35.2732 10.7496L34.1611 11.8616C36.8146 14.7634 38.2861 18.5531 38.2861 22.4852C38.2861 26.4172 36.8146 30.207 34.1611 33.1087L35.2732 34.2207C38.2241 31.0199 39.8624 26.8258 39.8624 22.4722C39.8624 18.1186 38.2241 13.9245 35.2732 10.7236V10.7496Z"
        fill="#EFEFEF"
      />
      <path
        d="M11.888 10.8458C14.7897 8.19237 18.5795 6.72087 22.5115 6.72087C26.4436 6.72087 30.2333 8.19237 33.1351 10.8458L34.2471 9.73381C31.0463 6.78287 26.8521 5.14453 22.4986 5.14453C18.145 5.14453 13.9509 6.78287 10.75 9.73381L11.888 10.8458Z"
        fill="#EFEFEF"
      />
      <path
        d="M10.8458 33.12C8.19551 30.2189 6.72596 26.4315 6.72596 22.502C6.72596 18.5725 8.19551 14.7851 10.8458 11.884L9.73382 10.772C6.78287 13.9728 5.14453 18.167 5.14453 22.5205C5.14453 26.8741 6.78287 31.0682 9.73382 34.2691L10.8458 33.12Z"
        fill="#EFEFEF"
      />
      <path
        d="M33.1199 34.1465C30.2181 36.8 26.4284 38.2715 22.4963 38.2715C18.5643 38.2715 14.7745 36.8 11.8728 34.1465L10.7607 35.2585C13.9616 38.2095 18.1557 39.8478 22.5093 39.8478C26.8629 39.8478 31.057 38.2095 34.2579 35.2585L33.1199 34.1465Z"
        fill="#EFEFEF"
      />
      <path
        d="M29.5205 18.7896C29.2981 16.4692 27.2965 15.6908 24.7647 15.4535V12.2583H22.8076V15.3942C22.2923 15.3942 21.766 15.3942 21.2433 15.3942V12.2583H19.301V15.4758H15.3311V17.5701C15.3311 17.5701 16.7767 17.5441 16.7544 17.5701C17.0151 17.5414 17.2767 17.615 17.4842 17.7755C17.6916 17.9359 17.8287 18.1706 17.8665 18.43V27.2373C17.8609 27.3288 17.837 27.4183 17.7963 27.5004C17.7555 27.5825 17.6987 27.6557 17.6292 27.7155C17.561 27.7764 17.4812 27.8229 17.3946 27.8522C17.308 27.8815 17.2163 27.893 17.1251 27.886C17.1511 27.9082 15.7017 27.886 15.7017 27.886L15.3311 30.2249H19.2639V33.4943H21.2211V30.2731H22.7853V33.4795H24.7462V30.2472C28.0526 30.047 30.3582 29.2315 30.6474 26.1364C30.8809 23.6454 29.7096 22.5334 27.8376 22.0849C28.9756 21.5252 29.6799 20.4873 29.5205 18.7896ZM26.7775 25.7509C26.7775 28.1825 22.6111 27.9045 21.2841 27.9045V23.5898C22.6111 23.5936 26.7775 23.2118 26.7775 25.7509ZM25.8694 19.6718C25.8694 21.8959 22.3924 21.6253 21.2878 21.6253V17.7035C22.3924 17.7035 25.8694 17.3551 25.8694 19.6718Z"
        fill="#F09242"
      />
      <path
        d="M22.4963 42.75C18.4915 42.7493 14.5769 41.5611 11.2474 39.3357C7.91787 37.1103 5.32295 33.9477 3.79074 30.2476C2.25852 26.5476 1.85782 22.4763 2.63929 18.5485C3.42076 14.6207 5.34931 11.0129 8.18109 8.18109C11.0129 5.34931 14.6207 3.42076 18.5485 2.63929C22.4763 1.85782 26.5476 2.25852 30.2476 3.79074C33.9477 5.32295 37.1103 7.91787 39.3357 11.2474C41.5611 14.5769 42.7493 18.4915 42.75 22.4963C42.7505 25.1562 42.2269 27.7901 41.2093 30.2476C40.1916 32.7051 38.6997 34.9381 36.8189 36.8189C34.9381 38.6997 32.7051 40.1916 30.2476 41.2093C27.7901 42.2269 25.1562 42.7505 22.4963 42.75ZM22.4963 3.82909C18.8067 3.83202 15.2008 4.9287 12.1343 6.98052C9.06781 9.03234 6.67844 11.9472 5.26818 15.3566C3.85792 18.7661 3.49007 22.5171 4.21114 26.1356C4.9322 29.754 6.70981 33.0775 9.31927 35.686C11.9287 38.2944 15.2529 40.0707 18.8717 40.7903C22.4904 41.5099 26.2413 41.1406 29.6502 39.729C33.0591 38.3174 35.973 35.9269 38.0236 32.8596C40.0742 29.7923 41.1695 26.1859 41.1709 22.4963C41.1719 20.044 40.6895 17.6155 39.7512 15.3498C38.813 13.0841 37.4374 11.0255 35.703 9.29179C33.9686 7.55809 31.9095 6.18326 29.6434 5.24593C27.3773 4.30859 24.9486 3.82714 22.4963 3.82909Z"
        fill="white"
      />
    </SvgIcon>
  );
}
