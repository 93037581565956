import { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import MaxInputWithBalance from "../../components/MaxInputWithBalance";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { HifiPool } from "../../api/getHifiPools/types";
import DialogContentArea from "../../components/DialogContentArea";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import useDebounce from "hooks/useDebounce";
import { InlineDottyProgress } from "../../components/DottyProgress";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { One } from "@ethersproject/constants";
import FormHelperText from "@mui/material/FormHelperText";
import { multiplyBigNumberByScalar, numberWithCommas, truncateDecimals } from "utils/numbers";
import useUserBorrowLimitUsed from "../../hooks/useUserBorrowLimitUsed";
import { logEvent } from "../../utils/analytics";
import { BigNumber } from "ethers";
import {
  approve,
  sellUnderlyingAndRepayBorrow,
  buyHTokenAndRepayBorrow,
  depositUnderlyingAndRepayBorrow,
  permit,
} from "../../utils/transactions";
import { useProxyTargetContract } from "../../hooks/contracts";
import { getContractAddress, isTokenPermissive } from "../../contracts/addresses";
import { useTranslation } from "react-i18next";
import useQuoteForSellingUnderlying from "../../hooks/useQuoteForSellingUnderlying";
import usePaybackError from "../../hooks/usePaybackError";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import useAllowance from "hooks/useAllowance";
import DialogDataRow from "../../components/DialogDataRow";
import isExpired from "../../utils/isExpired";
import useQuoteForBuyingHToken from "../../hooks/useQuoteForBuyingHToken";
import useDebtInUnderlying from "../../hooks/useDebtInUnderlying";
import { displayBorrowLimitUsedChange } from "../ManageBorrowDialog";
import { Web3Provider } from "@ethersproject/providers";
import InfoIcon from "../../components/InfoIcon";
import IconTypography from "../../components/IconTypography";
import useUserDebts from "../../hooks/useUserDebts";

export default function RepayTab({
  onCloseClick,
  hifiPool,
  account,
  chainName,
  setPendingTxId,
  paybackInput,
  setPaybackInput,
  balance,
  walletProvider,
}: {
  onCloseClick: () => void;
  hifiPool: HifiPool;
  account: string;
  chainName: string;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
  paybackInput: string;
  setPaybackInput: React.Dispatch<React.SetStateAction<string>>;
  balance?: string;
  walletProvider?: Web3Provider;
}): JSX.Element {
  const [isRepayMax, setIsRepayMax] = useState(false);

  const { addTransactions, isTransactionQueued } = useContext(TransactionsContext);

  const { debouncedValue: debouncedPaybackInput, loading: loadingDebouncedPaybackInput } = useDebounce(paybackInput);

  const { t } = useTranslation();

  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });
  const { data: underlyingAllowance, isLoading: underlyingAllowanceIsLoading } = useAllowance({
    token: hifiPool.hToken.underlying,
    spenderAddress: dsProxyAddress,
    account,
  });

  const {
    data: hTokensRepaid,
    // status: hTokensRepaidStatus,
    isFetching: hTokensRepaidIsFetching,
    isLoading: hTokensRepaidIsLoading,
  } = useQuoteForSellingUnderlying({
    underlyingIn: debouncedPaybackInput,
    hifiPool,
  });

  const { data: underlyingBalance } = useUserErc20Balance({
    token: hifiPool.hToken.underlying,
    account,
    walletProvider,
  });

  const { isError: isInsufficientLiquidity } = useQuoteForBuyingHToken({
    hifiPool,
    hTokenOut: balance || "",
  });

  const { data: userDebts, status: userDebtsStatus } = useUserDebts({ chainName, account });

  // Value conversions --------------------------------------------------------------------------------------------|
  const balanceBn = useMemo(() => {
    if (balance) {
      return parseUnits(balance, hifiPool.hToken.decimals);
    }
  }, [balance, hifiPool]);

  const debouncedPaybackInputBn = useMemo(() => {
    if (debouncedPaybackInput) {
      return parseUnits(debouncedPaybackInput, hifiPool.hToken.underlying.decimals);
    }
  }, [debouncedPaybackInput, hifiPool]);

  const underlyingBalanceBn = useMemo(() => {
    if (underlyingBalance) {
      return parseUnits(underlyingBalance, hifiPool.hToken.underlying.decimals);
    }
  }, [underlyingBalance, hifiPool]);

  const hTokensRepaidBn = useMemo(() => {
    if (hTokensRepaid) {
      return parseUnits(hTokensRepaid, hifiPool.hToken.decimals);
    }
  }, [hTokensRepaid, hifiPool]);
  // --------------------------------------------------------------------------------------------------------------|

  const { data: _debtInUnderlying, status: debtInUnderlyingStatus } = useDebtInUnderlying({
    hifiPool,
    balance: balance || "",
  });
  let debtInUnderlying: BigNumber | undefined;
  if (_debtInUnderlying?.eq(0) && balanceBn?.gt(0)) {
    // If there is a dusty position, allow user to payoff but submitting one unit of underlying
    debtInUnderlying = One;
  } else {
    debtInUnderlying = _debtInUnderlying;
  }

  // Determine if user wants to repay entire debt -----------------------------------------------------------------|

  // Determine if the user has clicked the Max button
  const isUserRepayingEntireDebt =
    debouncedPaybackInputBn && debtInUnderlying && debouncedPaybackInputBn.eq(debtInUnderlying);

  useEffect(() => {
    // Reset the isRepayMax state anytime the input has changed.
    setIsRepayMax(false);
  }, [paybackInput]);

  useEffect(() => {
    // If we determined that the user clicked the Max button at some point, then set isRepayMax to true.  We do
    // this because in the isUserRepayingEntireDebt above, debtInUnderlying changes with the market as it gets
    // refetched at an interval of time.  This is why we set isRepayMax to false when the whenever input changes.
    if (isUserRepayingEntireDebt) {
      setIsRepayMax(true);
    }
  }, [isUserRepayingEntireDebt]);
  // --------------------------------------------------------------------------------------------------------------|

  // Borrow limit used fetches -------------------------------------------------------------------------------------|
  const { data: currentBorrowLimitUsed, status: currentBorrowLimitUsedStatus } = useUserBorrowLimitUsed({
    chainName,
    account,
  });

  const { data: newBorrowLimitUsed, status: newBorrowLimitUsedStatus } = useUserBorrowLimitUsed({
    chainName,
    account,
    debtAmountModify: isRepayMax
      ? `-${balance}`
      : hTokensRepaid
      ? `-${hTokensRepaid}`
      : debouncedPaybackInput.includes("-")
      ? ""
      : debouncedPaybackInput && `-${debouncedPaybackInput}`,
  });
  // --------------------------------------------------------------------------------------------------------------|

  // Max button calcs ---------------------------------------------------------------------------------------------|
  const suggestedMaxRepay = useMemo(() => {
    if (debtInUnderlying && underlyingBalanceBn) {
      const val = debtInUnderlying.gt(underlyingBalanceBn) ? underlyingBalanceBn : debtInUnderlying;
      return formatUnits(val, hifiPool.hToken.underlying.decimals);
    }
  }, [underlyingBalanceBn, debtInUnderlying, hifiPool]);
  // --------------------------------------------------------------------------------------------------------------|

  // Loading, disabled, and error calcs ---------------------------------------------------------------------------|
  const paybackInputErrorMsg = usePaybackError({
    debouncedPaybackInputBn,
    debtInUnderlyingBn: debtInUnderlying,
    underlyingBalanceBn,
    hTokensRepaidIsError: false,
    hTokensRepaidIsLoading,
  });

  const loadingRepay =
    debtInUnderlyingStatus === "loading" ||
    loadingDebouncedPaybackInput ||
    underlyingAllowanceIsLoading ||
    hTokensRepaidIsFetching;

  const isRepaySubmitDisabled =
    loadingRepay ||
    !debouncedPaybackInputBn ||
    debouncedPaybackInputBn.lte(BigNumber.from("0")) ||
    // debtInUnderlyingStatus === "error" ||
    !!paybackInputErrorMsg ||
    userDebtsStatus !== "success";
  // --------------------------------------------------------------------------------------------------------------|

  const proxyTargetContract = useProxyTargetContract();
  const onRepaySubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (
        isRepaySubmitDisabled ||
        !debouncedPaybackInputBn ||
        !proxyTargetContract ||
        !dsProxyAddress ||
        !balanceBn ||
        !userDebts
      ) {
        return;
      }

      const txs = [];

      const maxUnderlyingInBn = multiplyBigNumberByScalar(
        debouncedPaybackInputBn,
        1.02,
        hifiPool.hToken.underlying.decimals,
      );

      if (isTokenPermissive(chainName, hifiPool.hToken.underlying.id)) {
        let permitAmount = debouncedPaybackInputBn.toString();
        if (isRepayMax) {
          if (!isInsufficientLiquidity && !isExpired(hifiPool)) {
            permitAmount = maxUnderlyingInBn.toString();
          } else {
            // Make sure that we send enough underlying to eliminate dusty position
            permitAmount = balanceBn.div(hifiPool.underlyingPrecisionScalar).add(One).toString();
          }
        }
        txs.push(
          permit({
            contractAddress: hifiPool.hToken.underlying.id,
            description: t("transactions.messages.allowance", {
              spender: "Hifi",
              token: hifiPool.hToken.underlying.symbol,
            }),
            ownerAddress: account,
            spenderAddress: dsProxyAddress,
            amount: permitAmount,
            // !isInsufficientLiquidity && !isExpired(hifiPool) && isRepayMax
            //   ? maxUnderlyingInBn.toString()
            //   : debouncedPaybackInputBn.toString(),
          }),
        );
      } else {
        if (
          underlyingAllowance === "0" &&
          !isTransactionQueued({ methodName: "approve", contractAddress: hifiPool.hToken.underlying.id })
        ) {
          txs.push(
            approve({
              description: t("transactions.messages.allowance", {
                spender: "Hifi",
                token: hifiPool.hToken.underlying.symbol,
              }),
              contractAddress: hifiPool.hToken.underlying.id,
              spenderAddress: dsProxyAddress,
              callbackArgs: {
                spenderAddress: "DSPROXY_ADDRESS",
                tokenAddress: hifiPool.hToken.underlying.id,
              },
            }),
          );
        }
      }

      logEvent("paybackClick", {
        withdrawInput: debouncedPaybackInput,
        hTokenAddress: hifiPool.hToken.id,
      });

      const callbackArgs = {
        hifiPoolAddress: hifiPool.id,
        underlyingAddress: hifiPool.hToken.underlying.id,
      };
      const description = t("transactions.payback.description", {
        amount: numberWithCommas(formatUnits(debouncedPaybackInputBn, hifiPool.hToken.underlying.decimals)),
        token: hifiPool.hToken.underlying.symbol,
      });

      const completionMessage = t("transactions.payback.completionMessage", {
        amount: numberWithCommas(formatUnits(debouncedPaybackInputBn, hifiPool.hToken.underlying.decimals)),
        token: hifiPool.hToken.underlying.symbol,
      });

      if (!isInsufficientLiquidity && !isExpired(hifiPool)) {
        if (isRepayMax) {
          // If the user has entered in the total amount they owe, then we need to buy an exact amount of hTokens
          // instead of selling an exact amount of underlying
          txs.push(
            buyHTokenAndRepayBorrow({
              contractAddress: dsProxyAddress,
              maxUnderlyingIn: maxUnderlyingInBn,
              hTokenOut: balanceBn,
              hifiPoolAddress: hifiPool.id,
              balanceSheetAddress: getContractAddress(chainName, "balanceSheet"),
              proxyTargetContract,
              description,
              completionMessage,
              hasSignature: isTokenPermissive(chainName, hifiPool.hToken.underlying.id),
              callbackArgs,
            }),
          );
        } else if (hTokensRepaidBn) {
          txs.push(
            sellUnderlyingAndRepayBorrow({
              contractAddress: dsProxyAddress,
              underlyingIn: debouncedPaybackInputBn,
              borrowPositions: Object.keys(userDebts).length,
              minHTokenOut: multiplyBigNumberByScalar(hTokensRepaidBn, 0.98, hifiPool.hToken.decimals),
              hifiPoolAddress: hifiPool.id,
              balanceSheetAddress: getContractAddress(chainName, "balanceSheet"),
              proxyTargetContract,
              description,
              completionMessage,
              hasSignature: isTokenPermissive(chainName, hifiPool.hToken.underlying.id),
              callbackArgs,
            }),
          );
        }
      } else {
        // AMM has insufficient liquiidty so we will mint HTokens
        const underlyingAmount = isRepayMax
          ? // Make sure that we send enough underlying to eliminate dusty position
            balanceBn.div(hifiPool.underlyingPrecisionScalar).add(One)
          : debouncedPaybackInputBn;

        txs.push(
          depositUnderlyingAndRepayBorrow({
            contractAddress: dsProxyAddress,
            underlyingAmount,
            hTokenAddress: hifiPool.hToken.id,
            balanceSheetAddress: getContractAddress(chainName, "balanceSheet"),
            proxyTargetContract,
            description,
            completionMessage,
            underlyingAddress: hifiPool.hToken.underlying.id,
            hasSignature: isTokenPermissive(chainName, hifiPool.hToken.underlying.id),
            callbackArgs,
          }),
        );
      }

      const pendingTxIds = addTransactions({
        batchName: t("Repay"),
        transactions: txs,
      });
      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      isRepaySubmitDisabled,
      addTransactions,
      chainName,
      debouncedPaybackInput,
      debouncedPaybackInputBn,
      dsProxyAddress,
      hifiPool,
      isTransactionQueued,
      proxyTargetContract,
      setPendingTxId,
      hTokensRepaidBn,
      t,
      underlyingAllowance,
      balanceBn,
      isRepayMax,
      isInsufficientLiquidity,
      account,
      userDebts,
    ],
  );

  return (
    <form onSubmit={onRepaySubmit}>
      <DialogContentArea>
        <Box mb={4}>
          <Typography display="inline" variant="h5" color="textSecondary">
            {t("Maturity Date")}:&nbsp;&nbsp;
          </Typography>
          <Typography variant="h5" display="inline">
            {new Date(hifiPool.hToken.maturity * 1000).toLocaleString()}
          </Typography>
        </Box>
        <MaxInputWithBalance
          autoFocus
          fullWidth
          error={!!paybackInputErrorMsg}
          balance={underlyingBalance && truncateDecimals(underlyingBalance, 2)}
          balanceLabel={t("Wallet")}
          onChange={(e: React.ChangeEvent) => setPaybackInput((e.target as HTMLTextAreaElement).value)}
          value={paybackInput}
          tokenSymbol={hifiPool.hToken.underlying.symbol}
          style={{ fontSize: "1.75em" }}
          decimals={hifiPool.hToken.underlying.decimals}
          max={suggestedMaxRepay}
          inputProps={{
            min: 0,
          }}
          startAdornment={
            <InputAdornment position="start">
              <TokenIcon style={{ fontSize: "1.15em" }} symbol={hifiPool.hToken.underlying.symbol as TokenSymbol} />
            </InputAdornment>
          }
        />
        {!!paybackInputErrorMsg && (
          <Typography variant="h6">
            <FormHelperText error>{paybackInputErrorMsg}</FormHelperText>
          </Typography>
        )}
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <IconTypography variant="h6" color="textSecondary">
            {t("Debt")}
            <Tooltip title={t("tooltips.repay.debt") as string} arrow placement="top">
              <InfoIcon tabIndex={0} />
            </Tooltip>
          </IconTypography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {debtInUnderlyingStatus === "loading" && <InlineDottyProgress />}
            {debtInUnderlyingStatus === "success" &&
              debtInUnderlying &&
              debtInUnderlying.gt(BigNumber.from("0")) &&
              `${numberWithCommas(
                truncateDecimals(formatUnits(debtInUnderlying, hifiPool.hToken.underlying.decimals), 2),
              )} ${hifiPool.hToken.underlying.symbol}`}
            {debtInUnderlyingStatus !== "loading" &&
              (!debtInUnderlying || debtInUnderlying.lte(BigNumber.from("0"))) && <span>&ndash;</span>}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <Typography variant="h6" color="textSecondary">
            {t("Borrow Limit Used")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(currentBorrowLimitUsedStatus === "loading" ||
              newBorrowLimitUsedStatus === "loading" ||
              loadingDebouncedPaybackInput ||
              hTokensRepaidIsFetching) && <InlineDottyProgress />}
            {currentBorrowLimitUsedStatus !== "loading" &&
              newBorrowLimitUsedStatus !== "loading" &&
              !hTokensRepaidIsFetching &&
              !loadingDebouncedPaybackInput &&
              displayBorrowLimitUsedChange({
                currentValue: currentBorrowLimitUsed,
                newValue: newBorrowLimitUsed,
                currentValueStatus: currentBorrowLimitUsedStatus,
                newValueStatus: newBorrowLimitUsedStatus,
                isError:
                  currentBorrowLimitUsedStatus === "error" ||
                  newBorrowLimitUsedStatus === "error" ||
                  debtInUnderlyingStatus === "error" ||
                  (debouncedPaybackInputBn && debtInUnderlying && debouncedPaybackInputBn.gt(debtInUnderlying)),
              })}
          </Typography>
        </DialogDataRow>
      </DialogContentArea>
      <DialogContentArea>
        <Button
          disabled={isRepaySubmitDisabled}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
        >
          {t("Repay")} {hifiPool.hToken.underlying.symbol}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </form>
  );
}
