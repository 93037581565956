import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent as _logEvent, setUserProperties } from "firebase/analytics";
import { getDatabase, ref, push, Database } from "firebase/database";
import { FirebaseOptions } from "firebase/app";

let analytics: Analytics;
let database: Database;
let accountAddress: string;

export function init(config: FirebaseOptions): void {
  const app = initializeApp(config);
  analytics = getAnalytics(app);
  database = getDatabase(app);
}

export function setAccount(account: string): void {
  if (analytics) {
    let acc = account;
    try {
      acc = account.substr(2);
      accountAddress = acc;
      // eslint-disable-next-line
    } catch (e) {}
    setUserProperties(analytics, { account: acc });
  }
}

export function setNetwork(network: string): void {
  if (analytics) {
    setUserProperties(analytics, { network });
  }
}

export function logEvent(name: string, properties: Record<string, unknown>): void {
  if (analytics) {
    const accountAddressObj = accountAddress ? { accountAddress } : {};
    const eventData = { ...properties, ...accountAddressObj };

    if (typeof properties === "object") {
      Object.keys(properties).forEach(prop => {
        const val = Object(properties)[prop];
        if (typeof val === "string" && val.indexOf("0x") === 0) {
          Object(properties)[prop] = val.substr(2);
        }
      });
      _logEvent(analytics, name, eventData);
      storeEventInRTDB(name, eventData); // Store event in RTDB
    } else {
      if (accountAddress) {
        _logEvent(analytics, name, accountAddressObj);
        storeEventInRTDB(name, accountAddressObj); // Store event in RTDB
      } else {
        _logEvent(analytics, name);
        storeEventInRTDB(name, {}); // Store event in RTDB
      }
    }
  }
}

function storeEventInRTDB(eventName: string, data: Record<string, unknown>) {
  const eventsRef = ref(database, "events");
  push(eventsRef, {
    eventName,
    timestamp: Date.now(),
    ...data,
  });
}
