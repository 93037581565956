import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Dialog } from "../../components/ActionDialog";
import RatioText from "../../components/RatioText";
import { Web3Provider } from "@ethersproject/providers";
import { Collateral } from "../../api/getCollaterals/types";
import useUserLockedCollaterals from "../../hooks/useUserLockedCollaterals";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import { QueryStatus } from "react-query";
import { useTranslation } from "react-i18next";
import usePendingTransactionUI from "../../hooks/usePendingTransactionUI";
import SingleTaskPanel from "../../components/SingleTaskPanel";
import WithdrawTab from "../WithdrawTab";
import { isWeth } from "../../contracts/addresses";
import DepositEthTab from "../DepositEthTab";
import DepositErc20Tab from "../DepositErc20Tab";

export function displayBorrowLimitChange({
  currentValue,
  currentValueStatus,
  newValue,
  newValueStatus,
}: {
  currentValue?: string;
  currentValueStatus: QueryStatus;
  newValue?: string;
  newValueStatus: QueryStatus;
}): JSX.Element {
  const newValueFloat = newValue && parseFloat(newValue);
  const currentValueFloat = currentValue && parseFloat(currentValue);
  if (
    currentValueStatus !== "success" ||
    newValueStatus !== "success" ||
    (!currentValueFloat && !newValueFloat) ||
    (!!newValueFloat && newValueFloat < 0)
  ) {
    return <span>&ndash;</span>;
  } else if (!!currentValue && (!newValue || newValue === currentValue)) {
    return <span>${numberWithCommas(truncateDecimals(currentValue, 2))}</span>;
  } else {
    return (
      <span>
        ${truncateDecimals(numberWithCommas(currentValue || 0), 2)}&nbsp;&nbsp;&rarr;&nbsp;&nbsp;$
        {truncateDecimals(numberWithCommas(newValue || 0), 2)}
      </span>
    );
  }
}

export function displayCurrentSupplyChange({
  currentValue,
  newValue,
  symbol,
}: {
  currentValue?: string;
  newValue?: string;
  symbol: string;
}): JSX.Element {
  const newValueFloat = newValue && parseFloat(newValue);
  const currentValueFloat = currentValue && parseFloat(currentValue);
  if ((!currentValueFloat && !newValueFloat) || (newValueFloat && newValueFloat < 0)) {
    return <span>&ndash;</span>;
  } else if (!!currentValue && (!newValue || newValue === currentValue)) {
    return (
      <span>
        {numberWithCommas(currentValue)} {symbol}
      </span>
    );
  } else {
    return (
      <span>
        {numberWithCommas(currentValue || "0")}&nbsp;&nbsp;&rarr;&nbsp;&nbsp;{numberWithCommas(newValue || "0")}{" "}
        {symbol}
      </span>
    );
  }
}

export function displayBorrowLimitUsedChange({
  currentValue,
  currentValueStatus,
  newValue,
  newValueStatus,
}: {
  currentValue?: string;
  currentValueStatus: QueryStatus;
  newValue?: string;
  newValueStatus: QueryStatus;
}): JSX.Element {
  const newValueFloat = newValue && parseFloat(newValue);
  const currentValueFloat = currentValue && parseFloat(currentValue);
  if (
    currentValueStatus !== "success" ||
    newValueStatus !== "success" ||
    (!newValueFloat && !currentValueFloat) ||
    (!!newValueFloat && newValueFloat < 0)
  ) {
    return <span>&ndash;</span>;
  } else if ((!newValueFloat || newValueFloat === currentValueFloat) && !!currentValueFloat) {
    return <RatioText percent={Math.round(currentValueFloat * 100)} />;
  } else {
    return (
      <>
        <RatioText percent={Math.round((currentValueFloat || 0) * 100)} />
        &nbsp;&nbsp;&rarr;&nbsp;&nbsp;
        <RatioText percent={Math.round((newValueFloat || 0) * 100)} />
      </>
    );
  }
}

export default function ManageCollateralDialog({
  onCloseClick,
  actionType = "deposit",
  collateral,
  account,
  walletProvider,
  chainName,
}: {
  onCloseClick: () => void;
  actionType?: "deposit" | "withdraw";
  collateral: Collateral;
  account: string;
  walletProvider: Web3Provider | undefined;
  chainName: string;
}): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(actionType === "deposit" ? 0 : 1);
  const [depositInput, setDepositInput] = useState("");
  const [withdrawInput, setWithdrawInput] = useState("");

  const { t } = useTranslation();

  const {
    data: lockedCollaterals,
    status: lockedCollateralsStatus,
    isFetching: lockedCollateralsIsFetching,
  } = useUserLockedCollaterals({ chainName, account });
  // --------------------------------------------------------------------------------------------------------------------|

  const lockedCollateralFloat = lockedCollaterals ? parseFloat(lockedCollaterals[collateral.id] || "0") : 0;

  const { showTx, transaction, setPendingTxId } = usePendingTransactionUI(onCloseClick);

  const _onClose = useCallback(
    (_evt, _reason) => {
      if (_reason !== "backdropClick" || !transaction || !showTx) {
        onCloseClick();
      }
    },
    [onCloseClick, transaction, showTx],
  );

  return (
    <Dialog onClose={_onClose} open={!transaction}>
      {transaction && showTx && (
        <DialogContent>
          <SingleTaskPanel chainName={chainName} onClose={onCloseClick} transaction={transaction} />
        </DialogContent>
      )}
      {!showTx && (
        <>
          <Box>
            <Tabs
              value={selectedTab}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="inherit"
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <Tab disableRipple label={<Typography variant="h4">{t("Deposit")}</Typography>} />
              <Tab
                disabled={
                  lockedCollateralsStatus !== "success" || lockedCollateralsIsFetching || lockedCollateralFloat === 0
                }
                disableRipple
                label={<Typography variant="h4">{t("Withdraw")}</Typography>}
              />
            </Tabs>
            <Box position="relative" />
          </Box>
          <DialogContent>
            {selectedTab === 0 &&
              // work-around for using ETH as WETH
              (isWeth(chainName, collateral.id) ? (
                <DepositEthTab
                  onCloseClick={onCloseClick}
                  collateral={collateral}
                  account={account}
                  walletProvider={walletProvider}
                  chainName={chainName}
                  setDepositInput={setDepositInput}
                  depositInput={depositInput}
                  setPendingTxId={setPendingTxId}
                />
              ) : (
                <DepositErc20Tab
                  onCloseClick={onCloseClick}
                  collateral={collateral}
                  account={account}
                  walletProvider={walletProvider}
                  chainName={chainName}
                  setDepositInput={setDepositInput}
                  depositInput={depositInput}
                  setPendingTxId={setPendingTxId}
                />
              ))}
            {selectedTab === 1 && (
              <WithdrawTab
                onCloseClick={onCloseClick}
                collateral={collateral}
                account={account}
                chainName={chainName}
                setWithdrawInput={setWithdrawInput}
                withdrawInput={withdrawInput}
                setPendingTxId={setPendingTxId}
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
