import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { QueryObserverResult, useQuery } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import getQuoteForSellingUnderlying from "../../api/getQuoteForSellingUnderlying";
import isExpired from "../../utils/isExpired";
import useMemoRef from "hooks/useMemoRef";

export default function useQuoteForSellingUnderlying({
  underlyingIn,
  hifiPool,
}: {
  underlyingIn: string;
  hifiPool: HifiPool;
}): QueryObserverResult<string | undefined> {
  const { data, status, ...others } = useQuery(
    ["quoteForSellingUnderlying", hifiPool.id, underlyingIn],
    ({ queryKey }) => {
      if (parseUnits(queryKey[2], hifiPool.hToken.underlying.decimals).gt(BigNumber.from("0"))) {
        return getQuoteForSellingUnderlying({
          poolAddress: queryKey[1],
          underlyingIn: parseUnits(queryKey[2], hifiPool.hToken.underlying.decimals),
        });
      } else {
        return BigNumber.from("0");
      }
    },
    {
      cacheTime: 0.5 * 60 * 1000,
      staleTime: 0.5 * 60 * 1000,
      enabled:
        !!underlyingIn &&
        parseUnits(underlyingIn, hifiPool.hToken.underlying.decimals).gte(BigNumber.from("0")) &&
        !isExpired(hifiPool),
      refetchInterval: 0.5 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const _data = useMemoRef(
    lastValue => {
      if (status === "idle") {
        return;
      } else if (data && status === "success") {
        return formatUnits(data, hifiPool.hToken.decimals);
      }
      return lastValue;
    },
    [data, hifiPool, status],
  );
  return { data: _data, status, ...others } as QueryObserverResult<string | undefined>;
}
