import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/material/styles";

export default styled(ErrorOutlineIcon)(({ theme }) => ({
  [`&.MuiSvgIcon-root`]: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    transform: "rotate(180deg)",
    fontSize: 22,
  },
}));
