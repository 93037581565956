import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import { HifiPool } from "../../api/getHifiPools/types";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import useQuoteForBuyingHToken from "../../hooks/useQuoteForBuyingHToken";
import calcAPY from "../../utils/calcAPY";
import { InlineDottyProgress } from "../../components/DottyProgress";
import useHifiPoolTotalLiquidityInUnderlying from "../../hooks/useHifiPoolTotalLiquidityInUnderlying";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import IconTypography from "../../components/IconTypography";
import isExpired from "../../utils/isExpired";

export default function MarketRow({
  hifiPool,
  onMarketClick,
}: {
  hifiPool: HifiPool;
  onMarketClick: (hifiPool: HifiPool) => void;
}): JSX.Element {
  const {
    data: hTokenSpotPrice,
    status: hTokenSpotPriceStatus,
    isLoading: hTokenSpotPriceIsLoading,
  } = useQuoteForBuyingHToken({ hifiPool, hTokenOut: "1" });

  const { data: poolLiquidity, status: poolLiquidityStatus } = useHifiPoolTotalLiquidityInUnderlying(hifiPool);

  const isAPYError = !isExpired(hifiPool) && hTokenSpotPriceStatus === "error";

  const symbol = hifiPool.hToken.underlying.symbol as TokenSymbol;
  return (
    <TableRow
      hover={!isAPYError}
      onClick={() => {
        if (!isAPYError) onMarketClick(hifiPool);
      }}
    >
      <TableCell align="left">
        <IconTypography variant="h5">
          <Box display="flex" alignItems="center">
            <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          </Box>
          {symbol}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{new Date(hifiPool.hToken.maturity * 1000).toLocaleDateString()}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {!isExpired(hifiPool) && hTokenSpotPriceIsLoading && <InlineDottyProgress />}
          {isExpired(hifiPool) && <span>&ndash;</span>}
          {!isExpired(hifiPool) &&
            hTokenSpotPrice &&
            hTokenSpotPriceStatus === "success" &&
            `${(calcAPY(parseFloat(hTokenSpotPrice), 1, hifiPool.maturity * 1000) * 100).toFixed(2)}%`}
          {isAPYError && <span>&ndash;</span>}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {isExpired(hifiPool) && <span>&ndash;</span>}
          {!isExpired(hifiPool) && poolLiquidityStatus === "loading" && <InlineDottyProgress />}
          {!isExpired(hifiPool) &&
            poolLiquidityStatus === "success" &&
            !!poolLiquidity &&
            poolLiquidity > 0 &&
            `$${truncateDecimals(numberWithCommas(poolLiquidity), 2)}`}
          {poolLiquidityStatus === "success" && poolLiquidity === 0 && <span>&ndash;</span>}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
