import { MulticallBatcher } from "contexts/web3Base";
import { getMulticallContractByName } from "../../utils/contracts";
import { getErc20MulticallContract } from "utils/contracts";
import { Collateral } from "./types";
import BPromise from "bluebird";
import get from "lodash/get";
import { formatUnits } from "@ethersproject/units";
import deepFreeze from "utils/deepFreeze";
import { collateralAddresses, isWeth } from "../../contracts/addresses";

const fetchCollateral = async ({
  collateralAddress,
  chainName,
}: {
  collateralAddress: string;
  chainName: string;
}): Promise<Collateral> => {
  const fintrollerContract = getMulticallContractByName({ contractName: "fintroller", chainName });
  const collateralContract = getErc20MulticallContract({ address: collateralAddress });

  return new Promise(async (resolve, reject) => {
    try {
      // work-around for using ETH as WETH
      const res = isWeth(chainName, collateralAddress)
        ? await Promise.all([
            18,
            "Ether",
            "ETH",
            MulticallBatcher.queue(fintrollerContract.getCollateralRatio(collateralAddress)),
            MulticallBatcher.queue(fintrollerContract.getLiquidationIncentive(collateralAddress)),
            MulticallBatcher.queue(fintrollerContract.getCollateralCeiling(collateralAddress)),
          ])
        : await Promise.all([
            MulticallBatcher.queue(collateralContract.decimals()),
            MulticallBatcher.queue(collateralContract.name()),
            MulticallBatcher.queue(collateralContract.symbol()),
            MulticallBatcher.queue(fintrollerContract.getCollateralRatio(collateralAddress)),
            MulticallBatcher.queue(fintrollerContract.getLiquidationIncentive(collateralAddress)),
            MulticallBatcher.queue(fintrollerContract.getCollateralCeiling(collateralAddress)),
          ]);
      resolve(
        deepFreeze({
          id: collateralAddress.toLowerCase(),
          decimals: res[0],
          name: res[1],
          symbol: res[2],
          collateralizationRatio: formatUnits(res[3], 18),
          liquidationIncentive: res[4],
          ceiling: res[5],
        }),
      );
    } catch (e) {
      reject(e);
    }
  });
};

const getCollaterals = async (chainName: string): Promise<Collateral[]> => {
  const addresses: string[] = get(collateralAddresses, chainName, []);
  return BPromise.map(addresses, async collateralAddress => {
    return fetchCollateral({ chainName, collateralAddress });
  });
};

export default getCollaterals;
