import { memo, useCallback, useContext, useMemo, useState } from "react";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BigNumber } from "ethers";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Collateral } from "../../api/getCollaterals/types";
import Grid from "@mui/material/Grid";
import { HifiPool } from "../../api/getHifiPools/types";
import { LighterPaper } from "../../components/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import ManageBorrowDialog from "../../containers/ManageBorrowDialog";
import ManageCollateralDialog from "../../containers/ManageCollateralDialog";
import OverlayProgress from "../../components/OverlayProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Context as Web3Context } from "../../contexts/web3";
import { formatUnits, parseUnits } from "@ethersproject/units";
import isExpired from "../../utils/isExpired";
import useCollateralMap from "../../hooks/useCollateralMap";
import useEligibleCollaterals from "../../hooks/useEligibleCollaterals";
import useHTokenMap from "../../hooks/useHTokenMap";
import useHifiPools from "../../hooks/useHifiPools";
import { useTranslation } from "react-i18next";
import useUserBorrowLimitUsed from "../../hooks/useUserBorrowLimitUsed";
import useUserDebts from "../../hooks/useUserDebts";
import useUserEligibleLockedCollaterals from "../../hooks/useUserEligibleLockedCollaterals";
import useUserHypotheticalBorrowLimit from "../../hooks/useUserHypotheticalBorrowLimit";
import useUserTotalCollateraValueInHTokens from "../../hooks/useUserTotalCollateralValueInHTokens";
import useUserTotalDebtInUnderlying from "../../hooks/useUserTotalDebtInUnderlying";
import { One } from "@ethersproject/constants";
import { compact } from "lodash";
import HeadlineGrid from "../../components/HeadlineGrid";
import BorrowMarketsTable from "../../components/BorrowMarketsTable";
import CollateralsTable from "../../components/CollateralsTable";
import MyCollateralsTable from "../../components/MyCollateralsTable";
import MyDebtsTable from "../../components/MyDebtsTable";
import get from "lodash/get";
import collateralRatioColors from "../../utils/collateralRatioColors";
import Link from "@mui/material/Link";
import { usePageEvent } from "../../hooks/analytics";
import useSettings from "../../hooks/useSettings";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useTvl from "../../hooks/useTvl";
import numeral from "numeral";

type ProgressBarType = {
  value: number;
};
const ProgressBar = styled(LinearProgress)<ProgressBarType>(({ theme, value }) => ({
  "&": {
    height: ".75em",
    borderRadius: 400,

    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: get(theme.palette, collateralRatioColors(value)),
    },
  },
  "&.MuiLinearProgress-colorPrimary": {
    backgroundColor: theme.palette.text.secondary,
  },
}));

const _EmptyHeadline = () => {
  return (
    <HeadlineGrid container spacing={4} style={{ visibility: "hidden" }}>
      <Grid item>
        <LighterPaper>
          <Box p={6} display="flex" flexDirection="column" alignItems="center">
            <Typography color="textPrimary" variant="body2">
              &nbsp;
            </Typography>
            <Box mt={1}>
              <Typography variant="h4">&nbsp;</Typography>
            </Box>
          </Box>
        </LighterPaper>
      </Grid>
    </HeadlineGrid>
  );
};
const EmptyHeadline = memo(_EmptyHeadline);

export default function BorrowV1(): JSX.Element {
  usePageEvent("borrow");
  const { chainName, account, walletProvider, connect, connecting, initialized } = useContext(Web3Context);
  const [managedCollateral, setManagedCollateral] = useState<Collateral | null>(null);
  const [managedMarket, setManagedMarket] = useState<HifiPool | null>(null);

  // Debt fetch and data processing ----------------------------------------------------------------------------------|
  const { data: hifiPools, isLoading: isHifiPoolsLoading } = useHifiPools(chainName);
  const { data: userDebts, status: userDebtsStatus } = useUserDebts({ chainName, account });
  const { data: borrowLimit } = useUserHypotheticalBorrowLimit({ chainName, account });
  const { data: totalDebt, status: totalDebtStatus } = useUserTotalDebtInUnderlying({
    account,
    chainName,
  });
  const { data: hTokenMap, status: hTokenMapStatus } = useHTokenMap(chainName);
  const { data: borrowLimitUsed } = useUserBorrowLimitUsed({ chainName, account });

  const { showDustyPositions } = useSettings();

  const { t } = useTranslation();

  const debts = useMemo(() => {
    if (userDebts && hTokenMap && hifiPools && userDebtsStatus === "success" && hTokenMapStatus === "success") {
      return compact(
        Object.keys(userDebts).map(hTokenAddress => {
          const balance = userDebts[hTokenAddress];
          const hifiPool = hifiPools.find(hifiPool => hifiPool.hToken.id === hTokenAddress) as HifiPool;
          const truncatedFloat = parseFloat(truncateDecimals(balance, 2));
          // Ingore dusty debt positions (eg. positions < 0.000001, for underlying decimals === 6)
          if (
            (!showDustyPositions && truncatedFloat > 0) ||
            (showDustyPositions && parseUnits(balance, hifiPool.hToken.decimals).gt(0))
          ) {
            // if (parseUnits(balance, hifiPool.hToken.decimals).gt(0)) {
            return { hifiPool, balance };
          }
        }),
      ).sort((a, b) => a.hifiPool.hToken.maturity - b.hifiPool.hToken.maturity);
    }
    return [];
  }, [userDebts, userDebtsStatus, hTokenMapStatus, hTokenMap, hifiPools, showDustyPositions]) as Array<{
    hifiPool: HifiPool;
    balance: string;
  }>;

  const otherMarkets = useMemo(() => {
    if (userDebts && hifiPools) {
      return hifiPools.filter(hifiPool => {
        const truncatedDebt = parseFloat(truncateDecimals(userDebts[hifiPool.hToken.id] || "0", 2));
        // return !truncatedDebt && !isExpired(hifiPool);
        return (
          ((!showDustyPositions && truncatedDebt === 0) ||
            (showDustyPositions && parseUnits(userDebts[hifiPool.hToken.id] || "0", hifiPool.hToken.decimals).eq(0))) &&
          !isExpired(hifiPool)
        );
      });
    }
  }, [userDebts, hifiPools, showDustyPositions]);
  // ---------------------------------------------------------------------------------------------------------------|

  // Collateral fetch and data processing --------------------------------------------------------------------------|
  const { data: totalCollateralValue, status: totalCollateralValueStatus } = useUserTotalCollateraValueInHTokens({
    chainName,
    account,
  });
  const { data: collateralMap, status: collateralMapStatus } = useCollateralMap(chainName);
  const { data: collaterals, status: collateralsStatus } = useEligibleCollaterals(chainName);
  const { data: userLockedCollaterals, status: isUserLockedCollateralsStatus } = useUserEligibleLockedCollaterals({
    chainName,
    account,
  });

  const lockedCollaterals = useMemo(() => {
    if (
      userLockedCollaterals &&
      collateralMap &&
      isUserLockedCollateralsStatus === "success" &&
      collateralMapStatus === "success"
    ) {
      return compact(
        Object.keys(userLockedCollaterals).map(collaterlAddress => {
          const amountLocked = userLockedCollaterals[collaterlAddress];
          const collateral = collateralMap[collaterlAddress];
          if (parseUnits(amountLocked, collateral.decimals).gt(One)) {
            return { collateral, amountLocked };
          }
        }),
      ).sort((a, b) => parseFloat(b.amountLocked) - parseFloat(a.amountLocked));
    }
    return [];
  }, [userLockedCollaterals, collateralMapStatus, isUserLockedCollateralsStatus, collateralMap]);

  const otherCollaterals = useMemo(() => {
    if (collaterals) {
      return collaterals.filter(c => {
        const amountLocked = (userLockedCollaterals || {})[c.id];
        return !amountLocked || parseUnits(amountLocked, c.decimals).lte(One);
      });
    }
  }, [collaterals, userLockedCollaterals]);
  // ---------------------------------------------------------------------------------------------------------------|

  // Row click handlers --------------------------------------------------------------------------------------------|
  const onCollateralClick = useCallback((collateral: Collateral) => {
    setManagedCollateral(collateral);
  }, []);

  const onCloseManageCollateralClick = useCallback(() => {
    setManagedCollateral(null);
  }, []);

  const onMarketClick = useCallback((hifiPool: HifiPool) => {
    setManagedMarket(hifiPool);
  }, []);

  const onCloseManageMarketClick = useCallback(() => {
    setManagedMarket(null);
  }, []);
  // --------------------------------------------------------------------------------------------------------------|

  const truncatedBorrowLimit = borrowLimit && truncateDecimals(borrowLimit, 2);
  const truncatedBorrowLimitFloat = truncatedBorrowLimit && parseFloat(truncatedBorrowLimit);

  const { data: tvl, isLoading: isTvlLoading } = useTvl();

  return (
    <>
      {/* {totalCollateralValueStatus === "loading" && (
      <Box pb={15} mb={9} position="relative">
        <OverlayProgress size="3.25rem" />
      </Box>
    )} */}
      {totalCollateralValueStatus === "success" &&
        totalCollateralValue &&
        totalCollateralValue.gt(BigNumber.from("0")) && (
          <>
            <HeadlineGrid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={11} md={3} lg={2}>
                <LighterPaper>
                  <Box p={6} display="flex" flexDirection="column" alignItems="center">
                    <Typography color="textSecondary" variant="body2">
                      {t("Collateral Value")}
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="h4">
                        ${truncateDecimals(numberWithCommas(formatUnits(totalCollateralValue, 18)), 2)}
                      </Typography>
                    </Box>
                  </Box>
                </LighterPaper>
              </Grid>
              <Grid item xs={12} sm={11} md={6} lg={8}>
                <LighterPaper>
                  <Box p={6}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography color="textSecondary" variant="body2">
                        {t("Borrow Limit")} ({borrowLimitUsed ? Math.round(parseFloat(borrowLimitUsed) * 100) : 0}%)
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {truncatedBorrowLimit && `$${numberWithCommas(truncatedBorrowLimit)}`}
                      </Typography>
                    </Box>
                    <Box mt={4}>
                      <ProgressBar
                        variant="determinate"
                        value={
                          truncatedBorrowLimitFloat && truncatedBorrowLimitFloat > 0
                            ? Math.min(parseFloat(borrowLimitUsed || "0"), 1) * 100
                            : 0
                        }
                      />
                    </Box>
                  </Box>
                </LighterPaper>
              </Grid>
              <Grid item xs={12} sm={11} md={3} lg={2}>
                <LighterPaper>
                  <Box p={6} display="flex" flexDirection="column" alignItems="center">
                    <Typography color="textSecondary" variant="body2">
                      {t("Borrow Balance")}
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="h4">
                        {totalDebtStatus !== "success" && <span>&nbsp;</span>}
                        {totalDebtStatus === "success" &&
                          `$${truncateDecimals(numberWithCommas(totalDebt ? formatUnits(totalDebt, 6) : "0"), 2)}`}
                      </Typography>
                    </Box>
                  </Box>
                </LighterPaper>
              </Grid>
            </HeadlineGrid>
          </>
        )}

      {/* Show a blank spot for either the balances or the help banner to appear in */}
      {((totalCollateralValueStatus === "loading" && !!account) || !initialized) && <EmptyHeadline />}

      {((totalCollateralValueStatus === "success" &&
        totalCollateralValue &&
        totalCollateralValue.eq(BigNumber.from("0"))) ||
        (!account && initialized)) && (
        <HeadlineGrid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={11} lg={12} md={9}>
            <Box
              p={6}
              sx={{
                backgroundColor: "secondary.main",
                borderRadius: 1,
                position: "relative",
                overflow: "hidden",
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/papersquiggle-blue.svg);`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
              }}
            >
              <Carousel
                showArrows={false}
                showStatus={false}
                swipeable
                showThumbs={false}
                interval={5000}
                autoPlay={!isTvlLoading}
                infiniteLoop
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="fit-content"
                    position="relative"
                  >
                    <Typography variant="h4" position="absolute" sx={{ transform: "translateX(-100%)" }} left={-15}>
                      TVL
                    </Typography>
                    <Typography variant="h3">
                      {isTvlLoading && <Skeleton width={160} />}
                      {!!tvl && numeral(tvl).format("($0.00a)").toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Link
                    href="https://www.youtube.com/watch?v=xWDkY2W5WaQ"
                    style={{ marginBottom: ".2em", display: "inline-block" }}
                    color="textPrimary"
                    variant="h4"
                    underline="none"
                    target="_blank"
                  >
                    {t("helpBanners.borrow.step0.title")}
                    <ArrowForwardIcon style={{ verticalAlign: "middle", marginLeft: ".2em" }} />
                  </Link>
                  <Typography variant="body1">{t("helpBanners.borrow.step0.subtitle")}</Typography>
                </Box>
              </Carousel>
            </Box>
          </Grid>
        </HeadlineGrid>
      )}

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={11} lg={6} md={9} sx={{ marginBottom: 9 }}>
          {(collateralsStatus === "loading" || isUserLockedCollateralsStatus === "loading") && (
            <Box pt={15} position="relative">
              <OverlayProgress size="3.25rem" />
            </Box>
          )}
          {collateralsStatus !== "loading" && isUserLockedCollateralsStatus !== "loading" && (
            <Grid container spacing={6}>
              {!!lockedCollaterals.length && (
                <MyCollateralsTable collaterals={lockedCollaterals} onCollateralClick={onCollateralClick} />
              )}

              {!!otherCollaterals?.length && (
                <CollateralsTable
                  collaterals={otherCollaterals}
                  chainName={chainName}
                  onCollateralClick={onCollateralClick}
                />
              )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={11} lg={6} md={9}>
          {(isHifiPoolsLoading || userDebtsStatus === "loading") && (
            <Box pt={15} position="relative">
              <OverlayProgress size="3.25rem" />
            </Box>
          )}
          {!isHifiPoolsLoading && userDebtsStatus !== "loading" && (
            <Grid container spacing={6}>
              {!!debts.length && <MyDebtsTable debts={debts} onMarketClick={onMarketClick} />}

              {!!otherMarkets?.length && (
                <BorrowMarketsTable
                  markets={otherMarkets}
                  onMarketClick={onMarketClick}
                  title={debts.length ? t("Other Borrow Markets") : t("Borrow Markets")}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {managedCollateral && (
        <ManageCollateralDialog
          collateral={managedCollateral}
          account={account}
          walletProvider={walletProvider}
          chainName={chainName}
          onCloseClick={onCloseManageCollateralClick}
        />
      )}
      {managedMarket && (
        <ManageBorrowDialog
          hifiPool={managedMarket}
          actionType={isExpired(managedMarket) ? "payback" : "borrow"}
          balance={userDebts && userDebts[managedMarket.hToken.id]}
          account={account}
          chainName={chainName}
          onCloseClick={onCloseManageMarketClick}
          walletProvider={walletProvider}
          connect={connect}
          connecting={connecting}
        />
      )}
    </>
  );
}
