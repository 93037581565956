import { useCallback, useContext, useMemo } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import MaxInputWithBalance from "../../components/MaxInputWithBalance";
import TokenIcon from "../../icons/TokenIcon";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { Collateral } from "../../api/getCollaterals/types";
import useUserLockedCollaterals from "../../hooks/useUserLockedCollaterals";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { InlineDottyProgress } from "../../components/DottyProgress";
import DialogContentArea from "../../components/DialogContentArea";
import useUserHypotheticalBorrowLimit from "../../hooks/useUserHypotheticalBorrowLimit";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import useUserTotalDebt from "../../hooks/useUserTotalDebt";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import { withdrawCollateral } from "../../utils/transactions";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../utils/analytics";
import { useProxyTargetContract } from "../../hooks/contracts";
import { getContractAddress, isWeth } from "../../contracts/addresses";
import useWithdrawableCollateral from "../../hooks/useWithdrawableCollateral";
import FormHelperText from "@mui/material/FormHelperText";
import useUserBorrowLimitUsed from "../../hooks/useUserBorrowLimitUsed";
import DialogDataRow from "../../components/DialogDataRow";
import {
  displayBorrowLimitChange,
  displayBorrowLimitUsedChange,
  displayCurrentSupplyChange,
} from "../ManageCollateralDialog";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import useUserDebts from "../../hooks/useUserDebts";

export default function WithdrawTab({
  onCloseClick,
  collateral,
  account,
  chainName,
  setWithdrawInput,
  withdrawInput,
  setPendingTxId,
}: {
  onCloseClick: () => void;
  collateral: Collateral;
  account: string;
  chainName: string;
  setWithdrawInput: React.Dispatch<React.SetStateAction<string>>;
  withdrawInput: string;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element {
  const { t } = useTranslation();

  const { addTransactions } = useContext(TransactionsContext);

  const { data: lockedCollaterals, isFetching: lockedCollateralsIsFetching } = useUserLockedCollaterals({
    chainName,
    account,
  });
  const { data: withdrawableCredit } = useWithdrawableCollateral({ chainName, account, collateral });
  const { data: totalDebt, status: totalDebtStatus } = useUserTotalDebt({ chainName, account });
  const { data: userDebts, status: userDebtsStatus } = useUserDebts({ chainName, account });
  // --------------------------------------------------------------------------------------------------------------------|

  // Value converstions --------------------------------------------------------------------------------------------|
  const withdrawInputFloat = parseFloat(withdrawInput);
  const withdrawInputBn = useMemo(() => {
    if (!withdrawInput) return;
    try {
      return parseUnits(withdrawInput, collateral.decimals);
      // eslint-disable-next-line
    } catch (e) {}
  }, [withdrawInput, collateral]);
  const totalDebtFloat = totalDebt && parseFloat(totalDebt);
  // ---------------------------------------------------------------------------------------------------------------|

  // Borrow limit fetches ------------------------------------------------------------------------------------------|
  const { data: borrowLimit, status: borrowLimitStatus } = useUserHypotheticalBorrowLimit({
    chainName,
    account,
  });

  const { data: newBorrowLimit, status: newBorrowLimitStatus } = useUserHypotheticalBorrowLimit({
    chainName,
    account,
    collateralModify: collateral,
    collateralAmountModify: `-${withdrawInput || "0"}`,
  });
  // ---------------------------------------------------------------------------------------------------------------|

  // Borrow limit used fetches -------------------------------------------------------------------------------------|
  const { data: currentBorrowLimitUsed } = useUserBorrowLimitUsed({
    chainName,
    account,
  });

  const { data: newBorrowLimitUsed } = useUserBorrowLimitUsed({
    chainName,
    account,
    collateralModify: collateral,
    collateralAmountModify: `-${withdrawInput || "0"}`,
  });
  // --------------------------------------------------------------------------------------------------------------|

  // Error messages -----------------------------------------------------------------------------------------------|
  const insufficientRemoveFunds = useMemo(() => {
    if (withdrawableCredit && withdrawInput) {
      return parseUnits(withdrawableCredit, collateral.decimals).lt(parseUnits(withdrawInput, collateral.decimals));
    }
    return false;
  }, [withdrawInput, collateral, withdrawableCredit]);
  // --------------------------------------------------------------------------------------------------------------|

  // Calc suggested withdraw amount -------------------------------------------------------------------------------|
  const { data: percentLimitedWithdrawAmount } = useWithdrawableCollateral({
    chainName,
    account,
    collateral,
    percentLimit: 0.8,
  });
  const hasDebt = totalDebtFloat !== 0;
  const suggestedMaxWithdraw = useMemo(() => {
    if (!!withdrawableCredit && !!percentLimitedWithdrawAmount) {
      if (hasDebt) {
        return percentLimitedWithdrawAmount;
      } else {
        return withdrawableCredit;
      }
    }
  }, [hasDebt, withdrawableCredit, percentLimitedWithdrawAmount]);
  // --------------------------------------------------------------------------------------------------------------|

  const proxyTargetContract = useProxyTargetContract();
  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });
  const onWithdrawSubmit = useCallback(
    e => {
      e.preventDefault();
      if (
        !withdrawInputFloat ||
        withdrawInputFloat <= 0 ||
        !proxyTargetContract ||
        !withdrawInputBn ||
        !dsProxyAddress ||
        !userDebts
      )
        return;

      const txs = [];

      logEvent("withdrawClick", {
        withdrawInput,
      });
      txs.push(
        withdrawCollateral({
          contractAddress: dsProxyAddress,
          withdrawInput: withdrawInputBn,
          collateralAddress: collateral.id,
          borrowPositions: Object.keys(userDebts).length,
          proxyTargetContract,
          balanceSheetAddress: getContractAddress(chainName, "balanceSheet"),
          isWeth: isWeth(chainName, collateral.id),
          description: t("transactions.withdraw.description", {
            amount: numberWithCommas(formatUnits(withdrawInputBn, collateral.decimals)),
            token: collateral.symbol,
          }),
          completionMessage: t("transactions.withdraw.completionMessage", {
            amount: numberWithCommas(formatUnits(withdrawInputBn, collateral.decimals)),
            token: collateral.symbol,
          }),
          callbackArgs: { collateralAddress: collateral.id },
        }),
      );
      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Withdraw Collateral"),
      });
      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      addTransactions,
      chainName,
      collateral.decimals,
      collateral.id,
      collateral.symbol,
      proxyTargetContract,
      setPendingTxId,
      t,
      withdrawInput,
      withdrawInputBn,
      withdrawInputFloat,
      dsProxyAddress,
      userDebts,
    ],
  );

  return (
    <form onSubmit={onWithdrawSubmit}>
      <DialogContentArea>
        <MaxInputWithBalance
          autoFocus
          fullWidth
          error={insufficientRemoveFunds}
          onChange={(e: React.ChangeEvent) => setWithdrawInput((e.target as HTMLTextAreaElement).value)}
          value={withdrawInput}
          style={{ fontSize: "1.75em" }}
          decimals={collateral.decimals}
          max={suggestedMaxWithdraw}
          balanceLabel={t("Available Balance")}
          maxButtonLabel={hasDebt && percentLimitedWithdrawAmount != withdrawableCredit ? t("80% Limit") : t("Max")}
          tokenSymbol={collateral.symbol}
          balance={withdrawableCredit && truncateDecimals(withdrawableCredit, 6)}
          inputProps={{
            min: 0,
          }}
          startAdornment={
            <InputAdornment position="start">
              <TokenIcon style={{ fontSize: "1.15em" }} symbol={collateral.symbol as TokenSymbol} />
            </InputAdornment>
          }
        />
        {insufficientRemoveFunds && (
          <Typography variant="body1">
            <FormHelperText error>{t("errors.insufficientBalance")}</FormHelperText>
          </Typography>
        )}
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Currently Supplying")}
          </Typography>
          <Typography variant="h6" style={{ overflowWrap: "anywhere" }}>
            {lockedCollateralsIsFetching && <InlineDottyProgress />}
            {!lockedCollateralsIsFetching &&
              lockedCollaterals &&
              displayCurrentSupplyChange({
                currentValue: lockedCollaterals[collateral.id],
                newValue: formatUnits(
                  parseUnits(lockedCollaterals[collateral.id] || "0", collateral.decimals).sub(
                    parseUnits(withdrawInput || "0", collateral.decimals),
                  ),
                  collateral.decimals,
                ),
                symbol: collateral.symbol,
              })}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <Typography variant="h6" color="textSecondary">
            {t("Borrow Limit")}
          </Typography>
          <Typography variant="h6" style={{ overflowWrap: "anywhere" }}>
            {(borrowLimitStatus === "loading" || newBorrowLimitStatus === "loading") && <InlineDottyProgress />}
            {borrowLimitStatus !== "loading" &&
              newBorrowLimitStatus !== "loading" &&
              displayBorrowLimitChange({
                currentValue: borrowLimit,
                newValue: newBorrowLimit,
                currentValueStatus: borrowLimitStatus,
                newValueStatus: newBorrowLimitStatus,
              })}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Borrow Limit Used")}
          </Typography>
          <Typography variant="h6" style={{ overflowWrap: "anywhere" }}>
            {(borrowLimitStatus === "loading" ||
              newBorrowLimitStatus === "loading" ||
              totalDebtStatus === "loading") && <InlineDottyProgress />}
            {borrowLimitStatus !== "loading" &&
              newBorrowLimitStatus !== "loading" &&
              totalDebtStatus !== "loading" &&
              displayBorrowLimitUsedChange({
                currentValue: currentBorrowLimitUsed,
                newValue: newBorrowLimitUsed,
                currentValueStatus: newBorrowLimitStatus,
                newValueStatus: newBorrowLimitStatus,
              })}
          </Typography>
        </DialogDataRow>
      </DialogContentArea>
      <DialogContentArea>
        <Button
          disabled={
            !withdrawInputFloat || withdrawInputFloat < 0 || insufficientRemoveFunds || userDebtsStatus !== "success"
          }
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
        >
          {t("Withdraw Collateral")}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </form>
  );
}
