import { useMemo, useContext } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Context as Web3Context } from "../../contexts/web3";

const endpointMap: Record<string, string | undefined> = {
  kovan: process.env.REACT_APP_APOLLO_BALANCER_HTTP_URL_KOVAN,
  homestead: process.env.REACT_APP_APOLLO_BALANCER_HTTP_URL_MAINNET,
};

export const Provider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { chainName } = useContext(Web3Context);

  const client = useMemo(() => {
    return new ApolloClient({
      uri: endpointMap[chainName || ""],
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
  }, [chainName]);

  return !!client && <ApolloProvider client={client}>{children}</ApolloProvider>;
};
