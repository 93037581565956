import { DEFAULT_NETWORK } from "../../contexts/web3";
import { getContractAddress } from "../../contracts/addresses";
import { GetContractByNameProps, GetMulticallContractByNameProps } from "./types";
import { Contract } from "@ethersproject/contracts";
import { Web3Provider } from "@ethersproject/providers";
import { Contract as MulticallContract } from "ethcall";
import { ContractProps, MulticallContractProps } from "./types";
import abis from "../../contracts/abis";

/* Returns null on errors */
export function getContract({ abi, address, provider }: ContractProps): Contract | null {
  if (!address || !abi) return null;

  try {
    if (provider instanceof Web3Provider) {
      return new Contract(address, abi, provider.getSigner());
    }
    return new Contract(address, abi, provider);
  } catch {
    return null;
  }
}

export function getMulticallContract({ abi, address }: MulticallContractProps): MulticallContract {
  return new MulticallContract(address, abi);
}

export function getErc20Contract({ address, provider }: ContractProps): Contract | null {
  return getContract({
    abi: abis.erc20,
    address,
    provider,
  });
}

export function getErc20MulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.erc20,
    address,
  });
}

export function getErc721AMulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.erc721a,
    address,
  });
}

export function getErc721MulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.erc721Enumerable,
    address,
  });
}

export function getErc721EnumerableMulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.erc721Enumerable,
    address,
  });
}

export function getContractByName({ contractName, provider, chainName }: GetContractByNameProps): Contract | null {
  return getContract({
    abi: abis[contractName],
    address: getContractAddress(chainName || DEFAULT_NETWORK, contractName),
    provider,
  });
}

export function getMulticallContractByName({
  contractName,
  chainName,
}: GetMulticallContractByNameProps): MulticallContract {
  return getMulticallContract({
    abi: abis[contractName],
    address: getContractAddress(chainName || DEFAULT_NETWORK, contractName),
  });
}

export function getHTokenMulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.hToken,
    address,
  });
}

export function getHifiPoolMulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.hifiPool,
    address,
  });
}

export function getRedemptionPoolMulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.redemption,
    address,
  });
}

export function getBPoolMulticallContract({ address }: { address: string }): MulticallContract {
  return getMulticallContract({
    abi: abis.bPool,
    address,
  });
}
