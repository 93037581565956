import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLLATERAL, ErrorType, LIQUIDITY, UseBorrowErrorProps } from "./types";

export default function useBorrowError({
  owedAtMaturityIsError,
  owedAtMaturityIsLoading,
  debouncedBorrowInput,
  insufficientCollateralRatio,
}: UseBorrowErrorProps): string {
  const [error, setError] = useState<ErrorType | "">("");

  const { t } = useTranslation();

  const errorsMsgs = {
    [LIQUIDITY]: t("errors.insufficientLiquidity"),
    [COLLATERAL]: t("errors.collateralNeeded"),
  };

  useEffect(() => {
    const newErrors: (ErrorType | "")[] = [];
    if (!owedAtMaturityIsLoading) {
      if (owedAtMaturityIsError) {
        newErrors.push(LIQUIDITY);
      }
      if (insufficientCollateralRatio && !!debouncedBorrowInput) {
        newErrors.push(COLLATERAL);
      }

      if (!newErrors.length) {
        setError("");
      } else if (!newErrors.includes(error)) {
        setError(newErrors[0]);
      }
    }
  }, [owedAtMaturityIsError, debouncedBorrowInput, insufficientCollateralRatio, owedAtMaturityIsLoading, error]);

  return error ? errorsMsgs[error] : "";
}
