import { MaxUint256 } from "@ethersproject/constants";
import { LAST_RESULT } from "contexts/transactionsBase";
import { AddErc2612PermitSignatureParam, AddTransactionTransactionParam } from "contexts/transactionsBase/types";

import {
  ApproveProps,
  PermitProps,
  BorrowHTokenAndBuyUnderlyingProps,
  BuyHTokenAndRepayBorrowProps,
  DepositCollateralProps,
  DepositUnderlyingAndMintHTokenAndAddLiquidityProps,
  RedeemHTokenProps,
  RemoveLiquidityParams,
  SellUnderlyingAndRepayBorrowProps,
  SellUnderlyingProps,
  SellHTokenProps,
  SupplyUnderlyingAndRepayBorrowProps,
  WithdrawCollateralProps,
  RemoveLiquidityAndRedeemHTokenProps,
  RemoveLiquidityAndWithdrawUnderlyingProps,
} from "./types";

export function createDsProxy({
  contractAddress,
  description,
  skipConfirmations,
}: {
  contractAddress: string;
  description: string;
  skipConfirmations?: boolean;
}): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxyRegistry",
    description,
    methodName: "build()",
    notifyTopic: "dsProxy",
    requiredConfirmations: skipConfirmations ? 2 : 10,
    callbackArgs: {},
  };
}

export function depositCollateral({
  contractAddress,
  depositInput,
  collateralAddress,
  proxyTargetContract,
  balanceSheetAddress,
  isWeth = false,
  description,
  completionMessage,
  hasSignature,
  callbackArgs,
}: DepositCollateralProps): AddTransactionTransactionParam {
  if (hasSignature && !isWeth) {
    return {
      contractAddress,
      abiName: "dsProxy",
      dsProxyTargetAbiName: "proxyTarget",
      dsProxyTargetAddress: proxyTargetContract.address,
      description,
      completionMessage,
      methodName: "depositCollateralWithSignature",
      args: [
        balanceSheetAddress,
        collateralAddress,
        depositInput,
        `${LAST_RESULT}.deadline`,
        `${LAST_RESULT}.signature`,
      ],
      notifyTopic: "collateral",
      callbackArgs,
      transactionParams: {
        gasLimit: 330000,
      },
    };
  }
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    methodName: "execute(address,bytes)",
    args: !isWeth
      ? [
          proxyTargetContract?.address,
          proxyTargetContract?.interface.encodeFunctionData("depositCollateral", [
            balanceSheetAddress,
            collateralAddress,
            depositInput,
          ]),
        ]
      : [
          proxyTargetContract?.address,
          proxyTargetContract?.interface.encodeFunctionData("wrapEthAndDepositCollateral", [
            collateralAddress,
            balanceSheetAddress,
          ]),
        ],
    notifyTopic: "collateral",
    callbackArgs,
    transactionParams: !isWeth
      ? {
          gasLimit: 330000,
        }
      : {
          gasLimit: 330000,
          value: depositInput,
        },
  };
}

export function borrowHTokenAndBuyUnderlying({
  contractAddress,
  underlyingOut,
  maxBorrowAmount,
  proxyTargetContract,
  balanceSheetAddress,
  hifiPoolAddress,
  description,
  completionMessage,
  borrowPositions,
  callbackArgs,
}: BorrowHTokenAndBuyUnderlyingProps): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    methodName: "execute(address,bytes)",
    args: [
      proxyTargetContract?.address,
      proxyTargetContract?.interface.encodeFunctionData("borrowHTokenAndBuyUnderlying", [
        balanceSheetAddress,
        hifiPoolAddress,
        maxBorrowAmount,
        underlyingOut,
      ]),
    ],
    transactionParams: {
      // gasLimit: 14966 * borrowPositions + 400000,
      gasLimit: 35966 * (borrowPositions + 1) + 480000,
    },
    notifyTopic: "borrow",
    callbackArgs,
  };
}

export function approve({
  description,
  contractAddress,
  spenderAddress,
  callbackArgs,
}: ApproveProps): AddTransactionTransactionParam {
  return {
    // @ts-ignore
    contractAddress,
    abiName: "erc20",
    description,
    methodName: "approve",
    args: [spenderAddress, MaxUint256],
    notifyTopic: "approve",
    callbackArgs,
  };
}

export function withdrawCollateral({
  contractAddress,
  withdrawInput,
  collateralAddress,
  balanceSheetAddress,
  proxyTargetContract,
  isWeth = false,
  description,
  completionMessage,
  borrowPositions,
  callbackArgs,
}: WithdrawCollateralProps): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    methodName: "execute(address,bytes)",
    args: !isWeth
      ? [
          proxyTargetContract?.address,
          proxyTargetContract?.interface.encodeFunctionData("withdrawCollateral", [
            balanceSheetAddress,
            collateralAddress,
            withdrawInput,
          ]),
        ]
      : [
          proxyTargetContract?.address,
          proxyTargetContract?.interface.encodeFunctionData("withdrawCollateralAndUnwrapWeth", [
            collateralAddress,
            balanceSheetAddress,
            withdrawInput,
          ]),
        ],
    notifyTopic: "collateral",
    callbackArgs,
    transactionParams: !isWeth
      ? {
          gasLimit: 250000 + 44000 * borrowPositions,
        }
      : { gasLimit: 250000 + 22182 * borrowPositions },
  };
}

export function sellUnderlyingAndRepayBorrow({
  contractAddress,
  underlyingIn,
  minHTokenOut,
  hifiPoolAddress,
  balanceSheetAddress,
  proxyTargetContract,
  description,
  completionMessage,
  hasSignature = true,
  borrowPositions,
  callbackArgs,
}: SellUnderlyingAndRepayBorrowProps): AddTransactionTransactionParam {
  if (hasSignature) {
    return {
      contractAddress,
      abiName: "dsProxy",
      dsProxyTargetAbiName: "proxyTarget",
      dsProxyTargetAddress: proxyTargetContract.address,
      description,
      completionMessage,
      methodName: "sellUnderlyingAndRepayBorrowWithSignature",
      args: [
        hifiPoolAddress,
        balanceSheetAddress,
        underlyingIn,
        minHTokenOut,
        `${LAST_RESULT}.deadline`,
        `${LAST_RESULT}.signature`,
      ],
      notifyTopic: "borrow",
      callbackArgs,
      transactionParams: {
        gasLimit: 365000 + 22000 * borrowPositions,
      },
    };
  }
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    methodName: "execute(address,bytes)",
    args: [
      proxyTargetContract.address,
      proxyTargetContract.interface.encodeFunctionData("sellUnderlyingAndRepayBorrow", [
        hifiPoolAddress,
        balanceSheetAddress,
        underlyingIn,
        minHTokenOut,
      ]),
    ],
    notifyTopic: "borrow",
    callbackArgs,
    transactionParams: {
      gasLimit: 335000 + 3332 * borrowPositions,
    },
  };
}

export function depositUnderlyingAndRepayBorrow({
  contractAddress,
  underlyingAmount,
  hTokenAddress,
  balanceSheetAddress,
  proxyTargetContract,
  description,
  completionMessage,
  hasSignature = true,
  callbackArgs,
}: SupplyUnderlyingAndRepayBorrowProps): AddTransactionTransactionParam {
  if (hasSignature) {
    return {
      contractAddress,
      abiName: "dsProxy",
      dsProxyTargetAbiName: "proxyTarget",
      dsProxyTargetAddress: proxyTargetContract.address,
      description,
      completionMessage,
      methodName: "depositUnderlyingAndRepayBorrowWithSignature",
      args: [
        hTokenAddress,
        balanceSheetAddress,
        underlyingAmount,
        `${LAST_RESULT}.deadline`,
        `${LAST_RESULT}.signature`,
      ],
      notifyTopic: "borrow",
      callbackArgs,
      transactionParams: {
        gasLimit: 330000,
      },
    };
  }
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    methodName: "execute(address,bytes)",
    args: [
      proxyTargetContract.address,
      proxyTargetContract.interface.encodeFunctionData("depositUnderlyingAndRepayBorrow", [
        hTokenAddress,
        balanceSheetAddress,
        underlyingAmount,
      ]),
    ],
    notifyTopic: "borrow",
    callbackArgs,
    transactionParams: {
      gasLimit: 230000,
    },
  };
}

export function buyHTokenAndRepayBorrow({
  contractAddress,
  maxUnderlyingIn,
  hTokenOut,
  hifiPoolAddress,
  balanceSheetAddress,
  proxyTargetContract,
  description,
  completionMessage,
  hasSignature = true,
  callbackArgs,
}: BuyHTokenAndRepayBorrowProps): AddTransactionTransactionParam {
  if (hasSignature) {
    return {
      contractAddress,
      abiName: "dsProxy",
      dsProxyTargetAbiName: "proxyTarget",
      dsProxyTargetAddress: proxyTargetContract.address,
      description,
      completionMessage,
      methodName: "buyHTokenAndRepayBorrowWithSignature",
      args: [
        hifiPoolAddress,
        balanceSheetAddress,
        maxUnderlyingIn,
        hTokenOut,
        `${LAST_RESULT}.deadline`,
        `${LAST_RESULT}.signature`,
      ],
      notifyTopic: "borrow",
      callbackArgs,
      transactionParams: {
        gasLimit: 382000,
      },
    };
  }
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    methodName: "execute(address,bytes)",
    args: [
      proxyTargetContract.address,
      proxyTargetContract.interface.encodeFunctionData("buyHTokenAndRepayBorrow", [
        hifiPoolAddress,
        balanceSheetAddress,
        maxUnderlyingIn,
        hTokenOut,
      ]),
    ],
    notifyTopic: "borrow",
    callbackArgs,
    transactionParams: {
      gasLimit: 382000,
    },
  };
}

export function sellUnderlying({
  contractAddress,
  description,
  completionMessage,
  proxyTargetContract,
  hifiPoolAddress,
  hTokenAmount,
  underlyingAmount,
  hasSignature = true,
  callbackArgs,
}: SellUnderlyingProps): AddTransactionTransactionParam {
  if (hasSignature) {
    return {
      contractAddress,
      abiName: "dsProxy",
      dsProxyTargetAbiName: "proxyTarget",
      dsProxyTargetAddress: proxyTargetContract.address,
      description,
      completionMessage,
      methodName: "sellUnderlyingWithSignature",
      args: [hifiPoolAddress, underlyingAmount, hTokenAmount, `${LAST_RESULT}.deadline`, `${LAST_RESULT}.signature`],
      notifyTopic: "lend",
      callbackArgs,
      transactionParams: {
        gasLimit: 330000,
      },
    };
  }
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    methodName: "execute(address,bytes)",
    args: [
      proxyTargetContract.address,
      proxyTargetContract.interface.encodeFunctionData("sellUnderlying", [
        hifiPoolAddress,
        underlyingAmount,
        hTokenAmount,
      ]),
    ],
    notifyTopic: "lend",
    callbackArgs,
    transactionParams: {
      gasLimit: 330000,
    },
  };
}

export function sellHToken({
  contractAddress,
  description,
  completionMessage,
  proxyTargetContract,
  hifiPoolAddress,
  hTokenAmount,
  underlyingAmount,
  callbackArgs,
}: SellHTokenProps): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    dsProxyTargetAbiName: "proxyTarget",
    dsProxyTargetAddress: proxyTargetContract.address,
    methodName: "sellHTokenWithSignature",
    args: [hifiPoolAddress, hTokenAmount, underlyingAmount, `${LAST_RESULT}.deadline`, `${LAST_RESULT}.signature`],
    notifyTopic: "lend",
    callbackArgs,
    transactionParams: {
      gasLimit: 330000,
    },
  };
}

export function redeemHToken({
  contractAddress,
  description,
  completionMessage,
  proxyTargetContract,
  hTokenAddress,
  hTokenAmount,
  underlyingAmount,
  callbackArgs,
}: RedeemHTokenProps): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxy",
    description,
    completionMessage,
    dsProxyTargetAbiName: "proxyTarget",
    dsProxyTargetAddress: proxyTargetContract.address,
    methodName: "redeemWithSignature",
    args: [hTokenAddress, hTokenAmount, underlyingAmount, `${LAST_RESULT}.deadline`, `${LAST_RESULT}.signature`],
    notifyTopic: "lend",
    callbackArgs,
    transactionParams: {
      gasLimit: 250000,
    },
  };
}

export function depositUnderlyingAndMintHTokenAndAddLiquidity({
  contractAddress,
  description,
  completionMessage,
  proxyTargetContract,
  hifiPoolAddress,
  depositAmount,
  underlyingOffered,
  hasSignature = true,
  callbackArgs,
}: DepositUnderlyingAndMintHTokenAndAddLiquidityProps): AddTransactionTransactionParam {
  if (hasSignature) {
    return {
      contractAddress,
      abiName: "dsProxy",
      dsProxyTargetAbiName: "proxyTarget",
      dsProxyTargetAddress: proxyTargetContract.address,
      description,
      completionMessage,
      methodName: "depositUnderlyingAndMintHTokenAndAddLiquidityWithSignature",
      args: [hifiPoolAddress, depositAmount, underlyingOffered, `${LAST_RESULT}.deadline`, `${LAST_RESULT}.signature`],
      notifyTopic: "pool",
      callbackArgs,
      transactionParams: {
        gasLimit: 680000,
      },
    };
  } else {
    return {
      contractAddress,
      abiName: "dsProxy",
      description,
      completionMessage,
      methodName: "execute(address,bytes)",
      args: [
        proxyTargetContract.address,
        proxyTargetContract.interface.encodeFunctionData("depositUnderlyingAndMintHTokenAndAddLiquidity", [
          hifiPoolAddress,
          depositAmount,
          underlyingOffered,
        ]),
      ],
      notifyTopic: "pool",
      callbackArgs,
      transactionParams: {
        gasLimit: 680000,
      },
    };
  }
}

export function removeLiquidityAndRedeemHToken({
  contractAddress,
  description,
  completionMessage,
  proxyTargetContract,
  hifiPoolAddress,
  poolTokensBurned,
  callbackArgs,
}: RemoveLiquidityAndRedeemHTokenProps): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxy",
    dsProxyTargetAbiName: "proxyTarget",
    dsProxyTargetAddress: proxyTargetContract.address,
    description,
    completionMessage,
    methodName: "removeLiquidityAndRedeemWithSignature",
    args: [hifiPoolAddress, poolTokensBurned, `${LAST_RESULT}.deadline`, `${LAST_RESULT}.signature`],
    notifyTopic: "pool",
    callbackArgs,
    transactionParams: {
      gasLimit: 295000,
    },
  };
}

export function removeLiquidityAndWithdrawUnderlying({
  contractAddress,
  description,
  completionMessage,
  proxyTargetContract,
  hifiPoolAddress,
  poolTokensBurned,
  withdrawAmount,
  callbackArgs,
}: RemoveLiquidityAndWithdrawUnderlyingProps): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxy",
    dsProxyTargetAbiName: "proxyTarget",
    dsProxyTargetAddress: proxyTargetContract.address,
    description,
    completionMessage,
    methodName: "removeLiquidityAndWithdrawUnderlyingWithSignature",
    args: [hifiPoolAddress, poolTokensBurned, withdrawAmount, `${LAST_RESULT}.deadline`, `${LAST_RESULT}.signature`],
    notifyTopic: "pool",
    callbackArgs,
    transactionParams: {
      gasLimit: 350000,
    },
  };
}

export function removeLiquidity({
  contractAddress,
  description,
  completionMessage,
  proxyTargetContract,
  hifiPoolAddress,
  poolTokensBurned,
  callbackArgs,
}: RemoveLiquidityParams): AddTransactionTransactionParam {
  return {
    contractAddress,
    abiName: "dsProxy",
    dsProxyTargetAbiName: "proxyTarget",
    dsProxyTargetAddress: proxyTargetContract.address,
    description,
    completionMessage,
    methodName: "removeLiquidityWithSignature",
    args: [hifiPoolAddress, poolTokensBurned, `${LAST_RESULT}.deadline`, `${LAST_RESULT}.signature`],
    notifyTopic: "pool",
    callbackArgs,
    transactionParams: {
      gasLimit: 280000,
    },
  };
}

export function permit({
  contractAddress,
  description,
  ownerAddress,
  spenderAddress,
  amount,
}: PermitProps): AddErc2612PermitSignatureParam {
  return {
    contractAddress,
    description,
    ownerAddress,
    notifyTopic: "permit",
    spenderAddress,
    amount,
    deadline: Math.floor((Date.now() + 60 * 60 * 1000) / 1000),
  };
}
