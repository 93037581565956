import { Web3Provider } from "@ethersproject/providers";
import { formatUnits } from "@ethersproject/units";
import { QueryStatus } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import useHifiPoolLiquidity from "../useHifiPoolLiquidity";
import useMemoRef from "hooks/useMemoRef";

export default function useShareOfPool({
  account,
  hifiPool,
  walletProvider,
  underlyingProvided = 0,
  lpTokensProvided = 0,
}: {
  account: string;
  hifiPool: HifiPool;
  walletProvider: Web3Provider | undefined;
  underlyingProvided?: string | number;
  lpTokensProvided?: string | number;
}): { status: QueryStatus; data: number | undefined; isFetching: boolean } {
  const {
    data: poolLiquidityData,
    status: poolLiquidityStatus,
    isFetching: poolLiquidityIsFetching,
  } = useHifiPoolLiquidity(hifiPool);
  const accountBalanceRes = useUserErc20Balance({ token: hifiPool, account, walletProvider });

  const additionalUnderlying = parseFloat(`${underlyingProvided || 0}`);
  const additionalLpTokens = parseFloat(`${lpTokensProvided || 0}`);

  return useMemoRef(
    lastValue => {
      let status: QueryStatus = "idle";
      let data = lastValue?.data;

      if (poolLiquidityStatus === "error" || accountBalanceRes.status === "error" || isNaN(additionalLpTokens)) {
        status = "error";
      } else if (poolLiquidityStatus === "loading" || accountBalanceRes.status === "loading") {
        status = "loading";
      } else if (
        poolLiquidityStatus === "success" &&
        accountBalanceRes.status === "success" &&
        poolLiquidityData &&
        accountBalanceRes.data
      ) {
        status = "success";

        const totalLPFloat = parseFloat(formatUnits(poolLiquidityData.totalLPTokenSupply, hifiPool.decimals));
        const totalUnderlyingFloat = parseFloat(
          formatUnits(poolLiquidityData.normalizedUnderlyingReserves, hifiPool.decimals),
        );

        const accountLpBalance = parseFloat(accountBalanceRes.data);
        const additionalLPFromUnderlying = (totalLPFloat * additionalUnderlying) / totalUnderlyingFloat;
        const totalAccountLPAmount = additionalLPFromUnderlying + accountLpBalance + additionalLpTokens;

        data = totalAccountLPAmount / (totalLPFloat + additionalLPFromUnderlying + additionalLpTokens);
      }

      return { status, data, isFetching: poolLiquidityIsFetching || accountBalanceRes.isFetching };
    },
    [
      accountBalanceRes,
      additionalLpTokens,
      additionalUnderlying,
      poolLiquidityData,
      poolLiquidityStatus,
      poolLiquidityIsFetching,
      hifiPool,
    ],
  );
}
