import { TransactionState } from "contexts/transactionsBase/types";
// import useDebounce from "hooks/useDebounce";
import { useContext, useState } from "react";
import { Context as TransactionsDialogContext } from "../../contexts/transactionsDialog";
import usePendingTransaction from "../usePendingTransaction";

export default function usePendingTransactionUI(onClose: () => void): {
  showTx: boolean;
  transaction: TransactionState | undefined;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
} {
  const [pendingTxId, setPendingTxId] = useState("");
  const { isTaskDialogOpen } = useContext(TransactionsDialogContext);

  // const { debouncedValue: transaction } = useDebounce(
  //   usePendingTransaction({ transactionId: pendingTxId, onClose }),
  //   0,
  // );
  const transaction = usePendingTransaction({ transactionId: pendingTxId, onClose });
  const showTx = !isTaskDialogOpen && !!transaction;
  return { showTx, transaction, setPendingTxId };
}
