import { MulticallBatcher } from "contexts/web3Base";
import deepFreeze from "utils/deepFreeze";
import { BigNumber } from "ethers";
import { getHifiPoolMulticallContract } from "../../utils/contracts";
import { getErc20MulticallContract } from "utils/contracts";
import { HifiPool } from "../getHifiPools/types";

const getUnderlyingOffered = async ({
  hifiPool,
  underlyingInput,
}: {
  hifiPool: HifiPool;
  underlyingInput: BigNumber;
}): Promise<BigNumber> => {
  const hifiPoolContract = getHifiPoolMulticallContract({ address: hifiPool.id });
  const hTokenContract = getErc20MulticallContract({ address: hifiPool.hToken.id });

  const res: [BigNumber, BigNumber] = await Promise.all([
    MulticallBatcher.queue(hifiPoolContract.getNormalizedUnderlyingReserves()),
    MulticallBatcher.queue(hTokenContract.balanceOf(hifiPool.id)),
  ]);

  // if (res[0].eq(0)) {
  //   return deepFreeze(underlyingInput);
  // }

  return deepFreeze(underlyingInput.mul(res[0]).div(res[1].add(res[0])));
};

export default getUnderlyingOffered;
