import SvgIcon from "@mui/material/SvgIcon";

export default function MEC1({ style }: { style?: React.CSSProperties }): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 512 512" style={style}>
      <circle cx="256" cy="256" r="256" fill="#101010" />

      <circle
        cx="256.5"
        cy="256.5"
        r="217.5"
        stroke="#6E2EF4"
        fill="#161616"
        strokeWidth="12"
        shapeRendering="crispEdges"
      />

      <path
        d="M252.52 177.539V247.232C253.45 247.751 254.371 248.268 255.323 248.794C255.735 249.021 256.17 249.27 256.587 249.501C257.012 249.266 257.443 249.026 257.863 248.794C258.815 248.268 259.736 247.751 260.666 247.232V177.539C264.506 176.548 267.853 174.19 270.079 170.907C272.304 167.625 273.257 163.644 272.757 159.709C272.257 155.775 270.34 152.158 267.364 149.537C264.388 146.915 260.559 145.469 256.593 145.469C252.627 145.469 248.797 146.915 245.822 149.537C242.846 152.158 240.929 155.775 240.429 159.709C239.929 163.644 240.882 167.625 243.107 170.907C245.333 174.19 248.68 176.548 252.52 177.539ZM256.593 153.635C258.204 153.635 259.779 154.112 261.119 155.008C262.459 155.903 263.503 157.175 264.12 158.664C264.736 160.152 264.898 161.791 264.583 163.371C264.269 164.951 263.493 166.403 262.354 167.542C261.214 168.682 259.763 169.458 258.182 169.772C256.602 170.086 254.964 169.925 253.475 169.308C251.987 168.692 250.714 167.647 249.819 166.308C248.924 164.968 248.446 163.393 248.446 161.781C248.449 159.622 249.308 157.551 250.835 156.023C252.362 154.496 254.433 153.637 256.593 153.635Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M263.333 306.719C263.83 307.074 268.393 310.321 270.091 311.585C276.6 305.599 281.64 298.321 281.004 288.805C280.452 281.286 276.994 274.277 271.362 269.264C270.809 269.57 265.699 272.413 263.895 273.465C268.928 277.701 272.429 282.678 272.875 289.348C273.336 296.238 269.105 301.751 263.333 306.719Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M248.39 254.249C244.257 252.049 240.296 249.541 236.54 246.745C240.104 240.322 241.531 232.932 240.615 225.644C239.698 218.357 236.485 211.55 231.441 206.21C230.465 205.132 229.218 204.334 227.83 203.9C226.442 203.466 224.963 203.412 223.546 203.743C222.13 204.073 220.828 204.777 219.775 205.78C218.722 206.784 217.957 208.051 217.559 209.45C215.399 216.515 215.222 224.038 217.048 231.197C218.874 238.356 222.633 244.875 227.914 250.042C231.862 253.645 236.22 256.77 240.899 259.353C243.307 257.524 245.807 255.82 248.39 254.249ZM230.408 240.629C227.369 236.529 225.333 231.774 224.463 226.746C223.592 221.717 223.912 216.555 225.397 211.672C229.08 215.415 231.533 220.193 232.428 225.367C233.324 230.541 232.619 235.866 230.408 240.629Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M252.549 298.047C253.831 299.343 255.187 300.562 256.613 301.697C258.043 300.561 259.407 299.342 260.696 298.047V281.619C259.434 280.349 258.069 279.187 256.613 278.145C255.163 279.186 253.803 280.348 252.549 281.619V298.047Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M256.626 332.018C255.098 333.194 252.646 335.53 252.549 335.637V385.814C252.549 386.894 252.978 387.93 253.742 388.694C254.506 389.458 255.542 389.887 256.623 389.887C257.703 389.887 258.739 389.458 259.503 388.694C260.267 387.93 260.696 386.894 260.696 385.814V335.637C260.6 335.529 258.156 333.195 256.626 332.018Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M240.925 324.188C237.555 327.645 234.976 331.792 233.365 336.343C231.755 340.893 231.151 345.739 231.596 350.546C232.151 354.882 233.569 359.063 235.767 362.841C237.966 366.62 240.899 369.919 244.394 372.544V360.937C241.825 357.668 240.198 353.758 239.691 349.63C239.381 345.995 239.862 342.336 241.102 338.905C242.341 335.474 244.31 332.352 246.872 329.755C247.782 328.805 248.75 327.91 249.769 327.076C247.606 325.546 245.332 323.91 243.053 322.141C242.307 322.816 241.575 323.493 240.925 324.188Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M289.691 203.747C288.272 203.412 286.788 203.464 285.396 203.897C284.003 204.33 282.753 205.129 281.774 206.21C276.73 211.549 273.517 218.356 272.6 225.644C271.684 232.932 273.111 240.322 276.675 246.744C271.964 250.18 266.996 253.249 261.814 255.923C248.48 263.289 233.366 271.638 232.218 288.809C231.202 303.993 244.638 313.482 255.434 321.106C259.329 323.647 262.983 326.54 266.348 329.749C268.91 332.346 270.878 335.468 272.118 338.899C273.358 342.33 273.839 345.989 273.528 349.624C273.021 353.756 271.395 357.672 268.825 360.948V372.538C272.321 369.913 275.254 366.614 277.452 362.835C279.65 359.057 281.069 354.876 281.623 350.54C282.069 345.733 281.465 340.887 279.855 336.336C278.244 331.786 275.665 327.639 272.295 324.182C268.564 320.554 264.491 317.296 260.133 314.451C250.056 307.334 239.636 299.976 240.347 289.352C241.198 276.618 253.126 270.03 265.753 263.054C272.722 259.205 279.927 255.226 285.302 250.041C290.583 244.874 294.341 238.356 296.167 231.197C297.993 224.038 297.816 216.516 295.656 209.451C295.262 208.054 294.501 206.787 293.452 205.784C292.403 204.781 291.104 204.078 289.691 203.747ZM282.807 240.628C280.598 235.862 279.894 230.535 280.788 225.358C281.682 220.181 284.132 215.399 287.811 211.65C289.299 216.535 289.621 221.701 288.752 226.734C287.883 231.766 285.847 236.525 282.807 240.628Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M244.4 191.444V183.265C231.339 181.475 224.158 170.377 220.433 161.307C219.794 159.756 218.847 158.351 217.649 157.176C216.451 156.001 215.028 155.082 213.465 154.472C211.925 153.867 210.278 153.584 208.624 153.642C206.971 153.7 205.347 154.098 203.854 154.811C176.347 167.82 148.36 170.731 134.125 171.308C132.551 171.36 131.026 171.87 129.737 172.776C128.448 173.681 127.452 174.943 126.87 176.407C126.274 177.849 126.112 179.434 126.403 180.967C126.695 182.5 127.427 183.915 128.511 185.037C136.581 193.518 154.933 209.376 179.279 209.589C179.869 214.937 182.296 219.915 186.147 223.673C191.442 228.911 199.175 231.95 209.131 232.8C208.488 230.071 208.079 227.291 207.91 224.492C189.724 222.21 187.647 211.472 187.408 209.071C193.67 208.201 199.767 206.401 205.496 203.729C206.478 203.278 207.24 202.455 207.615 201.442C207.99 200.428 207.947 199.308 207.496 198.326C207.045 197.344 206.222 196.582 205.209 196.207C204.195 195.832 203.074 195.875 202.093 196.326C195.037 199.761 187.286 201.531 179.438 201.498C171.59 201.465 163.855 199.63 156.828 196.136C169.933 195.492 182.847 192.722 195.065 187.936C195.558 187.728 196.005 187.425 196.38 187.045C196.756 186.664 197.053 186.213 197.255 185.718C197.456 185.222 197.558 184.692 197.555 184.157C197.551 183.622 197.443 183.093 197.235 182.6C197.027 182.107 196.724 181.66 196.343 181.284C195.962 180.908 195.511 180.611 195.016 180.41C194.52 180.208 193.99 180.106 193.455 180.11C192.92 180.113 192.391 180.222 191.898 180.43C170.932 189.272 146.272 188.283 144.17 188.255C140.714 185.569 137.467 182.625 134.455 179.449C149.309 178.846 178.528 175.8 207.338 162.176C207.847 161.947 208.398 161.823 208.957 161.811C209.515 161.8 210.071 161.901 210.589 162.108C211.108 162.316 211.58 162.626 211.976 163.02C212.373 163.413 212.686 163.883 212.897 164.4C223.263 190.812 244.241 191.444 244.4 191.444Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M268.818 191.444V183.265C281.879 181.475 289.06 170.377 292.785 161.307C293.424 159.756 294.371 158.351 295.569 157.176C296.767 156.001 298.19 155.082 299.753 154.472C301.293 153.867 302.94 153.584 304.594 153.642C306.247 153.7 307.871 154.098 309.364 154.811C336.871 167.82 364.858 170.73 379.093 171.308C380.658 171.365 382.172 171.873 383.456 172.769C384.739 173.665 385.737 174.913 386.33 176.362C386.922 177.811 387.084 179.4 386.796 180.938C386.509 182.477 385.783 183.9 384.707 185.037C376.637 193.518 358.285 209.376 333.939 209.589C333.349 214.937 330.922 219.915 327.071 223.673C321.776 228.911 314.043 231.95 304.087 232.8C304.73 230.071 305.139 227.291 305.308 224.492C323.494 222.21 325.571 211.472 325.81 209.07C319.548 208.201 313.451 206.401 307.722 203.729C306.74 203.278 305.978 202.455 305.603 201.442C305.228 200.428 305.271 199.308 305.722 198.326C306.173 197.344 306.996 196.582 308.009 196.207C309.023 195.832 310.144 195.875 311.125 196.326C318.181 199.761 325.932 201.531 333.78 201.498C341.628 201.465 349.363 199.63 356.39 196.136C343.285 195.492 330.371 192.722 318.153 187.936C317.66 187.728 317.213 187.425 316.838 187.045C316.462 186.664 316.165 186.213 315.963 185.717C315.556 184.717 315.563 183.595 315.983 182.6C316.403 181.604 317.201 180.817 318.202 180.41C319.203 180.003 320.324 180.01 321.32 180.43C342.286 189.272 366.946 188.283 369.048 188.255C372.504 185.569 375.751 182.625 378.763 179.449C363.909 178.846 334.69 175.8 305.88 162.176C305.371 161.947 304.82 161.823 304.261 161.811C303.703 161.8 303.147 161.901 302.629 162.108C302.11 162.316 301.638 162.626 301.242 163.02C300.845 163.413 300.532 163.883 300.321 164.4C289.955 190.812 268.977 191.444 268.818 191.444Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
      />
    </SvgIcon>
  );
}
