import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { QueryObserverResult } from "react-query";
import useMemoRef from "hooks/useMemoRef";
import useUserDebts from "../useUserDebts";

export default function useUserTotalDebt({
  chainName,
  account,
  debtAmountModify = "0",
}: {
  chainName: string;
  account: string;
  debtAmountModify?: string;
}): QueryObserverResult<string | undefined> {
  const { data: userDebts, status, ...others } = useUserDebts({ chainName, account });

  const total = useMemoRef(
    lastValue => {
      if (status === "success") {
        let tot = BigNumber.from("0");
        if (userDebts) {
          const hTokenAddresses = Object.keys(userDebts);
          if (hTokenAddresses.length) {
            tot = hTokenAddresses.reduce((memo, hTokenAddress) => {
              return memo.add(parseUnits(userDebts[hTokenAddress], 18));
            }, BigNumber.from("0"));
          }
        }
        return formatUnits(tot.add(parseUnits(debtAmountModify || "0", 18)), 18);
      }
      return lastValue;
    },
    [userDebts, debtAmountModify, status],
  );

  return { data: total, status, ...others } as QueryObserverResult<string | undefined>;
}
