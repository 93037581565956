import { QueryObserverResult, useQuery } from "react-query";
import { Collateral } from "../../api/getCollaterals/types";
import getUserLockedCollaterals from "../../api/getUserLockedCollaterals";
import { UserLockedCollaterals } from "../../api/getUserLockedCollaterals/types";
import useCollateralMap from "../useCollateralMap";
import useUserDSProxy from "../useUserDSProxy";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";

function buildQueryKey({
  chainName,
  account,
  collateralMap,
}: {
  chainName: string;
  account: string;
  collateralMap?: Record<string, Collateral>;
}): [string, string, string, string | Record<string, Collateral>] {
  return ["userCollaterals", chainName, account, collateralMap || ""];
}

export default function useUserLockedCollaterals({
  chainName,
  account,
}: {
  chainName: string;
  account: string;
}): QueryObserverResult<UserLockedCollaterals> {
  const collateralMapRes = useCollateralMap(chainName);
  const { data: collateralMap, isFetching: collateralMapIsFetching } = collateralMapRes;

  const dsProxyRes = useUserDSProxy({
    chainName,
    account,
  });
  const { data: dsProxyAddress, isFetching: dsProxyAddressIsFetching } = dsProxyRes;

  const res = useQuery(
    buildQueryKey({ chainName, account: dsProxyAddress as string, collateralMap }),
    ({ queryKey }) => getUserLockedCollaterals({ chainName: queryKey[1], account: queryKey[2], collateralMap }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !collateralMapIsFetching && !dsProxyAddressIsFetching,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { status, isFetching } = useCombinedQueryHooks([collateralMapRes, dsProxyRes, res]);
  return {
    ...res,
    status,
    isFetching,
  } as QueryObserverResult<UserLockedCollaterals>;
}
