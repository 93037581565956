import { MulticallBatcher } from "contexts/web3Base";
import { getMulticallContractByName } from "../../utils/contracts";
import deepFreeze from "utils/deepFreeze";
import { BigNumber } from "ethers";

const getMaxBonds = async (chainName: string): Promise<BigNumber> => {
  const fintrollerContract = getMulticallContractByName({ contractName: "fintroller", chainName });
  const maxBonds: BigNumber = await MulticallBatcher.queue(fintrollerContract.maxBonds());

  return deepFreeze(maxBonds);
};

export default getMaxBonds;
