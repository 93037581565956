import { JsonRpcProvider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import isEqual from "lodash/isEqual";
import { QueryObserverResult, useQueries, UseQueryOptions } from "react-query";
import { buildQueryObject } from "hooks/useUserErc20Balance";
import useHifiPools from "../useHifiPools";
import { useCallback, useMemo } from "react";
import get from "lodash/get";
import { UserLendingPosition } from "./types";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { HifiPool } from "../../api/getHifiPools/types";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";

export default function useUserLendingPositions({
  chainName,
  account,
  provider,
}: {
  chainName: string;
  account: string;
  provider: JsonRpcProvider;
}): QueryResponse<UserLendingPosition[]> {
  const hifiPoolsRes = useHifiPools(chainName);
  const { data: hifiPools, status: hifiPoolsStatus } = hifiPoolsRes;

  const balanceQueryObjects = useMemo(() => {
    if (hifiPools?.length && hifiPoolsStatus === "success" && !!account) {
      return (hifiPools || []).map(hifiPool => {
        return {
          ...buildQueryObject({ account, tokenAddress: hifiPool.hToken.id, provider }),
          retry: false,
          refetchOnWindowFocus: false,
        };
      }) as UseQueryOptions[];
    }
  }, [account, hifiPools, hifiPoolsStatus, provider]);

  const balanceQueries = useQueries(balanceQueryObjects || []) as QueryObserverResult<BigNumber>[];

  return useCombinedQueryHooks(
    [hifiPoolsRes, ...balanceQueries],
    useCallback((data, lastValue) => {
      const [hifiPools, ...balances] = data as [HifiPool[], ...BigNumber[]];
      const _data = hifiPools.map((hifiPool, i) => {
        return {
          hifiPool,
          hTokenBalance: get(balances, [i]) || BigNumber.from("0"),
        };
      });
      if (!isEqual(_data, lastValue)) {
        // Since we are using useQueries above, we need to compare each item with the previously returned array
        // if all items are equal, then don't return new array.
        return _data;
      }
    }, []),
  );
}
