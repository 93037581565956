import { QueryObserverResult, useQuery } from "react-query";
import getCollaterals from "../../api/getCollaterals";
import { Collateral } from "../../api/getCollaterals/types";

export default function useCollaterals(chainName: string): QueryObserverResult<Collateral[]> {
  return useQuery(["collaterals", chainName], () => getCollaterals(chainName), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
