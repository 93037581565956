import { JsonRpcProvider } from "@ethersproject/providers";
import { formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { MutationStatus, QueryObserverResult, QueryStatus, useQueries, UseQueryOptions } from "react-query";
import isExpired from "../../utils/isExpired";
import useMemoRef from "hooks/useMemoRef";
import { buildQueryObject } from "../useQuoteForSellingHToken";
import useUserLendingPositions from "../useUserLendingPositions";

export default function useUserTotalLendBalanceInUnderlying({
  chainName,
  account,
  provider,
}: {
  chainName: string;
  account: string;
  provider: JsonRpcProvider;
}): {
  status: MutationStatus;
  data: undefined | BigNumber;
  isFetching: boolean;
} {
  const {
    data: userLendingPositions,
    status: userLendingPositionsStatus,
    isFetching: userLendingPositionsIsFetching,
  } = useUserLendingPositions({ chainName, account, provider });

  const filteredPositions = useMemo(() => {
    return userLendingPositions?.filter(pos => pos.hTokenBalance.gt(BigNumber.from("0"))) || [];
  }, [userLendingPositions]);

  const amountsInUnderlyingRequests = useMemo(() => {
    return filteredPositions.map(position => {
      return buildQueryObject({
        hTokenIn: formatUnits(position.hTokenBalance, position.hifiPool.hToken.decimals),
        hifiPool: position.hifiPool,
        options: {
          enabled: !isExpired(position.hifiPool),
        },
      });
    }) as UseQueryOptions[];
  }, [filteredPositions]);

  const amountsQueries = useQueries(amountsInUnderlyingRequests) as QueryObserverResult<BigNumber>[];

  return useMemoRef(
    lastValue => {
      let status: QueryStatus = "idle";
      let data = lastValue?.data;

      if (userLendingPositionsStatus === "error") {
        status = "error";
      } else if (userLendingPositionsStatus === "loading" || amountsQueries.some(qry => qry.status === "loading")) {
        status = "loading";
      } else if (userLendingPositionsStatus === "success" || !account) {
        status = "success";

        data = amountsQueries.reduce((memo, amountRes, i) => {
          // If the amountRes.data is undefined, it means this position is expired or useQuoteForSellingHToken failed
          // (insufficient liquidity); in this case, we just add the hTokens amount in underlying decimals
          return memo.add(
            amountRes.data ||
              filteredPositions[i].hTokenBalance.div(filteredPositions[i].hifiPool.underlyingPrecisionScalar) ||
              BigNumber.from("0"),
          );
        }, BigNumber.from("0"));
      }

      return {
        status,
        data,
        isFetching: userLendingPositionsIsFetching || amountsQueries.some(qry => qry.isFetching),
      };
    },
    [amountsQueries, account, userLendingPositionsIsFetching, userLendingPositionsStatus, filteredPositions],
  );
}
