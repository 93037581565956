import { styled } from "@mui/material/styles";
import MuiTable, { TableProps } from "@mui/material/Table";
import MuiTableContainer, { TableContainerProps } from "@mui/material/TableContainer";
import { LightPaper } from "../Paper";

const PREFIX = "Table";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledMuiTable = styled(MuiTable)(({ theme }) => ({
  [`&.${classes.root}`]: {
    "& .MuiTableCell-root": {
      border: "none",
    },
    "& .MuiTableHead-root .MuiTableCell-root": {
      borderBottom: `1px solid ${theme.palette.borderColor}`,
    },
    "& .MuiTableRow-root": {
      cursor: "pointer",
    },
  },
}));

export const TablePaper = styled(LightPaper)(({ theme }) => ({
  "&.MuiPaper-root": {
    border: `1px solid ${theme.palette.borderColor}`,
  },
}));

export const TableContainer = (props: TableContainerProps): JSX.Element => {
  const { children, ...others } = props;
  return (
    <MuiTableContainer {...others} component={TablePaper}>
      {children}
    </MuiTableContainer>
  );
};

export default function Table(props: TableProps): JSX.Element {
  const { children, ...others } = props;

  return (
    <StyledMuiTable {...others} className={classes.root}>
      {children}
    </StyledMuiTable>
  );
}
