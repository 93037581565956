import { Theme, createTheme as createMuiTheme } from "@mui/material/styles";
import { ActionPanelWidthsReturn, Size } from "./types";

const bananaFontFace = {
  fontFamily: "Banana",
  src: `url(${process.env.PUBLIC_URL}/fonts/BananaGrotesk-Regular.otf) format('opentype')`,
};

export default function createTheme(size: Size): Theme {
  const sizeMapping = {
    xs: {
      SPACING: 3,
      FONT_SIZE: "11px",
    },
    sm: {
      SPACING: 4,
      FONT_SIZE: "13px",
    },
    md: {
      SPACING: 5,
      FONT_SIZE: "14px",
    },
    lg: {
      SPACING: 5,
      FONT_SIZE: "16px",
    },
  };

  const BG_COLOR = "#111111";
  const BORDER_COLOR = "#363636";
  const LIGHTER_PAPER_BG = "#303030";
  const PRIMARY_TEXT_COLOR = "#FFFFFF";
  const SECONDARY_TEXT_COLOR = "#828282";
  const PRIMARY_COLOR = "#6E2EF4";
  const { SPACING, FONT_SIZE } = sizeMapping[size];
  const RADIUS = 10;
  const SUCCESS_COLOR = "#4CD182";
  const ERROR_COLOR = "#f44336";
  const H4_SIZE = "1.375rem";
  const H6_SIZE = "1.125rem";

  const LARGE_BUTTON = {
    fontSize: "1.4rem",
    padding: `.94em ${35 / 8}rem`,

    "& .MuiButton-label": {
      fontFamily: "Banana",
    },
  };

  const HOVER_COLOR = "#626262";

  return createMuiTheme({
    // breakpoints: {
    //   values: {
    //     xs: 0,
    //     sm: 600,
    //     md: 960,
    //     lg: 1280,
    //     xl: 1680,
    //   },
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": [bananaFontFace],
          html: {
            fontSize: FONT_SIZE,
          },
          body: {
            fontFamily: `"Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
          },
          // Override the web3 connect modal to always be on top
          "#WEB3_CONNECT_MODAL_ID .web3modal-modal-lightbox": {
            zIndex: 2000,
            backgroundColor: "rgba(0, 0, 0, 0.8) !important",
            // backgroundColor: "rgba(16, 16, 16, 0.2) !important",
            // backdropFilter: "blur(6px)",
          },
          ".web3modal-provider-name": {
            fontFamily: "Banana",
            fontSize: H4_SIZE,
          },
          ".web3modal-provider-wrapper:hover": {
            "& .web3modal-provider-description": {
              color: PRIMARY_TEXT_COLOR,
            },
          },
          "input::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "input[type=number]": {
            "-moz-appearance": "textfield",
          },
          ".matic-widget": {
            zIndex: 1299,
          },

          // Snackbar overrides -----------------------------------------------------------|
          ".SnackbarItem-wrappedRoot": {
            borderRadius: `${RADIUS}px !important`,
            overflow: "hidden !important",
          },
          ".SnackbarContent-root.SnackbarItem-contentRoot": {
            backgroundColor: `${BORDER_COLOR} !important`,
            padding: `${SPACING * 3}px ${SPACING * 6}px ${SPACING * 3}px !important`,
            alignItems: "flex-start !important",
            borderRadius: `${RADIUS}px !important`,
            // Fixes weird resizing issue for smaller screens
            flexWrap: "inherit !important",

            "& #notistack-snackbar": {
              alignItems: "flex-start !important",
              "& > .MuiSvgIcon-root": {
                fontSize: "1.5rem !important",
              },
            },
          },
          ".SnackbarItem-variantSuccess": {
            "& #notistack-snackbar > .MuiSvgIcon-root": {
              color: SUCCESS_COLOR,
            },
          },
          ".SnackbarItem-variantError": {
            "& #notistack-snackbar > .MuiSvgIcon-root": {
              color: ERROR_COLOR,
            },
          },
          ".control-dots": {
            bottom: "-60% !important",
          },
          ".carousel.carousel-slider": {
            overflow: "visible",
          },
        },
        // --------------------------------------------------------------------------------|
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0.8) !important",
            // backgroundColor: "rgba(16, 16, 16, 0.2)",
            // backdropFilter: "blur(6px)",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: BG_COLOR,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderRadius: "400px",
            fontSize: H6_SIZE,
            lineHeight: 1,
            minWidth: "initial",
            padding: ".7em 1.2em",
            fontFamily: "Inter",
          },
          outlined: {
            padding: ".7em 1.2em",
          },
          outlinedSizeLarge: LARGE_BUTTON,
          containedSizeLarge: LARGE_BUTTON,
          outlinedSizeSmall: {
            padding: ".6em 1em",
            fontSize: "1rem",
          },
          containedSizeSmall: {
            padding: ".6em 1em",
            fontSize: "1rem",
          },
          contained: {
            boxShadow: "none",
            color: "#FFF",

            "&:hover": {
              boxShadow: "none",
              backgroundColor: HOVER_COLOR,
            },
          },
          containedPrimary: {
            "&:hover": {
              backgroundColor: "#875EFD",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: H4_SIZE,
            lineHeight: "1.1876em",
          },
          input: {
            fontFamily: "Banana",
            height: "1.1876em",
          },
        },
      },
      MuiInput: {
        defaultProps: {
          disableUnderline: true,
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            fontSize: H4_SIZE,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: "1.67rem 1rem",
            textTransform: "none",
            position: "relative",

            "& .MuiTypography-root": {
              color: SECONDARY_TEXT_COLOR,
            },

            "&:hover .MuiTypography-root, &.Mui-selected .MuiTypography-root": {
              color: PRIMARY_TEXT_COLOR,
            },

            // "&:hover .MuiTypography-root": {
            //   color: PRIMARY_TEXT_COLOR,
            // },

            "&::before": {
              display: "block",
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: 0,
              border: `1px solid ${BORDER_COLOR}`,
              content: "''",
            },
          },

          textColorInherit: {
            opacity: 1,

            "&.Mui-disabled": {
              "& .MuiTypography-root": {
                color: HOVER_COLOR,
              },
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            padding: `${SPACING * 4}px ${SPACING * 5}px`,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: `${SPACING * 3}px 0`,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: "#252525",
            paddingBottom: `${SPACING}px`,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: `${SPACING * 8}px ${SPACING * 8}px`,
            flex: "none",
          },
          dividers: {
            padding: `${SPACING * 6}px ${SPACING * 8}px`,

            "&:last-child": {
              paddingBottom: `${SPACING * 8}px`,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: `${SPACING * 4.5}px ${SPACING * 8}px`,
            "& .MuiTypography-h6": {
              fontSize: H4_SIZE,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          adornedEnd: {
            paddingRight: ".7em",
            paddingLeft: "0",
          },
          adornedStart: {
            paddingRight: "1em",
            paddingLeft: "0",
          },
          input: {
            padding: "1em 1em 1em 0", // This (em) is correct -- it should be relative to the font-size given to the input
          },
          notchedOutline: {
            borderColor: BORDER_COLOR,
          },
          root: {
            "&:hover $notchedOutline": {
              borderColor: BORDER_COLOR,
            },
            "&.Mui-disabled": {
              backgroundColor: "#292929",
              "& input": {
                color: PRIMARY_TEXT_COLOR,
                WebkitTextFillColor: PRIMARY_TEXT_COLOR,
              },
              "& fieldset": {
                borderColor: "transparent !important",
              },
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionStart: {
            marginRight: ".7em",
            height: "100%",
            padding: "1em .72em",
            maxHeight: "none",
            backgroundColor: LIGHTER_PAPER_BG,
            borderRadius: `${RADIUS}px 0 0 ${RADIUS}px`,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            paddingTop: ".2em",
            fontSize: "1em",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "13px",
            backgroundColor: "#242424",
            border: "1px solid #363636",
            padding: "10px 15px",
          },
          arrow: {
            fontSize: "24px",
            "&::before": {
              backgroundColor: "#242424",
              border: "1px solid #363636",
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            "& .MuiTableRow-root.MuiTableRow-hover": {
              "&:hover": {
                backgroundColor: "inherit",

                "& .MuiTableCell-root:first-child": {
                  position: "relative",

                  "&::before": {
                    content: "''",
                    width: "4px",
                    backgroundColor: PRIMARY_COLOR,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                  },
                },
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: `${SPACING * 4}px`,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          gutters: {
            paddingRight: `${SPACING * 6}px !important`,
            paddingLeft: `${SPACING * 6}px !important`,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: SECONDARY_TEXT_COLOR,
          },
          thumb: {
            width: 24,
            height: 24,
          },
        },
      },
    },
    palette: {
      mode: "dark",
      lightPaperBg: "#181818",
      lighterPaperBg: LIGHTER_PAPER_BG,
      borderColor: BORDER_COLOR,
      background: {
        default: BG_COLOR,
        paper: "#212121",
      },
      primary: {
        main: PRIMARY_COLOR,
      },
      info: {
        main: BORDER_COLOR,
      },
      secondary: {
        main: "#1D3EB0",
      },
      success: {
        main: SUCCESS_COLOR,
      },
      warning: {
        main: "#CED14C",
      },
      error: {
        main: ERROR_COLOR,
        light: "#F64D4D",
      },
      action: {
        hover: HOVER_COLOR,
      },
      text: {
        secondary: SECONDARY_TEXT_COLOR,
        primary: PRIMARY_TEXT_COLOR,
      },
      purpleText: "#794DF6",
    },
    typography: {
      subtitle1: {
        fontSize: "0.875rem",
      },
      body1: {
        fontFamily: "Inter",
        fontWeight: "normal",
        fontSize: "1rem",
      },
      body2: {
        fontFamily: "Banana",
        fontSize: "1rem",
      },
      h6: {
        fontFamily: "Inter",
        fontWeight: "normal",
        fontSize: H6_SIZE,
      },
      h5: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: H6_SIZE,
      },
      h4: {
        fontFamily: "Inter",
        fontWeight: "normal",
        fontSize: H4_SIZE,
      },
      h2: {
        fontFamily: "Banana",
        fontWeight: "normal",
        fontSize: "1.75rem",
      },
    },
    shape: {
      borderRadius: RADIUS,
    },
    spacing: SPACING,
  });
}

export const actionPanelWidths = (theme: Theme): ActionPanelWidthsReturn => ({
  width: 600,
  [theme.breakpoints.down("lg")]: {
    width: 600,
  },
  [theme.breakpoints.down("md")]: {
    width: 500,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
});
