import Button from "@mui/material/Button";
import detectEthereumProvider from "@metamask/detect-provider";
import { useTranslation } from "react-i18next";

export default function SwitchEthereumButton(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      size="medium"
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        detectEthereumProvider().then(async (provider: any) => {
          try {
            await provider.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: "0x1",
                },
              ],
            });
          } catch (e) {
            // @ts-ignore
          }
        });
      }}
    >
      {t("Switch to Ethereum Mainnet")}
    </Button>
  );
}
