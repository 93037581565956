import { DataHandler, QueryResponse, QueryResponseInput } from "./types";
import { QueryStatus } from "react-query";
import useMemoRef from "../useMemoRef";
import { isEqual } from "lodash";

function defaultStatus(statuses: Array<QueryStatus>) {
  let status: QueryStatus = "idle";
  if (statuses.some(status => status === "error")) {
    status = "error";
  } else if (statuses.some(status => status === "loading")) {
    status = "loading";
  } else if (!!statuses.length && statuses.every(status => status === "success")) {
    status = "success";
  }
  return status;
}

export default function useCombinedQueryHooks<T>(
  queryResponses: Array<QueryResponseInput>,

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataHandler?: DataHandler<T>,

  statusHandler?: (
    statuses: Array<QueryStatus>,
    datas: Array<unknown>,
    defaultStatus: (statuses: Array<QueryStatus>) => QueryStatus,
  ) => QueryStatus,
): QueryResponse<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputs = useMemoRef<{ data: Array<any>; status: Array<QueryStatus>; isFetching: Array<boolean> }>(
    lastValue => {
      const inputs = {
        data: queryResponses.map(res => res.data),
        status: queryResponses.map(res => res.status),
        isFetching: queryResponses.map(res => res.isFetching),
      };
      if (
        !lastValue ||
        !isEqual(inputs.data, lastValue.data) ||
        !isEqual(inputs.status, lastValue.status) ||
        !isEqual(inputs.isFetching, lastValue.isFetching)
      ) {
        return inputs;
      } else {
        return lastValue;
      }
    },
    [queryResponses],
  );

  const numQueryResponses = queryResponses.length;

  return useMemoRef<QueryResponse<T>>(
    lastValue => {
      let data = lastValue?.data;
      let status: QueryStatus;

      if (!!statusHandler && !!numQueryResponses) {
        status = statusHandler(inputs.status, inputs.data, defaultStatus);
      } else {
        status = defaultStatus(inputs.status);
      }

      if (status === "success") {
        const _data = dataHandler && dataHandler(inputs.data, data, inputs.status);
        data = typeof _data !== "undefined" ? _data : data;
      }

      return {
        status,
        data,
        isFetching: inputs.isFetching.some(isFetching => isFetching),
      } as QueryResponse<T>;
    },
    [inputs, dataHandler, statusHandler, numQueryResponses],
  );
}
