import { HToken } from "../../api/getHifiPools/types";
import useMaxBonds from "../useMaxBonds";
import useUserDebts from "../useUserDebts";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { UserDebts } from "../../api/getUserDebts/types";
import { BigNumber } from "ethers";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";
import { useCallback } from "react";

export default function ({
  chainName,
  account,
  hToken,
}: {
  chainName: string;
  account: string;
  hToken: HToken;
}): QueryResponse<boolean> {
  const debtsRes = useUserDebts({ chainName, account });
  const maxBondsRes = useMaxBonds(chainName);

  return useCombinedQueryHooks(
    [debtsRes, maxBondsRes],
    useCallback(
      data => {
        const [userDebts, maxBonds] = data as [UserDebts, BigNumber];

        const _debts = userDebts || {};
        const totalBonds = Object.keys(_debts).length + (_debts[hToken.id] ? 0 : 1);
        return !!maxBonds && maxBonds.toNumber() < totalBonds;
      },
      [hToken],
    ),
  );
}
