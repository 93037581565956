import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { TableContainer } from "../../components/Table";
import Table from "../../components/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { HifiPool } from "../../api/getHifiPools/types";
import { memo } from "react";
import IconTypography from "../IconTypography";
import PoolRow from "../../containers/PoolRow";
import { Web3Provider } from "@ethersproject/providers";
import TableHeader from "../TableHeader";
import InfoIcon from "../InfoIcon";

function MyLiquidityTable({
  myLiquidityPools,
  walletProvider,
  onPoolClick,
  account,
  title,
  chainName,
  onMyLiquidityChange,
}: {
  myLiquidityPools: readonly HifiPool[];
  walletProvider?: Web3Provider;
  title: string;
  onPoolClick: (hifiPool: HifiPool) => void;
  account: string;
  chainName: string;
  onMyLiquidityChange?: (poolId: string, liquidity: number | undefined) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item lg={9} md={9} sm={11} xs={12}>
      <TableHeader variant="body2">{title}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "0px" }} />
              <TableCell align="left">
                <IconTypography variant="body1" color="textSecondary">
                  {t("Maturity Date")}
                  <Tooltip title={t("tooltips.pool.maturityDate") as string} arrow placement="top">
                    <InfoIcon tabIndex={0} />
                  </Tooltip>
                </IconTypography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Total Liquidity")}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: "33%" }}>
                <Typography variant="body1" color="textSecondary">
                  {t("My Liquidity")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...myLiquidityPools]
              .sort((a, b) => a.maturity - b.maturity)
              .map(pool => {
                return (
                  <PoolRow
                    walletProvider={walletProvider}
                    onPoolClick={onPoolClick}
                    key={pool.id}
                    pool={pool}
                    account={account}
                    onMyLiquidityChange={onMyLiquidityChange}
                    chainName={chainName}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(MyLiquidityTable);
