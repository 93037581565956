import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { QueryObserverResult, useQueries } from "react-query";
import { Collateral } from "../../api/getCollaterals/types";
import useCollateralMap from "../useCollateralMap";
import { buildQueryObject } from "../useTokenPrice";
import useUserEligibleLockedCollaterals from "../useUserEligibleLockedCollaterals";
import get from "lodash/get";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { UserLockedCollaterals } from "../../api/getUserLockedCollaterals/types";
import { CollateralMap } from "../useCollateralMap/types";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";

export default function useUserHypotheticalBorrowLimit({
  chainName,
  account,
  collateralModify,
  collateralAmountModify,
}: {
  chainName: string;
  account: string;
  collateralModify?: Collateral;
  collateralAmountModify?: string;
}): QueryResponse<string> {
  const lockedCollateralsRes = useUserEligibleLockedCollaterals({ chainName, account });
  const {
    data: userCollaterals,
    status: userLockedCollateralsStatus,
    isFetching: userLockedCollateralsIsFetching,
  } = lockedCollateralsRes;

  const collateralMapRes = useCollateralMap(chainName);
  const {
    data: collateralMap,
    isSuccess: collateralMapSuccess,
    isFetching: collateralMapIsFetching,
  } = collateralMapRes;

  const userLockedCollateralAddresses = useMemo(() => {
    return Object.keys(userCollaterals || []);
  }, [userCollaterals]);

  // Build query objects for the price of each of user's locked collateral types
  const tokenPriceQueryObjects =
    useMemo(() => {
      if (
        collateralMapSuccess &&
        !collateralMapIsFetching &&
        userLockedCollateralsStatus === "success" &&
        !userLockedCollateralsIsFetching
      ) {
        return compact(
          userLockedCollateralAddresses.map(collateralAddress => {
            if (collateralMap && collateralMap[collateralAddress]) {
              return {
                ...buildQueryObject({ chainName, symbol: collateralMap[collateralAddress].symbol }),
                retry: false,
                refetchOnWindowFocus: false,
              };
            }
          }),
        );
      }
    }, [
      chainName,
      collateralMap,
      collateralMapIsFetching,
      collateralMapSuccess,
      userLockedCollateralAddresses,
      userLockedCollateralsIsFetching,
      userLockedCollateralsStatus,
    ]) || [];

  // Build query object for the price of collateralModify
  // let collateralModifyPriceQuery = [] as UseQueryOptions[];

  const collateralModifyPriceQuery = useMemo(() => {
    if (collateralModify && collateralAmountModify) {
      return [
        {
          ...buildQueryObject({ chainName, symbol: collateralModify.symbol }),
          retry: false,
          refetchOnWindowFocus: false,
        },
      ];
    }
    return [];
  }, [chainName, collateralAmountModify, collateralModify]);

  // Run query for token prices
  const tokenPriceQueries = useQueries([
    ...tokenPriceQueryObjects,
    ...collateralModifyPriceQuery,
  ]) as QueryObserverResult<string>[];

  return useCombinedQueryHooks(
    [lockedCollateralsRes, collateralMapRes, ...tokenPriceQueries],
    useCallback(
      data => {
        const [userCollaterals, collateralMap, ...tokenPrices] = data as [
          UserLockedCollaterals,
          CollateralMap,
          ...Array<string>
        ];

        const totalCurrentCollateralInHTokens = userLockedCollateralAddresses.reduce(
          (memo, lockedCollateralAddress, i) => {
            const collateralPrice = get(tokenPrices, i) || "0";
            const collateralLocked = userCollaterals[lockedCollateralAddress] || "0";
            const collat = collateralMap[lockedCollateralAddress];
            return memo.add(
              parseUnits(collateralPrice, 18)
                .mul(parseUnits(collateralLocked, 18))
                .div(parseUnits(collat.collateralizationRatio, 18)),
            );
          },
          BigNumber.from("0"),
        );

        let collateralModifyInHTokens = BigNumber.from("0");
        if (collateralAmountModify && collateralModify) {
          const collateralModifyPrice = get(tokenPrices, [tokenPrices.length - 1]) || "0";

          collateralModifyInHTokens = parseUnits(collateralModifyPrice, 18)
            .mul(parseUnits(collateralAmountModify, 18))
            .div(parseUnits(collateralModify.collateralizationRatio, 18));
        }

        return formatUnits(totalCurrentCollateralInHTokens.add(collateralModifyInHTokens), 18);
      },
      [collateralAmountModify, collateralModify, userLockedCollateralAddresses],
    ),
  );
}
