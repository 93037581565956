import { useEffect, useState } from "react";

export default function useDebounce<T>(
  value: T,
  delay = 500,
): {
  debouncedValue: T;
  loading: boolean;
} {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [lastValue, setLastValue] = useState(value);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      setLastValue(value);
      if (debouncedValue === value) return;

      // Update debounced value after delay
      setLoading(true);
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setLoading(false);
      }, delay || 0);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        setLoading(false);
        clearTimeout(handler);
      };
    },
    [value, delay, debouncedValue], // Only re-call effect if value or delay changes
  );

  return { debouncedValue: debouncedValue, loading: value !== lastValue || loading };
}
