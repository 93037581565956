import { useCallback, useContext, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { InlineDottyProgress } from "../../components/DottyProgress";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { Context as TransactionsContext, LAST_RESULT } from "contexts/transactionsBase";
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import MaxInputWithBalance from "../../components/MaxInputWithBalance";
import TokenIcon from "../../icons/TokenIcon";
import { HifiPool } from "../../api/getHifiPools/types";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { Web3Provider } from "@ethersproject/providers";
import useShareOfPool from "../../hooks/useShareOfPool";
import { formatUnits, parseUnits } from "@ethersproject/units";
import DialogContentArea from "../../components/DialogContentArea";
import DialogDataRow from "../../components/DialogDataRow";
import { BigNumber } from "ethers";
import FormHelperText from "@mui/material/FormHelperText";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import { AddressZero } from "@ethersproject/constants";
import {
  approve,
  createDsProxy,
  depositUnderlyingAndMintHTokenAndAddLiquidity,
  permit,
} from "../../utils/transactions";
import { useTranslation } from "react-i18next";
// import { useMintInputs } from "../../hooks/useMintInputs";
import { useProxyTargetContract } from "../../hooks/contracts";
import { getContractAddress, isTokenPermissive } from "../../contracts/addresses";
import { truncateDecimals } from "utils/numbers";
import useDebounce from "hooks/useDebounce";
// import { useUnderlyingOutForRemoveLiquidityAndSellHToken } from "../../hooks/useUnderlyingOutForRemoveLiquidityAndSellHToken";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import useLiquidityError from "../../hooks/useLiquidityError";
import { useUnderlyingOffered } from "../../hooks/useUnderlyingOffered";
import shouldSkipDSProxyConfirmations from "../../utils/shouldSkipDSProxyConfirmations";
import { displayShareOfPoolChange } from "../ManageLiquidityDialog";
import useAllowance from "hooks/useAllowance";
import { Context as TosContext } from "@hifi/react-tos-modal";
import { logEvent } from "../../utils/analytics";

const PREFIX = "AddLiquidityTab";

const classes = {
  selectedButton: `${PREFIX}-selectedButton`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.selectedButton}`]: {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

export default function AddLiquidityTab({
  onCloseClick,
  pool,
  account,
  walletProvider,
  chainName,
  connect,
  connecting,
  setAddLiquidityInput,
  addLiquidityInput,
  setPendingTxId,
}: {
  onCloseClick: () => void;
  pool: HifiPool;
  account: string;
  walletProvider: Web3Provider | undefined;
  chainName: string;
  connect: () => void;
  connecting: boolean;
  setAddLiquidityInput: React.Dispatch<React.SetStateAction<string>>;
  addLiquidityInput: string;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element {
  const { debouncedValue: debouncedAddLiquidityInput, loading: loadingAddLiquidityInput } =
    useDebounce(addLiquidityInput);

  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });
  const { addTransactions, isTransactionQueued } = useContext(TransactionsContext);
  const { userAccepted, invokeModal } = useContext(TosContext);

  const { t } = useTranslation();

  const { data: underlyingBalance } = useUserErc20Balance({
    token: pool.hToken.underlying,
    account,
    walletProvider,
  });

  // const { data: underlyingOut } = useUnderlyingOutForRemoveLiquidityAndSellHToken({
  //   poolTokensBurned: debouncedRemoveLiquidityInput,
  //   hifiPool: pool,
  // });

  const { data: underlyingAllowance, isLoading: underlyingAllowanceIsLoading } = useAllowance({
    token: pool.hToken.underlying,
    spenderAddress: dsProxyAddress,
    account,
  });

  const { data: currentShareOfPool, status: currentShareOfPoolStatus } = useShareOfPool({
    account,
    hifiPool: pool,
    walletProvider,
  });

  const addLiquidityInputBn = useMemo(() => {
    if (debouncedAddLiquidityInput) {
      return parseUnits(debouncedAddLiquidityInput, pool.hToken.underlying.decimals);
    }
  }, [debouncedAddLiquidityInput, pool]);

  // const { data: mintInputs, status: mintInputsStatus } = useMintInputs({
  //   underlyingOffered: addLiquidityInputBn,
  //   hifiPoolAddress: pool.id,
  // });

  const { data: underlyingOffered, status: underlyingOfferedStatus } = useUnderlyingOffered({
    underlyingInput: addLiquidityInputBn,
    hifiPool: pool,
  });

  const insufficientFunds = useMemo(() => {
    if (addLiquidityInputBn && underlyingBalance) {
      return parseUnits(underlyingBalance, pool.hToken.underlying.decimals).lt(addLiquidityInputBn);
    }
    return false;
  }, [addLiquidityInputBn, pool, underlyingBalance]);

  const proxyTargetContract = useProxyTargetContract();

  const {
    data: shareOfPool,
    status: shareOfPoolStatus,
    isFetching: shareOfPoolFetching,
  } = useShareOfPool({
    account,
    hifiPool: pool,
    walletProvider,
    underlyingProvided:
      addLiquidityInput && underlyingOffered && formatUnits(underlyingOffered, pool.hToken.underlying.decimals),
    lpTokensProvided: 0,
  });

  const addLiqudityButtonDisabled =
    !addLiquidityInputBn ||
    addLiquidityInputBn.lte(BigNumber.from("0")) ||
    insufficientFunds ||
    underlyingAllowanceIsLoading ||
    underlyingOfferedStatus !== "success" ||
    loadingAddLiquidityInput;

  const addLiquidityError = useLiquidityError({
    debouncedAddLiquidityInput,
    insufficientFunds,
  });

  const onAddLiquiditySubmit = useCallback(
    e => {
      e.preventDefault();

      if (!account) {
        if (userAccepted) {
          connect();
          return;
        } else {
          invokeModal(() => {
            connect();
          });
          return;
        }
      }

      if (addLiqudityButtonDisabled || !proxyTargetContract || !addLiquidityInputBn || !dsProxyAddress) return;

      const txs = [];
      // If DSProxy has already been created, or if there is a create DSProxy tx queued up, then skip
      // DSProxy creation tx.
      if (
        dsProxyAddress === AddressZero &&
        !isTransactionQueued({
          methodName: "build()",
          contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
        })
      ) {
        txs.push(
          createDsProxy({
            contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
            description: t("transactions.dsProxy"),
            skipConfirmations: shouldSkipDSProxyConfirmations(chainName),
          }),
        );
      }

      if (isTokenPermissive(chainName, pool.hToken.underlying.id)) {
        txs.push(
          permit({
            contractAddress: pool.hToken.underlying.id,
            description: t("transactions.messages.allowance", {
              spender: "Hifi",
              token: pool.hToken.underlying.symbol,
            }),
            ownerAddress: account,
            spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
            amount: addLiquidityInputBn.toString(),
          }),
        );
      } else {
        // If approve has already been done, or if there is an approve tx queued up, then skip
        // approve tx.
        if (
          underlyingAllowance === "0" &&
          !isTransactionQueued({ methodName: "approve", contractAddress: pool.hToken.underlying.id })
        ) {
          txs.push(
            approve({
              description: t("transactions.messages.allowance", {
                spender: "Hifi",
                token: pool.hToken.underlying.symbol,
              }),
              contractAddress: pool.hToken.underlying.id,
              spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
              callbackArgs: {
                spenderAddress: "DSPROXY_ADDRESS",
                tokenAddress: pool.hToken.underlying.id,
              },
            }),
          );
        }
      }

      const callbackArgs = {
        hifiPoolAddress: pool.id,
        underlyingAddress: pool.hToken.underlying.id,
        hTokenAddress: pool.hToken.id,
      };

      logEvent("addLiquidity", {
        addLiquidityInput,
      });

      if (underlyingOffered) {
        txs.push(
          depositUnderlyingAndMintHTokenAndAddLiquidity({
            contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}-1` : dsProxyAddress,
            description: t("transactions.addLiquidity.description", {
              amount: addLiquidityInput,
              symbol: pool.hToken.underlying.symbol,
              poolName: pool.name,
            }),
            completionMessage: t("transactions.addLiquidity.completionMessage", {
              amount: addLiquidityInput,
              symbol: pool.hToken.underlying.symbol,
              poolName: pool.name,
            }),
            proxyTargetContract,
            hifiPoolAddress: pool.id,
            depositAmount: addLiquidityInputBn.sub(underlyingOffered),
            balanceSheetAddress: getContractAddress(chainName, "balanceSheet"),
            underlyingOffered,
            hasSignature: isTokenPermissive(chainName, pool.hToken.underlying.id),
            callbackArgs,
          }),
        );
      }

      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Add Liquidity"),
      });

      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      addLiqudityButtonDisabled,
      dsProxyAddress,
      isTransactionQueued,
      pool,
      t,
      addLiquidityInput,
      chainName,
      addTransactions,
      proxyTargetContract,
      setPendingTxId,
      addLiquidityInputBn,
      underlyingOffered,
      account,
      connect,
      underlyingAllowance,
      userAccepted,
      invokeModal,
    ],
  );

  let addLiquidityBtnText = t("Add Liquidity");
  if (connecting) {
    addLiquidityBtnText = t("Connecting...");
  } else if (!account) {
    addLiquidityBtnText = t("Connect Wallet");
  }

  const shareOfPoolLoading = underlyingOfferedStatus === "loading" || loadingAddLiquidityInput || shareOfPoolFetching;

  return (
    <Root onSubmit={onAddLiquiditySubmit}>
      <DialogContentArea>
        <Box mb={4}>
          <Typography display="inline" variant="h5" color="textSecondary">
            {t("Maturity Date")}:&nbsp;&nbsp;
          </Typography>
          <Typography variant="h5" display="inline">
            {new Date(pool.maturity * 1000).toLocaleString()}
          </Typography>
        </Box>
        <MaxInputWithBalance
          tokenSymbol={pool.hToken.underlying.symbol}
          autoFocus
          balance={underlyingBalance && truncateDecimals(underlyingBalance, 2)}
          balanceLabel={t("Wallet")}
          error={!!addLiquidityError}
          fullWidth
          style={{ fontSize: "1.75em" }}
          value={addLiquidityInput}
          onChange={(e: React.ChangeEvent) => setAddLiquidityInput((e.target as HTMLTextAreaElement).value)}
          max={underlyingBalance}
          inputProps={{
            min: 0,
          }}
          decimals={pool.hToken.underlying.decimals}
          startAdornment={
            <InputAdornment position="start">
              <TokenIcon style={{ fontSize: "1.15em" }} symbol={pool.hToken.underlying.symbol as TokenSymbol} />
            </InputAdornment>
          }
        />
        {!!addLiquidityError && (
          <Typography variant="body1">
            <FormHelperText error>{addLiquidityError}</FormHelperText>
          </Typography>
        )}
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <Typography variant="body1" color="textSecondary">
            {t("Share of Pool")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {shareOfPoolLoading && <InlineDottyProgress />}
            {!shareOfPoolLoading &&
              displayShareOfPoolChange({
                currentValue: currentShareOfPool,
                currentValueStatus: currentShareOfPoolStatus,
                newValue: shareOfPool,
                newValueStatus: shareOfPoolStatus,
              })}
          </Typography>
        </DialogDataRow>
      </DialogContentArea>

      <DialogContentArea>
        <Button
          disabled={(addLiqudityButtonDisabled && !!account) || connecting}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
        >
          {addLiquidityBtnText}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </Root>
  );
}
