import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import classnames from "classnames";

const PREFIX = "IconTypography";

const classes = {
  iconContainer: `${PREFIX}-iconContainer`,
};

const StyledTypography = styled(Typography)(() => ({
  [`&.${classes.iconContainer}`]: {
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle",
  },
}));

export default function IconTypography(props: TypographyProps): JSX.Element {
  return (
    <StyledTypography {...props} className={classnames(classes.iconContainer, props.className)}>
      {props.children}
    </StyledTypography>
  );
}
