import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { QueryObserverResult, useQuery } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import getQuoteForBuyingHToken from "../../api/getQuoteForBuyingHToken";
import isExpired from "../../utils/isExpired";
import useMemoRef from "hooks/useMemoRef";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function buildQueryOptions({ hifiPool, hTokenOut }: { hifiPool: HifiPool; hTokenOut: string }) {
  return {
    cacheTime: 0.5 * 60 * 1000,
    staleTime: 0.5 * 60 * 1000,
    refetchInterval: 0.5 * 60 * 1000,
    enabled:
      !!hTokenOut && parseUnits(hTokenOut, hifiPool.hToken.decimals).gte(BigNumber.from("0")) && !isExpired(hifiPool),
    refetchOnWindowFocus: false,
    retry: false,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function buildQueryKey({ hifiPoolAddress, hTokenOut }: { hifiPoolAddress: string; hTokenOut: string }) {
  return ["quoteForBuyingHToken", hifiPoolAddress, hTokenOut];
}

export function buildQueryFn(hifiPool: HifiPool) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return ({ queryKey }: { queryKey: string[] }) => {
    if (parseUnits(queryKey[2], hifiPool.hToken.decimals).gt(BigNumber.from("0"))) {
      return getQuoteForBuyingHToken({
        poolAddress: queryKey[1],
        hTokenOut: parseUnits(queryKey[2], hifiPool.hToken.decimals),
      });
    } else {
      return BigNumber.from("0");
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function buildQueryObject({ hifiPool, hTokenOut }: { hifiPool: HifiPool; hTokenOut: string }) {
  return {
    queryKey: buildQueryKey({ hifiPoolAddress: hifiPool.id, hTokenOut }),
    queryFn: buildQueryFn(hifiPool),
    ...buildQueryOptions({ hifiPool, hTokenOut }),
  };
}

export default function useQuoteForBuyingHToken({
  hTokenOut,
  hifiPool,
}: {
  hTokenOut: string;
  hifiPool: HifiPool;
}): QueryObserverResult<string | undefined> {
  const { data, status, ...others } = useQuery(
    ["quoteForBuyingHToken", hifiPool.id, hTokenOut],
    buildQueryFn(hifiPool),
    buildQueryOptions({ hifiPool, hTokenOut }),
  );

  const _data = useMemoRef(
    lastValue => {
      if (status == "idle") {
        return;
      } else if (data && status === "success") {
        return formatUnits(data, hifiPool.hToken.underlying.decimals);
      }
      return lastValue;
    },
    [data, hifiPool, status],
  );

  return { data: _data, status, ...others } as QueryObserverResult<string | undefined>;
}
