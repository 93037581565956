import { memo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table, { TableContainer } from "../../components/Table";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useTranslation } from "react-i18next";
import { HifiPool } from "../../api/getHifiPools/types";
import { UserLendingPosition } from "../../hooks/useUserLendingPositions/types";
import MyMarketRow from "../../containers/MyMarketRow";
import IconTypography from "../IconTypography";
import TableHeader from "../TableHeader";
import InfoIcon from "../InfoIcon";

function MyLendingTable({
  myLending,
  onMarketClick,
}: {
  myLending: UserLendingPosition[];
  onMarketClick: (hifiPool: HifiPool) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item lg={9} md={9} sm={11} xs={12}>
      <TableHeader variant="body2">{t("Lending")}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body1" color="textSecondary">
                  {t("Asset")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Maturity Date")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <IconTypography variant="body1" color="textSecondary">
                  {t("APY")}
                  <Tooltip title={t("tooltips.borrow.apy") as string} arrow placement="top">
                    <InfoIcon tabIndex={0} />
                  </Tooltip>
                </IconTypography>
              </TableCell>
              <TableCell align="right" style={{ width: "33%" }}>
                <Typography variant="body1" color="textSecondary">
                  {t("Balance")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myLending
              .sort((a, b) => a.hifiPool.maturity - b.hifiPool.maturity)
              .map(market => {
                return (
                  <MyMarketRow
                    key={market.hifiPool.id}
                    hifiPool={market.hifiPool}
                    onMarketClick={onMarketClick}
                    balance={market.hTokenBalance}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(MyLendingTable);
