import { useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Tab from "@mui/material/Tab";
import RatioText from "../../components/RatioText";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Dialog } from "../../components/ActionDialog";
import { HifiPool } from "../../api/getHifiPools/types";
import { parseUnits } from "@ethersproject/units";
import { QueryStatus } from "react-query";
import { BigNumber } from "ethers";
import { useTranslation } from "react-i18next";
import usePendingTransactionUI from "../../hooks/usePendingTransactionUI";
import SingleTaskPanel from "../../components/SingleTaskPanel";
import { Web3Provider } from "@ethersproject/providers";
import isExpired from "../../utils/isExpired";
import BorrowTab from "../BorrowTab";
import RepayTab from "../RepayTab";

export function displayBorrowLimitUsedChange({
  currentValue,
  currentValueStatus,
  newValue,
  newValueStatus,
  isError,
}: {
  currentValue?: string;
  currentValueStatus: QueryStatus;
  newValue?: string;
  newValueStatus: QueryStatus;
  isError?: boolean;
}): JSX.Element {
  const newValueFloat = newValue && parseFloat(newValue);
  const currentValueFloat = currentValue && parseFloat(currentValue);
  if (
    currentValueStatus !== "success" ||
    newValueStatus !== "success" ||
    (!newValueFloat && !currentValueFloat) ||
    (!!newValueFloat && newValueFloat < 0) ||
    (newValueFloat && newValueFloat > 1) ||
    isError
  ) {
    return <span>&ndash;</span>;
  } else if ((typeof newValueFloat === "undefined" || newValueFloat === currentValueFloat) && !!currentValueFloat) {
    return <RatioText percent={Math.round(currentValueFloat * 100)} />;
  } else {
    return (
      <>
        <RatioText percent={Math.round((currentValueFloat || 0) * 100)} />
        &nbsp;&nbsp;&rarr;&nbsp;&nbsp;
        <RatioText percent={Math.round((newValueFloat || 0) * 100)} />
      </>
    );
  }
}

export default function ManageBorrowDialog({
  onCloseClick,
  actionType = "borrow",
  hifiPool,
  account,
  chainName,
  balance,
  walletProvider,
  connect,
  connecting,
}: {
  onCloseClick: () => void;
  actionType?: "borrow" | "payback";
  hifiPool: HifiPool;
  account: string;
  chainName: string;
  balance?: string;
  walletProvider?: Web3Provider;
  connect: () => void;
  connecting: boolean;
}): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(actionType === "borrow" ? 0 : 1);
  const [borrowInput, setBorrowInput] = useState("");
  const [paybackInput, setPaybackInput] = useState("");

  const { t } = useTranslation();

  const { showTx, transaction, setPendingTxId } = usePendingTransactionUI(onCloseClick);

  // Value conversions --------------------------------------------------------------------------------------------|
  const balanceBn = useMemo(() => {
    if (balance) {
      return parseUnits(balance, hifiPool.hToken.decimals);
    }
  }, [balance, hifiPool]);
  // --------------------------------------------------------------------------------------------------------------|

  const _onClose = useCallback(
    (_evt, _reason) => {
      if (_reason !== "backdropClick" || !transaction || !showTx) {
        onCloseClick();
      }
    },
    [onCloseClick, transaction, showTx],
  );

  return (
    <Dialog onClose={_onClose} open={!transaction}>
      {transaction && showTx && (
        <DialogContent>
          <SingleTaskPanel chainName={chainName} onClose={onCloseClick} transaction={transaction} />
        </DialogContent>
      )}
      {!showTx && (
        <>
          <Box>
            <Tabs
              value={selectedTab}
              variant="fullWidth"
              indicatorColor="primary"
              onChange={(_e, value) => setSelectedTab(value)}
              textColor="inherit"
            >
              <Tab
                disabled={isExpired(hifiPool)}
                disableRipple
                label={<Typography variant="h4">{t("Borrow")}</Typography>}
              />
              <Tab
                disabled={!balanceBn || balanceBn.eq(BigNumber.from("0"))}
                disableRipple
                label={<Typography variant="h4">{t("Repay")}</Typography>}
              />
            </Tabs>
            <Box position="relative" />
          </Box>
          <DialogContent>
            {selectedTab === 0 && (
              <BorrowTab
                onCloseClick={onCloseClick}
                hifiPool={hifiPool}
                account={account}
                chainName={chainName}
                connecting={connecting}
                connect={connect}
                setPendingTxId={setPendingTxId}
                setBorrowInput={setBorrowInput}
                borrowInput={borrowInput}
                balance={balance}
              />
            )}
            {selectedTab === 1 && (
              <RepayTab
                onCloseClick={onCloseClick}
                hifiPool={hifiPool}
                account={account}
                chainName={chainName}
                setPendingTxId={setPendingTxId}
                paybackInput={paybackInput}
                setPaybackInput={setPaybackInput}
                balance={balance}
                walletProvider={walletProvider}
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
