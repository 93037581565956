import { useCallback, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import MaxInput from "components/MaxInput";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { numberWithCommas } from "utils/numbers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MaxInputWithBalanceProps } from "./types";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../components/ActionDialog";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import DialogContent from "@mui/material/DialogContent";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";

const PREFIX = "index";

const classes = {
  balance: `${PREFIX}-balance`,
  maxInput: `${PREFIX}-maxInput`,
  dropdownAnchor: `${PREFIX}-dropdownAnchor`,
  dropdownAnchorInner: `${PREFIX}-dropdownAnchorInner`,
  expandIcon: `${PREFIX}-expandIcon`,
  menuListItem: `${PREFIX}-menuListItem`,
};

type RootProps = {
  hasDropdown?: boolean;
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")<RootProps>(({ theme, hasDropdown }) => ({
  [`& .${classes.balance}`]: {
    position: "absolute",
    bottom: "0.6em",
    left: hasDropdown ? "7.5em" : "5.8em",
    cursor: "pointer",
  },

  [`& .${classes.maxInput}`]: {
    "& .MuiInputBase-input": {
      padding: ".6em 1em 1.35em 0", // This (em) is correct -- it should be relative to the font-size given to the input
    },
    "& .MuiInputAdornment-positionStart": {
      // paddingRight: hasDropdown => (hasDropdown ? "2em" : ".8em"),
    },
  },

  [`& .${classes.dropdownAnchor}`]: {
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
  },

  [`& .${classes.dropdownAnchorInner}`]: {
    opacity: "0",
  },

  [`& .${classes.expandIcon}`]: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: ".4em",
    fontSize: "1.2em",
  },

  [`& .${classes.menuListItem}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.focus,
    },
  },
}));

export default function MaxInputWithBalance(props: MaxInputWithBalanceProps): JSX.Element {
  const { balance, tokenSymbol, max, onMenuItemClick, onChange, tokenOptions, balanceLabel, ...others } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const ref = useRef();

  const { t } = useTranslation();

  const startAdornment = props.startAdornment;
  const balanceFloat = typeof balance === "number" ? balance : parseFloat(balance as string);
  const inputValueFloat = parseFloat(inputValue);

  const _onChange = useCallback(
    e => {
      setInputValue(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  const handleClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const _onMenuItemClick = useCallback(
    (tokenSymbol: TokenSymbol) => {
      if (onMenuItemClick) {
        onMenuItemClick(tokenSymbol);
        handleClose();
      }
    },
    [onMenuItemClick, handleClose],
  );

  const onBalanceClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const curr: any = ref.current;
    curr.onMaxClick();
  }, []);

  const onMenuClick = useCallback(
    event => {
      if (event.key && event.key !== "Enter") return;

      if (tokenOptions && tokenOptions.length > 0) {
        setIsMenuOpen(true);
      }
    },
    [tokenOptions],
  );

  const bLabel = balanceLabel || t("Balance");

  return (
    <Root>
      <Box position="relative">
        {balance && !isNaN(balanceFloat) ? (
          <Typography
            color={balanceFloat < inputValueFloat ? "textSecondary" : "textSecondary"}
            className={classes.balance}
            onClick={onBalanceClick}
            variant="body1"
          >
            {bLabel}:&nbsp;&nbsp;{numberWithCommas(balance)}&nbsp;{tokenSymbol ? tokenSymbol : ""}
          </Typography>
        ) : (
          <></>
        )}
        <MaxInput className={classes.maxInput} onChange={_onChange} ref={ref} max={max} {...others} />
        {startAdornment && !!tokenOptions && !!tokenOptions.length && (
          <Box
            style={{ fontSize: props.style?.fontSize }}
            className={classes.dropdownAnchor}
            tabIndex={0}
            onKeyDown={onMenuClick}
            onClick={onMenuClick}
          >
            <Box className={classes.dropdownAnchorInner}>{startAdornment}</Box>
            <ExpandMoreIcon className={classes.expandIcon} />
          </Box>
        )}
      </Box>
      {onMenuItemClick && (
        <Dialog open={isMenuOpen} onClose={handleClose}>
          <DialogTitle>{t("Select a Token")}</DialogTitle>
          <DialogContent style={{ paddingTop: 0 }}>
            <MenuList>
              {tokenOptions &&
                tokenOptions.map(token => {
                  const { symbol, name, balance } = token;
                  return (
                    <MenuItem
                      disabled={tokenSymbol === symbol}
                      key={symbol}
                      onClick={() => _onMenuItemClick(symbol)}
                      className={classes.menuListItem}
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <Box display="flex" alignItems="center" flexGrow={1}>
                          <Box display="flex" alignItems="center" mr={2}>
                            <TokenIcon style={{ fontSize: "2em" }} symbol={symbol} />
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="h6">{symbol}</Typography>
                            {name && (
                              <Typography color="textSecondary" variant="body1">
                                {name}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {balance && (
                          <Box textAlign="right">
                            <Typography variant="h6">{balance}</Typography>
                          </Box>
                        )}
                      </Box>
                    </MenuItem>
                  );
                })}
            </MenuList>
          </DialogContent>
        </Dialog>
      )}
    </Root>
  );
}
