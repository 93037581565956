import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { SingleTaskPanelProps } from "./types";
import Link from "@mui/material/Link";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const PREFIX = "SingleTaskPanel";

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    fontSize: "6rem",
    border: `4px solid ${theme.palette.text.primary}`,
    borderRadius: "1000px",
  },
}));

export default function SingleTaskPanel(props: SingleTaskPanelProps): JSX.Element | null {
  const { transaction, chainName, onClose } = props;
  const { description, attempted } = transaction;
  const [shouldRender, setShouldRender] = useState(false);

  const { t } = useTranslation();

  let etherscanDomain = chainName !== "homestead" ? `${chainName}.etherscan.io` : "etherscan.io";
  if (chainName === "matic") {
    etherscanDomain = "polygonscan.com";
  }

  useEffect(() => {
    setTimeout(() => {
      setShouldRender(true);
    });
  }, [setShouldRender]);

  if (!shouldRender) {
    return <StyledBox mt={30} mb={29}></StyledBox>;
  }

  return !transaction.txHash ? (
    <Box pt={10} pb={4}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb={2}>
          <Typography variant="h4" align="center">
            {attempted && <span>{t("transactions.messages.approve")}</span>}
            {!attempted && <span>{t("transactions.messages.queued")}</span>}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" align="center" color="textSecondary">
          <span style={{ fontSize: ".9em" }}>{description}</span>
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box pt={6}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb={6}>
          <ArrowUpwardIcon className={classes.icon} />
        </Box>
        <Typography variant="h4" align="center" color="textPrimary" style={{ marginBottom: ".2em" }}>
          {t("transactions.messages.submitted")}
        </Typography>
        <Link
          target="_blank"
          color="textSecondary"
          underline="always"
          variant="h6"
          href={`https://${etherscanDomain}/tx/${transaction.txHash}`}
        >
          {t("View on Explorer")}
        </Link>
        <Box mt={9} width="100%">
          <Button color="primary" size="large" variant="contained" fullWidth type="submit" onClick={onClose}>
            {t("Close")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
