import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import { BigNumber } from "ethers";
import { HifiPool } from "../../api/getHifiPools/types";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import useQuoteForBuyingHToken from "../../hooks/useQuoteForBuyingHToken";
import calcAPY from "../../utils/calcAPY";
import { InlineDottyProgress } from "../../components/DottyProgress";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import useQuoteForSellingHToken from "../../hooks/useQuoteForSellingHToken";
import { formatUnits } from "@ethersproject/units";
import IconTypography from "../../components/IconTypography";
import isExpired from "../../utils/isExpired";

export default function MyMarketRow({
  hifiPool,
  onMarketClick,
  balance,
}: {
  hifiPool: HifiPool;
  onMarketClick: (hifiPool: HifiPool) => void;
  balance: BigNumber;
}): JSX.Element {
  const { data: underlyingValue, status: underlyingValueStatus } = useQuoteForSellingHToken({
    hTokenIn: formatUnits(balance, 18),
    hifiPool,
  });

  const {
    data: hTokenSpotPrice,
    status: hTokenSpotPriceStatus,
    isLoading: hTokenSpotPriceIsLoading,
  } = useQuoteForBuyingHToken({ hifiPool, hTokenOut: "1" });

  const symbol = hifiPool.hToken.underlying.symbol as TokenSymbol;
  return (
    <TableRow hover onClick={() => onMarketClick(hifiPool)}>
      <TableCell align="left">
        <IconTypography variant="h5">
          <Box display="flex" alignItems="center">
            <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          </Box>
          {symbol}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{new Date(hifiPool.hToken.maturity * 1000).toLocaleDateString()}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {isExpired(hifiPool) && <span>&ndash;</span>}
          {!isExpired(hifiPool) && hTokenSpotPriceIsLoading && <InlineDottyProgress />}
          {!isExpired(hifiPool) &&
            hTokenSpotPrice &&
            hTokenSpotPriceStatus === "success" &&
            `${(calcAPY(parseFloat(hTokenSpotPrice), 1, hifiPool.maturity * 1000) * 100).toFixed(2)}%`}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {underlyingValueStatus === "loading" && <InlineDottyProgress />}
          {underlyingValueStatus === "success" &&
            underlyingValue &&
            `${numberWithCommas(truncateDecimals(underlyingValue, 2))} ${hifiPool.hToken.underlying.symbol}`}
          {underlyingValueStatus === "error" &&
            `${numberWithCommas(truncateDecimals(formatUnits(balance, 18), 2))} ${hifiPool.hToken.underlying.symbol}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
