export const firebaseConfig = {
  apiKey: "AIzaSyDKCkVTgMgATIc6ch5WJwoqkDvW9XBaX6E",
  authDomain: "highfidelityfinance.firebaseapp.com",
  databaseURL: "https://highfidelityfinance.firebaseio.com",
  projectId: "highfidelityfinance",
  storageBucket: "highfidelityfinance.appspot.com",
  messagingSenderId: "588117726322",
  appId: "1:588117726322:web:562c3ce0a9ca1555fe9eeb",
  measurementId: "G-CZS070CHX8",
};

export const sanctionedCountries = [
  "CU", // Cuba
  "IR", // Iran
  "KP", // North Korea
  "RU", // Russia
  "SY", // Syria
  "UA", // Ukraine (for Crimea, Donetsk, and Luhansk regions)
  "BY", // Belarus
  "MM", // Burma (Myanmar)
  "CF", // Central African Republic
  "CD", // Congo, Dem. Rep. of
  "ET", // Ethiopia
  "HK", // Hong Kong
  "IQ", // Iraq
  "LB", // Lebanon
  "LY", // Libya
  "SD", // Sudan
  "VE", // Venezuela
  "YE", // Yemen
  "ZW", // Zimbabwe
  "US",
];
