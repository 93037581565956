import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { Collateral } from "../../api/getCollaterals/types";
import useUserHypotheticalBorrowLimit from "../useUserHypotheticalBorrowLimit";
import useUserTotalDebt from "../useUserTotalDebt";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";
import { useCallback } from "react";

export default function useUserBorrowLimitUsed({
  chainName,
  account,
  debtAmountModify,
  collateralModify,
  collateralAmountModify,
}: {
  chainName: string;
  account: string;
  debtAmountModify?: string;
  collateralModify?: Collateral;
  collateralAmountModify?: string;
}): QueryResponse<string> {
  const totalDebtRes = useUserTotalDebt({
    chainName,
    account,
    debtAmountModify,
  });

  const borrowLimitRes = useUserHypotheticalBorrowLimit({
    chainName,
    account,
    collateralModify,
    collateralAmountModify,
  });

  return useCombinedQueryHooks(
    [totalDebtRes, borrowLimitRes],
    useCallback(data => {
      const [totalDebt, borrowLimit] = data as [string, string];
      if (totalDebt && borrowLimit) {
        const borrowLimitBn = parseUnits(borrowLimit, 18);
        // if (parseFloat(truncateDecimals(totalDebt, 2)) === 0 && borrowLimitBn.gt(BigNumber.from("0"))) {
        //   // If the total debt is dusty (less than 0.01), then just return 0%
        //   data = "0";
        // } else {
        if (borrowLimitBn.gt(BigNumber.from("0"))) {
          return formatUnits(parseUnits(totalDebt, 18 * 2).div(borrowLimitBn), 18);
        }
        // }
      }
    }, []),
  );
}
