import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collateral } from "../../api/getCollaterals/types";

export const INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS";
export const COLLATERAL_CAP = "COLLATERAL_CAP";

export type ErrorType = typeof INSUFFICIENT_FUNDS | typeof COLLATERAL_CAP;

export default function useDepositError({
  insufficientDepositFunds,
  isLoading,
  depositInput,
  balanceSheetBalance,
  collateral,
}: {
  insufficientDepositFunds: boolean;
  depositInput: string;
  balanceSheetBalance?: BigNumber;
  isLoading: boolean;
  collateral: Collateral;
}): string {
  const [error, setError] = useState<ErrorType | "">("");

  const { t } = useTranslation();

  const errorsMsgs = {
    [INSUFFICIENT_FUNDS]: t("errors.insufficientFunds"),
    [COLLATERAL_CAP]: t("errors.depositLimit"),
  };

  useEffect(() => {
    const newErrors: (ErrorType | "")[] = [];
    if (!isLoading) {
      if (insufficientDepositFunds) {
        newErrors.push(INSUFFICIENT_FUNDS);
      }
      if (
        !!depositInput &&
        !!balanceSheetBalance &&
        parseUnits(depositInput, collateral.decimals).add(balanceSheetBalance).gt(collateral.ceiling)
      ) {
        newErrors.push(COLLATERAL_CAP);
      }

      if (!newErrors.length) {
        setError("");
      } else if (!newErrors.includes(error)) {
        setError(newErrors[0]);
      }
    }
  }, [insufficientDepositFunds, depositInput, balanceSheetBalance, isLoading, error, collateral]);

  return error ? errorsMsgs[error] : "";
}
