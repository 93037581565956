import { AddressZero } from "@ethersproject/constants";
import { QueryObserverResult, useQuery } from "react-query";
import getUserDSProxy from "../../api/getUserDSProxy";
import { Context as Web3Context } from "../../contexts/web3";
import { useContext } from "react";

export default function useUserDSProxy({
  chainName,
  account,
}: {
  account: string;
  chainName: string;
}): QueryObserverResult<string> {
  const { walletProvider } = useContext(Web3Context);

  const { status, ...others } = useQuery(
    ["userDSProxy", account, chainName],
    () => getUserDSProxy({ chainName, account, walletProvider }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!walletProvider,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  if (status !== "success") {
    return { ...others, data: AddressZero, status } as QueryObserverResult<string>;
  }
  return { status, ...others } as QueryObserverResult<string>;
}
