import { BigNumber } from "ethers";
import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import getAccountErc20Balance from "../../api/getAccountErc20Balance";

function buildQueryOptions(account: string) {
  return {
    enabled: !!account,
    retry: false,
    refetchOnWindowFocus: false,
  };
}

function buildQueryFn({ tokenAddress, account }: { tokenAddress: string; account: string }) {
  return () => getAccountErc20Balance({ erc20Address: tokenAddress, account });
}

export function buildQueryKey({
  account,
  tokenAddress,
}: {
  account: string;
  tokenAddress: string;
}): [string, string, string] {
  return ["accountErc20Balance", account, tokenAddress];
}

export function buildQueryObject({
  tokenAddress,
  account,
}: {
  tokenAddress: string;
  account: string;
}): UseQueryOptions {
  return {
    queryKey: buildQueryKey({ account, tokenAddress }),
    queryFn: buildQueryFn({ tokenAddress, account }),
    ...buildQueryOptions(account),
  };
}

export default function useAccountErc20Balance({
  tokenAddress,
  account,
}: {
  tokenAddress: string;
  account: string;
}): QueryObserverResult<BigNumber | undefined> {
  const resp = useQuery(
    buildQueryKey({ account, tokenAddress }),
    buildQueryFn({ tokenAddress, account }),
    buildQueryOptions(account),
  );

  return resp;
}
