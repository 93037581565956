import { memo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table, { TableContainer } from "../../components/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useTranslation } from "react-i18next";
import { HifiPool } from "../../api/getHifiPools/types";
import { UserLendingPosition } from "../../hooks/useUserLendingPositions/types";
import TableHeader from "../TableHeader";
import LendMarketRow from "../../containers/LendMarketRow";

function LendMarketsTable({
  lendMarkets,
  onMarketClick,
  title,
}: {
  lendMarkets: UserLendingPosition[];
  onMarketClick: (hifiPool: HifiPool) => void;
  title: string;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item lg={9} md={9} sm={11} xs={12}>
      <TableHeader variant="body2">{title}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body1" color="textSecondary">
                  {t("Asset")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Maturity Date")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("APY")}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: "33%" }}>
                <Typography variant="body1" color="textSecondary">
                  {t("Liquidity")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lendMarkets
              .sort((a, b) => a.hifiPool.maturity - b.hifiPool.maturity)
              .map(market => {
                return (
                  <LendMarketRow key={market.hifiPool.id} hifiPool={market.hifiPool} onMarketClick={onMarketClick} />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(LendMarketsTable);
