import { FormEvent, useCallback, useContext, useMemo } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import InfoIcon from "../../components/InfoIcon";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import MaxInputWithBalance from "../../components/MaxInputWithBalance";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { HifiPool } from "../../api/getHifiPools/types";
import DialogContentArea from "../../components/DialogContentArea";
import useDebounce from "hooks/useDebounce";
import calcAPY from "../../utils/calcAPY";
import { InlineDottyProgress } from "../../components/DottyProgress";
import useQuoteForBuyingUnderlying from "../../hooks/useQuoteForBuyingUnderlying";
import useBorrowError from "../../hooks/useBorrowError";
import FormHelperText from "@mui/material/FormHelperText";
import IconTypography from "../../components/IconTypography";
import { multiplyBigNumberByScalar, numberWithCommas, truncateDecimals } from "utils/numbers";
import useUserBorrowLimitUsed from "../../hooks/useUserBorrowLimitUsed";
import { BigNumber } from "ethers";
import { useTranslation } from "react-i18next";
import useUserAvailableCredit from "../../hooks/useUserAvailableCredit";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import DialogDataRow from "../../components/DialogDataRow";
import useUserMaxBondsExceeded from "../../hooks/useUserMaxBondsExceeded";
import { displayBorrowLimitUsedChange } from "../ManageBorrowDialog";
import { useProxyTargetContract } from "../../hooks/contracts";
import { borrowHTokenAndBuyUnderlying } from "../../utils/transactions";
import { getContractAddress } from "../../contracts/addresses";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import useUserDebts from "../../hooks/useUserDebts";
import useUserSuggestedLimit from "../../hooks/useUserSuggestedLimit";
import useDebtInUnderlying from "../../hooks/useDebtInUnderlying";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { Context as TosContext } from "@hifi/react-tos-modal";
import { logEvent } from "../../utils/analytics";

export default function BorrowTab({
  onCloseClick,
  hifiPool,
  account,
  chainName,
  connecting,
  setPendingTxId,
  connect,
  borrowInput,
  setBorrowInput,
  balance,
}: {
  onCloseClick: () => void;
  hifiPool: HifiPool;
  account: string;
  chainName: string;
  connecting: boolean;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
  connect: () => void;
  borrowInput: string;
  setBorrowInput: React.Dispatch<React.SetStateAction<string>>;
  balance?: string;
}): JSX.Element {
  const { addTransactions } = useContext(TransactionsContext);

  const { userAccepted, invokeModal } = useContext(TosContext);

  const { debouncedValue: debouncedBorrowInput, loading: loadingDebouncedBorrowInput } = useDebounce(borrowInput);

  const { t } = useTranslation();

  const { data: debtInUnderlyingBn } = useDebtInUnderlying({ hifiPool, balance: balance || "" });
  const debtInUnderlying = debtInUnderlyingBn && formatUnits(debtInUnderlyingBn, hifiPool.hToken.underlying.decimals);

  const { data: maxBondsExceeded, status: maxBondsExceededStatus } = useUserMaxBondsExceeded({
    chainName,
    account,
    hToken: hifiPool.hToken,
  });

  const { data: userDebts, status: userDebtsStatus } = useUserDebts({ chainName, account });

  const {
    data: owedAtMaturity,
    status: owedAtMaturityStatus,
    isFetching: owedAtMaturityIsFetching,
    isError: owedAtMaturityIsError,
    isLoading: owedAtMaturityIsLoading,
  } = useQuoteForBuyingUnderlying({
    hifiPool,
    underlyingOut: debouncedBorrowInput,
  });

  const { data: availableCredit } = useUserAvailableCredit({ chainName, hifiPool, account });

  // Value conversions --------------------------------------------------------------------------------------------|
  const debouncedBorrowInputFloat = parseFloat(debouncedBorrowInput);
  const debouncedBorrowInputBn = useMemo(() => {
    if (debouncedBorrowInput) {
      return parseUnits(debouncedBorrowInput, hifiPool.hToken.underlying.decimals);
    }
  }, [debouncedBorrowInput, hifiPool]);

  const owedAtMaturityBn = useMemo(() => {
    if (owedAtMaturity) {
      return parseUnits(owedAtMaturity, hifiPool.hToken.decimals);
    }
  }, [owedAtMaturity, hifiPool]);
  // --------------------------------------------------------------------------------------------------------------|

  // Borrow limit used fetches -------------------------------------------------------------------------------------|
  const { data: currentBorrowLimitUsed, status: currentBorrowLimitUsedStatus } = useUserBorrowLimitUsed({
    chainName,
    account,
  });

  const { data: newBorrowLimitUsed, status: newBorrowLimitUsedStatus } = useUserBorrowLimitUsed({
    chainName,
    account,
    debtAmountModify: !owedAtMaturityIsError ? owedAtMaturity : void 0,
  });
  // --------------------------------------------------------------------------------------------------------------|

  // Max button calcs ---------------------------------------------------------------------------------------------|
  const { data: suggestedMaxBorrow } = useUserSuggestedLimit({ chainName, hifiPool, account });
  // --------------------------------------------------------------------------------------------------------------|

  // Loading, disabled, and error calcs ---------------------------------------------------------------------------|
  const insufficientCollateralRatio =
    !!account &&
    ((!!newBorrowLimitUsed && parseUnits(newBorrowLimitUsed, 18).gt(parseUnits("1", 18))) ||
      (!newBorrowLimitUsed && !!debouncedBorrowInput));

  const borrowInputErrorMsg = useBorrowError({
    owedAtMaturityIsError,
    debouncedBorrowInput,
    insufficientCollateralRatio,
    owedAtMaturityIsLoading,
  });

  const loadingBorrow =
    loadingDebouncedBorrowInput || owedAtMaturityIsFetching || newBorrowLimitUsedStatus === "loading";

  const isBorrowSubmitDisabled =
    !!borrowInputErrorMsg ||
    loadingBorrow ||
    !debouncedBorrowInputBn ||
    debouncedBorrowInputBn.lte(BigNumber.from("0")) ||
    !!maxBondsExceeded ||
    maxBondsExceededStatus === "loading" ||
    userDebtsStatus !== "success";

  const proxyTargetContract = useProxyTargetContract();
  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });
  const onBorrowSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (!account) {
        if (userAccepted) {
          connect();
          return;
        } else {
          invokeModal(() => {
            connect();
          });
          return;
        }
      }

      if (isBorrowSubmitDisabled || !proxyTargetContract || !owedAtMaturityBn || !dsProxyAddress || !userDebts) {
        return;
      }
      logEvent("borrowClick", {
        borrowInput: debouncedBorrowInput,
        hToken: hifiPool.hToken.id,
      });
      const txs = [];
      txs.push(
        borrowHTokenAndBuyUnderlying({
          contractAddress: dsProxyAddress,
          underlyingOut: debouncedBorrowInputBn,
          maxBorrowAmount: multiplyBigNumberByScalar(owedAtMaturityBn, 1.02, hifiPool.hToken.decimals),
          borrowPositions: Object.keys(userDebts).length,
          proxyTargetContract,
          hifiPoolAddress: hifiPool.id,
          balanceSheetAddress: getContractAddress(chainName, "balanceSheet"),
          description: t("transactions.borrow.description", {
            amount: numberWithCommas(formatUnits(debouncedBorrowInputBn || "", hifiPool.hToken.underlying.decimals)),
            token: hifiPool.hToken.underlying.symbol,
          }),
          completionMessage: t("transactions.borrow.completionMessage", {
            amount: numberWithCommas(formatUnits(debouncedBorrowInputBn || "", hifiPool.hToken.underlying.decimals)),
            token: hifiPool.hToken.underlying.symbol,
          }),
          callbackArgs: {
            hifiPoolAddress: hifiPool.id,
            underlyingAddress: hifiPool.hToken.underlying.id,
          },
        }),
      );
      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Borrow"),
      });
      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      isBorrowSubmitDisabled,
      debouncedBorrowInput,
      debouncedBorrowInputBn,
      hifiPool,
      chainName,
      t,
      proxyTargetContract,
      addTransactions,
      setPendingTxId,
      owedAtMaturityBn,
      connect,
      account,
      dsProxyAddress,
      userDebts,
      userAccepted,
      invokeModal,
    ],
  );

  return (
    <form onSubmit={onBorrowSubmit}>
      <DialogContentArea>
        <Box mb={4}>
          <Typography display="inline" variant="h5" color="textSecondary">
            {t("Maturity Date")}:&nbsp;&nbsp;
          </Typography>
          <Typography variant="h5" display="inline">
            {new Date(hifiPool.hToken.maturity * 1000).toLocaleString()}
          </Typography>
        </Box>
        <MaxInputWithBalance
          autoFocus
          fullWidth
          error={!!borrowInputErrorMsg || !!maxBondsExceeded}
          style={{ fontSize: "1.75em" }}
          value={borrowInput}
          maxButtonLabel={t("80% Limit")}
          max={suggestedMaxBorrow}
          inputProps={{
            min: 0,
          }}
          balanceLabel={t("Available Credit")}
          tokenSymbol={hifiPool.hToken.underlying.symbol}
          balance={availableCredit && truncateDecimals(availableCredit, 2)}
          onChange={(e: React.ChangeEvent) => setBorrowInput((e.target as HTMLTextAreaElement).value)}
          decimals={hifiPool.hToken.underlying.decimals}
          startAdornment={
            <InputAdornment position="start">
              <TokenIcon style={{ fontSize: "1.15em" }} symbol={hifiPool.hToken.underlying.symbol as TokenSymbol} />
            </InputAdornment>
          }
        />
        {!!borrowInputErrorMsg && (
          <Typography variant="h6">
            <FormHelperText error>{borrowInputErrorMsg}</FormHelperText>
          </Typography>
        )}
        {!borrowInputErrorMsg && !!maxBondsExceeded && (
          <Typography variant="h6">
            <FormHelperText error>{t("errors.maxBondsExceeded")}</FormHelperText>
          </Typography>
        )}
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <Typography variant="h6" color="textSecondary">
            {t("Estimated APY")}
          </Typography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(owedAtMaturityIsFetching || loadingDebouncedBorrowInput) && <InlineDottyProgress />}
            {!loadingDebouncedBorrowInput &&
              !owedAtMaturityIsFetching &&
              owedAtMaturityStatus === "success" &&
              debouncedBorrowInputFloat > 0 &&
              owedAtMaturity &&
              `${(
                calcAPY(debouncedBorrowInputFloat, parseFloat(owedAtMaturity), hifiPool.maturity * 1000) * 100
              ).toFixed(2)}%`}
            {!loadingDebouncedBorrowInput &&
              !owedAtMaturityIsFetching &&
              (!owedAtMaturity || owedAtMaturityStatus === "error" || debouncedBorrowInputFloat === 0) && (
                <span>&ndash;</span>
              )}
          </Typography>
        </DialogDataRow>
        {!!account && !!debtInUnderlying && (
          <DialogDataRow>
            <IconTypography variant="h6" color="textSecondary">
              {t("Debt")}
              <Tooltip title={t("tooltips.repay.debt") as string} arrow placement="top">
                <InfoIcon tabIndex={0} />
              </Tooltip>
            </IconTypography>
            <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
              {parseFloat(debtInUnderlying) > 0 &&
                `${numberWithCommas(truncateDecimals(debtInUnderlying, 2))} ${hifiPool.hToken.underlying.symbol}`}
              {parseFloat(debtInUnderlying) <= 0 && <span>&ndash;</span>}
            </Typography>
          </DialogDataRow>
        )}
        {!!account && (
          <DialogDataRow>
            <Typography variant="h6" color="textSecondary">
              {t("Borrow Limit Used")}
            </Typography>
            <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
              {(currentBorrowLimitUsedStatus === "loading" ||
                newBorrowLimitUsedStatus === "loading" ||
                loadingDebouncedBorrowInput ||
                owedAtMaturityIsFetching) && <InlineDottyProgress />}
              {currentBorrowLimitUsedStatus !== "loading" &&
                newBorrowLimitUsedStatus !== "loading" &&
                !owedAtMaturityIsFetching &&
                !loadingDebouncedBorrowInput &&
                displayBorrowLimitUsedChange({
                  currentValue: currentBorrowLimitUsed,
                  newValue: newBorrowLimitUsed,
                  currentValueStatus: currentBorrowLimitUsedStatus,
                  newValueStatus: newBorrowLimitUsedStatus,
                })}
            </Typography>
          </DialogDataRow>
        )}
      </DialogContentArea>
      <DialogContentArea>
        <Button
          disabled={(isBorrowSubmitDisabled && !!account) || connecting}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
        >
          {connecting && t("Connecting...")}
          {!connecting && (account ? `${t("Borrow")} ${hifiPool.hToken.underlying.symbol}` : t("Connect Wallet"))}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </form>
  );
}
