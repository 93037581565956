import SvgIcon from "@mui/material/SvgIcon";

export default function RCC1({ style }: { style?: React.CSSProperties }): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 512 512" style={style}>
      <circle cx="256" cy="256" r="256" fill="#101010" />
      <path d="M471.998 276.221L256.703 400.936L40 276.221L255.295 151.487L471.998 276.221Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0002 300.046V276.222L256.703 400.936V424.779L40.0002 300.046ZM472 300.046V276.222L256.705 400.936V424.779L472 300.046Z"
        fill="#D2D2D2"
      />
      <path d="M268.116 330.446L142.274 257.536L142.088 191.827L267.929 263.808L268.116 330.446Z" fill="#422A7F" />
      <path
        d="M268.651 331.374L141.737 257.838V257.536L141.551 190.897L142.366 191.362L268.488 263.505V263.807L268.651 331.374ZM142.832 257.21L267.602 329.493L267.416 264.132L142.646 192.756L142.832 257.21Z"
        fill="#422A7F"
      />
      <path d="M268.674 330.77L387.595 264.852L387.619 276.465L268.697 342.383L268.674 330.77Z" fill="#8758EB" />
      <path
        d="M387.062 265.758V276.256L269.212 341.571V331.072L387.062 265.758ZM388.133 263.923L268.117 330.445V343.313L388.133 276.791V263.923Z"
        fill="#8758EB"
      />
      <path d="M141.458 269.219L141.412 257.605L267.579 330.747L267.603 342.361L141.458 269.219Z" fill="#6943B8" />
      <path
        d="M141.975 258.557L267.047 331.072V341.431L141.975 268.916V258.557ZM140.88 256.676V269.544L268.143 343.312V330.445L140.88 256.676Z"
        fill="#8758EB"
      />
      <path
        d="M386.971 191.504L387.18 264.414L268.119 330.448L267.933 263.81L326.159 170.646L386.971 191.504Z"
        fill="#5836A0"
      />
      <path
        d="M267.583 331.353L267.396 263.67V263.53L325.855 169.995L387.483 191.108V191.503L387.716 264.645L267.583 331.353ZM268.492 263.995L268.678 329.541L386.621 264.111L386.435 191.898L326.391 171.319L268.492 263.995Z"
        fill="#8758EB"
      />
      <path d="M235.218 338.095L259.333 323.947V337.527L235.242 351.675L235.218 338.095Z" fill="#6943B8" />
      <path
        d="M258.261 327.119V337.876L236.337 349.854V339.097L258.308 327.119H258.261ZM259.334 325.251L235.218 338.379V351.675L259.31 338.546V325.179L259.334 325.251Z"
        fill="#8758EB"
      />
      <path d="M258.775 325.01L234.683 337.924L234.636 263.203L258.775 250.289V325.01Z" fill="#422A7F" />
      <path
        d="M234.15 338.83V262.878L259.337 249.383V325.335L234.15 338.83ZM235.198 263.528V337.018L258.218 324.685V251.195L235.198 263.528Z"
        fill="#8758EB"
      />
      <path d="M403.654 188.135L326.322 161.493L200.48 88.584L275.505 114.087L403.654 188.135Z" fill="#4A2B8D" />
      <path
        d="M403.464 188.646L326.155 162.005L200.197 89.0488L200.663 88.0732L275.641 113.669L403.906 187.763L403.464 188.646ZM205.416 90.8605L326.575 161.006L398.477 185.789L275.222 114.552L205.416 90.8605Z"
        fill="#4A2B8D"
      />
      <path
        d="M403.652 188.134L398.433 196.798L328.837 173.269L259.73 276.048L251.761 273.122L326.064 161.47L403.652 188.134Z"
        fill="#8758EB"
      />
      <path d="M326.322 161.493L251.763 273.122L125.921 200.213L200.48 88.584L326.322 161.493Z" fill="#5836A0" />
      <path
        d="M251.946 273.934L251.504 273.679L125.149 200.375L125.476 199.91L200.314 87.8398L200.757 88.0953L327.111 161.307L326.785 161.771L251.946 273.934ZM126.687 200.026L251.597 272.378L325.55 161.655L200.64 89.3031L126.687 200.026Z"
        fill="#8758EB"
      />
      <path d="M202.899 155.824L234.68 208.108V350.768L154.412 304.268V180.677L202.899 155.824Z" fill="#4A2B8D" />
      <path
        d="M235.217 351.699L234.402 351.234L153.855 304.571V180.33L203.087 155.129L235.217 207.97V351.699ZM154.95 303.944L234.169 349.817V208.272L202.714 156.546L154.95 181.027V303.944Z"
        fill="#8758EB"
      />
      <path
        d="M242.047 231.989L235.546 228.25L194.539 161.542L154.207 181.238L149.407 178.683L195.867 155.897L242.047 231.989Z"
        fill="#8758EB"
      />
      <path d="M242.044 231.986L195.864 155.895L253.018 132.645L242.044 231.986Z" fill="#8758EB" />
      <path
        d="M242.417 233.634L241.578 232.287L194.979 155.638L195.561 155.383L253.648 131.784L242.417 233.634ZM196.657 156.149L241.672 230.336L252.343 133.48L196.657 156.149Z"
        fill="#8758EB"
      />
      <path d="M277.322 194.8L265.509 187.948L265.462 122.587L277.299 129.439L277.322 194.8Z" fill="#4A2B8D" />
      <path
        d="M277.976 195.751L265.045 188.272V121.634L277.953 129.113L277.976 195.751ZM266.14 187.645L276.881 193.87V129.74L266.14 123.515V187.645Z"
        fill="#8758EB"
      />
      <path d="M290.02 122.1L277.299 129.44L265.462 122.588L278.184 115.248L290.02 122.1Z" fill="#BA1A15" />
      <path d="M290.068 175.893L277.323 194.8L277.3 129.439L290.021 122.1L290.068 175.893Z" fill="#5836A0" />
      <path
        d="M276.788 196.587V129.113L290.628 121.169V176.054V176.194L276.788 196.587ZM277.837 129.74V193.01L289.486 175.729V122.957L277.837 129.74Z"
        fill="#8758EB"
      />
      <path d="M277.299 133.946L263.11 125.723L263.087 120.962L277.299 129.184V133.946Z" fill="#8758EB" />
      <path d="M293.19 120.032L277.299 129.183L263.087 120.961L278.977 111.81L293.19 120.032Z" fill="#5836A0" />
      <path
        d="M277.303 129.927L277.023 129.764L262.018 121.077L278.98 111.299L279.26 111.461L294.288 120.032L277.303 129.927ZM264.185 121.077L277.303 128.673L292.098 120.148L278.98 112.553L264.185 121.077Z"
        fill="#4A2B8D"
      />
      <path d="M293.19 124.794L277.3 133.945V129.184L293.19 120.032V124.794Z" fill="#8758EB" />
      <path d="M154.834 303.99V292.399L234.1 338.203V349.793L154.834 303.99Z" fill="#8758EB" />
      <path
        d="M155.368 293.329L233.562 338.505V348.841L155.368 303.665V293.329ZM154.273 291.447V304.292L234.633 350.746V337.901L154.273 291.447Z"
        fill="#8758EB"
      />
      <path
        d="M181.954 177.333C183.034 176.589 184.321 176.199 185.635 176.218C186.938 176.251 188.221 176.544 189.409 177.078V195.868L178.062 189.342V185.486C178.075 183.926 178.43 182.388 179.102 180.98C179.774 179.571 180.747 178.327 181.954 177.333Z"
        fill="#4A2B8D"
      />
      <path
        d="M200.409 188.344C202.655 192.425 204.027 196.925 204.44 201.56V204.487L191.928 197.287V178.496C195.507 181.053 198.416 184.43 200.409 188.344Z"
        fill="#4A2B8D"
      />
      <path d="M193.022 301.319L174.196 290.542V260.997L193.022 271.774V301.319Z" fill="#4A2B8D" />
      <path d="M207.26 309.564L196.565 303.549V274.004L207.26 280.043V309.564Z" fill="#4A2B8D" />
      <path d="M193.018 250.333L174.192 239.556V210.034L193.018 220.811V250.333Z" fill="#4A2B8D" />
      <path
        d="M193.72 251.517L173.496 239.904V208.896L193.72 220.509V251.517ZM174.871 239.091L192.346 249.101V221.229L174.871 211.241V239.091Z"
        fill="#4A2B8D"
      />
      <path d="M207.259 258.601L196.565 252.562V223.04L207.259 229.079V258.601Z" fill="#4A2B8D" />
    </SvgIcon>
  );
}
