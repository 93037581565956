import { sanctionedCountries } from "../../config";
import useGeoLocation from "../useGeoLocation";
import { Context as Web3Context } from "../../contexts/web3";
import { useContext } from "react";
import { useWhitelistedEthAddresses } from "../useWhitelistedEthAddresses";

export default function useCanLend(): boolean | undefined {
  const { data: geoResult } = useGeoLocation();
  const whitelist = useWhitelistedEthAddresses();
  const { account } = useContext(Web3Context);

  const countryCode = geoResult?.country_code2;
  return (
    (!!account && whitelist?.map(addy => addy.toLowerCase()).includes(account.toLowerCase())) ||
    (countryCode ? !sanctionedCountries.includes(countryCode) : void 0)
  );
}
