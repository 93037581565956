import { BigNumber } from "ethers";
import { QueryObserverOptions, QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import getBurnParams from "../../api/getBurnParams";
import { HifiPoolBurnParams } from "../../api/getBurnParams/types";
import { HifiPool } from "../../api/getHifiPools/types";

function buildQueryKey({
  poolTokensBurned,
  hifiPool,
}: {
  poolTokensBurned: BigNumber | undefined;
  hifiPool: HifiPool;
}) {
  return ["burnParams", hifiPool.id, poolTokensBurned?.toString()];
}

function buildQueryFn({ poolTokensBurned, hifiPool }: { poolTokensBurned: BigNumber | undefined; hifiPool: HifiPool }) {
  return () => {
    if (poolTokensBurned) {
      if (poolTokensBurned.eq(BigNumber.from("0"))) {
        return { underlyingReturned: BigNumber.from("0"), hTokenReturned: BigNumber.from("0") };
      } else {
        return getBurnParams({ hifiPool, poolTokensBurned });
      }
    }
  };
}

function buildQueryOptions(poolTokensBurned?: BigNumber) {
  return {
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0.5 * 60 * 1000,
    staleTime: 0.5 * 60 * 1000,
    refetchInterval: 0.5 * 60 * 1000,
    enabled: !!poolTokensBurned && poolTokensBurned.gte(BigNumber.from("0")),
  };
}

export function buildQueryObject({
  poolTokensBurned,
  hifiPool,
  options = {},
}: {
  poolTokensBurned: BigNumber | undefined;
  hifiPool: HifiPool;
  options?: QueryObserverOptions;
}): UseQueryOptions {
  return {
    queryKey: buildQueryKey({ poolTokensBurned, hifiPool }),
    queryFn: buildQueryFn({ poolTokensBurned, hifiPool }),
    ...buildQueryOptions(poolTokensBurned),
    ...options,
  };
}

export function useBurnParams({
  poolTokensBurned,
  hifiPool,
}: {
  poolTokensBurned: BigNumber | undefined;
  hifiPool: HifiPool;
}): QueryObserverResult<HifiPoolBurnParams> {
  return useQuery(
    buildQueryKey({ poolTokensBurned, hifiPool }),
    buildQueryFn({ poolTokensBurned, hifiPool }),
    buildQueryOptions(poolTokensBurned),
  );
}
