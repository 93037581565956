import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { QueryObserverOptions, QueryObserverResult, useQuery } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import getQuoteForSellingHToken from "../../api/getQuoteForSellingHToken";
import isExpired from "../../utils/isExpired";
import useMemoRef from "hooks/useMemoRef";

export function buildQueryKey({
  poolAddress,
  hTokenIn,
}: {
  poolAddress: string;
  hTokenIn: string;
}): [string, string, string] {
  return ["quoteForSellingHToken", poolAddress, hTokenIn];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function buildQueryOptions({ hifiPool, hTokenIn }: { hifiPool: HifiPool; hTokenIn: string }) {
  return {
    cacheTime: 0.5 * 60 * 1000,
    staleTime: 0.5 * 60 * 1000,
    enabled: !!hTokenIn && parseUnits(hTokenIn, hifiPool.hToken.decimals).gt(BigNumber.from("0")),
    refetchInterval: 0.5 * 60 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
  };
}

export function buildQueryFn(hifiPool: HifiPool) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return ({ queryKey }: { queryKey: string[] }) => {
    return getQuoteForSellingHToken({
      poolAddress: queryKey[1],
      hTokenIn: parseUnits(queryKey[2], hifiPool.hToken.decimals),
    });
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function buildQueryObject({
  hTokenIn,
  hifiPool,
  options = {},
}: {
  hTokenIn: string;
  hifiPool: HifiPool;
  options?: QueryObserverOptions;
}) {
  return {
    queryKey: buildQueryKey({ poolAddress: hifiPool.id, hTokenIn }),
    queryFn: buildQueryFn(hifiPool),
    ...buildQueryOptions({ hifiPool, hTokenIn }),
    ...options,
  };
}

export default function useQuoteForSellingHToken({
  hTokenIn,
  hifiPool,
}: {
  hTokenIn: string;
  hifiPool: HifiPool;
}): QueryObserverResult<string | undefined> {
  const { id: poolAddress, hToken } = hifiPool;
  const { data, status, ...others } = useQuery(
    buildQueryKey({ poolAddress, hTokenIn }),
    ({ queryKey }) => {
      if (parseUnits(queryKey[2], hifiPool.hToken.decimals).gt(BigNumber.from("0"))) {
        return getQuoteForSellingHToken({
          poolAddress: queryKey[1],
          hTokenIn: parseUnits(queryKey[2], hToken.decimals),
        });
      } else {
        return BigNumber.from("0");
      }
    },
    {
      cacheTime: 3 * 60 * 1000,
      staleTime: 3 * 60 * 1000,
      enabled:
        !!hTokenIn && parseUnits(hTokenIn, hifiPool.hToken.decimals).gte(BigNumber.from("0")) && !isExpired(hifiPool),
      refetchInterval: 3 * 60 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const _data = useMemoRef(
    lastValue => {
      if (status == "idle") {
        return;
      } else if (data && status === "success") {
        return formatUnits(data, hToken.underlying.decimals);
      }
      return lastValue;
    },
    [data, status, hToken],
  );
  return { data: _data, status, ...others } as QueryObserverResult<string | undefined>;
}
