import type { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { IProviderOptions, ThemeColors } from "web3modal";
import type { Status } from "../../constants/loadingStates";

export type Web3ContextState = {
  account: string;
  provider: JsonRpcProvider;
  walletProvider?: Web3Provider;
  chainId: number;
  chainName: string;
  status: Status;
  providerName: string;
};

export type Web3ContextProviderProps = {
  children: JSX.Element;
  walletModalTheme?: ThemeColors;
  onConnect?: () => boolean;
  onAccountChange?: (account: string) => void;
};

export type Web3Context = Web3ContextState & {
  connecting: boolean;
  connect: () => void;
  disconnect: () => void;
  reconnect: () => void;
  providerOptions: IProviderOptions;
  initialized: boolean;
};

export const UPDATE_STATE = "UPDATE_STATE";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_CONNECTING = "SET_CONNECTING";

type Web3ContextUpdateStateAction = {
  type: typeof UPDATE_STATE;
  payload: Web3ContextState;
};

type Web3ContextSetAccountAction = {
  type: typeof SET_ACCOUNT;
  payload: string;
};

type Web3ContextSetConnectingAction = {
  type: typeof SET_CONNECTING;
  payload: Status;
};

export type Web3ContextAction =
  | Web3ContextUpdateStateAction
  | Web3ContextSetAccountAction
  | Web3ContextSetConnectingAction;
