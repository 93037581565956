import { useMemo } from "react";
import { QueryObserverResult } from "react-query";
import useCollaterals from "../useCollaterals";
import { CollateralMap } from "./types";

export default function useCollateralMap(chainName: string): QueryObserverResult<CollateralMap> {
  const { data, ...others } = useCollaterals(chainName);

  // Todo: test memoization
  const resp: CollateralMap = useMemo(() => {
    if (data) {
      return data.reduce((memo, collateral) => {
        return { ...memo, [collateral.id]: collateral };
      }, {});
    } else {
      return {};
    }
  }, [data]);

  return { data: resp, ...others } as QueryObserverResult<CollateralMap>;
}
