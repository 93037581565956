import { useCallback, useContext, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { InlineDottyProgress } from "../../components/DottyProgress";
import Button from "@mui/material/Button";
import { Context as TransactionsContext, LAST_RESULT } from "contexts/transactionsBase";
import Typography from "@mui/material/Typography";
import { CloseSection } from "../../components/ActionDialog";
import { HifiPool } from "../../api/getHifiPools/types";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { Web3Provider } from "@ethersproject/providers";
import { formatUnits, parseUnits } from "@ethersproject/units";
import DialogContentArea from "../../components/DialogContentArea";
import DialogDataRow from "../../components/DialogDataRow";
import { BigNumber } from "ethers";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import { AddressZero } from "@ethersproject/constants";
import Slider from "@mui/material/Slider";
import {
  createDsProxy,
  removeLiquidity,
  permit,
  removeLiquidityAndRedeemHToken,
  removeLiquidityAndWithdrawUnderlying,
} from "../../utils/transactions";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../utils/analytics";
import { useProxyTargetContract } from "../../hooks/contracts";
import { getContractAddress } from "../../contracts/addresses";
import { multiplyBigNumberByScalar, numberWithCommas, truncateDecimals } from "utils/numbers";
import useDebounce from "hooks/useDebounce";
import useUserBurnParams from "../../hooks/useUserBurnParams";
import shouldSkipDSProxyConfirmations from "../../utils/shouldSkipDSProxyConfirmations";
// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import IconTypography from "../../components/IconTypography";
import { getDialogJazziconSize } from "../../utils/getJazziconSize";
import useWindowBreakpoint from "hooks/useWindowBreakpoint";
import isExpired from "../../utils/isExpired";
import { useBurnParams } from "../../hooks/useBurnParams";

const SliderButton = styled(Button)(({ theme }) => ({
  "&": {
    marginRight: theme.spacing(2),
    padding: ".5em .8em",

    "&:last-child": {
      marginRight: 0,
    },
  },
}));

export default function RemoveLiquidityTab({
  onCloseClick,
  pool,
  account,
  walletProvider,
  chainName,
  setRemoveLiquidityPercentInput,
  removeLiquidityPercentInput,
  setPendingTxId,
}: {
  onCloseClick: () => void;
  pool: HifiPool;
  account: string;
  walletProvider: Web3Provider | undefined;
  chainName: string;
  setRemoveLiquidityPercentInput: React.Dispatch<React.SetStateAction<number>>;
  removeLiquidityPercentInput: number;
  setPendingTxId: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element {
  const { debouncedValue: debouncedRemoveLiquidityInput, loading: loadingRemoveLiquidityInput } =
    useDebounce(removeLiquidityPercentInput);

  const bp = useWindowBreakpoint();

  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });
  const { addTransactions, isTransactionQueued } = useContext(TransactionsContext);

  const { t } = useTranslation();

  const { data: lpTokenBalance } = useUserErc20Balance({ token: pool, walletProvider, account });

  const removeLiquidityInputBn = useMemo(() => {
    if (debouncedRemoveLiquidityInput && lpTokenBalance) {
      return multiplyBigNumberByScalar(
        parseUnits(lpTokenBalance, pool.decimals),
        debouncedRemoveLiquidityInput / 100,
        pool.decimals,
      );
    }
  }, [debouncedRemoveLiquidityInput, pool, lpTokenBalance]);

  const removeLiquidityInput = useMemo(() => {
    if (debouncedRemoveLiquidityInput && lpTokenBalance) {
      return (parseFloat(lpTokenBalance) * debouncedRemoveLiquidityInput) / 100;
    }
  }, [debouncedRemoveLiquidityInput, lpTokenBalance]);

  const proxyTargetContract = useProxyTargetContract();

  const {
    data: userBurnParams,
    status: userBurnParamsStatus,
    isFetching: userBurnParamsIsFetching,
  } = useUserBurnParams({
    account,
    chainName,
    hifiPool: pool,
    poolTokensBurned: removeLiquidityInputBn,
  });

  const { data: burnParams } = useBurnParams({
    hifiPool: pool,
    poolTokensBurned: removeLiquidityInputBn,
  });

  const removeLiqudityButtonDisabled =
    !removeLiquidityInputBn ||
    removeLiquidityInputBn.lte(BigNumber.from("0")) ||
    userBurnParamsStatus !== "success" ||
    loadingRemoveLiquidityInput;

  const onSliderChange = useCallback(
    (_e: unknown, value: number | number[]) => {
      setRemoveLiquidityPercentInput(value as number);
    },
    [setRemoveLiquidityPercentInput],
  );

  const onRemoveLiquiditySubmit = useCallback(
    e => {
      e.preventDefault();

      if (
        removeLiqudityButtonDisabled ||
        !proxyTargetContract ||
        !removeLiquidityInputBn ||
        !dsProxyAddress ||
        !userBurnParams ||
        !burnParams
      )
        return;

      const txs = [];

      // If DSProxy has already been created, or if there is a create DSProxy tx queued up, then skip
      // DSProxy creation tx.
      if (
        dsProxyAddress === AddressZero &&
        !isTransactionQueued({
          methodName: "build()",
          contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
        })
      ) {
        txs.push(
          createDsProxy({
            contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
            description: t("transactions.dsProxy"),
            skipConfirmations: shouldSkipDSProxyConfirmations(chainName),
          }),
        );
      }

      txs.push(
        permit({
          contractAddress: pool.id,
          description: t("transactions.messages.allowance", {
            spender: "Hifi",
            token: pool.symbol,
          }),
          ownerAddress: account,
          spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
          amount: removeLiquidityInputBn.toString(),
        }),
      );

      const callbackArgs = {
        hifiPoolAddress: pool.id,
        underlyingAddress: pool.hToken.underlying.id,
        hTokenAddress: pool.hToken.id,
      };

      logEvent("removeLiquidity", {
        removeLiquidityInput,
      });

      if (isExpired(pool)) {
        txs.push(
          removeLiquidityAndRedeemHToken({
            contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}-1` : dsProxyAddress,
            description: t("transactions.removeLiquidity.description", {
              amount: removeLiquidityInput,
              symbol: pool.symbol,
              poolName: pool.name,
            }),
            completionMessage: t("transactions.removeLiquidity.completionMessage", {
              amount: removeLiquidityInput,
              symbol: pool.symbol,
              poolName: pool.name,
            }),
            proxyTargetContract,
            hifiPoolAddress: pool.id,
            poolTokensBurned: removeLiquidityInputBn,
            callbackArgs,
          }),
        );
      } else if (userBurnParams.withdrawAmount.gt(0) && burnParams.hTokenReturned.gt(0)) {
        txs.push(
          removeLiquidityAndWithdrawUnderlying({
            contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}-1` : dsProxyAddress,
            description: t("transactions.removeLiquidity.description", {
              amount: removeLiquidityInput,
              symbol: pool.symbol,
              poolName: pool.name,
            }),
            completionMessage: t("transactions.removeLiquidity.completionMessage", {
              amount: removeLiquidityInput,
              symbol: pool.symbol,
              poolName: pool.name,
            }),
            proxyTargetContract,
            hifiPoolAddress: pool.id,
            poolTokensBurned: removeLiquidityInputBn,
            callbackArgs,
            withdrawAmount: userBurnParams.withdrawAmount,
          }),
        );
      } else {
        txs.push(
          removeLiquidity({
            contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}-1` : dsProxyAddress,
            description: t("transactions.removeLiquidity.description", {
              amount: removeLiquidityInput,
              symbol: pool.symbol,
              poolName: pool.name,
            }),
            completionMessage: t("transactions.removeLiquidity.completionMessage", {
              amount: removeLiquidityInput,
              symbol: pool.symbol,
              poolName: pool.name,
            }),
            proxyTargetContract,
            hifiPoolAddress: pool.id,
            poolTokensBurned: removeLiquidityInputBn,
            callbackArgs,
          }),
        );
      }

      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Remove Liquidity"),
      });

      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      isTransactionQueued,
      pool,
      t,
      addTransactions,
      proxyTargetContract,
      setPendingTxId,
      removeLiqudityButtonDisabled,
      removeLiquidityInput,
      removeLiquidityInputBn,
      chainName,
      dsProxyAddress,
      account,
      userBurnParams,
      burnParams,
    ],
  );

  return (
    <form onSubmit={onRemoveLiquiditySubmit}>
      <DialogContentArea>
        <Box mb={4}>
          <Typography display="inline" variant="h5" color="textSecondary">
            {t("Maturity Date")}:&nbsp;&nbsp;
          </Typography>
          <Typography variant="h5" display="inline">
            {new Date(pool.maturity * 1000).toLocaleString()}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "1px solid",
            borderColor: "borderColor",
            borderRadius: 1,
            paddingBottom: 4,
            paddingTop: 5,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Box display="flex" alignItems="center" mb={5}>
            <Box>
              <Typography variant="h2">{Math.round(removeLiquidityPercentInput)}%</Typography>
            </Box>
            <Box flexGrow={1} display="flex" justifyContent="flex-end">
              <SliderButton
                onClick={() => setRemoveLiquidityPercentInput(25)}
                size="small"
                color="info"
                variant="contained"
              >
                25%
              </SliderButton>
              <SliderButton
                onClick={() => setRemoveLiquidityPercentInput(50)}
                size="small"
                color="info"
                variant="contained"
              >
                50%
              </SliderButton>
              <SliderButton
                onClick={() => setRemoveLiquidityPercentInput(75)}
                size="small"
                color="info"
                variant="contained"
              >
                75%
              </SliderButton>
              <SliderButton
                onClick={() => setRemoveLiquidityPercentInput(100)}
                size="small"
                color="info"
                variant="contained"
              >
                {t("Max")}
              </SliderButton>
            </Box>
          </Box>
          <Slider value={removeLiquidityPercentInput} onChange={onSliderChange} />
        </Box>
      </DialogContentArea>
      <DialogContentArea>
        <DialogDataRow>
          <IconTypography variant="body1" color="textSecondary">
            <Box mr={2} display="flex">
              <TokenIcon symbol={pool.hToken.underlying.symbol as TokenSymbol} />
            </Box>
            {t("poolToken", {
              token: pool.hToken.underlying.symbol,
            })}
          </IconTypography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(userBurnParamsIsFetching && !!removeLiquidityPercentInput) ||
              (loadingRemoveLiquidityInput && <InlineDottyProgress />)}
            {!userBurnParamsIsFetching &&
              !loadingRemoveLiquidityInput &&
              (!removeLiquidityPercentInput || userBurnParamsStatus !== "success") && <span>&ndash;</span>}
            {userBurnParamsStatus === "success" &&
              userBurnParams &&
              !loadingRemoveLiquidityInput &&
              `${numberWithCommas(formatUnits(userBurnParams.underlyingReturned, pool.hToken.underlying.decimals))}`}
          </Typography>
        </DialogDataRow>
        <DialogDataRow>
          <IconTypography variant="body1" color="textSecondary">
            <Box mr={2} display="flex">
              <Jazzicon diameter={getDialogJazziconSize(bp)} seed={jsNumberForAddress(pool.id)} />
            </Box>
            {t("poolToken", {
              token: `h${pool.hToken.underlying.symbol}`,
            })}
          </IconTypography>
          <Typography variant="h5" style={{ overflowWrap: "anywhere" }}>
            {(userBurnParamsIsFetching && !!removeLiquidityPercentInput) ||
              (loadingRemoveLiquidityInput && <InlineDottyProgress />)}
            {!userBurnParamsIsFetching &&
              !loadingRemoveLiquidityInput &&
              (!removeLiquidityPercentInput || userBurnParamsStatus !== "success") && <span>&ndash;</span>}
            {userBurnParamsStatus === "success" &&
              userBurnParams &&
              !loadingRemoveLiquidityInput &&
              `${numberWithCommas(
                truncateDecimals(formatUnits(userBurnParams.hTokenReturned, pool.hToken.decimals), 6),
              )}`}
          </Typography>
        </DialogDataRow>
      </DialogContentArea>
      <DialogContentArea>
        <Button
          disabled={removeLiqudityButtonDisabled}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
        >
          {t("Remove Liquidity")}
        </Button>
        <CloseSection onClose={onCloseClick} />
      </DialogContentArea>
    </form>
  );
}
