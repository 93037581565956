import { useCallback, useRef, useImperativeHandle, forwardRef } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { MaxInputProps } from "./types";
import { parseUnits } from "@ethersproject/units";
import { One } from "@ethersproject/constants";

const MaxInput = forwardRef((props: MaxInputProps, ref) => {
  const { t } = useTranslation("common-ui");
  const {
    Component = OutlinedInput,
    max,
    decimals = 0,
    onChange,
    disabled,
    maxButtonLabel,
    inputProps = {},
    ...others
  } = props;

  const inputRef = useRef<HTMLElement | null>(null);

  const _onChange = useCallback(
    e => {
      let value = e.target.value;
      const valueParts = value.split(".");
      const floatVal = parseFloat(value);
      const decimalsString = valueParts[1] ? `.${valueParts[1].substring(0, decimals)}` : "";

      value = decimals >= 0 && floatVal && !isNaN(floatVal) ? `${valueParts[0]}${decimalsString}` : value;
      if (onChange) {
        onChange({ ...e, target: { value } });
      }
    },
    [onChange, decimals],
  );

  const onMaxClick = useCallback(() => {
    _onChange({ target: { value: `${max}` } });
  }, [_onChange, max]);

  useImperativeHandle(ref, () => ({
    onMaxClick: () => {
      onMaxClick();
    },
  }));

  const maxBn = max !== undefined ? parseUnits(max as string, decimals) : undefined;
  const maxGtZero = maxBn !== undefined && maxBn.gte(One);

  const onWheel = useCallback(() => {
    inputRef.current?.blur();
  }, []);

  inputProps.max = max;

  return (
    <Component
      {...others}
      disabled={disabled}
      type="number"
      inputRef={inputRef}
      onChange={_onChange}
      onWheel={onWheel}
      inputProps={{
        step: "any",
        ...inputProps,
      }}
      endAdornment={
        maxGtZero && (
          <InputAdornment position="end">
            <Button
              style={{ padding: ".5em .8em" }}
              disabled={disabled}
              onClick={onMaxClick}
              variant="contained"
              color="info"
              size="small"
            >
              {maxButtonLabel || t("MaxInput.max")}
            </Button>
          </InputAdornment>
        )
      }
    />
  );
});

MaxInput.displayName = "MaxInput";
export default MaxInput;
