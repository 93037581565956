import { BigNumber } from "ethers";
import { QueryObserverResult, useQuery } from "react-query";
import { HToken } from "../../api/getHifiPools/types";
import getTotalUnderlyingReserve from "../../api/getTotalUnderlyingReserve";

export default function useTotalUnderlyingReserve(hToken: HToken): QueryObserverResult<BigNumber | undefined> {
  return useQuery(["totalUnderlyingReserve", hToken.id], () => getTotalUnderlyingReserve(hToken.id), {
    cacheTime: 0.5 * 60 * 1000,
    staleTime: 0.5 * 60 * 1000,
    refetchInterval: 0.5 * 60 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
