import { BigNumber } from "ethers";
import { Zero } from "@ethersproject/constants";
import { HifiPool } from "../../api/getHifiPools/types";
import { useBurnParams } from "../useBurnParams";
import useUserHTokenDepositorBalance from "../useUserHTokenDepositorBalance";
import { parseUnits } from "@ethersproject/units";
import isExpired from "../../utils/isExpired";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { HifiPoolBurnParams } from "../../api/getBurnParams/types";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";
import { useCallback } from "react";

export default function useUserBurnParams({
  account,
  chainName,
  hifiPool,
  poolTokensBurned,
}: {
  account: string;
  chainName: string;
  hifiPool: HifiPool;
  poolTokensBurned?: BigNumber;
}): QueryResponse<{ underlyingReturned: BigNumber; hTokenReturned: BigNumber; withdrawAmount: BigNumber }> {
  const depositorBalanceRes = useUserHTokenDepositorBalance({
    hToken: hifiPool.hToken,
    account,
    chainName,
  });

  // Get hTokensReturned
  const burnParamRes = useBurnParams({
    poolTokensBurned,
    hifiPool,
  });

  return useCombinedQueryHooks(
    [depositorBalanceRes, burnParamRes],
    useCallback(
      data => {
        const [depositorBalance, burnParams] = data as [string, HifiPoolBurnParams];

        const hTokenReturnedBn = burnParams.hTokenReturned || BigNumber.from("0");
        if (isExpired(hifiPool)) {
          return {
            underlyingReturned: (burnParams.underlyingReturned || BigNumber.from("0")).add(
              hTokenReturnedBn.div(hifiPool.underlyingPrecisionScalar),
            ),
            hTokenReturned: Zero,
            withdrawAmount: Zero,
          };
        } else {
          const depositorBalanceBn = parseUnits(depositorBalance, hifiPool.hToken.underlying.decimals);
          const normalizedBalanceBn = depositorBalanceBn.mul(hifiPool.underlyingPrecisionScalar);
          const normalizedWithdrawlableBn = normalizedBalanceBn.lt(hTokenReturnedBn)
            ? normalizedBalanceBn
            : hTokenReturnedBn;

          const hTokenReturned = (burnParams.hTokenReturned || BigNumber.from("0")).sub(normalizedWithdrawlableBn);

          return {
            underlyingReturned: (burnParams.underlyingReturned || BigNumber.from("0")).add(
              normalizedWithdrawlableBn.div(hifiPool.underlyingPrecisionScalar),
            ),
            hTokenReturned: hTokenReturned.lt(Zero) ? Zero : hTokenReturned,
            withdrawAmount: normalizedWithdrawlableBn.div(hifiPool.underlyingPrecisionScalar),
          };
        }
      },
      [hifiPool],
    ),
  );
}
