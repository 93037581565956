import SvgIcon from "@mui/material/SvgIcon";

export default function SHEETp({ style }: { style?: React.CSSProperties }): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 64 64" style={style}>
      <circle cx="32" cy="32" r="30" fill="#259F31" stroke="#F75500" strokeWidth={4} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0703 18H25.356H30.6417V20.6429H25.356V25.9297H35.2852V18H35.9297H41.2852H46.5011V20.6429H41.2852V25.9297H49.1429V31.2109H54.4286V43.1038H9.5V36.5011V31.2109V25.9297H20.0703V20.6429V18Z"
        fill="white"
      />
      <rect x="27.7852" y="37.6406" width="12" height="2.64" fill="#259F31" />
      <rect x="15.2852" y="34.8594" width="7.92857" height="5.28571" fill="white" />
    </SvgIcon>
  );
}
