import { numberWithCommas, truncateDecimals } from "utils/numbers";
import Box from "@mui/material/Box";
import { HifiPool } from "../../api/getHifiPools/types";
import IconTypography from "../../components/IconTypography";
import { InlineDottyProgress } from "../../components/DottyProgress";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TokenIcon from "../../icons/TokenIcon";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import Typography from "@mui/material/Typography";
import { formatUnits } from "@ethersproject/units";
import isExpired from "../../utils/isExpired";
import useDebtInUnderlying from "../../hooks/useDebtInUnderlying";
import useQuoteForBuyingUnderlying from "../../hooks/useQuoteForBuyingUnderlying";
import calcAPY from "../../utils/calcAPY";

export default function MyBorrowRow({
  hifiPool,
  balance,
  onMarketClick,
}: {
  hifiPool: HifiPool;
  onMarketClick: (hifiPool: HifiPool) => void;
  balance: string;
}): JSX.Element {
  const { hToken } = hifiPool;
  const symbol = hToken.underlying.symbol as TokenSymbol;

  const {
    data: debtInUnderlying,
    status: debtInUnderlyingStatus,
    isLoading: debtInUnderlyingIsLoading,
  } = useDebtInUnderlying({ hifiPool, balance });

  const {
    data: underlyingSpotPrice,
    status: underlyingSpotPriceStatus,
    isLoading: underlyingSpotPriceIsLoading,
  } = useQuoteForBuyingUnderlying({ hifiPool, underlyingOut: "1" });

  return (
    <TableRow hover onClick={() => onMarketClick(hifiPool)}>
      <TableCell align="left">
        <IconTypography variant="h5">
          <Box display="flex" alignItems="center">
            <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          </Box>
          {symbol}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{new Date(hToken.maturity * 1000).toLocaleDateString()}</Typography>
      </TableCell>{" "}
      <TableCell align="right">
        <Typography variant="h6">
          {isExpired(hifiPool) && <span>&ndash;</span>}
          {!isExpired(hifiPool) && underlyingSpotPriceIsLoading && <InlineDottyProgress />}
          {!isExpired(hifiPool) &&
            underlyingSpotPrice &&
            underlyingSpotPriceStatus === "success" &&
            `${(calcAPY(1, parseFloat(underlyingSpotPrice), hifiPool.maturity * 1000) * 100).toFixed(2)}%`}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {debtInUnderlyingIsLoading && <InlineDottyProgress />}
          {debtInUnderlyingStatus === "success" &&
            debtInUnderlying &&
            `${numberWithCommas(
              truncateDecimals(formatUnits(debtInUnderlying, hifiPool.hToken.underlying.decimals), 2),
            )} ${hifiPool.hToken.underlying.symbol}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
