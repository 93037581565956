import { MulticallBatcher } from "contexts/web3Base";
import deepFreeze from "utils/deepFreeze";
import { BigNumber } from "ethers";
import { getHifiPoolMulticallContract } from "../../utils/contracts";
import { getErc20MulticallContract } from "utils/contracts";
import { HifiPool } from "../getHifiPools/types";
import { HifiPoolBurnParams } from "./types";

const getBurnParams = async ({
  hifiPool,
  poolTokensBurned,
}: {
  hifiPool: HifiPool;
  poolTokensBurned: BigNumber;
}): Promise<HifiPoolBurnParams> => {
  const hifiPoolContract = getHifiPoolMulticallContract({ address: hifiPool.id });
  const hTokenContract = getErc20MulticallContract({ address: hifiPool.hToken.id });

  const res = await Promise.all([
    MulticallBatcher.queue(hifiPoolContract.getNormalizedUnderlyingReserves()),
    MulticallBatcher.queue(hifiPoolContract.totalSupply()),
    MulticallBatcher.queue(hifiPoolContract.underlyingPrecisionScalar()),
    MulticallBatcher.queue(hTokenContract.balanceOf(hifiPool.id)),
  ]);

  const [normalizedUnderlyingReserves, supply, underlyingPrecisionScalar, hTokenReserves] = res;
  const hTokenReturned = poolTokensBurned.mul(hTokenReserves).div(supply);
  const normalizedUnderlyingReturned = poolTokensBurned.mul(normalizedUnderlyingReserves).div(supply);
  const underlyingReturned = normalizedUnderlyingReturned.div(underlyingPrecisionScalar);

  return deepFreeze({ underlyingReturned, hTokenReturned });
};

export default getBurnParams;
