import { memo } from "react";
import { LighterPaper } from "../Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HeadlineGrid from "../HeadlineGrid";

const EmptyHeadline = (): JSX.Element => {
  return (
    <HeadlineGrid item lg={9} md={9} sm={11} xs={12} style={{ visibility: "hidden" }}>
      <LighterPaper>
        <Box p={6} display="flex" flexDirection="column" alignItems="center">
          <Typography color="textSecondary" variant="body2">
            &nbsp;
          </Typography>
          <Box mt={1}>
            <Typography variant="h4">&nbsp;</Typography>
          </Box>
        </Box>
      </LighterPaper>
    </HeadlineGrid>
  );
};
export default memo(EmptyHeadline);
