export type UseBorrowErrorProps = {
  owedAtMaturityIsError: boolean;
  debouncedBorrowInput: string;
  insufficientCollateralRatio: boolean;
  owedAtMaturityIsLoading: boolean;
};

export const LIQUIDITY = "LIQUIDITY";
export const COLLATERAL = "COLLATERAL";

export type ErrorType = typeof LIQUIDITY | typeof COLLATERAL;
