import { QueryObserverResult, useQuery } from "react-query";
import getHifiPoolLiquidity from "../../api/getHifiPoolLiquidity";
import { HifiPoolLiquidity } from "../../api/getHifiPoolLiquidity/types";
import { HifiPool } from "../../api/getHifiPools/types";

export function buildQueryKey(hifiPoolAddress: string): [string, string] {
  return ["hifiPoolLiquidity", hifiPoolAddress];
}

export default function useHifiPoolLiquidity(hifiPool: HifiPool): QueryObserverResult<HifiPoolLiquidity> {
  const resp = useQuery(
    buildQueryKey(hifiPool.id),
    () =>
      getHifiPoolLiquidity({
        hifiPoolAddress: hifiPool.id,
        hTokenAddress: hifiPool.hToken.id,
      }),
    {
      staleTime: 5 * 60 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  return resp;
}
