import { MulticallBatcher } from "contexts/web3Base";
import { getMulticallContractByName } from "../../utils/contracts";
import BPromise from "bluebird";
import { formatUnits } from "@ethersproject/units";
import { UserDebts } from "./types";
import { HTokenMap } from "../../hooks/useHTokenMap/types";
import deepFreeze from "utils/deepFreeze";

const getUserDebts = async ({
  chainName,
  account,
  hTokenMap,
}: {
  chainName: string;
  account: string;
  hTokenMap: HTokenMap | undefined;
}): Promise<UserDebts> => {
  if (!hTokenMap) {
    return {};
  }
  const balanceSheetContract = getMulticallContractByName({ contractName: "balanceSheet", chainName });
  const bondList: string[] = await MulticallBatcher.queue(balanceSheetContract.getBondList(account));
  const amounts: string[] = await BPromise.map(bondList, hTokenAddress => {
    return MulticallBatcher.queue(balanceSheetContract.getDebtAmount(account, hTokenAddress));
  });

  return deepFreeze(
    bondList.reduce((memo, hTokenAddress, i) => {
      const addy = hTokenAddress.toLowerCase();
      const hToken = hTokenMap[addy];
      if (hToken) {
        const decimals = hToken.decimals;
        const amount = amounts[i];
        return { ...memo, [addy]: formatUnits(amount, decimals) };
      }
      return memo;
    }, {}),
  );
};

export default getUserDebts;
