import { formatUnits, parseUnits } from "@ethersproject/units";
import { truncateDecimals } from "utils/numbers";
import { BigNumber } from "ethers";
import { MutationStatus } from "react-query";
import { Collateral } from "../../api/getCollaterals/types";
import useTokenPrice from "../useTokenPrice";
import useUserHypotheticalBorrowLimit from "../useUserHypotheticalBorrowLimit";
import useUserLockedCollaterals from "../useUserLockedCollaterals";
import useUserTotalDebt from "../useUserTotalDebt";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { useCallback } from "react";
import { UserLockedCollaterals } from "../../api/getUserLockedCollaterals/types";

export default function useWithdrawableCollateral({
  chainName,
  account,
  collateral,
  percentLimit = 1,
}: {
  chainName: string;
  account: string;
  collateral: Collateral;
  percentLimit?: number;
}): { status: MutationStatus; data: undefined | string; isFetching: boolean } {
  const hypotheticalBorrowLimitRes = useUserHypotheticalBorrowLimit({ chainName, account });

  const lockedCollateralsRes = useUserLockedCollaterals({ chainName, account });

  const tokenPriceRes = useTokenPrice({ chainName, symbol: collateral.symbol });

  const totalDebtRes = useUserTotalDebt({
    chainName,
    account,
  });

  return useCombinedQueryHooks(
    [hypotheticalBorrowLimitRes, lockedCollateralsRes, tokenPriceRes, totalDebtRes],
    useCallback(
      data => {
        const [borrowLimit, userCollaterals, collateralPrice, userDebt] = data as [
          string,
          UserLockedCollaterals,
          string,
          string,
        ];

        if (borrowLimit && userDebt && collateralPrice && userCollaterals) {
          const userDebtBn = parseUnits(userDebt, 18);

          // If user has no debt, then just allow them to withdraw total locked amount
          if (userDebtBn.eq(BigNumber.from("0"))) {
            return userCollaterals[collateral.id] || "0";
          } else {
            const borrowLimitFloat = parseFloat(borrowLimit);
            const userDebtFloat = parseFloat(userDebt);
            const collateralPriceFloat = parseFloat(collateralPrice);

            let removableBorrowLimitFloat = borrowLimitFloat - userDebtFloat / percentLimit;
            // If removableBorrowLimitFloat is so small that it's string rep has "e" notation, just consider the value 0;
            // otherwise the formatUnits will fail below
            removableBorrowLimitFloat = removableBorrowLimitFloat.toString().includes("e")
              ? 0
              : removableBorrowLimitFloat;

            // In the off chance that the user's debt exceeds their borrow limit
            removableBorrowLimitFloat = removableBorrowLimitFloat < 0 ? 0 : removableBorrowLimitFloat;

            let removableCollateralFloat =
              (removableBorrowLimitFloat / collateralPriceFloat) * parseFloat(collateral.collateralizationRatio);
            // If removableCollateralFloat is so small that it's string rep has "e" notation, just consider the value 0;
            // otherwise the truncateDecimals below will return bad value
            removableCollateralFloat = removableCollateralFloat.toString().includes("e") ? 0 : removableCollateralFloat;

            let lockedCollateralFloat = parseFloat(userCollaterals[collateral.id] || "0");
            // If lockedCollateralFloat is so small that it's string rep has "e" notation, just consider the value 0;
            // otherwise the formatUnits will fail below
            lockedCollateralFloat = lockedCollateralFloat.toString().includes("e") ? 0 : lockedCollateralFloat;

            if (removableCollateralFloat < lockedCollateralFloat) {
              return formatUnits(
                parseUnits(truncateDecimals(removableCollateralFloat, collateral.decimals), collateral.decimals),
                collateral.decimals,
              );
            } else {
              return userCollaterals[collateral.id];
            }
          }
        }
      },
      [collateral, percentLimit],
    ),
  );
}
