import { Web3Provider } from "@ethersproject/providers";
import { truncateDecimals, numberWithCommas } from "utils/numbers";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { HifiPool } from "../../api/getHifiPools/types";
import useHifiPoolTotalLiquidityInUnderlying from "../../hooks/useHifiPoolTotalLiquidityInUnderlying";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { useUserPoolLiquidityInUnderlying } from "../../hooks/useUserPoolLiquidityInUnderlying";
import { InlineDottyProgress } from "../../components/DottyProgress";

export default function PoolRow({
  pool,
  onPoolClick,
  account,
  chainName,
  walletProvider,
  onMyLiquidityChange,
}: {
  pool: HifiPool;
  onPoolClick: (hifiPool: HifiPool) => void;
  account: string;
  chainName: string;
  walletProvider: Web3Provider | undefined;
  onMyLiquidityChange?: (poolId: string, liquidity: number | undefined) => void;
}): JSX.Element {
  useUserErc20Balance({
    token: pool,
    walletProvider,
    account,
  });

  const { data: myLiquidity, status: myLiquidityStatus } = useUserPoolLiquidityInUnderlying({
    account,
    hifiPool: pool,
    walletProvider,
    chainName,
  });

  const { status: liqudityStatus, data: liquidity } = useHifiPoolTotalLiquidityInUnderlying(pool);

  useEffect(() => {
    if ((myLiquidityStatus === "success" || myLiquidityStatus === "error") && onMyLiquidityChange) {
      // Update total
      onMyLiquidityChange(pool.id, myLiquidity);
    }
  }, [myLiquidity, myLiquidityStatus, onMyLiquidityChange, pool]);

  useEffect(() => {
    return () => {
      if (onMyLiquidityChange) {
        onMyLiquidityChange(pool.id, void 0);
      }
    };
  }, [pool, onMyLiquidityChange]);

  return (
    <>
      <TableRow hover onClick={() => onPoolClick(pool)}>
        <TableCell align="left">
          <Box display="flex" alignItems="center">
            <Jazzicon diameter={24} seed={jsNumberForAddress(pool.id)} />
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="h5">{new Date(pool.maturity * 1000).toLocaleDateString()}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="h6">
            {liqudityStatus === "loading" && <InlineDottyProgress />}
            {liqudityStatus === "success" && liquidity && `$${truncateDecimals(numberWithCommas(liquidity), 2)}`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="h6">
            {!walletProvider && myLiquidityStatus !== "loading" && <span>&ndash;</span>}
            {myLiquidityStatus === "loading" && <InlineDottyProgress />}
            {myLiquidityStatus === "success" &&
              typeof myLiquidity === "number" &&
              `$${numberWithCommas(truncateDecimals(myLiquidity, 2))}`}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
