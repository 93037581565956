import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import useSettings from "../../hooks/useSettings";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Typography from "@mui/material/Typography";
import { Context as Web3Context } from "../../contexts/web3";
import Box from "@mui/material/Box";
import useUserLendingPositions from "../../hooks/useUserLendingPositions";
import OverlayProgress from "../../components/OverlayProgress";
import { HifiPool } from "../../api/getHifiPools/types";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import ManageLendDialog from "../../containers/ManageLendDialog";
import RedeemDialog from "../../containers/RedeemDialog";
import { formatUnits } from "@ethersproject/units";
import useUserTotalLendBalanceInUnderlying from "../../hooks/useUserTotalLendBalanceInUnderlying";
import { LighterPaper } from "../../components/Paper";
import { useTranslation } from "react-i18next";
import HeadlineGrid from "../../components/HeadlineGrid";
import EmptyHeadline from "../../components/EmptyHeadline";
import MyRedeemableTable from "../../components/MyRedeemableTable";
import MyLendingTable from "../../components/MyLendingTable";
import LendingMarketsTable from "../../components/LendingMarketsTable";
import isExpired from "../../utils/isExpired";
import { usePageEvent } from "../../hooks/analytics";
import { styled } from "@mui/material/styles";

const _LendBalance = ({ truncatedFloatTotalLendBalance }: { truncatedFloatTotalLendBalance: number }) => {
  const { t } = useTranslation();
  return (
    <HeadlineGrid item lg={9} md={9} sm={11} xs={12}>
      <LighterPaper>
        <Box p={6} display="flex" flexDirection="column" alignItems="center">
          <Typography color="textSecondary" variant="body2">
            {t("Lend Balance")}
          </Typography>
          <Box mt={1}>
            <Typography variant="h4">
              ${numberWithCommas(truncateDecimals(truncatedFloatTotalLendBalance, 2))}
            </Typography>
          </Box>
        </Box>
      </LighterPaper>
    </HeadlineGrid>
  );
};
const LendBalance = memo(_LendBalance);

type HelpBannerType = {
  backgroundImage: string;
};
const HelpBanner = styled(Box)<HelpBannerType>(({ theme, backgroundImage }) => ({
  "&": {
    backgroundColor: "#5225B0",
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    overflow: "hidden",
    backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
}));

export default function LendV1(): JSX.Element {
  usePageEvent("lend");
  const { chainName, account, provider, walletProvider, connect, connecting } = useContext(Web3Context);
  const [managedMarket, setManagedMarket] = useState<HifiPool | null>(null);
  const [redeemMarket, setRedeemMarket] = useState<HifiPool | null>(null);
  const { showDustyPositions } = useSettings();

  const { data: lendingPositions, status: lendingPositionsStatus } = useUserLendingPositions({
    chainName,
    account,
    provider,
  });

  const { data: totalLendBalance, status: totalLendBalanceStatus } = useUserTotalLendBalanceInUnderlying({
    chainName,
    account,
    provider,
  });

  const truncatedFloatTotalLendBalance = useMemo(() => {
    if (totalLendBalance) {
      return parseFloat(truncateDecimals(formatUnits(totalLendBalance, 6), 2));
    }
  }, [totalLendBalance]);

  const myLending = useMemo(() => {
    if (lendingPositions) {
      return lendingPositions.filter(pos => {
        const truncatedFloat = parseFloat(
          truncateDecimals(
            formatUnits(pos.hTokenBalance, pos.hifiPool.hToken.decimals),
            2,
            // pos.hifiPool.hToken.underlying.decimals,
          ),
        );
        return (
          ((!showDustyPositions && truncatedFloat > 0) || (showDustyPositions && pos.hTokenBalance.gt(0))) &&
          !isExpired(pos.hifiPool)
        );
      });
    }
  }, [lendingPositions, showDustyPositions]);

  const myRedeemable = useMemo(() => {
    if (lendingPositions) {
      return lendingPositions.filter(pos => {
        const truncatedFloat = parseFloat(
          truncateDecimals(
            formatUnits(pos.hTokenBalance, pos.hifiPool.hToken.decimals),
            2,
            // pos.hifiPool.hToken.underlying.decimals,
          ),
        );
        // Ingore dusty debt positions - make sure that the hToken balance will amount to at least 1 unit
        // in underlying decimals.
        return (
          ((!showDustyPositions && truncatedFloat > 0) || (showDustyPositions && pos.hTokenBalance.gt(0))) &&
          isExpired(pos.hifiPool)
        );
      });
    }
  }, [lendingPositions, showDustyPositions]);

  const lendMarkets = useMemo(() => {
    if (lendingPositions) {
      return lendingPositions.filter(pos => {
        const truncatedFloat = parseFloat(
          truncateDecimals(
            formatUnits(pos.hTokenBalance, pos.hifiPool.hToken.decimals),
            2,
            // pos.hifiPool.hToken.underlying.decimals,
          ),
        );
        return (
          ((!showDustyPositions && truncatedFloat === 0) || (showDustyPositions && pos.hTokenBalance.eq(0))) &&
          !isExpired(pos.hifiPool)
        );
      });
    }
  }, [lendingPositions, showDustyPositions]);

  const onMarketClick = useCallback((hifiPool: HifiPool) => {
    setManagedMarket(hifiPool);
  }, []);

  const onCloseManageMarketClick = useCallback(() => {
    setManagedMarket(null);
  }, []);

  const onRedeemClick = useCallback((hifiPool: HifiPool) => {
    setRedeemMarket(hifiPool);
  }, []);

  const onCloseRedeemMarketClick = useCallback(() => {
    setRedeemMarket(null);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      {lendingPositionsStatus === "loading" && (
        <Box pt={10} position="relative">
          <OverlayProgress />
        </Box>
      )}
      {lendingPositionsStatus === "success" && (
        <>
          <Grid container spacing={6} justifyContent="center">
            {!!truncatedFloatTotalLendBalance && truncatedFloatTotalLendBalance > 0 && (
              <LendBalance truncatedFloatTotalLendBalance={truncatedFloatTotalLendBalance} />
            )}
            {/* Show a blank spot for either the balance or the help banner to appear in */}
            {totalLendBalanceStatus === "loading" && !truncatedFloatTotalLendBalance && <EmptyHeadline />}
            {totalLendBalanceStatus !== "loading" &&
              (!truncatedFloatTotalLendBalance || !account || !totalLendBalance) && (
                <HeadlineGrid item lg={9} md={9} sm={11} xs={12}>
                  <HelpBanner p={6} backgroundImage={`url(${process.env.PUBLIC_URL}/images/papersquiggle-purple.svg)`}>
                    <Link
                      href="https://www.youtube.com/watch?v=HS5GvsEbJ5g"
                      style={{ marginBottom: ".2em", display: "inline-block" }}
                      color="textPrimary"
                      variant="h4"
                      underline="none"
                      target="_blank"
                    >
                      {t("helpBanners.lend.step0.title")}
                      <ArrowForwardIcon style={{ verticalAlign: "middle", marginLeft: ".2em" }} />
                    </Link>
                    <Typography variant="body1">{t("helpBanners.lend.step0.subtitle")}</Typography>
                  </HelpBanner>
                </HeadlineGrid>
              )}

            {!!myRedeemable?.length && <MyRedeemableTable myRedeemable={myRedeemable} onRedeemClick={onRedeemClick} />}

            {!!myLending?.length && <MyLendingTable myLending={myLending} onMarketClick={onMarketClick} />}

            {!!lendMarkets?.length && (
              <LendingMarketsTable
                title={myLending?.length ? t("Other Lend Markets") : t("Lend Markets")}
                lendMarkets={lendMarkets}
                onMarketClick={onMarketClick}
              />
            )}
          </Grid>
        </>
      )}
      {managedMarket && (
        <ManageLendDialog
          hifiPool={managedMarket}
          account={account}
          chainName={chainName}
          onCloseClick={onCloseManageMarketClick}
          walletProvider={walletProvider}
          connect={connect}
          connecting={connecting}
        />
      )}
      {redeemMarket && (
        <RedeemDialog
          hifiPool={redeemMarket}
          account={account}
          chainName={chainName}
          onCloseClick={onCloseRedeemMarketClick}
          walletProvider={walletProvider}
        />
      )}
    </>
  );
}
