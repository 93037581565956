import { QueryObserverResult, useQuery } from "react-query";
import { HToken } from "../../api/getHifiPools/types";
import getUserDebts from "../../api/getUserDebts";
import { UserDebts } from "../../api/getUserDebts/types";
import useHTokenMap from "../useHTokenMap";
import useUserDSProxy from "../useUserDSProxy";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";

export default function useUserDebts({
  chainName,
  account,
}: {
  chainName: string;
  account: string;
}): QueryObserverResult<UserDebts> {
  const hTokenMapQueryRes = useHTokenMap(chainName);
  const { data: hTokenMap, isFetching: hTokenMapIsFetching } = hTokenMapQueryRes;

  const dsProxyQueryRes = useUserDSProxy({ chainName, account });
  const { data: dsProxyAddress, isFetching: dsProxyAddressIsFetching } = dsProxyQueryRes;

  const debtsQueryRes = useQuery(
    ["userDebts", chainName, dsProxyAddress, hTokenMap],
    ({ queryKey }) =>
      getUserDebts({
        chainName: queryKey[1] as string,
        account: queryKey[2] as string,
        hTokenMap: queryKey[3] as Record<string, HToken> | undefined,
      }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !hTokenMapIsFetching && !dsProxyAddressIsFetching,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { status, isFetching } = useCombinedQueryHooks([hTokenMapQueryRes, dsProxyQueryRes, debtsQueryRes]);

  return {
    ...debtsQueryRes,
    status,
    isFetching,
  } as QueryObserverResult<UserDebts>;
}
