import { useState, createContext, useCallback, useEffect } from "react";
import { SettingsContext } from "./types";

export const Context = createContext<SettingsContext>({
  showDustyPositions: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowDustyPositions: () => {},
});

export const Provider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const localStorageShowDustyPositions = window.localStorage.getItem("showDustyPositions");

  const [showDustyPositions, setShowDustyPositions] = useState(
    localStorageShowDustyPositions ? JSON.parse(localStorageShowDustyPositions) : false,
  );

  const saveStateToLocalStorage = useCallback(() => {
    window.localStorage.setItem("showDustyPositions", JSON.stringify(showDustyPositions));
  }, [showDustyPositions]);

  useEffect(
    function beforeunload() {
      try {
        window.addEventListener("beforeunload", saveStateToLocalStorage);

        return () => window.removeEventListener("beforeunload", saveStateToLocalStorage);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    [saveStateToLocalStorage],
  );

  return <Context.Provider value={{ showDustyPositions, setShowDustyPositions }}>{children}</Context.Provider>;
};
