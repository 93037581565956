import { memo } from "react";
import Table, { TableContainer } from "../../components/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableHeader from "../../components/TableHeader";
import { useTranslation } from "react-i18next";
import { Collateral } from "../../api/getCollaterals/types";
import { isWeth } from "../../contracts/addresses";
import EthCollateralRow from "../../containers/EthCollateralRow";
import Erc20CollateralRow from "../../containers/Erc20CollateralRow";

function CollateralsTable({
  collaterals,
  chainName,
  onCollateralClick,
}: {
  collaterals: Collateral[];
  chainName: string;
  onCollateralClick: (collateral: Collateral) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <TableHeader variant="body2">{t("Collateral")}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body1" color="textSecondary">
                  {t("Asset")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Wallet")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Col. ratio")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collaterals.map(collateral => {
              // work-around for using ETH as WETH
              return isWeth(chainName, collateral.id) ? (
                <EthCollateralRow onCollateralClick={onCollateralClick} key={collateral.id} collateral={collateral} />
              ) : (
                <Erc20CollateralRow onCollateralClick={onCollateralClick} key={collateral.id} collateral={collateral} />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(CollateralsTable);
