import { multiplyBigNumberByScalar } from "utils/numbers";
import { HifiPool } from "../../api/getHifiPools/types";
import useQuoteForSellingHToken from "../useQuoteForSellingHToken";
import useUserHypotheticalBorrowLimit from "../useUserHypotheticalBorrowLimit";
import useUserTotalDebt from "../useUserTotalDebt";
import { formatUnits, parseUnits } from "@ethersproject/units";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { useCallback } from "react";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";

export default function useUserSuggestedLimit({
  chainName,
  hifiPool,
  account,
  percent = 0.8,
}: {
  chainName: string;
  hifiPool: HifiPool;
  account: string;
  percent?: number;
}): QueryResponse<string> {
  const borrowLimitRes = useUserHypotheticalBorrowLimit({
    chainName,
    account,
  });
  const { data: hTokenBorrowLimit } = borrowLimitRes;

  const totalDebRes = useUserTotalDebt({ chainName, account });
  const { data: debt } = totalDebRes;

  const decimals = hifiPool.hToken.decimals;
  const remainingBorrowable =
    hTokenBorrowLimit && debt
      ? formatUnits(
          multiplyBigNumberByScalar(parseUnits(hTokenBorrowLimit, decimals), percent, decimals).sub(
            parseUnits(debt, decimals),
          ),
          decimals,
        )
      : "";

  const quoteRes = useQuoteForSellingHToken({
    hTokenIn: remainingBorrowable,
    hifiPool,
  });

  return useCombinedQueryHooks(
    [quoteRes, borrowLimitRes, totalDebRes],
    useCallback(data => {
      return data[0];
    }, []),
  );
}
