import { styled } from "@mui/material/styles";

const PREFIX = "DialogDataRow";

const classes = {
  dataRow: `${PREFIX}-dataRow`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.dataRow}`]: {
    paddingBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",

    "& + &": {
      borderTop: `1px solid ${theme.palette.borderColor}`,
      paddingTop: theme.spacing(4),
    },

    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

export default function DialogDataRow({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  return <Root className={classes.dataRow}>{children}</Root>;
}
