import { useCallback, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { CloseSection, Dialog } from "../../components/ActionDialog";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { HifiPool } from "../../api/getHifiPools/types";
import DialogContentArea from "../../components/DialogContentArea";
import { Context as TransactionsContext, LAST_RESULT } from "contexts/transactionsBase";
import usePendingTransactionUI from "../../hooks/usePendingTransactionUI";
import SingleTaskPanel from "../../components/SingleTaskPanel";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { Web3Provider } from "@ethersproject/providers";
import { useTranslation } from "react-i18next";
import { logEvent } from "../../utils/analytics";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import MaxInput from "components/MaxInput";
import TokenIcon from "../../icons/TokenIcon";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import { createDsProxy, permit, redeemHToken } from "../../utils/transactions";
import { useProxyTargetContract } from "../../hooks/contracts";
import { parseUnits } from "ethers/lib/utils";
import useTotalUnderlyingReserve from "../../hooks/useTotalUnderlyingReserve";
import FormHelperText from "@mui/material/FormHelperText";
import { getInputJazziconSize } from "../../utils/getJazziconSize";
import useWindowBreakpoint from "hooks/useWindowBreakpoint";
import { AddressZero } from "@ethersproject/constants";
import { getContractAddress } from "../../contracts/addresses";
import shouldSkipDSProxyConfirmations from "../../utils/shouldSkipDSProxyConfirmations";

export default function ManageRedeemDialog({
  onCloseClick,
  hifiPool,
  account,
  chainName,
  walletProvider,
}: {
  onCloseClick: () => void;
  actionType?: "lend" | "sell";
  hifiPool: HifiPool;
  account: string;
  chainName: string;
  walletProvider?: Web3Provider;
}): JSX.Element {
  const { t } = useTranslation();

  const bp = useWindowBreakpoint();

  const { addTransactions, isTransactionQueued } = useContext(TransactionsContext);

  const { showTx, transaction, setPendingTxId } = usePendingTransactionUI(onCloseClick);

  const { data: dsProxyAddress } = useUserDSProxy({ chainName, account });

  const { data: totalUnderlyingReserve, isLoading: totalUnderlyingReserveIsLoading } = useTotalUnderlyingReserve(
    hifiPool.hToken,
  );

  const { data: hTokenBalance } = useUserErc20Balance({
    token: hifiPool.hToken,
    account,
    walletProvider,
  });

  const errorMsg =
    hTokenBalance &&
    totalUnderlyingReserve?.lt(
      parseUnits(hTokenBalance, hifiPool.hToken.decimals).div(hifiPool.underlyingPrecisionScalar),
    )
      ? t("errors.insufficientUnderlyingReserves")
      : "";

  const buttonDisabled = !!errorMsg || totalUnderlyingReserveIsLoading;

  const proxyTargetContract = useProxyTargetContract();
  const onRedeemClick = useCallback(
    e => {
      e.preventDefault();

      if (!hTokenBalance || buttonDisabled || !proxyTargetContract || !dsProxyAddress) return;

      const txs = [];

      // If DSProxy has already been created, or if there is a create DSProxy tx queued up, then skip
      // DSProxy creation tx.
      if (
        dsProxyAddress === AddressZero &&
        !isTransactionQueued({
          methodName: "build()",
          contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
        })
      ) {
        txs.push(
          createDsProxy({
            contractAddress: getContractAddress(chainName, "dsProxyRegistry"),
            description: t("transactions.dsProxy"),
            skipConfirmations: shouldSkipDSProxyConfirmations(chainName),
          }),
        );
      }

      // if (
      //   hTokenAllowance === "0" &&
      //   !isTransactionQueued({ methodName: "approve", contractAddress: hifiPool.hToken.id })
      // ) {
      //   txs.push(
      //     approve({
      //       description: t("transactions.messages.allowance", {
      //         spender: "Hifi",
      //         token: hifiPool.hToken.symbol,
      //       }),
      //       contractAddress: hifiPool.hToken.id,
      //       spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
      //       callbackArgs: {
      //         spenderAddress: "DSPROXY_ADDRESS",
      //         tokenAddress: hifiPool.hToken.id,
      //       },
      //     }),
      //   );
      // }

      const hTokenBalanceBn = parseUnits(hTokenBalance, hifiPool.hToken.decimals);

      txs.push(
        permit({
          contractAddress: hifiPool.hToken.id,
          description: t("transactions.messages.allowance", {
            spender: "Hifi",
            token: hifiPool.hToken.symbol,
          }),
          ownerAddress: account,
          spenderAddress: dsProxyAddress === AddressZero ? LAST_RESULT : dsProxyAddress,
          amount: hTokenBalanceBn.toString(),
        }),
      );

      logEvent("redeemClick", {
        hTokenAddress: hifiPool.hToken.id,
      });

      txs.push(
        redeemHToken({
          contractAddress: dsProxyAddress === AddressZero ? `${LAST_RESULT}-1` : dsProxyAddress,
          description: t("transactions.redeem.description", {
            amount: numberWithCommas(truncateDecimals(hTokenBalance, hifiPool.hToken.underlying.decimals)),
            hToken: `h${hifiPool.hToken.underlying.symbol}`,
            token: hifiPool.hToken.underlying.symbol,
          }),
          completionMessage: t("transactions.redeem.completionMessage", {
            amount: numberWithCommas(truncateDecimals(hTokenBalance, hifiPool.hToken.underlying.decimals)),
            hToken: `h${hifiPool.hToken.underlying.symbol}`,
            token: hifiPool.hToken.underlying.symbol,
          }),
          proxyTargetContract,
          hTokenAddress: hifiPool.hToken.id,
          hTokenAmount: hTokenBalanceBn,
          underlyingAmount: hTokenBalanceBn.div(hifiPool.underlyingPrecisionScalar),
          callbackArgs: {
            hTokenAddress: hifiPool.hToken.id,
            underlyingAddress: hifiPool.hToken.underlying.id,
            hifiPoolAddress: hifiPool.id,
          },
        }),
      );

      const pendingTxIds = addTransactions({
        transactions: txs,
        batchName: t("Redeem"),
      });
      setPendingTxId(pendingTxIds[pendingTxIds.length - 1]);
    },
    [
      addTransactions,
      hifiPool,
      isTransactionQueued,
      setPendingTxId,
      t,
      hTokenBalance,
      proxyTargetContract,
      buttonDisabled,
      dsProxyAddress,
      chainName,
      account,
    ],
  );

  const _onClose = useCallback(
    (_evt, _reason) => {
      if (_reason !== "backdropClick" || !transaction || !showTx) {
        onCloseClick();
      }
    },
    [onCloseClick, transaction, showTx],
  );

  return (
    <Dialog onClose={_onClose} open={!transaction}>
      {transaction && showTx && (
        <DialogContent>
          <SingleTaskPanel chainName={chainName} onClose={onCloseClick} transaction={transaction} />
        </DialogContent>
      )}
      {!showTx && (
        <>
          <Box>
            <Tabs textColor="inherit" value={0} variant="fullWidth" indicatorColor="primary">
              <Tab disableRipple label={<Typography variant="h4">{t("Redeem")}</Typography>} />
            </Tabs>
            <Box position="relative" />
          </Box>
          <DialogContent>
            <form onSubmit={onRedeemClick}>
              <DialogContentArea>
                <Box mb={4}>
                  <Typography display="inline" variant="h5" color="textSecondary">
                    {t("Maturity Date")}:&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {new Date(hifiPool.hToken.maturity * 1000).toLocaleString()}
                  </Typography>
                </Box>
                <MaxInput
                  fullWidth
                  disabled
                  value={hTokenBalance}
                  style={{ fontSize: "1.75em" }}
                  decimals={hifiPool.hToken.decimals}
                  startAdornment={
                    <InputAdornment position="start">
                      <Jazzicon diameter={getInputJazziconSize(bp)} seed={jsNumberForAddress(hifiPool.hToken.id)} />
                    </InputAdornment>
                  }
                />
                {!!errorMsg && (
                  <Typography variant="body1">
                    <FormHelperText error>{errorMsg}</FormHelperText>
                  </Typography>
                )}
                <Box display="flex" pt={2.5} pb={2.5} justifyContent="center">
                  <ArrowDownwardIcon style={{ fontSize: "2.5em" }} />
                </Box>
                <MaxInput
                  fullWidth
                  disabled
                  value={hTokenBalance && truncateDecimals(hTokenBalance, hifiPool.hToken.underlying.decimals)}
                  style={{ fontSize: "1.75em" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <TokenIcon
                        style={{ fontSize: "1.15em" }}
                        symbol={hifiPool.hToken.underlying.symbol as TokenSymbol}
                      />
                    </InputAdornment>
                  }
                />
              </DialogContentArea>
              <DialogContentArea>
                <Button
                  type="submit"
                  disabled={buttonDisabled}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  {t("Redeem")}
                </Button>
                <CloseSection onClose={onCloseClick} />
              </DialogContentArea>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
