import { BreakPoint } from "hooks/useWindowBreakpoint/types";

export function getInputJazziconSize(bp: BreakPoint): number {
  return {
    lg: 31,
    md: 31,
    sm: 26,
    xs: 21,
  }[bp];
}

export function getDialogJazziconSize(bp: BreakPoint): number {
  return {
    lg: 24,
    md: 21,
    sm: 19.5,
    xs: 16.5,
  }[bp];
}
