import { useContext, useEffect } from "react";
import { logEvent } from "../../utils/analytics";
import { Context as Web3Context } from "../../contexts/web3";

export function usePageEvent(pageName: string): void {
  const { initialized } = useContext(Web3Context);
  useEffect(() => {
    if (initialized) {
      logEvent("page", { pageName, referrer: document.referrer });
    }
  }, [pageName, initialized]);
}
