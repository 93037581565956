import { formatUnits, parseUnits } from "@ethersproject/units";
import { HifiPool } from "../../api/getHifiPools/types";
import useQuoteForSellingHToken from "../useQuoteForSellingHToken";
import useUserHypotheticalBorrowLimit from "../useUserHypotheticalBorrowLimit";
import useUserTotalDebt from "../useUserTotalDebt";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";
import { useCallback } from "react";

export default function useUserAvailableCredit({
  chainName,
  hifiPool,
  account,
}: {
  chainName: string;
  hifiPool: HifiPool;
  account: string;
}): QueryResponse<string> {
  const borrowLimitRes = useUserHypotheticalBorrowLimit({ chainName, account });
  const { data: hTokenBorrowLimit } = borrowLimitRes;

  const totalDebtRes = useUserTotalDebt({ chainName, account });
  const { data: totalDebt } = totalDebtRes;

  const underlyingAmountRes = useQuoteForSellingHToken({
    hTokenIn:
      hTokenBorrowLimit && totalDebt
        ? formatUnits(parseUnits(hTokenBorrowLimit, 18).sub(parseUnits(totalDebt, 18)), 18)
        : "",
    hifiPool,
  });

  return useCombinedQueryHooks(
    [underlyingAmountRes, borrowLimitRes, totalDebtRes],
    useCallback(data => {
      const [underlyingAmount]: [string] = data;
      return underlyingAmount;
    }, []),
  );
}
