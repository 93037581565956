import { useState, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CopyIcon from "../../icons/Copy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import copy from "copy-to-clipboard";
import { CopyTextProps } from "./types";
import { useTranslation } from "react-i18next";

const PREFIX = "CopyText";

const classes = {
  linkText: `${PREFIX}-linkText`,
  icon: `${PREFIX}-icon`,
  copiedText: `${PREFIX}-copiedText`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.linkText}`]: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    "&:hover, &:focus": {
      color: theme.palette.text.primary,
      outline: "none",
    },
  },

  [`& .${classes.icon}`]: {
    marginRight: ".4em",
  },

  [`& .${classes.copiedText}`]: {},
}));

export default function CopyText({ label, text }: CopyTextProps): JSX.Element {
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  label = label || t("Copy Address");

  const onCopyAddressClick = useCallback(
    event => {
      if (event.key && event.key !== "Enter") return;

      copy(text);
      setCopied(true);
    },
    [text],
  );

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return (
    <Root>
      {copied && (
        <Box display="flex" alignItems="center" className={classes.copiedText}>
          <CheckCircleOutlineIcon className={classes.icon} />
          {t("Copied")}
        </Box>
      )}
      {!copied && (
        <Box className={classes.linkText} onClick={onCopyAddressClick} onKeyDown={onCopyAddressClick} tabIndex={0}>
          <CopyIcon className={classes.icon} />
          {label}
        </Box>
      )}
    </Root>
  );
}
