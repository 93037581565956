import { Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import { useCallback, useMemo } from "react";
import { QueryStatus, QueryObserverResult, useQueries } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import useHifiPools from "../useHifiPools";
import { buildQueryObject } from "hooks/useUserErc20Balance";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";

export default function useUserLiquidityPools({
  account,
  chainName,
  walletProvider,
}: {
  account: string;
  chainName: string;
  walletProvider?: Web3Provider;
}): { status: QueryStatus; data: undefined | readonly HifiPool[]; isFetching: boolean } {
  const useHifiPoolsResp = useHifiPools(chainName);
  const { data: hifiPools, status: hifiPoolsStatus, isFetching: hifiPoolsIsFetching } = useHifiPoolsResp;

  const userHifiPoolBalanceQueryObjects = useMemo(() => {
    if (hifiPoolsStatus === "success" && !hifiPoolsIsFetching) {
      return compact(
        hifiPools?.map(hifiPool => {
          if (walletProvider) {
            return {
              ...buildQueryObject({
                tokenAddress: hifiPool.id,
                provider: walletProvider,
                account,
              }),
            };
          }
        }),
      );
    }
  }, [hifiPools, hifiPoolsStatus, account, hifiPoolsIsFetching, walletProvider]);

  // Run queries for acccount balances of lpTokens
  const lpTokenBalanceQueries = useQueries(userHifiPoolBalanceQueryObjects || []) as QueryObserverResult<BigNumber>[];

  return useCombinedQueryHooks(
    [useHifiPoolsResp, ...lpTokenBalanceQueries],
    useCallback((data, lastValue) => {
      const [_hifiPools, ..._lpTokenBalances] = data;
      const lpTokenBalances = _lpTokenBalances as Array<BigNumber>;
      const hifiPools = _hifiPools as HifiPool[];

      if (hifiPools) {
        const _data = hifiPools.filter((_hifiPool, i) => {
          return ((lpTokenBalances[i] && lpTokenBalances[i]) || BigNumber.from("0")).gt(BigNumber.from("0"));
        });
        if (!isEqual(_data, lastValue)) {
          // Since we are using useQueries above, we need to compare each item with the previously returned array
          // if all items are equal, then don't return new array.
          return _data;
        }
        return lastValue;
      }
    }, []),
  );
}
