import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { OverlayProgressProps } from "./types";

export default function OverlayProgress({ size = "5.6875rem", className }: OverlayProgressProps): JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      zIndex={1000}
      position="absolute"
      className={className}
    >
      <CircularProgress style={{ width: size, height: size }} />
    </Box>
  );
}
