import classnames from "classnames";
import { DottyProgressProps, InlineDottyProgressProps } from "./types";
import { keyframes, styled } from "@mui/material/styles";

const PREFIX = "DottyProgress";

const classes = {
  root: `${PREFIX}-root`,
  dot: `${PREFIX}-dot`,
  frozen: `${PREFIX}-frozen`,
};

const wave = keyframes`
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-2.2em);
  }
`;

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "relative",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  [`& .${classes.dot}`]: {
    display: "inline-block",
    width: "1em",
    height: "1em",
    borderRadius: "50%",
    marginRight: "1.8em",
    background: theme.palette.text.primary,
    animation: `${wave} .85s linear infinite`,
    "&:nth-child(2)": {
      animationDelay: "-.75s",
    },
    "&:nth-child(3)": {
      animationDelay: "-0.65s",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  [`&.${classes.frozen}`]: {
    animation: "",
  },
}));

export default function DottyProgress(props: DottyProgressProps): JSX.Element {
  const { className, frozen, ...others } = props;
  return (
    <Root className={classnames(classes.root, className)} {...others}>
      <span className={classnames(classes.dot, { [classes.frozen]: frozen })} />
      <span className={classnames(classes.dot, { [classes.frozen]: frozen })} />
      <span className={classnames(classes.dot, { [classes.frozen]: frozen })} />
    </Root>
  );
}

const inlineDottyProgressStyles = { fontSize: ".25em", display: "inline-block" };
export function InlineDottyProgress(props: InlineDottyProgressProps): JSX.Element {
  return <DottyProgress {...props} style={inlineDottyProgressStyles} />;
}
