import { TransactionResponse } from "@ethersproject/abstract-provider";
import { logEvent } from "../../utils/analytics";
import { getContract } from "../../utils/contracts";
import abis from "../../contracts/abis/";
import { getLastResultValue, LAST_RESULT } from ".";
import { GetAbiByName, TransactionsContextAction, TransactionState } from "./types";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";

export async function executeStandardTx({
  currentTx,
  walletProvider,
  account,
  chainName,
  lastResults,
  dispatch,
  getAbiByName,
}: {
  currentTx: TransactionState;
  walletProvider: Web3Provider;
  account: string;
  chainName: string;
  lastResults: unknown[];
  dispatch: React.Dispatch<TransactionsContextAction>;
  getAbiByName: GetAbiByName;
}): Promise<void> {
  if (currentTx.abiName && currentTx.methodName && currentTx.contractAddress) {
    let addy = currentTx.contractAddress;
    if (addy && addy.includes(LAST_RESULT)) {
      const lastResultValue = getLastResultValue({
        requestString: addy,
        lastResults,
      }) as string;
      addy = lastResultValue;
      if (!addy) {
        return;
        // If the next transaction is a call to an address specified by the previous result, but the
        // previous result has not yet arrived, just return;
      }
    }

    const abi = getAbiByName(currentTx.abiName) || abis[currentTx.abiName];
    if (!abi) {
      throw new Error("ABI was not found.");
    }
    const contract = getContract({
      provider: walletProvider,
      address: addy,
      abi,
    });

    try {
      if (contract) {
        dispatch({
          type: "ATTEMPT_TRANSACTION",
          payload: {
            account,
            chainName,
            transactionId: currentTx.id,
          },
        });

        // Construct list of args; filling in the LAST_RESULT from previous tx if necessary
        const args = (currentTx.args || []).map(arg =>
          typeof arg === "string" && arg.includes(LAST_RESULT)
            ? getLastResultValue({ requestString: arg, lastResults })
            : arg,
        );

        let txReq: TransactionResponse | undefined = void 0;
        if (currentTx.dsProxyTargetAbiName && currentTx.dsProxyTargetAddress) {
          // This is a DSProxy transaction

          const dsProxyTargetAbi = getAbiByName(currentTx.dsProxyTargetAbiName);

          const dsProxyTargetContract = getContract({
            provider: walletProvider,
            address: currentTx.dsProxyTargetAddress,
            abi: dsProxyTargetAbi,
          });

          if (dsProxyTargetContract) {
            console.log("currentTx", currentTx);
            const _args = [
              dsProxyTargetContract.address,
              dsProxyTargetContract.interface.encodeFunctionData(currentTx.methodName, args),
            ];
            logEvent("transactionInitWallet", {
              contractAddress: currentTx.contractAddress || "",
              description: currentTx.description,
              txId: currentTx.id,
              args: JSON.stringify(_args),
              transactionParams: JSON.stringify(currentTx.transactionParams) || null,
            });
            txReq = await contract["execute(address,bytes)"](..._args, currentTx.transactionParams || {});
          }
        } else {
          // Non DSProxy transaction
          logEvent("transactionInitWallet", {
            contractAddress: currentTx.contractAddress || "",
            description: currentTx.description,
            txId: currentTx.id,
            args: JSON.stringify(args),
            transactionParams: JSON.stringify(currentTx.transactionParams) || null,
          });
          txReq = await contract[currentTx.methodName](...args, currentTx.transactionParams || {});
        }

        if (txReq) {
          logEvent("transactionSubmitted", {
            contractAddress: currentTx.contractAddress || "",
            description: currentTx.description,
            txId: currentTx.id,
            txHash: txReq.hash,
          });

          dispatch({
            type: "SUBMIT_TRANSACTION",
            payload: {
              account,
              chainName,
              transactionId: currentTx.id,
              txHash: txReq.hash,
              nonce: txReq.nonce,
            },
          });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log("eaadfadfasdfasdf", e);
      if (/* Metamask */ e.code === 4001 || /* WalletConnect */ e.message?.includes("rejected")) {
        logEvent("transactionRejected", {
          contractAddress: currentTx.contractAddress || "",
          description: currentTx.description,
          txId: currentTx.id,
        });

        dispatch({
          type: "CLEAR_TRANSACTIONS",
          payload: {
            account,
            chainName,
            batchId: currentTx.batchId,
          },
        });
      } else {
        const errorMessage = typeof e === "string" ? e : e.message;
        const errorCode = e.code;
        dispatch({
          type: "TRANSACTION_ERROR",
          payload: {
            account,
            chainName,
            transactionId: currentTx.id,
            errorMessage,
            errorCode,
          },
        });
        logEvent("transactionFailed", {
          contractAddress: currentTx.contractAddress || "",
          description: currentTx.description,
          txId: currentTx.id,
        });
      }
    }
  }
}

export async function executeERC2612PermitSign({
  currentTx,
  walletProvider,
  account,
  chainName,
  dispatch,
  lastResults,
}: {
  currentTx: TransactionState;
  walletProvider: Web3Provider;
  account: string;
  chainName: string;
  dispatch: React.Dispatch<TransactionsContextAction>;
  lastResults: unknown[];
}): Promise<void> {
  if (currentTx.contractAddress) {
    // Construct list of args; filling in the LAST_RESULT from previous tx if necessary
    const args = (currentTx.args || []).map(arg =>
      typeof arg === "string" && arg.includes(LAST_RESULT)
        ? getLastResultValue({ requestString: arg, lastResults })
        : arg,
    );

    const [ownerAddress, spenderAddress, amount, deadline] = args as [
      string,
      string,
      string | undefined,
      number | undefined,
      number | undefined,
    ];

    dispatch({
      type: "ATTEMPT_TRANSACTION",
      payload: {
        account,
        chainName,
        transactionId: currentTx.id,
      },
    });

    try {
      let result;
      if (
        // Polygon USDC
        currentTx.contractAddress.toLocaleLowerCase() ===
        "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174".toLocaleLowerCase()
      ) {
        const contract = new ethers.Contract(currentTx.contractAddress, abis["erc20Permit"], walletProvider);
        const name = await contract.name();
        const nonce = await contract.nonces(ownerAddress);

        const domain = {
          name: name,
          version: "1",
          verifyingContract: currentTx.contractAddress,
          salt: ethers.utils.hexZeroPad(ethers.BigNumber.from(137).toHexString(), 32),
        };
        const types = {
          Permit: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "nonce", type: "uint256" },
            { name: "deadline", type: "uint256" },
          ],
        };
        const value = {
          owner: ownerAddress,
          spender: spenderAddress,
          value: amount,
          nonce: nonce,
          deadline: deadline,
        };

        const populated = await ethers.utils._TypedDataEncoder.resolveNames(domain, types, value, name => {
          return walletProvider.resolveName(name) as Promise<string>;
        });

        const payload = ethers.utils._TypedDataEncoder.getPayload(populated.domain, types, populated.value);

        payload.types.EIP712Domain = [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "verifyingContract", type: "address" },
          { name: "salt", type: "bytes32" },
        ];

        const signature = await walletProvider.send("eth_signTypedData_v4", [
          ownerAddress.toLowerCase(),
          JSON.stringify(payload),
        ]);

        const { v, r, s } = ethers.utils.splitSignature(signature);

        result = {
          deadline,
          nonce,
          owner: ownerAddress,
          r,
          s,
          spender: spenderAddress,
          v,
          value,
        };
      } else if (
        // Ethereum Mainnet USDC
        currentTx.contractAddress.toLocaleLowerCase() ===
        "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48".toLocaleLowerCase()
      ) {
        const contract = new ethers.Contract(currentTx.contractAddress, abis["erc20Permit"], walletProvider);
        const name = await contract.name();
        const nonce = await contract.nonces(ownerAddress);

        const domain = {
          name: name,
          version: "2",
          chainId: ethers.utils.hexZeroPad(ethers.BigNumber.from(1).toHexString(), 32),
          verifyingContract: currentTx.contractAddress,
        };
        const types = {
          Permit: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "nonce", type: "uint256" },
            { name: "deadline", type: "uint256" },
          ],
        };
        const value = {
          owner: ownerAddress,
          spender: spenderAddress,
          value: amount,
          nonce: nonce,
          deadline: deadline,
        };

        const populated = await ethers.utils._TypedDataEncoder.resolveNames(domain, types, value, name => {
          return walletProvider.resolveName(name) as Promise<string>;
        });

        const payload = ethers.utils._TypedDataEncoder.getPayload(populated.domain, types, populated.value);

        payload.types.EIP712Domain = [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ];

        const signature = await walletProvider.send("eth_signTypedData_v4", [
          ownerAddress.toLowerCase(),
          JSON.stringify(payload),
        ]);

        const { v, r, s } = ethers.utils.splitSignature(signature);

        result = {
          deadline,
          nonce,
          owner: ownerAddress,
          r,
          s,
          spender: spenderAddress,
          v,
          value,
        };
      } else {
        const contract = new ethers.Contract(currentTx.contractAddress, abis["erc20Permit"], walletProvider);
        const name = await contract.name();
        const version = await contract.version();
        const nonce = await contract.nonces(ownerAddress);
        const { chainId } = await walletProvider.getNetwork();

        const domain = {
          name: name,
          version: version,
          chainId: ethers.utils.hexZeroPad(ethers.BigNumber.from(chainId).toHexString(), 32),
          verifyingContract: currentTx.contractAddress,
        };
        const types = {
          Permit: [
            { name: "owner", type: "address" },
            { name: "spender", type: "address" },
            { name: "value", type: "uint256" },
            { name: "nonce", type: "uint256" },
            { name: "deadline", type: "uint256" },
          ],
        };
        const value = {
          owner: ownerAddress,
          spender: spenderAddress,
          value: amount,
          nonce: nonce,
          deadline: deadline,
        };

        const populated = await ethers.utils._TypedDataEncoder.resolveNames(domain, types, value, name => {
          return walletProvider.resolveName(name) as Promise<string>;
        });

        const payload = ethers.utils._TypedDataEncoder.getPayload(populated.domain, types, populated.value);

        payload.types.EIP712Domain = [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ];

        const signature = await walletProvider.send("eth_signTypedData_v4", [
          ownerAddress.toLowerCase(),
          JSON.stringify(payload),
        ]);

        const { v, r, s } = ethers.utils.splitSignature(signature);

        result = {
          deadline,
          nonce,
          owner: ownerAddress,
          r,
          s,
          spender: spenderAddress,
          v,
          value,
        };
      }

      dispatch({
        type: "SET_TRANSACTION_RAW_RESULT",
        payload: {
          account,
          chainName,
          transactionId: currentTx.id,
          result,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (/* Metamask */ e.code === 4001 || /* WalletConnect */ e.message?.includes("rejected")) {
        logEvent("transactionRejected", {
          contractAddress: currentTx.contractAddress || "",
          description: currentTx.description,
          txId: currentTx.id,
        });

        dispatch({
          type: "CLEAR_TRANSACTIONS",
          payload: {
            account,
            chainName,
            batchId: currentTx.batchId,
          },
        });
      } else {
        const errorMessage = typeof e === "string" ? e : e.message;
        const errorCode = e.code;
        dispatch({
          type: "TRANSACTION_ERROR",
          payload: {
            account,
            chainName,
            transactionId: currentTx.id,
            errorMessage,
            errorCode,
          },
        });
        logEvent("transactionFailed", {
          contractAddress: currentTx.contractAddress || "",
          description: currentTx.description,
          txId: currentTx.id,
        });
      }
    }
  }
}
