import { useContext, useEffect, useMemo } from "react";
import { Context as TransactionContext } from "../../contexts/transactionsBase";
import { Callback, UseTransactionCompleteWithoutContextProps } from "./types";

const confirmedTxs = JSON.parse(window.localStorage.getItem("confirmedTxs") || "{}") as Record<string, true>;
const saveStateToLocalStorage = () => {
  window.localStorage.setItem("confirmedTxs", JSON.stringify(confirmedTxs));
};
window.addEventListener("beforeunload", saveStateToLocalStorage);

export function useTransactionCompleteWithoutContext({
  notifyTopic,
  getTransactions,
  callback,
}: UseTransactionCompleteWithoutContextProps): void {
  const txs = getTransactions();
  const tx = useMemo(() => {
    return txs
      .filter(t => !confirmedTxs[t.id] && !t.error)
      .find(t => {
        return t.notifyTopic === notifyTopic;
      });
  }, [txs, notifyTopic]);

  useEffect(() => {
    if (
      tx &&
      // Standard transaction
      ((tx.abiName && tx.requiredConfirmations <= tx.confirmations && !confirmedTxs[tx.id]) ||
        // Permit signature
        (!tx.abiName && typeof tx.rawResult !== "undefined"))
    ) {
      confirmedTxs[tx.id] = true;
      callback({ ...tx.callbackArgs, txHash: tx.txHash, id: tx.id });
    }
  }, [tx, callback]);
}

export default function useTransactionComplete(notifyTopic: string, callback: Callback): void {
  const { getTransactions } = useContext(TransactionContext);
  return useTransactionCompleteWithoutContext({ notifyTopic, getTransactions, callback });
}
