import { MulticallBatcher } from "contexts/web3Base";
import { BigNumber } from "ethers";
import { getHifiPoolMulticallContract } from "../../utils/contracts";

export default async function ({
  poolAddress,
  underlyingIn,
}: {
  poolAddress: string;
  underlyingIn: BigNumber;
}): Promise<BigNumber> {
  const poolContract = getHifiPoolMulticallContract({ address: poolAddress });
  return MulticallBatcher.queue(poolContract.getQuoteForSellingUnderlying(underlyingIn));
}
