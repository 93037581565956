import { useState, useEffect, createContext, useCallback, useReducer } from "react";
import groupBy from "lodash/groupBy";
import { v4 as uuidv4 } from "uuid";
import deepFreeze from "../../utils/deepFreeze";
import get from "lodash/get";
import { logEvent } from "../../utils/analytics";

import {
  TransactionsState,
  TransactionsContextAction,
  AddTransactions,
  CancelTransaction,
  SetTransactionResult,
  GetTransactions,
  TransactionContext,
  GetBatchName,
  TransactionsContextProviderProps,
  GetTransaction,
  IsTransactionQueued,
  ClearAllTransactions,
  AddTransactionTransactionParam,
  AddErc2612PermitSignatureParam,
  TransactionState,
  TransactionsAccountState,
} from "./types";
import { executeERC2612PermitSign, executeStandardTx } from "./executeTransaction";

export const LAST_RESULT = "LAST_RESULT";

const initialTransactionState: TransactionState = deepFreeze<TransactionState>({
  id: "",
  contractAddress: "",
  description: "",
  abiName: "",
  methodName: "",
  args: [],
  notifyTopic: "",
  txHash: "",
  batchId: "",
  requiredConfirmations: 1,
  confirmations: 0,
  error: false,
  errorMessage: "",
  attempted: false,
});

const initialAccountState: TransactionsAccountState = deepFreeze<TransactionsAccountState>({
  transactionList: [],
  transactions: {},
  batchNames: {},
});

function updateTransaction(
  state: TransactionsState,
  account: string,
  chainName: string,
  txId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
): TransactionsState {
  const accountState = state[`${chainName}-${account}`] || initialAccountState;
  try {
    return deepFreeze<TransactionsState>({
      ...state,
      [`${chainName}-${account}`]: {
        ...accountState,
        transactionList: accountState.transactionList,
        transactions: {
          ...accountState.transactions,
          [txId]: {
            ...accountState.transactions[txId],
            ...payload,
          },
        },
      },
    });
  } catch (e) {
    return state;
  }
}

function deleteFromObj<T>(obj: T, key: string): T {
  const copy = { ...obj };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (copy as any)[key];
  return copy;
}

const Reducer = (state: TransactionsState, action: TransactionsContextAction): TransactionsState => {
  switch (action.type) {
    case "ADD_TRANSACTIONS":
      return deepFreeze<TransactionsState>({
        ...state,
        [`${action.payload.chainName}-${action.payload.account}`]: {
          transactionList: [
            ...(state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState).transactionList,
            ...action.payload.transactions.map(tx => tx.id),
          ],
          transactions: {
            ...(state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState).transactions,
            ...action.payload.transactions.reduce((memo, tx) => ({ ...memo, [tx.id]: tx }), {}),
          },
          batchNames: {
            ...(state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState).batchNames,
            [action.payload.batchId]: action.payload.batchName,
          },
        },
      });
    case "CLEAR_ALL_TRANSACTIONS":
      return deepFreeze<TransactionsState>({
        ...state,
        [`${action.payload.chainName}-${action.payload.account}`]: initialAccountState,
      });
    case "CLEAR_TRANSACTIONS":
      return deepFreeze<TransactionsState>({
        ...state,
        [`${action.payload.chainName}-${action.payload.account}`]: {
          transactionList: (
            state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState
          ).transactionList.filter(txId => {
            return (
              (state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState).transactions[txId]
                .batchId !== action.payload.batchId
            );
          }),
          transactions: Object.keys(
            (state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState).transactions,
          ).reduce((memo, txId) => {
            const tx = (state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState)
              .transactions[txId];
            if (tx.batchId === action.payload.batchId) return memo;
            return { ...memo, [tx.id]: tx };
          }, {}),
          batchNames: deleteFromObj(
            (state[`${action.payload.chainName}-${action.payload.account}`] || initialAccountState).batchNames,
            action.payload.batchId,
          ),
        },
      });
    case "SUBMIT_TRANSACTION":
      return updateTransaction(state, action.payload.account, action.payload.chainName, action.payload.transactionId, {
        txHash: action.payload.txHash,
        nonce: action.payload.nonce,
      });
    case "ATTEMPT_TRANSACTION":
      return updateTransaction(state, action.payload.account, action.payload.chainName, action.payload.transactionId, {
        attempted: true,
      });
    case "UPDATE_TRANSACTION_CONFIRMATION":
      return updateTransaction(state, action.payload.account, action.payload.chainName, action.payload.transactionId, {
        confirmations: action.payload.confirmations,
      });
    case "TRANSACTION_ERROR":
      return updateTransaction(state, action.payload.account, action.payload.chainName, action.payload.transactionId, {
        error: true,
        errorMessage: action.payload.errorMessage || "",
        errorCode: action.payload.errorCode,
      });
    case "SET_TRANSACTION_RESULT":
      return updateTransaction(state, action.payload.account, action.payload.chainName, action.payload.transactionId, {
        result: action.payload.result,
      });
    case "SET_TRANSACTION_RAW_RESULT":
      return updateTransaction(state, action.payload.account, action.payload.chainName, action.payload.transactionId, {
        rawResult: action.payload.result,
      });
    case "SET_STATE":
      return deepFreeze<TransactionsState>(action.payload);

    default:
      return state;
  }
};

const initialState = {};

function _isTxCurrent(tx: TransactionState) {
  return (
    // ERC-2612 signature
    (!tx.abiName &&
      !tx.attempted &&
      tx.methodName?.includes("signERC2612Permit") &&
      typeof tx.rawResult === "undefined") ||
    // Standard tx
    (!!tx.abiName && (!tx.txHash || tx.confirmations < tx.requiredConfirmations))
  );
}

function _getCurrentBatchTransaction(batch: Array<TransactionState>) {
  try {
    const currentTx = batch.find(tx => {
      return _isTxCurrent(tx) && batch.filter(t => t.batchId === tx.batchId).every(t => !t.error);
    });
    return currentTx;
  } catch (e) {
    return;
  }
}

// function _getCurrentTransaction(state, account, chainName) {
//   return _getCurrentBatchTransaction(_getTransactions(state, account, chainName));
// }

function _getTransaction(state: TransactionsState, txId: string, account: string, chainName: string) {
  return state[`${chainName}-${account}`]?.transactions[txId];
}

function _getTransactions(state: TransactionsState, account: string, chainName: string) {
  if (!account) return [];

  try {
    return state[`${chainName}-${account}`].transactionList.map(txId =>
      _getTransaction(state, txId, account, chainName),
    );
  } catch (e) {
    return [];
  }
}

function _getLatestTransactionNonce(state: TransactionsState, account: string, chainName: string) {
  const nonces = _getTransactions(state, account, chainName).map(tx => (typeof tx.nonce === "number" ? tx.nonce : -1));
  return nonces.length ? Math.max(...nonces) : -1;
}

// function _getTransactionBatch(state: TransactionsState, txId: string, account: string, chainName: string) {
//   const txs = _getTransactions(state, account, chainName);
//   const tx = _getTransaction(state, txId, account, chainName);
//   return txs.filter(t => t.batchId === tx.batchId);
// }

function _getLastResults(state: TransactionsState, txId: string, account: string, chainName: string) {
  const txs = _getTransactions(state, account, chainName);
  const index = txs.findIndex(t => t.id === txId);
  const lastResults = [];
  for (let i = index - 1; i >= 0; --i) {
    // Search in reverse order
    lastResults.push(txs[i].result);
  }
  return lastResults;
}

export function getLastResultValue({
  requestString,
  lastResults,
}: {
  requestString: string;
  lastResults: unknown[];
}): unknown {
  const depth = parseInt(requestString.split(".")[0].split("-")[1], 10) || 0;
  const lastResult = lastResults[depth];
  const accessor = requestString.split(".")[1];
  return get(lastResult, accessor, lastResult);
}

export const Context = createContext<TransactionContext>({
  addTransactions: () => [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getTransactions: () => [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancelTransaction: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearAllTransactions: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTransactionResult: () => {},
  getBatchName: () => void 0,
  getTransaction: () => void 0,
  isTransactionQueued: () => false,
});
export const Provider = ({
  children,
  account,
  provider,
  walletProvider,
  chainName,
  getAbiByName,
}: TransactionsContextProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  const [initialized, setInitialized] = useState(false);

  const addTransactions: AddTransactions = useCallback(
    ({ transactions, batchName }) => {
      const batchId = uuidv4();
      const txIds = transactions.map(() => uuidv4());
      dispatch({
        type: "ADD_TRANSACTIONS",
        payload: {
          account,
          chainName,
          transactions: transactions.map((tx, i) => {
            if ("abiName" in tx || ("dsProxyTargetAbiName" in tx && "dsProxyTargetAddress" in tx)) {
              const txObj = tx as AddTransactionTransactionParam;
              return {
                ...initialTransactionState,
                ...txObj,
                id: txIds[i],
                batchId,
              };
            } else {
              const _tx = tx as AddErc2612PermitSignatureParam;
              return {
                ...initialTransactionState,
                contractAddress: _tx.contractAddress,
                methodName: "signERC2612Permit",
                description: _tx.description,
                args: [_tx.ownerAddress, _tx.spenderAddress, _tx.amount, _tx.deadline, _tx.nonce],
                id: txIds[i],
                batchId,
                notifyTopic: _tx.notifyTopic,
              };
            }
          }),
          batchName,
          batchId,
        },
      });
      return txIds;
    },
    [account, chainName],
  );

  const cancelTransaction: CancelTransaction = useCallback(
    tx => {
      if (tx.batchId) {
        dispatch({
          type: "CLEAR_TRANSACTIONS",
          payload: {
            account,
            chainName,
            batchId: tx.batchId,
          },
        });
      }
    },
    [account, chainName],
  );

  const clearAllTransactions: ClearAllTransactions = useCallback(() => {
    dispatch({
      type: "CLEAR_ALL_TRANSACTIONS",
      payload: {
        account,
        chainName,
      },
    });
  }, [account, chainName]);

  const getTransactions: GetTransactions = useCallback(() => {
    return _getTransactions(state, account, chainName);
  }, [state, account, chainName]);

  const getBatchName: GetBatchName = useCallback(
    batchId => {
      return state[`${chainName}-${account}`]?.batchNames[batchId];
    },
    [state, chainName, account],
  );

  const getTransaction: GetTransaction = useCallback(
    id => {
      return state[`${chainName}-${account}`]?.transactions[id];
    },
    [account, chainName, state],
  );

  const setTransactionResult: SetTransactionResult = useCallback(
    (txId, result) => {
      if (getTransaction(txId)) {
        dispatch({
          type: "SET_TRANSACTION_RESULT",
          payload: {
            account,
            chainName,
            transactionId: txId,
            result,
          },
        });
      }
    },
    [account, chainName, getTransaction],
  );

  const isTransactionQueued: IsTransactionQueued = useCallback(
    (filterOptions: { methodName: string; contractAddress: string }) => {
      const transactions = _getTransactions(state, account, chainName);
      return !!transactions.find(t => {
        const filterPassed = (Object.keys(filterOptions) as Array<keyof typeof filterOptions>).every(optionKey => {
          if (optionKey === "contractAddress") {
            return t.contractAddress === filterOptions.contractAddress;
          } else if (optionKey === "methodName") {
            return t.methodName === filterOptions.methodName;
          }
        });
        const isPending = (!t.txHash || t.requiredConfirmations > t.confirmations) && !t.error;
        return filterPassed && isPending;
      });
    },
    [account, chainName, state],
  );

  useEffect(
    function processTxs() {
      const accountState = state[`${chainName}-${account}`];
      if (!accountState || !account || !chainName) return;

      async function _processTxs(transactions: Array<TransactionState>) {
        const currentTx = _getCurrentBatchTransaction(transactions);
        if (!currentTx) {
          // All txs have been processed, do nothing
          // if (!batch.length) return;
          return;
        } else if (walletProvider) {
          if (!currentTx.txHash && !currentTx.error && !currentTx.attempted) {
            const lastResults = _getLastResults(state, currentTx.id, account, chainName);
            // If the next transaction uses a LAST_RESULT as an arg and LAST_RESULT is not yet defined, just return.
            // This function will get called again when LAST_RESULT is ready.
            if (
              (currentTx.args || []).some(arg => {
                const usesLastResult = typeof arg === "string" && arg.includes(LAST_RESULT);
                if (usesLastResult) {
                  const lastResultValue = getLastResultValue({
                    requestString: arg,
                    lastResults,
                  });
                  return typeof lastResultValue === "undefined";
                }
              })
            )
              return;

            if (
              !currentTx.abiName &&
              currentTx.methodName?.includes("signERC2612Permit") &&
              currentTx.contractAddress
            ) {
              // ERC-2612 signature
              executeERC2612PermitSign({
                currentTx,
                walletProvider,
                account,
                chainName,
                dispatch,
                lastResults,
              });
            } else if (currentTx.abiName && currentTx.methodName) {
              // Standard transaction
              executeStandardTx({
                currentTx,
                walletProvider,
                account,
                chainName,
                lastResults,
                dispatch,
                getAbiByName,
              });
            }
          } else if (currentTx.txHash && walletProvider) {
            const receipt = await walletProvider.waitForTransaction(currentTx.txHash, currentTx.confirmations + 1);
            if (currentTx.confirmations + 1 >= currentTx.requiredConfirmations) {
              logEvent("transactionConfirmed", {
                contractAddress: currentTx.contractAddress || "",
                description: currentTx.description,
                txId: currentTx.id,
                txHash: currentTx.txHash,
              });
            }
            if (receipt.status) {
              dispatch({
                type: "UPDATE_TRANSACTION_CONFIRMATION",
                payload: {
                  account,
                  chainName,
                  transactionId: currentTx.id,
                  confirmations: receipt.confirmations,
                },
              });
            } else {
              dispatch({
                type: "TRANSACTION_ERROR",
                payload: {
                  account,
                  chainName,
                  transactionId: currentTx.id,
                  errorMessage: "Transaction reverted.",
                },
              });
              logEvent("transactionReverted", {
                contractAddress: currentTx.contractAddress || "",
                description: currentTx.description,
                txId: currentTx.id,
                txHash: currentTx.txHash,
              });
            }
          }
        }
      }
      _processTxs(_getTransactions(state, account, chainName));
    },
    [state, chainName, account, walletProvider, getAbiByName],
  );

  const saveStateToLocalStorage = useCallback(() => {
    window.localStorage.setItem("transactionsState", JSON.stringify(state));
  }, [state]);

  useEffect(
    function beforeunload() {
      try {
        window.addEventListener("beforeunload", saveStateToLocalStorage);

        return () => window.removeEventListener("beforeunload", saveStateToLocalStorage);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    [saveStateToLocalStorage],
  );

  useEffect(
    function restoreState() {
      if (!account || initialized || !chainName) return;

      async function _restoreState() {
        try {
          const localStorageState = window.localStorage.getItem("transactionsState");
          if (localStorageState) {
            const parsedState: TransactionsState = JSON.parse(localStorageState);
            let accounts = Object.keys(parsedState);
            for (let i = 0; i < accounts.length; ++i) {
              const acc = accounts[i].split("-")[1];
              try {
                const nextAccountNouce = await provider.getTransactionCount(acc);
                const latestTxNonce = _getLatestTransactionNonce(parsedState, acc, chainName);
                if (latestTxNonce <= -1 || latestTxNonce + 1 < nextAccountNouce) {
                  // Do not restore the account's tx data from local cache if our cached version of txs is
                  // outdated relative to the account. We compare the nonce of the account with the latest
                  // nonce of our cached txs.
                  delete parsedState[`${chainName}-${acc}`];
                }
              } catch (e) {
                continue;
              }
            }

            dispatch({
              type: "SET_STATE",
              payload: parsedState,
            });

            accounts = Object.keys(parsedState);
            accounts.forEach(key => {
              const badTxs: Array<TransactionState> = [];
              Object.keys(parsedState[key].transactions || []).forEach(txId => {
                const tx = parsedState[key].transactions[txId];
                if (
                  // Standard tx that was attempted (submitted to wallet) but not confirmed by user
                  (!!tx.abiName && tx.attempted && !tx.txHash) ||
                  // Signature request tx that was attempted (submitted to wallet) but not confirmed by user
                  (!tx.abiName && tx.attempted && !tx.rawResult)
                ) {
                  badTxs.push(tx);
                }
              });
              const batches = groupBy(badTxs, tx => tx.batchId);
              Object.keys(batches).forEach(batchId => {
                dispatch({
                  type: "CLEAR_TRANSACTIONS",
                  payload: {
                    account: key.split("-")[1],
                    chainName,
                    batchId,
                  },
                });
              });
            });

            setInitialized(true);
          }
        } catch (e) {
          console.log(e);
        }
      }
      _restoreState();
    },
    [dispatch, account, chainName, initialized, provider],
  );

  return (
    <Context.Provider
      value={{
        addTransactions,
        getTransactions,
        cancelTransaction,
        setTransactionResult,
        getBatchName,
        getTransaction,
        isTransactionQueued,
        clearAllTransactions,
      }}
    >
      {children}
    </Context.Provider>
  );
};
