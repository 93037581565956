import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { QueryObserverResult, useQuery } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import getQuoteForBuyingUnderlying from "../../api/getQuoteForBuyingUnderlying";
import isExpired from "../../utils/isExpired";
import useMemoRef from "hooks/useMemoRef";

export function buildQueryKey({
  hifiPoolAddress,
  underlyingOut,
}: {
  hifiPoolAddress: string;
  underlyingOut: string;
}): [string, string, string] {
  return ["quoteForBuyingUnderlying", hifiPoolAddress, underlyingOut];
}

export default function useQuoteForBuyingUnderlying({
  underlyingOut,
  hifiPool,
}: {
  underlyingOut: string;
  hifiPool: HifiPool;
}): QueryObserverResult<string | undefined> {
  const { data, status, ...others } = useQuery(
    buildQueryKey({ hifiPoolAddress: hifiPool.id, underlyingOut }),
    ({ queryKey }) => {
      if (parseUnits(queryKey[2], hifiPool.hToken.underlying.decimals).gt(BigNumber.from("0"))) {
        return getQuoteForBuyingUnderlying({
          poolAddress: queryKey[1],
          underlyingOut: parseUnits(queryKey[2] || "0", hifiPool.hToken.underlying.decimals),
        });
      } else {
        return BigNumber.from("0");
      }
    },
    {
      cacheTime: 0.5 * 60 * 1000,
      staleTime: 0.5 * 60 * 1000,
      refetchInterval: 0.5 * 60 * 1000,
      enabled:
        !!underlyingOut &&
        parseUnits(underlyingOut, hifiPool.hToken.underlying.decimals).gte(BigNumber.from("0")) &&
        !isExpired(hifiPool),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const _data = useMemoRef(
    lastValue => {
      if (status == "idle") {
        return;
      } else if (data && status === "success") {
        return formatUnits(data, hifiPool.hToken.decimals);
      }
      return lastValue;
    },
    [data, hifiPool, status],
  );
  return { data: _data, status, ...others } as QueryObserverResult<string | undefined>;
}
