import { BigNumber } from "ethers";
import { MulticallBatcher } from "../../contexts/web3Base";
import { getErc20MulticallContract } from "../../utils/contracts";

const getAccountErc20Balance = async ({
  erc20Address,
  account,
}: {
  erc20Address: string;
  account: string;
}): Promise<BigNumber | undefined> => {
  const contract = getErc20MulticallContract({ address: erc20Address });
  return await MulticallBatcher.queue(contract.balanceOf(account));
};

export default getAccountErc20Balance;
