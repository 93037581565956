import { MulticallBatcher } from "contexts/web3Base";
import { getMulticallContractByName } from "../../utils/contracts";
import BPromise from "bluebird";
import { CollateralMap } from "../../hooks/useCollateralMap/types";
import { formatUnits } from "@ethersproject/units";
import { UserLockedCollaterals } from "./types";
import deepFreeze from "utils/deepFreeze";

const getUserLockedCollaterals = async ({
  chainName,
  account,
  collateralMap,
}: {
  chainName: string;
  account: string;
  collateralMap: CollateralMap | undefined;
}): Promise<UserLockedCollaterals> => {
  if (!collateralMap || Object.keys(collateralMap).length === 0) {
    return {};
  }
  const balanceSheetContract = getMulticallContractByName({ contractName: "balanceSheet", chainName });
  const collateralList: string[] = await MulticallBatcher.queue(balanceSheetContract.getCollateralList(account));
  const amounts: string[] = await BPromise.map(collateralList, collateralAddress => {
    const promise = MulticallBatcher.queue(balanceSheetContract.getCollateralAmount(account, collateralAddress));
    return promise;
  });
  return deepFreeze(
    collateralList.reduce((memo, collateralAddress, i) => {
      const decimals = collateralMap[collateralAddress.toLowerCase()].decimals;
      const amount = amounts[i];
      return { ...memo, [collateralAddress.toLowerCase()]: formatUnits(amount, decimals) };
    }, {}),
  );
};

export default getUserLockedCollaterals;
