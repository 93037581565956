import { useQuery } from "react-query";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import useClientIpAddress from "../useClientIpAddress";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { useCallback } from "react";
import { QueryResponse } from "hooks/useCombinedQueryHooks/types";

type GeoLocationRequest = {
  ipAddress?: string;
};

export type GeoLocationResponse = {
  country_code2: string;
};

export const getGeoLocationKey = (ipAddress?: string): ["geoLocation", string | undefined] => {
  return ["geoLocation", ipAddress];
};

export default function useGeoLocation(): QueryResponse<GeoLocationResponse> {
  const functions = useFunctions();
  const getGeoLocationFunction = httpsCallable<GeoLocationRequest, GeoLocationResponse>(
    functions,
    "getIpAddressGeoLocation",
  );

  const ipRes = useClientIpAddress();
  const { data: ipAddress } = ipRes;

  const geoRes = useQuery<GeoLocationResponse, Error>(
    getGeoLocationKey(ipAddress),
    async () => {
      const request: GeoLocationRequest = { ipAddress };
      const response = await getGeoLocationFunction(request);
      return response.data;
    },
    {
      retry: 3,
      enabled: !!ipAddress,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return useCombinedQueryHooks(
    [ipRes, geoRes],
    useCallback(data => {
      const [, geoResponse]: [string, GeoLocationResponse] = data;
      return geoResponse;
    }, []),
  );
}
