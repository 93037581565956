import TokenIcon from "../../icons/TokenIcon";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import { Collateral } from "../../api/getCollaterals/types";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import IconTypography from "../IconTypography";
import { numberWithCommas } from "utils/numbers";

export default function MyCollateralRow({
  collateral,
  onCollateralClick,
  amountLocked,
}: {
  collateral: Collateral;
  onCollateralClick: (collateral: Collateral) => void;
  amountLocked: string;
}): JSX.Element {
  const symbol = collateral.symbol as TokenSymbol;

  return (
    <TableRow hover onClick={() => onCollateralClick(collateral)}>
      <TableCell align="left">
        <IconTypography variant="h5">
          <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          {symbol}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {numberWithCommas(amountLocked)} {collateral.symbol}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
