import { QueryObserverResult, useQuery, UseQueryOptions } from "react-query";
import getTokenPrice from "../../api/getTokenPrice";

export default function useTokenPrice({
  chainName,
  symbol,
}: {
  chainName: string;
  symbol: string;
}): QueryObserverResult<string> {
  return useQuery(["tokenPrice", symbol], () => getTokenPrice({ chainName, symbol }), {
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export function buildQueryObject({ chainName, symbol }: { chainName: string; symbol: string }): UseQueryOptions {
  return {
    queryKey: ["tokenPrice", symbol],
    queryFn: () => getTokenPrice({ chainName, symbol }),
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
  };
}
