import { memo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table, { TableContainer } from "../../components/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useTranslation } from "react-i18next";
import { HifiPool } from "../../api/getHifiPools/types";
import { BigNumber } from "ethers";
import IconTypography from "../IconTypography";
import { TokenSymbol } from "../../icons/TokenIcon/types";
import { numberWithCommas, truncateDecimals } from "utils/numbers";
import TokenIcon from "../../icons/TokenIcon";
import { formatUnits } from "ethers/lib/utils";
import TableHeader from "../TableHeader";
import { UserLendingPosition } from "../../hooks/useUserLendingPositions/types";

function MyRedeemRow({
  hifiPool,
  onRedeemClick,
  balance,
}: {
  hifiPool: HifiPool;
  onRedeemClick: (hifiPool: HifiPool) => void;
  balance: BigNumber;
}) {
  const symbol = hifiPool.hToken.underlying.symbol as TokenSymbol;
  return (
    <TableRow hover onClick={() => onRedeemClick(hifiPool)}>
      <TableCell align="left">
        <IconTypography variant="h5">
          <Box display="flex" alignItems="center">
            <TokenIcon style={{ marginRight: ".5em" }} symbol={symbol} />
          </Box>
          {symbol}
        </IconTypography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{new Date(hifiPool.hToken.maturity * 1000).toLocaleDateString()}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">
          {truncateDecimals(numberWithCommas(formatUnits(balance, hifiPool.hToken.decimals)), 2)}{" "}
          {hifiPool.hToken.underlying.symbol}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function MyRedeemableTable({
  myRedeemable,
  onRedeemClick,
}: {
  myRedeemable: UserLendingPosition[];
  onRedeemClick: (hifiPool: HifiPool) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item lg={9} md={9} sm={11} xs={12}>
      <TableHeader variant="body2">{t("Redeem")}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body1" color="textSecondary">
                  {t("Asset")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Maturity Date")}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ width: "33%" }}>
                <Typography variant="body1" color="textSecondary">
                  {t("Balance")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myRedeemable.map(market => {
              return (
                <MyRedeemRow
                  key={market.hifiPool.id}
                  hifiPool={market.hifiPool}
                  onRedeemClick={onRedeemClick}
                  balance={market.hTokenBalance}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(MyRedeemableTable);
