import { useContext, useEffect } from "react";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import { TransactionState } from "contexts/transactionsBase/types";
import { Context as TransactionsDialogContext } from "../../contexts/transactionsDialog";

export default function usePendingTransaction({
  transactionId,
  onClose,
}: {
  transactionId: string;
  onClose: () => void;
}): TransactionState | undefined {
  const { getTransaction } = useContext(TransactionsContext);
  const { isTaskDialogOpen, getClosedBatches } = useContext(TransactionsDialogContext);
  const transaction = getTransaction(transactionId);

  useEffect(() => {
    if (
      // If transaction is completed, then close dialog
      // typeof transaction?.confirmations === "number" &&
      // transaction?.confirmations >= transaction?.requiredConfirmations

      // If transaction was submitted, then close the dialog
      transaction?.txHash
    ) {
      onClose();
    }
  }, [isTaskDialogOpen, transaction, onClose]);

  return transaction &&
    !transaction.error &&
    // If transaction was represented in a task dialog, make sure the task dialog wasn't closed
    !getClosedBatches()[transaction.batchId]
    ? transaction
    : void 0;
}
