import { parseUnits, formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";

export function truncateDecimals(numberString: string | number, decimals: number): string {
  numberString = `${numberString}`;
  const floatVal = parseFloat(numberString);
  if (!isNaN(floatVal)) {
    const valueParts = numberString.split(".");
    const decimalStr = valueParts[1] ? `${valueParts[1].substring(0, decimals)}` : "";
    if (decimals === 2) {
      const decimalsString = `${decimalStr}${"".padEnd(decimals - decimalStr.length, "0")}`;
      return `${valueParts[0]}.${decimalsString}`;
    } else {
      const isDecimalZero = decimalStr.split("").every(char => char === "0");
      const decimalsString = valueParts[1] && !isDecimalZero ? `.${decimalStr}` : "";
      return decimals >= 0 ? `${valueParts[0]}${decimalsString}` : numberString;
    }
  }
  return "";
}

export function multiplyBigNumberByScalar(bigNum1: BigNumber, scalar: number, decimals: number): BigNumber {
  const multiplierBn = parseUnits(truncateDecimals(scalar, decimals), decimals);
  const product = bigNum1.mul(multiplierBn);
  const formattedProduct = formatUnits(product, decimals * 2);
  return parseUnits(truncateDecimals(formattedProduct, decimals), decimals);
}

export function numberWithCommas(num: number | string): string {
  const numStr = num.toString();
  let decimalPart = "";
  const wholeNum = Math.floor(parseFloat(numStr));
  if (numStr.indexOf(".") != -1) {
    decimalPart = "." + numStr.split(".")[1];
  }
  const array = wholeNum.toString().split("");
  let index = -3;
  while (array.length + index > 0) {
    array.splice(index, 0, ",");
    // Decrement by 4 since we just added another unit to the array.
    index -= 4;
  }
  return array.join("") + decimalPart;
}
