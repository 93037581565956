import { Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "ethers";

const getUserEthBalance = async (walletProvider: Web3Provider | undefined): Promise<BigNumber | undefined> => {
  if (walletProvider) {
    const address = await walletProvider.getSigner().getAddress();
    return await walletProvider.getBalance(address);
  }
};

export default getUserEthBalance;
