import { Fragment, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import ConnectWalletButton from "../ConnectWalletButton";
import Logo from "../../icons/Logo";
import { useLocation, useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useWindowBreakpoint from "hooks/useWindowBreakpoint";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { LinkProps, NavLinkProps } from "./types";
import { useTranslation } from "react-i18next";
import useCanLend from "../../hooks/useCanLend";

const PREFIX = "AppBar";

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  chosen: `${PREFIX}-chosen`,
  connectBtn: `${PREFIX}-connectBtn`,
  logo: `${PREFIX}-logo`,
  hamburger: `${PREFIX}-hamburger`,
};

const StyledMuiAppBar = styled(MuiAppBar)(({ theme }) => ({
  [`& .${classes.toolbar}`]: {
    flexWrap: "wrap",
  },

  [`& .${classes.chosen}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.connectBtn}`]: {
    marginLeft: theme.spacing(4),
  },

  [`& .${classes.logo}`]: {
    cursor: "pointer",
  },

  [`& .${classes.hamburger}`]: {
    marginLeft: theme.spacing(2),
    "& .MuiSvgIcon-root": { fontSize: "2.6rem", padding: ".4rem" },
  },
}));

const LINK_PREFIX = "NavLink";

const linkClasses = {
  link: `${LINK_PREFIX}-link`,
};

const StyledLink = styled(Link)<LinkProps>(({ theme, chosen, text }) => ({
  [`&.${linkClasses.link}`]: {
    position: "relative",
    fontWeight: "bold",
    color: "transparent",
    textDecoration: "none",
    margin: theme.spacing(0, 6),
    cursor: "pointer",
    outline: "none !important",
    "&:focus, &:hover": {
      "&::before": {
        // borderBottom: chosen ? `2px solid ${theme.palette.purpleText}` : `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.purpleText,
      },
    },
    "&::before": {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      content: `"${text}"`,
      color: chosen ? theme.palette.purpleText : theme.palette.text.primary,
      borderBottom: chosen ? `1px solid ${theme.palette.purpleText}` : "none",
      fontWeight: chosen ? "bold" : "600",
    },
  },
}));

const NavLink = ({ to, children, refresh }: NavLinkProps) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <StyledLink
      className={linkClasses.link}
      variant="body1"
      component="button"
      tabIndex={0}
      chosen={location.pathname.includes(to)}
      text={children as string}
      onClick={() => {
        if (refresh) {
          window.location.href = `${window.location.protocol}//${window.location.host}${to}`;
        } else {
          history.push(to);
        }
      }}
    >
      {children}
    </StyledLink>
  );
};

function AppBar(): JSX.Element {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const history = useHistory();
  const bp = useWindowBreakpoint();
  const isSmall = bp === "sm" || bp === "xs";

  const { t } = useTranslation();

  const canLend = useCanLend();

  return (
    <StyledMuiAppBar position="static" color="default" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box flexGrow={1}>
          <Link href="http://hifi.finance" className={classes.logo}>
            <Logo style={{ fontSize: "5.5rem" }} />
          </Link>
        </Box>
        <Box display="flex" alignItems="center">
          {!isSmall && (
            <Fragment>
              {/* <NavLink to="/dashboard">{t("Dashboard")}</NavLink> */}
              <NavLink to="/borrow">{t("Borrow")}</NavLink>
              {canLend && <NavLink to="/lend">{t("Lend")}</NavLink>}
              <NavLink to="/pool">{t("Pool")}</NavLink>
            </Fragment>
          )}
          <ConnectWalletButton className={classes.connectBtn}>Connect Wallet</ConnectWalletButton>
          {isSmall && (
            <IconButton
              className={classes.hamburger}
              edge="end"
              size="small"
              color="inherit"
              onClick={() => setDrawerOpened(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      {/* zIndex one higher than the app bar when the app bar is floating */}
      <Drawer style={{ zIndex: 1302 }} onClose={() => setDrawerOpened(false)} open={drawerOpened} anchor="right">
        <List>
          {/* <ListItem
            color="primary"
            onClick={() => {
              history.push("/dashboard");
              setDrawerOpened(false);
            }}
            button
          >
            <ListItemText>
              <Typography variant="h4">{t("Dashboard")}</Typography>
            </ListItemText>
          </ListItem> */}
          <ListItem
            onClick={() => {
              history.push("/borrow");
              setDrawerOpened(false);
            }}
            button
          >
            <ListItemText style={{ paddingRight: 30, paddingLeft: 10 }}>
              <Typography variant="h4">{t("Borrow")}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push("/lend");
              setDrawerOpened(false);
            }}
            button
          >
            <ListItemText style={{ paddingRight: 30, paddingLeft: 10 }}>
              <Typography variant="h4">{t("Lend")}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push("/pool");
              setDrawerOpened(false);
            }}
            button
          >
            <ListItemText style={{ paddingRight: 30, paddingLeft: 10 }}>
              <Typography variant="h4">{t("Pool")}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </StyledMuiAppBar>
  );
}

AppBar.displayName = "AppBar";

export default AppBar;
