import { MulticallBatcher } from "../../contexts/web3Base";
import { getErc20MulticallContract } from "../../utils/contracts";
import deepFreeze from "../../utils/deepFreeze";
import { Erc20Token } from "./types";

const getErc20 = async (address: string): Promise<Erc20Token> => {
  const erc20Contract = getErc20MulticallContract({ address });

  return new Promise(async (resolve, reject) => {
    try {
      const res = await Promise.all([
        MulticallBatcher.queue(erc20Contract.decimals()),
        MulticallBatcher.queue(erc20Contract.name()),
        MulticallBatcher.queue(erc20Contract.symbol()),
      ]);
      resolve(
        deepFreeze({
          id: address.toLowerCase(),
          decimals: res[0],
          name: res[1],
          symbol: res[2],
        }),
      );
    } catch (e) {
      reject(e);
    }
  });
};

export default getErc20;
