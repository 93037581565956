import { useContext, useCallback } from "react";
import useTransactionComplete from "hooks/useTransactionComplete";
import { Context as Web3Context } from "../../contexts/web3";
import useUserDSProxy from "../../hooks/useUserDSProxy";
import useUserLockedCollaterals from "../../hooks/useUserLockedCollaterals";
import { Context as TransactionsContext } from "contexts/transactionsBase";
import useUserDebts from "../../hooks/useUserDebts";
import { buildQueryKey as buildQuoteForBuyingUnderlyingQueryKey } from "../../hooks/useQuoteForBuyingUnderlying";
import { buildQueryKey as buildUserHTokenDepositorBalanceQueryKey } from "../../hooks/useUserHTokenDepositorBalance";
import { buildQueryKey as buildHifiPoolLiquidityQueryKey } from "../../hooks/useHifiPoolLiquidity";
import { buildQueryKey as buildAllowanceQueryKey } from "hooks/useAllowance";
import { buildQueryKey as buildQuoteForBuyingHTokenQueryKey } from "../../hooks/useQuoteForBuyingHToken";
import { useQueryClient } from "react-query";
import { buildQueryKey as buildErc20BalanceQueryKey } from "hooks/useAccountErc20Balance";
import { AddressZero } from "@ethersproject/constants";
import BPromise from "bluebird";
import {
  ApproveCallbackArgs,
  BorrowCallbackArgs,
  CollateralCallbackArgs,
  LendCallbackArgs,
  PoolCallbackArgs,
} from "../../utils/transactions/types";
import { DefaultCallbackArgs } from "hooks/useTransactionComplete/types";

export default function TransactionHandlers({ children }: { children: React.ReactNode }): JSX.Element {
  const { chainName, account } = useContext(Web3Context);

  const { data: dsProxyAddress, refetch: refetchDSProxy } = useUserDSProxy({ chainName, account });
  const { refetch: refetchLockedCollaterals } = useUserLockedCollaterals({ chainName, account });
  const { refetch: refetchUserDebts } = useUserDebts({ chainName, account });
  const { setTransactionResult, getTransaction } = useContext(TransactionsContext);

  const queryClient = useQueryClient();

  useTransactionComplete(
    "lend",
    useCallback(
      async callbackArgs => {
        const { hTokenAddress, underlyingAddress, hifiPoolAddress } = callbackArgs as LendCallbackArgs &
          DefaultCallbackArgs;
        queryClient.refetchQueries(buildQuoteForBuyingHTokenQueryKey({ hifiPoolAddress, hTokenOut: "1" }));
        queryClient.refetchQueries(buildErc20BalanceQueryKey({ account, tokenAddress: hTokenAddress }));
        queryClient.refetchQueries(buildErc20BalanceQueryKey({ account, tokenAddress: underlyingAddress }));
      },
      [account, queryClient],
    ),
  );

  useTransactionComplete(
    "collateral",
    useCallback(
      async callbackArgs => {
        const { collateralAddress } = callbackArgs as CollateralCallbackArgs & DefaultCallbackArgs;

        refetchLockedCollaterals();
        queryClient.refetchQueries(buildErc20BalanceQueryKey({ account, tokenAddress: collateralAddress }));
      },
      [refetchLockedCollaterals, account, queryClient],
    ),
  );

  useTransactionComplete(
    "dsProxy",
    useCallback(
      async ({ id }) => {
        let proxyAddr: string | undefined = AddressZero;
        // In Polygon refetching the ds proxy address often returns AddressZero because the provider
        // has not yet udpated.  So we loop until we get a non-zero address.
        while (proxyAddr === AddressZero) {
          const { data } = await refetchDSProxy();
          proxyAddr = data;
          if (proxyAddr === AddressZero) {
            await BPromise.delay(500);
          }
        }
        setTransactionResult(id, proxyAddr);
      },
      [refetchDSProxy, setTransactionResult],
    ),
  );

  useTransactionComplete(
    "borrow",
    useCallback(
      async callbackArgs => {
        const { hifiPoolAddress, underlyingAddress } = callbackArgs as BorrowCallbackArgs & DefaultCallbackArgs;
        refetchUserDebts();
        queryClient.refetchQueries(buildQuoteForBuyingUnderlyingQueryKey({ hifiPoolAddress, underlyingOut: "1" }));
        queryClient.refetchQueries(buildErc20BalanceQueryKey({ account, tokenAddress: underlyingAddress }));
      },
      [queryClient, refetchUserDebts, account],
    ),
  );

  useTransactionComplete(
    "approve",
    useCallback(
      async callbackArgs => {
        const { spenderAddress, tokenAddress } = callbackArgs as ApproveCallbackArgs & DefaultCallbackArgs;
        if (spenderAddress === "DSPROXY_ADDRESS" && dsProxyAddress) {
          queryClient.refetchQueries(buildAllowanceQueryKey({ tokenAddress, spenderAddress: dsProxyAddress, account }));
        } else {
          queryClient.refetchQueries(buildAllowanceQueryKey({ tokenAddress, spenderAddress, account }));
        }
      },
      [dsProxyAddress, account, queryClient],
    ),
  );

  useTransactionComplete(
    "permit",
    useCallback(
      async ({ id }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rawResult: any = getTransaction(id)?.rawResult;
        setTransactionResult(id, {
          signature: `${rawResult.r}${rawResult.s.replace("0x", "")}${rawResult.v.toString(16)}`,
          deadline: rawResult.deadline,
        });
      },
      [setTransactionResult, getTransaction],
    ),
  );

  useTransactionComplete(
    "pool",
    useCallback(
      async callbackArgs => {
        const { hifiPoolAddress, underlyingAddress, hTokenAddress } = callbackArgs as PoolCallbackArgs &
          DefaultCallbackArgs;

        queryClient.refetchQueries(buildHifiPoolLiquidityQueryKey(hifiPoolAddress));
        queryClient.refetchQueries(buildErc20BalanceQueryKey({ account, tokenAddress: underlyingAddress }));
        queryClient.refetchQueries(buildErc20BalanceQueryKey({ account, tokenAddress: hifiPoolAddress }));
        queryClient.refetchQueries(buildErc20BalanceQueryKey({ account, tokenAddress: hTokenAddress }));
        if (dsProxyAddress) {
          queryClient.refetchQueries(
            buildUserHTokenDepositorBalanceQueryKey({ account: dsProxyAddress, tokenAddress: hTokenAddress }),
          );
        }
      },
      [queryClient, account, dsProxyAddress],
    ),
  );

  return <>{children}</>;
}
