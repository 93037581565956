import { useEffect, createContext, useReducer, useMemo, useState, useCallback } from "react";
import Web3Modal, { getProviderInfo, isMobile, verifyInjectedProvider } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider, InfuraProvider, JsonRpcProvider } from "@ethersproject/providers";
import { UNLOADED, LOADING, UNAVAILBLE, LOADED } from "../../constants/loadingStates";
import { default as MulticallBatcherImport } from "../../utils/MulticallBatcher";
import WalletLink from "walletlink";
import metaMask from "./Metamask";
import coinbase from "./Coinbase";
import {
  Web3ContextState,
  Web3Context,
  Web3ContextProviderProps,
  Web3ContextAction,
  UPDATE_STATE,
  SET_ACCOUNT,
  SET_CONNECTING,
} from "./types";

export const MulticallBatcher = MulticallBatcherImport;

export default function buildWeb3(
  defaultNetwork: string,
  infuraId: string,
): {
  Context: React.Context<Web3Context>;
  Provider: (props: Web3ContextProviderProps) => JSX.Element;
} {
  function getCustomProvider(chainName: string) {
    if (chainName === "matic") {
      return new JsonRpcProvider(`https://polygon-mainnet.infura.io/v3/${infuraId}`);
    }
    return new InfuraProvider(chainName, infuraId);
  }

  const initialProvider = getCustomProvider(defaultNetwork);
  MulticallBatcher.setup(initialProvider);

  const initialState: Web3ContextState = Object.freeze({
    account: "",
    provider: initialProvider,
    walletProvider: void 0,
    chainId: 1,
    chainName: defaultNetwork,
    status: UNLOADED,
    providerName: "",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let modalProvider: any;
  let onClose: () => void;
  let onAccountsChanged: (accounts: Array<string>) => void;
  let onChainChanged: (newNetwork: string, oldNetwork: string) => void;

  const Reducer = (state: Web3ContextState, action: Web3ContextAction): Web3ContextState => {
    switch (action.type) {
      case UPDATE_STATE:
        return Object.freeze({ ...state, ...action.payload });
      case SET_ACCOUNT:
        return Object.freeze({
          ...state,
          account: action.payload,
        });
      case SET_CONNECTING:
        return Object.freeze({
          ...state,
          status: action.payload,
        });
      default:
        return state;
    }
  };

  const disconnect = async (modal: Web3Modal, dispatch: React.Dispatch<Web3ContextAction>) => {
    if (modalProvider && modalProvider.removeAllListeners) {
      modalProvider.removeAllListeners();
      modalProvider = void 0;
    }

    // Remove cached wallet-connect data in case the user wants to switch networks
    localStorage.removeItem("walletconnect");

    MulticallBatcher.setup(initialProvider);

    await modal.clearCachedProvider();

    dispatch({
      type: UPDATE_STATE,
      payload: { ...initialState },
    });
  };

  const connect = async (
    state: Web3ContextState | null,
    modal: Web3Modal,
    dispatch: React.Dispatch<Web3ContextAction>,
  ) => {
    const { account, status } = state || {};
    if (state && !account && status !== LOADING) {
      try {
        dispatch({
          type: SET_CONNECTING,
          payload: LOADING,
        });
        modalProvider = await modal.connect();
        const walletProvider = new Web3Provider(modalProvider, "any");
        window.ethereum.autoRefreshOnNetworkChange = false;

        onClose = () => disconnect(modal, dispatch);
        modalProvider.on("disconnect", onClose);

        onAccountsChanged = async (accounts: Array<string>) => {
          if (accounts[0]) {
            dispatch({ type: SET_ACCOUNT, payload: accounts[0].toLowerCase() });
          } else {
            disconnect(modal, dispatch);
          }
        };
        modalProvider.on("accountsChanged", onAccountsChanged);

        onChainChanged = async (_newNetwork: string, oldNetwork: string) => {
          if (oldNetwork) {
            window.location.reload();
          }
        };
        walletProvider.on("network", onChainChanged);

        const { chainId, name } = (await walletProvider.getNetwork()) as { chainId: number; name: string };

        const { chainId: oldChainId } = await state.provider.getNetwork();
        const newProvider = getCustomProvider(name);
        if (oldChainId !== chainId) {
          MulticallBatcher.setup(newProvider);
        }
        const account = (await walletProvider.getSigner().getAddress()).toLowerCase();
        dispatch({
          type: UPDATE_STATE,
          payload: {
            account,
            walletProvider,
            chainId,
            chainName: name,
            providerName: getProviderInfo(modalProvider).name,
            status: LOADED,
            provider: newProvider,
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        const msg = e?.message || (typeof e === "string" ? e : "");
        if (
          // Metamask cancel
          msg.toLowerCase().includes("rejected") ||
          // Click away from Web3Modal or WalletConnect
          msg.toLowerCase().includes("closed") ||
          // Coinbase close scan
          e?.code === 4001
        ) {
          dispatch({
            type: SET_CONNECTING,
            payload: UNLOADED,
          });
        } else {
          dispatch({
            type: SET_CONNECTING,
            payload: UNAVAILBLE,
          });
        }
      }
    }
  };

  const reconnect = async (modal: Web3Modal, dispatch: React.Dispatch<Web3ContextAction>) => {
    dispatch({
      type: SET_CONNECTING,
      payload: LOADING,
    });
    await disconnect(modal, dispatch);
    dispatch({
      type: UPDATE_STATE,
      payload: { ...initialState, status: LOADING },
    });
    await connect(initialState, modal, dispatch);
  };

  const Context = createContext<Web3Context>({
    ...initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    connect: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    disconnect: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reconnect: () => {},
    connecting: false,
    providerOptions: {},
    initialized: false,
  });

  let modal: Web3Modal;
  const Provider = ({
    children,
    walletModalTheme,
    onConnect,
    onAccountChange,
  }: Web3ContextProviderProps): JSX.Element => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const [initialized, setInitialized] = useState(false);

    const hasMetaMask = verifyInjectedProvider("isMetaMask");
    const providerOptions = useMemo(() => {
      const installMetaMaskOption = {
        ["custom-example" as string]: {
          display: {
            logo: metaMask,
            name: "Install MetaMask",
            description: isMobile()
              ? "After installing, open the browser inside the MetaMask mobile app."
              : "After installing, refresh your browser.",
          },
          package: {},
          connector: async () => {
            setTimeout(() => {
              window.open("https://metamask.io/download");
              localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
              if (modal) {
                modal.setCachedProvider("");
              }
            });
          },
        },
      };

      return Object.freeze({
        ...(hasMetaMask ? {} : installMetaMaskOption),
        ["walletconnect" as string]: {
          package: WalletConnectProvider,
          options: {
            infuraId,
          },
        },
        "custom-coinbase": {
          display: {
            logo: coinbase,
            name: "Coinbase",
            description: isMobile() ? "Open in Coinbase Wallet" : "Scan with Coinbase Wallet to connect",
          },
          options: {
            networkUrl: `https://mainnet.infura.io/v3/${infuraId}`,
            // chainId: CHAIN_ID,
          },
          package: WalletLink,
          // @ts-ignore
          connector: async (_, options) => {
            const { appName, networkUrl, chainId } = options;
            const walletLink = new WalletLink({
              appName,
            });
            const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
            await provider.enable();
            return provider;
          },
        },
      });
    }, [hasMetaMask]);

    modal = useMemo(() => {
      const theme = walletModalTheme ? { theme: walletModalTheme } : {};
      return new Web3Modal({
        cacheProvider: true,
        providerOptions,
        ...theme,
      });
    }, [providerOptions, walletModalTheme]);

    const status = state.status;
    useEffect(() => {
      // On App load
      async function init() {
        if (modal && modal.cachedProvider) {
          if (status === UNLOADED) {
            try {
              await connect(initialState, modal, dispatch);
            } finally {
              setInitialized(true);
            }
          }
        } else {
          setInitialized(true);
        }
      }
      init();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const { account } = state;
    useEffect(() => {
      if (onAccountChange) {
        onAccountChange(account);
      }
    }, [account, onAccountChange]);

    const _reconnect = reconnect.bind(null, modal, dispatch);
    const _connect = useCallback(async () => {
      try {
        if (!onConnect || onConnect()) {
          await connect(state, modal, dispatch);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, modal]);

    return (
      <Context.Provider
        value={{
          ...state,
          connecting: state.status === LOADING,
          connect: _connect,
          disconnect: disconnect.bind(null, modal, dispatch),
          reconnect: _reconnect,
          providerOptions,
          initialized,
        }}
      >
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
}
