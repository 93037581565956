import { formatUnits, parseUnits } from "@ethersproject/units";
import { truncateDecimals } from "utils/numbers";
import { BigNumber } from "ethers";
import { compact } from "lodash";
import { MutationStatus, QueryObserverResult, useQueries } from "react-query";
import useCollateralMap from "../useCollateralMap";
import { buildQueryObject } from "../useTokenPrice";
import useUserEligibleLockedCollaterals from "../useUserEligibleLockedCollaterals";
import get from "lodash/get";
import { useCallback, useMemo } from "react";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { UserLockedCollaterals } from "../../api/getUserLockedCollaterals/types";
import { CollateralMap } from "../useCollateralMap/types";

export default function useUserTotalCollateraValueInHTokens({
  chainName,
  account,
}: {
  chainName: string;
  account: string;
}): { status: MutationStatus; data: undefined | BigNumber; isFetching: boolean } {
  const eligibleLockedCollateralsRes = useUserEligibleLockedCollaterals({ chainName, account });
  const { data: userCollaterals, status: userLockedCollateralsStatus } = eligibleLockedCollateralsRes;

  const collateralMapRes = useCollateralMap(chainName);
  const {
    data: collateralMap,
    isSuccess: collateralMapSuccess,
    isFetching: collateralMapIsFetching,
  } = collateralMapRes;

  const userLockedCollateralAddresses = useMemo(() => {
    return Object.keys(userCollaterals || []);
  }, [userCollaterals]);

  // Build query objects for the price of each of user's locked collateral types
  const tokenPriceQueryObjects =
    useMemo(() => {
      if (collateralMapSuccess && !collateralMapIsFetching && userLockedCollateralsStatus === "success") {
        return compact(
          userLockedCollateralAddresses.map(collateralAddress => {
            if (collateralMap && collateralMap[collateralAddress]) {
              return {
                ...buildQueryObject({ chainName, symbol: collateralMap[collateralAddress].symbol }),
                retry: false,
                refetchOnWindowFocus: false,
              };
            }
          }),
        );
      }
    }, [
      chainName,
      collateralMap,
      collateralMapIsFetching,
      collateralMapSuccess,
      userLockedCollateralAddresses,
      userLockedCollateralsStatus,
    ]) || [];

  // Run query for token prices
  const tokenPriceQueries = useQueries(tokenPriceQueryObjects) as QueryObserverResult<string>[];

  return useCombinedQueryHooks(
    [eligibleLockedCollateralsRes, collateralMapRes, ...tokenPriceQueries],
    useCallback(
      data => {
        const [userCollaterals, collateralMap, ...tokenPrices] = data as [
          UserLockedCollaterals,
          CollateralMap,
          ...string[]
        ];
        if (userCollaterals && collateralMap) {
          const data = userLockedCollateralAddresses.reduce((memo, lockedCollateralAddress, i) => {
            const collateralPrice = get(tokenPrices, [i]) || "0";
            const collateralLocked = userCollaterals[lockedCollateralAddress] || "0";
            return memo.add(parseUnits(collateralPrice, 18).mul(parseUnits(collateralLocked, 18)));
          }, BigNumber.from("0"));
          return parseUnits(truncateDecimals(formatUnits(data, 18 * 2), 18), 18);
        }
      },
      [userLockedCollateralAddresses],
    ),
  );
}
