import { BigNumber } from "ethers";
import { QueryObserverResult, useQuery } from "react-query";
import getMaxBonds from "../../api/getMaxBonds";

export default function useMaxBonds(chainName: string): QueryObserverResult<BigNumber | undefined> {
  return useQuery(["maxBonds", chainName], () => getMaxBonds(chainName), {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
