import { QueryObserverResult, useQuery } from "react-query";
import getAllowance from "../../api/getAllowance";
import { Erc20Token } from "../../api/getErc20/types";

export function buildQueryKey({
  tokenAddress,
  spenderAddress,
  account,
}: {
  tokenAddress: string;
  spenderAddress: string;
  account: string;
}): [string, string, string, string] {
  return ["allowance", tokenAddress, spenderAddress, account];
}

export default function useAllowance({
  token,
  spenderAddress = "",
  account = "",
}: {
  token: Erc20Token;
  spenderAddress?: string;
  account?: string;
}): QueryObserverResult<string> {
  const { status, data, ...others } = useQuery(
    buildQueryKey({ tokenAddress: token.id, spenderAddress, account }),
    () => getAllowance({ tokenAddress: token.id, spenderAddress, account }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!spenderAddress && !!account,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  if (status === "success" && data) {
    return { data: data.toString(), status, ...others } as QueryObserverResult<string>;
  }
  return { status, data, ...others } as QueryObserverResult<string>;
}
