import SvgIcon from "@mui/material/SvgIcon";

export default function CRE1p({ style }: { style?: React.CSSProperties }): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 64 65" style={style}>
      <circle cx="32" cy="32.687" r="32" fill="#101010" />
      <path
        d="M51.6675 42.1154L32.0641 53.5084L12.3325 42.1154L31.9359 30.7241L51.6675 42.1154Z"
        fill="#101010"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M51.6675 39.9393L32.0641 51.3305L12.3325 39.9393L31.9359 28.5463L51.6675 39.9393Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.6675 42.1153V39.9393L32.0641 51.3306V53.5083L51.6675 42.1153ZM12.3325 42.1154V39.9394L32.0641 51.3307V53.5084L12.3325 42.1154Z"
        fill="#D2D2D2"
      />
      <path
        d="M44.9037 39.9393L32.0415 47.4133L19.0962 39.9393L31.9584 32.4652L44.9037 39.9393Z"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.9037 21.0722L32.0415 28.5463L19.0962 21.0722L31.9584 13.5982L44.9037 21.0722Z"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0962 21.0722V39.9393L32.0415 47.4133V28.5463L19.0962 21.0722Z"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0962 21.0722V39.9393L32.0415 47.4133V28.5463L19.0962 21.0722Z"
        fill="#101010"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19.0937 21.5243V39.9564L32.0038 47.4838V28.9544L19.0937 21.5243Z" fill="#9F71FF" />
      <path d="M19.0937 21.5243V39.9564L32.0038 47.4838V28.9544L19.0937 21.5243Z" fill="#482299" />
      <path
        d="M44.9037 21.0722V39.9393L32.0415 47.4133V28.5463L44.9037 21.0722Z"
        fill="#101010"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0962 39.9393L32.0415 47.4134V45.6358L19.0962 38.16V39.9393Z"
        fill="#482299"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0415 47.4134L44.9037 39.9393V38.16L32.0415 45.6358V47.4134Z"
        fill="#101010"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5778 19.7313L32.0451 27.5969L18.4224 19.7313L31.955 11.8657L45.5778 19.7313Z"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4224 19.7312V21.0722L32.0451 28.9378V27.5968L18.4224 19.7312Z"
        fill="#3E1B8A"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5776 19.7312V21.0722L32.0449 28.9378V27.5968L45.5776 19.7312Z"
        fill="#63479C"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5778 19.7313L32.0451 27.5969L18.4224 19.7313L31.955 11.8657L45.5778 19.7313Z"
        fill="#63479C"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M44.9038 21.4834L44.9038 39.967L32.0563 47.373V28.9022L44.9038 21.4834Z" fill="#9F71FF" />
      <path
        d="M44.9038 21.4834L44.9038 39.967L32.0563 47.373V28.9022L44.9038 21.4834Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M44.9036 39.9394L32.0959 47.4134V45.6359L44.9036 38.1601V39.9394Z"
        fill="#7F5ACC"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.6556 42.483L36.4658 44.902V38.5883L40.6556 36.1693V42.483Z"
        fill="#AB8CED"
        stroke="white"
        strokeOpacity="0.75"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.896 20.4L32.0364 26.2542L42.104 20.4035L31.9636 14.5493L21.896 20.4Z"
        fill="#7F5ACC"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2631 19.7313L31.9636 13.2066V14.5493L42.104 20.4035L43.2631 19.7313Z"
        fill="#3E1B8A"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9635 13.2066V14.5493L21.8959 20.4L20.7368 19.7313L31.9635 13.2066Z"
        fill="#3E1B8A"
        stroke="#6E2EF4"
        strokeWidth="0.173251"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
