import { useMemo } from "react";
import useWindowBreakpoint from "hooks/useWindowBreakpoint";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import DisclaimerDialog from "./components/DisclaimerDialog";
import AppContainer from "./containers/AppContainer";
import Providers from "./contexts";
import { BrowserRouter as Router } from "react-router-dom";
import createTheme from "./theme";
import TransactionHandlers from "./containers/TransactionHandlers";
import { logEvent } from "./utils/analytics";

window.onerror = function (message, source, lineno, colno, error) {
  logEvent("jsError", {
    message: message || "",
    source: source || "",
    lineno: lineno || "",
    colno: colno || "",
    stack: error?.stack || "",
    userAgent: navigator.userAgent || "",
  });
};

export default function App(): JSX.Element {
  const bp = useWindowBreakpoint();
  const theme = useMemo(() => {
    return createTheme(bp);
  }, [bp]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Providers theme={theme}>
          <TransactionHandlers>
            <AppContainer />
          </TransactionHandlers>
        </Providers>
      </Router>
      {/* <DisclaimerDialog /> */}
    </ThemeProvider>
  );
}
