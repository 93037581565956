import { MulticallBatcher } from "contexts/web3Base";
import { getMulticallContractByName } from "../../utils/contracts";
import { formatUnits } from "@ethersproject/units";

const getTokenPrice = async ({ symbol, chainName }: { symbol: string; chainName: string }): Promise<string> => {
  const oracleContract = getMulticallContractByName({ contractName: "oracle", chainName });
  // work-around since WETH is displayed as ETH
  symbol = symbol.toLowerCase() === "eth" ? "WETH" : symbol;
  return formatUnits(await MulticallBatcher.queue(oracleContract.getPrice(symbol)), 8);
};

export default getTokenPrice;
