import { MulticallBatcher } from "contexts/web3Base";
import deepFreeze from "utils/deepFreeze";
import { getHifiPoolMulticallContract, getHTokenMulticallContract } from "../../utils/contracts";
import { HifiPoolLiquidity } from "./types";

const getHifiPoolLiquidity = async ({
  hifiPoolAddress,
  hTokenAddress,
}: {
  hifiPoolAddress: string;
  hTokenAddress: string;
}): Promise<HifiPoolLiquidity> => {
  return new Promise(async (resolve, reject) => {
    try {
      const hifiPoolContract = getHifiPoolMulticallContract({ address: hifiPoolAddress });
      const hTokenContract = getHTokenMulticallContract({ address: hTokenAddress });
      const res = await Promise.all([
        MulticallBatcher.queue(hTokenContract.balanceOf(hifiPoolAddress)),
        MulticallBatcher.queue(hifiPoolContract.totalSupply()),
        MulticallBatcher.queue(hifiPoolContract.getNormalizedUnderlyingReserves()),
      ]);
      resolve(
        deepFreeze({
          hTokenReserves: res[0],
          totalLPTokenSupply: res[1],
          normalizedUnderlyingReserves: res[2],
        }),
      );
    } catch (e) {
      reject(e);
    }
  });
};

export default getHifiPoolLiquidity;
