import { Provider, Call } from "ethcall";
import debounce from "lodash/debounce";
import { IMulticallBatcher, Instance, Resolve, Reject } from "./types";
import { BaseProvider } from "@ethersproject/providers";

export default class MulticallBatcher implements IMulticallBatcher {
  static instance: Instance = null;
  resolves: Resolve[];
  rejects: Reject[];
  callPromises: Call[];
  deactivated: boolean;
  multicallProvider: Provider;
  initPromise: Promise<void>;

  static setup(provider: BaseProvider): void {
    if (MulticallBatcher.instance) {
      MulticallBatcher.instance.deactivate();
    }
    MulticallBatcher.instance = new MulticallBatcher(provider);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static queue(contractCallPromise: Call): Promise<any> | undefined {
    if (MulticallBatcher.instance) {
      return MulticallBatcher.instance.queue(contractCallPromise);
    } else {
      throw new Error("MulticallBatcher.setup must be called first");
    }
  }

  constructor(provider: BaseProvider) {
    this.resolves = [];
    this.rejects = [];
    this.callPromises = [];
    this.deactivated = false;
    this.multicallProvider = new Provider();
    this.initPromise = this.multicallProvider.init(provider);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async queue(contractCallPromise: Call): Promise<any> {
    await this.initPromise;

    this.callPromises.push(contractCallPromise);
    this.flush();
    return new Promise((resolve, reject) => {
      this.resolves.push(resolve);
      this.rejects.push(reject);
    });
  }

  flush = debounce(async () => {
    const resolves = this.resolves;
    const rejects = this.rejects;
    const callPromises = this.callPromises;

    this.resolves = [];
    this.rejects = [];
    this.callPromises = [];

    try {
      if (!this.deactivated) {
        const results = await this.multicallProvider.tryAll(callPromises);
        if (!this.deactivated) {
          results.forEach((res, i) => {
            if (res === null) {
              rejects[i](new Error(`Reverted: ${callPromises[i].name}(${JSON.stringify(callPromises[i].params)})`));
            } else {
              resolves[i](res);
            }
          });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      rejects.forEach(reject => {
        reject(e);
      });
    }
  });

  deactivate(): void {
    this.deactivated = true;
    this.resolves = [];
    this.rejects = [];
    this.callPromises = [];
  }
}
