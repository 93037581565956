import { memo } from "react";
import Table, { TableContainer } from "../../components/Table";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { HifiPool } from "../../api/getHifiPools/types";
import IconTypography from "../../components/IconTypography";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import TableHeader from "../../components/TableHeader";
import InfoIcon from "../../components/InfoIcon";
import MyBorrowRow from "../../containers/MyBorrowRow";

function MyDebtsTable({
  debts,
  onMarketClick,
}: {
  debts: {
    hifiPool: HifiPool;
    balance: string;
  }[];
  onMarketClick: (hifiPool: HifiPool) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <TableHeader variant="body2">{t("Borrowing")}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body1" color="textSecondary">
                  {t("Asset")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Maturity Date")}
                </Typography>
              </TableCell>{" "}
              <TableCell align="right">
                <IconTypography variant="body1" color="textSecondary">
                  {t("APY")}
                  <Tooltip title={t("tooltips.borrow.apy") as string} arrow placement="top">
                    <InfoIcon tabIndex={0} />
                  </Tooltip>
                </IconTypography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Balance")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {debts.map(debt => {
              return (
                <MyBorrowRow
                  onMarketClick={onMarketClick}
                  hifiPool={debt.hifiPool}
                  key={debt.hifiPool.id}
                  balance={debt.balance}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(MyDebtsTable);
