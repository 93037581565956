import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Dialog } from "../../components/ActionDialog";
import { HifiPool } from "../../api/getHifiPools/types";
import usePendingTransactionUI from "../../hooks/usePendingTransactionUI";
import SingleTaskPanel from "../../components/SingleTaskPanel";
import useUserErc20Balance from "hooks/useUserErc20Balance";
import { Web3Provider } from "@ethersproject/providers";
import { useTranslation } from "react-i18next";
import LendTab from "../LendTab";
import SellTab from "../SellTab";
import { truncateDecimals } from "utils/numbers";

export default function ManageLendDialog({
  onCloseClick,
  actionType = "lend",
  hifiPool,
  account,
  chainName,
  walletProvider,
  connect,
  connecting,
}: {
  onCloseClick: () => void;
  actionType?: "lend" | "sell";
  hifiPool: HifiPool;
  account: string;
  chainName: string;
  walletProvider?: Web3Provider;
  connect: () => void;
  connecting: boolean;
}): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(actionType === "lend" ? 0 : 1);
  const [lendInput, setLendInput] = useState("");
  const [paybackInput, setSellInput] = useState("");

  const { t } = useTranslation();

  const { showTx, transaction, setPendingTxId } = usePendingTransactionUI(onCloseClick);

  const { data: hTokenBalance } = useUserErc20Balance({
    token: hifiPool.hToken,
    account,
    walletProvider,
  });

  const _onClose = useCallback(
    (_evt, _reason) => {
      if (_reason !== "backdropClick" || !transaction || !showTx) {
        onCloseClick();
      }
    },
    [onCloseClick, transaction, showTx],
  );

  // Do not allow the selling of hTokens if they are dusty with respect to underlying decimals
  const truncatedFloat = parseFloat(truncateDecimals(hTokenBalance || "0", hifiPool.hToken.underlying.decimals));

  return (
    <Dialog onClose={_onClose} open={!transaction}>
      {transaction && showTx && (
        <DialogContent>
          <SingleTaskPanel chainName={chainName} onClose={onCloseClick} transaction={transaction} />
        </DialogContent>
      )}
      {!showTx && (
        <>
          <Box>
            <Tabs
              value={selectedTab}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="inherit"
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <Tab disableRipple label={<Typography variant="h4">{t("Lend")}</Typography>} />
              <Tab
                disabled={truncatedFloat === 0}
                disableRipple
                label={<Typography variant="h4">{t("Sell")}</Typography>}
              />
            </Tabs>
            <Box position="relative" />
          </Box>
          <DialogContent>
            {selectedTab === 0 && (
              <LendTab
                onCloseClick={onCloseClick}
                hifiPool={hifiPool}
                account={account}
                chainName={chainName}
                walletProvider={walletProvider}
                connect={connect}
                connecting={connecting}
                setPendingTxId={setPendingTxId}
                setLendInput={setLendInput}
                lendInput={lendInput}
              />
            )}
            {selectedTab === 1 && (
              <SellTab
                onCloseClick={onCloseClick}
                hifiPool={hifiPool}
                account={account}
                chainName={chainName}
                walletProvider={walletProvider}
                setPendingTxId={setPendingTxId}
                setSellInput={setSellInput}
                paybackInput={paybackInput}
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
