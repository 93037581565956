import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { QueryObserverResult, QueryStatus, useQueries, UseQueryOptions } from "react-query";
import isExpired from "../../utils/isExpired";
import useHifiPools from "../useHifiPools";
import { buildQueryObject } from "../useQuoteForBuyingHToken";
import useUserDebts from "../useUserDebts";
import useCombinedQueryHooks from "hooks/useCombinedQueryHooks";
import { UserDebts } from "../../api/getUserDebts/types";
import { HifiPool } from "../../api/getHifiPools/types";

export default function useUserTotalDebtInUnderlying({ chainName, account }: { chainName: string; account: string }): {
  data: BigNumber | undefined;
  status: QueryStatus;
  isFetching: boolean;
} {
  const userDebtsRes = useUserDebts({
    chainName,
    account,
  });
  const { data: debts, status: debtsStatus, isFetching: debtsIsFetching } = userDebtsRes;

  const hifiPoolsRes = useHifiPools(chainName);
  const { data: hifiPools, status: hifiPoolsStatus, isFetching: hifiPoolsIsFetching } = hifiPoolsRes;

  const nonExpiredDebts = useMemo(() => {
    if (hifiPoolsStatus === "success" && debtsStatus === "success") {
      return compact(
        Object.keys(debts || []).map(hTokenAddress => {
          const pool = hifiPools?.find(p => p.hToken.id === hTokenAddress);
          if (pool && debts && !isExpired(pool)) {
            return {
              debt: debts[hTokenAddress],
              hifiPool: pool,
            };
          }
        }),
      );
    }
    return [];
  }, [debts, hifiPools, hifiPoolsStatus, debtsStatus]);

  // Build query objects for the price of each of user's locked collateral types
  const underlyingValueQueryObjects = useMemo(() => {
    if (debtsStatus === "success" && !debtsIsFetching && hifiPoolsStatus === "success" && !hifiPoolsIsFetching) {
      return nonExpiredDebts.map(({ hifiPool, debt }) => {
        return {
          ...buildQueryObject({ hifiPool, hTokenOut: debt }),
          enabled: !!debt && parseUnits(debt, hifiPool.hToken.decimals).gte(BigNumber.from("0")),
        };
      }) as UseQueryOptions[];
    }
  }, [debtsIsFetching, debtsStatus, hifiPoolsIsFetching, hifiPoolsStatus, nonExpiredDebts]);

  // Run query for token prices
  const underlyingValueQueries = useQueries(underlyingValueQueryObjects || []) as QueryObserverResult<BigNumber>[];

  return useCombinedQueryHooks(
    [userDebtsRes, hifiPoolsRes, ...underlyingValueQueries],
    useCallback(
      data => {
        const [debts, hifiPools, ...underlyingValue] = data as [UserDebts, HifiPool[], ...BigNumber[]];
        const nonExpiredSum = underlyingValue.reduce((memo, underlyingValue, i) => {
          // If there is no underlyingValue.data, that means the query failed for this pool (insufficient liquidity); and
          // in this case we just use the hToken debt for this pool and convert it into underlying decimals.
          return memo.add(
            underlyingValue ||
              parseUnits(nonExpiredDebts[i].debt, nonExpiredDebts[i].hifiPool.hToken.decimals).div(
                nonExpiredDebts[i].hifiPool.underlyingPrecisionScalar,
              ),
          );
        }, BigNumber.from("0"));

        const expiredSum = Object.keys(debts || []).reduce((memo, hTokenAddress) => {
          const pool = hifiPools?.find(p => p.hToken.id === hTokenAddress);
          if (pool && isExpired(pool) && debts) {
            return memo.add(parseUnits(debts[hTokenAddress], pool.hToken.decimals).div(pool.underlyingPrecisionScalar));
          }
          return memo;
        }, BigNumber.from("0"));

        return nonExpiredSum.add(expiredSum);
      },
      [nonExpiredDebts],
    ),
  );
}
