import { formatUnits } from "@ethersproject/units";
import { QueryObserverResult } from "react-query";
import { HifiPool } from "../../api/getHifiPools/types";
import useMemoRef from "hooks/useMemoRef";
import useHifiPoolLiquidity from "../useHifiPoolLiquidity";

export default function useHifiPoolTotalLiquidityInUnderlying(
  hifiPool: HifiPool,
): QueryObserverResult<number | undefined> {
  const { data, status, ...others } = useHifiPoolLiquidity(hifiPool);

  const _data = useMemoRef(
    lastValue => {
      if (status === "success" && data) {
        const total = data.hTokenReserves.add(data.normalizedUnderlyingReserves);
        return parseFloat(formatUnits(total, hifiPool.decimals));
      }
      return lastValue;
    },
    [data, status, hifiPool],
  );

  return { data: _data, status, ...others } as QueryObserverResult<number | undefined>;
}
