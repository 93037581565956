import SvgIcon from "@mui/material/SvgIcon";
import { USDCProps } from "./types";

export default function USDC({ style }: USDCProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" style={style}>
      <g>
        <circle fill="#2775CA" cx="12" cy="12" r="12" />
        <path
          fill="#FFFFFF"
          d="M9.8,20.3c0,0.3-0.2,0.4-0.5,0.4C5.6,19.5,3,16.1,3,12.1c0-4,2.6-7.4,6.3-8.6c0.3-0.1,0.5,0.1,0.5,0.4v0.7
      c0,0.2-0.1,0.4-0.3,0.5c-2.9,1.1-4.9,3.8-4.9,7c0,3.2,2.1,6,4.9,7c0.2,0.1,0.3,0.3,0.3,0.5V20.3z"
        />
        <path
          fill="#FFFFFF"
          d="M12.8,17.8c0,0.2-0.2,0.4-0.4,0.4h-0.8c-0.2,0-0.4-0.2-0.4-0.4v-1.2c-1.6-0.2-2.4-1.1-2.7-2.4
      c0-0.2,0.1-0.4,0.3-0.4h0.9c0.2,0,0.3,0.1,0.4,0.3c0.2,0.7,0.6,1.3,1.9,1.3c1,0,1.7-0.5,1.7-1.3c0-0.8-0.4-1.1-1.8-1.3
      c-2.1-0.3-3.1-0.9-3.1-2.6c0-1.3,1-2.3,2.4-2.5V6.5c0-0.2,0.2-0.4,0.4-0.4h0.8c0.2,0,0.4,0.2,0.4,0.4v1.2c1.2,0.2,2,0.9,2.2,2
      c0,0.2-0.1,0.4-0.3,0.4h-0.8c-0.2,0-0.3-0.1-0.4-0.3c-0.2-0.7-0.7-1-1.6-1c-1,0-1.5,0.5-1.5,1.2c0,0.7,0.3,1.1,1.8,1.3
      c2.1,0.3,3.1,0.9,3.1,2.6c0,1.3-1,2.4-2.5,2.7V17.8z"
        />
        <path
          fill="#FFFFFF"
          d="M14.7,20.7c-0.3,0.1-0.5-0.1-0.5-0.4v-0.7c0-0.2,0.1-0.4,0.3-0.5c2.9-1.1,4.9-3.8,4.9-7c0-3.2-2.1-6-4.9-7
      c-0.2-0.1-0.3-0.3-0.3-0.5V3.9c0-0.3,0.2-0.4,0.5-0.4c3.6,1.2,6.3,4.6,6.3,8.6C21,16.1,18.4,19.5,14.7,20.7z"
        />
      </g>
    </SvgIcon>
  );
}
