import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const INSUFFICIENT_FUNDS = "insufficient";

type ErrorType = typeof INSUFFICIENT_FUNDS;

export default function useLiquidityError({
  debouncedAddLiquidityInput,
  insufficientFunds,
}: {
  debouncedAddLiquidityInput: string;
  insufficientFunds: boolean;
}): string {
  const [error, setError] = useState<ErrorType | "">("");

  const { t } = useTranslation();

  const errorsMsgs = {
    [INSUFFICIENT_FUNDS]: t("errors.insufficientFunds"),
  };

  useEffect(() => {
    const newErrors: (ErrorType | "")[] = [];
    if (insufficientFunds && !!debouncedAddLiquidityInput) {
      newErrors.push(INSUFFICIENT_FUNDS);
    }

    if (!newErrors.length) {
      setError("");
    } else if (!newErrors.includes(error)) {
      setError(newErrors[0]);
    }
  }, [debouncedAddLiquidityInput, insufficientFunds, error]);

  return error ? errorsMsgs[error] : "";
}
