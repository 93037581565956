import { Web3Provider } from "@ethersproject/providers";
import { formatUnits } from "@ethersproject/units";
import { useEffect } from "react";
import { QueryObserverResult, useQuery } from "react-query";
import getUserEthBalance from "../../api/getUserEthBalance";
import useMemoRef from "../useMemoRef";

export default function useUserEthBalance({
  account,
  walletProvider,
}: {
  account: string;
  walletProvider: Web3Provider | undefined;
}): QueryObserverResult<string> {
  const {
    refetch,
    data: bal,
    status,
    ...others
  } = useQuery(
    [account],
    () => {
      return getUserEthBalance(walletProvider);
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!account,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  if (walletProvider) {
    walletProvider.on("block", () => {
      setTimeout(async () => {
        // I suspect that the updated balance is not quite ready yet in the wallet;
        // so wait a bit before fetching balance.
        try {
          // Wrap with try/catch in case the user changes the account they are on; seems that this should
          // be handled by the walletProvider.removeAllListeners(evt) below
          await refetch();
          // eslint-disable-next-line
        } catch (e) {}
      }, 2000);
    });
  }

  useEffect(() => {
    return () => {
      if (walletProvider) {
        try {
          walletProvider.removeAllListeners("block");

          // eslint-disable-next-line
        } catch (e) {}
      }
    };
  }, [account, walletProvider]);

  const _data = useMemoRef(
    lastValue => {
      if (status === "success" && bal) {
        return formatUnits(bal);
      }
      return lastValue;
    },
    [bal, status],
  );

  return { refetch, data: _data, status, ...others } as QueryObserverResult<string>;
}
