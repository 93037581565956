import { useEffect, useState } from "react";
import { BALANCE, ErrorType, LIQUIDITY, OWED, UsePaybackErrorProps } from "./types";
import { useTranslation } from "react-i18next";

export default function usePaybackError({
  debouncedPaybackInputBn,
  debtInUnderlyingBn,
  underlyingBalanceBn,
  hTokensRepaidIsError,
  hTokensRepaidIsLoading,
}: UsePaybackErrorProps): string {
  const [error, setError] = useState<ErrorType | "">("");

  const { t } = useTranslation();

  const errorsMsgs = {
    [OWED]: t("errors.exceedsAmountOwed"),
    [BALANCE]: t("errors.balanceExceeded"),
    [LIQUIDITY]: t("errors.insufficientLiquidity"),
  };

  useEffect(() => {
    const newErrors: (ErrorType | "")[] = [];
    if (!hTokensRepaidIsLoading) {
      if (debouncedPaybackInputBn && debtInUnderlyingBn && debouncedPaybackInputBn.gt(debtInUnderlyingBn)) {
        newErrors.push(OWED);
      }
      if (debouncedPaybackInputBn && underlyingBalanceBn && debouncedPaybackInputBn.gt(underlyingBalanceBn)) {
        newErrors.push(BALANCE);
      }
      if (hTokensRepaidIsError) {
        newErrors.push(LIQUIDITY);
      }

      if (!newErrors.length) {
        setError("");
      } else if (!newErrors.includes(error)) {
        setError(newErrors[0]);
      }
    }
  }, [
    debouncedPaybackInputBn,
    debtInUnderlyingBn,
    underlyingBalanceBn,
    hTokensRepaidIsError,
    error,
    hTokensRepaidIsLoading,
  ]);

  return error ? errorsMsgs[error] : "";
}
