import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Copy(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" {...props}>
      <rect fill="none" x="9" y="9" width="13" height="13" rx="2" ry="2" />
      <path fill="none" d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
    </SvgIcon>
  );
}
