import { memo } from "react";
import Table, { TableContainer } from "../../components/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableHeader from "../../components/TableHeader";
import { useTranslation } from "react-i18next";
import { Collateral } from "../../api/getCollaterals/types";
import MyCollateralRow from "../MyCollateralRow";

function MyCollateralsTable({
  collaterals,
  onCollateralClick,
}: {
  collaterals: { collateral: Collateral; amountLocked: string }[];
  onCollateralClick: (collateral: Collateral) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <TableHeader variant="body2">{t("My Collateral")}</TableHeader>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="body1" color="textSecondary">
                  {t("Asset")}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" color="textSecondary">
                  {t("Balance")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collaterals.map(lockedCollateral => {
              return (
                <MyCollateralRow
                  key={lockedCollateral.collateral.id}
                  collateral={lockedCollateral.collateral}
                  amountLocked={lockedCollateral.amountLocked}
                  onCollateralClick={onCollateralClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default memo(MyCollateralsTable);
